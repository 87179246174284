var _QlSelectSubOrganizationSearchQuery, _QlSelectSubOrganizationChildrenQuery;
import CtApiSuspense from "components/containers/CtApiSuspense";
import CtGraphqlNode from "components/containers/CtGraphqlNode";
import React from "react";
import { graphql } from "react-relay";
/**
 * Used to search all descendant organizations
 */
export const QlSelectSubOrganizationSearchQueryRequest = _QlSelectSubOrganizationSearchQuery !== void 0 ? _QlSelectSubOrganizationSearchQuery : _QlSelectSubOrganizationSearchQuery = require("./__generated__/QlSelectSubOrganizationSearchQuery.graphql");

/**
 * Used to search all descendant organizations
 */

export const QlSelectSubOrganizationChildrenQueryRequest = _QlSelectSubOrganizationChildrenQuery !== void 0 ? _QlSelectSubOrganizationChildrenQuery : _QlSelectSubOrganizationChildrenQuery = require("./__generated__/QlSelectSubOrganizationChildrenQuery.graphql");

/**
 * Query loader that will either show all the child orgs, or will search all descendants of an org
 */
const QlSelectSubOrganization = ({
  children,
  commonVariables,
  organizationFilters
}) => {
  return <CtApiSuspense>
      {organizationFilters ? <CtGraphqlNode query={QlSelectSubOrganizationSearchQueryRequest} render={response => children(response?.node?.descendants)} variables={{
      ...commonVariables,
      organizationFilters
    }} /> : <CtGraphqlNode query={QlSelectSubOrganizationChildrenQueryRequest} render={response => children(response?.node?.children)} variables={commonVariables} />}
    </CtApiSuspense>;
};
export default QlSelectSubOrganization;