import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { View } from "react-native";
import CpItem from "components/common/CpItem";
import { thumbnailButtonSizes } from "components/common/CpThumbnailItem";
import PrIcon from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import useScreenLayout from "hooks/useScreenLayout";
import { css, rnStyles, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  buttonContainer: css`
    background-color: ${({
    theme
  }) => theme.buttonColors.surface};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    overflow: hidden;
  `,
  icon: css`
    position: absolute;
    right: 0px;
    transform: rotate(15deg);
  `,
  label: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
  `
};
const largeStyles = {
  buttonContainer: css`
    ${localStyles.buttonContainer};
    height: ${thumbnailButtonSizes.smallButton.height}px;
    margin-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
    width: ${thumbnailButtonSizes.largeButton.width}px;
  `,
  icon: css`
    ${localStyles.icon};
    bottom: ${({
    theme
  }) => 0 - 5 * theme.spacing.half}px;
  `,
  innerContainer: css`
    ${styles.rowAndCenter};
    height: ${({
    theme
  }) => thumbnailButtonSizes.smallButton.height - theme.spacing.double}px;
  `,
  label: css`
    ${localStyles.label};
    text-align: center;
  `
};
const mediumStyles = {
  buttonContainer: css`
    ${localStyles.buttonContainer};
    height: ${thumbnailButtonSizes.mediumButton.height}px;
    margin: ${({
    theme
  }) => theme.spacing.single}px;
    width: ${thumbnailButtonSizes.mediumButton.width}px;
  `,
  icon: css`
    ${localStyles.icon};
    bottom: ${({
    theme
  }) => 0 - 3 * theme.spacing.single}px;
  `,
  innerContainer: css`
    height: ${({
    theme
  }) => thumbnailButtonSizes.mediumButton.height - theme.spacing.double}px;
  `,
  label: css`
    ${localStyles.label};
    text-align: center;
  `
};
const smallStyles = {
  buttonContainer: css`
    ${localStyles.buttonContainer};
    height: ${thumbnailButtonSizes.smallButton.height}px;
    justify-content: center;
    margin-vertical: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  icon: css`
    ${localStyles.icon};
    top: ${({
    theme
  }) => 0 - theme.spacing.single}px;
  `,
  innerContainer: css`
    ${styles.rowAndCenter};
  `,
  label: css`
    ${localStyles.label};
    margin-left: ${({
    theme
  }) => theme.spacing.double}px;
  `
};
const thumbnailStyles = {
  buttonContainer: css`
    ${localStyles.buttonContainer};
    height: ${thumbnailButtonSizes.largeButton.height}px;
    margin-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
    width: ${thumbnailButtonSizes.largeButton.width}px;
  `,
  icon: css`
    ${localStyles.icon};
    bottom: ${({
    theme
  }) => 0 - 7 * theme.spacing.half}px;
  `,
  innerContainer: css`
    height: ${({
    theme
  }) => thumbnailButtonSizes.largeButton.height - theme.spacing.double}px;
    justify-content: center;
  `,
  label: css`
    ${localStyles.label};
    text-align: center;
  `
};

/**
 * Renders a button akin to a TopicTableOfContentItem that navigates the user to a related node
 */
const CpLinkToNode = ({
  icon,
  label,
  onPress,
  size
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    brandColors,
    buttonColors
  } = useAppTheme();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const componentStyles = useMemo(() => {
    switch (size) {
      case "Large":
        return largeStyles;
      case "Medium":
        return mediumStyles;
      case "Thumbnail":
        return thumbnailStyles;
      default:
        return smallStyles;
    }
  }, [size]);
  let iconSize = isNarrowScreen ? 65 : 80;
  if (size === "Thumbnail") {
    iconSize = thumbnailButtonSizes.largeButton.imageHeight;
  }
  if (!onPress) {
    return null;
  }
  return <_StyledCpItem accessibilityHint={formatMessage(translations.accessibility.hint.linkToNode, {
    nodeName: formatMessage(label)
  })} accessibilityRole="link" onPress={onPress} style={[rnStyles.buttonShadow, {
    shadowColor: buttonColors.primary
  }]} $_css={componentStyles.buttonContainer}>
      <_StyledView $_css2={componentStyles.innerContainer}>
        <_StyledPrText children={label} $_css3={componentStyles.label} />
        <_StyledPrIcon accessibilityLabel="" accessibilityRole="none" fill={brandColors.nodeNavigatorIcon} icon={icon} size={iconSize} $_css4={componentStyles.icon} />
      </_StyledView>
    </_StyledCpItem>;
};
export default CpLinkToNode;
var _StyledCpItem = _styled(CpItem).withConfig({
  displayName: "CpLinkToNode___StyledCpItem",
  componentId: "sc-vu655e-0"
})(["", ""], p => p.$_css);
var _StyledView = _styled(View).withConfig({
  displayName: "CpLinkToNode___StyledView",
  componentId: "sc-vu655e-1"
})(["", ""], p => p.$_css2);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpLinkToNode___StyledPrText",
  componentId: "sc-vu655e-2"
})(["", ""], p => p.$_css3);
var _StyledPrIcon = _styled(PrIcon).withConfig({
  displayName: "CpLinkToNode___StyledPrIcon",
  componentId: "sc-vu655e-3"
})(["", ""], p => p.$_css4);