import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React from "react";
import { FormattedMessage } from "react-intl";
import { View } from "react-native";
import CpAppFooter from "components/app/CpAppFooter";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpLinkButton from "components/common/CpLinkButton";
import PrText from "components/primitives/PrText";
import Routes from "navigators/Routes";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  text: css`
    margin: ${({
    theme
  }) => theme.spacing.double}px;
    text-align: center;
  `
};

/**
 * The 404 page content
 */
const CpNotFound = () => {
  return <React.Fragment>
      <CpUpdatePageTitle title={translations.screens.not_found} />
      <_StyledView $_css={[styles.screen, styles.paddingDouble, styles.center]}>
        <_StyledPrText $_css2={localStyles.text}>
          <FormattedMessage id="V3Q93B" defaultMessage="Looks like the page you are looking for doesn't exist." />
        </_StyledPrText>
        <_StyledPrText2 $_css3={localStyles.text}>
          <FormattedMessage id="360WXw" defaultMessage="Try searching from {homeLink}" values={{
          homeLink: <CpLinkButton route={Routes.home}>
                  <FormattedMessage id="a8hl00" defaultMessage="the Home page." />
                </CpLinkButton>
        }} />
        </_StyledPrText2>
      </_StyledView>
      <CpAppFooter />
    </React.Fragment>;
};
export default CpNotFound;
var _StyledView = _styled(View).withConfig({
  displayName: "CpNotFound___StyledView",
  componentId: "sc-jvkzhr-0"
})(["", ""], p => p.$_css);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpNotFound___StyledPrText",
  componentId: "sc-jvkzhr-1"
})(["", ""], p => p.$_css2);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpNotFound___StyledPrText2",
  componentId: "sc-jvkzhr-2"
})(["", ""], p => p.$_css3);