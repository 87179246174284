import React, { createContext, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useColorScheme } from "react-native";
import darkTheme from "services/Theme/dark";
import lightTheme from "services/Theme/light";
import CpAppLoading from "components/app/CpAppLoading";
import useAppParams from "hooks/useAppParams";
import { StyledThemeProvider } from "services/Theme";
export const ThemeHelpersContext = createContext(undefined);

/**
 * The theme context provider component that must be placed in the component hierarchy above the first component that uses the theme.
 */
const CxTheme = ({
  children
}) => {
  const {
    stringParams: {
      initial_color_scheme: initialColorScheme
    }
  } = useAppParams();
  const browserColorScheme = useColorScheme();
  const preferredColorScheme = initialColorScheme || browserColorScheme;
  const [activeTheme, setActiveTheme] = useState({
    isCustom: false
  });
  const {
    colorScheme,
    themeValues
  } = activeTheme;

  // Used to populate the active theme, or to reset back to default
  const setTheme = useCallback(updater => {
    setActiveTheme(currentActiveTheme => {
      const requestedTheme = updater(currentActiveTheme.themeValues);
      return requestedTheme ? {
        ...currentActiveTheme,
        isCustom: true,
        themeValues: requestedTheme
      } : {
        isCustom: false
      };
    });
  }, []);

  // Used to change the active color
  const setThemeColor = useCallback(requestedTheme => {
    setActiveTheme(currentActiveTheme => {
      if (currentActiveTheme.isCustom) {
        if (requestedTheme === "dark" || requestedTheme === "light") {
          return {
            colorScheme: requestedTheme,
            isCustom: true,
            themeValues: currentActiveTheme.themeValues
          };
        }
      } else {
        if (requestedTheme === "dark") {
          // In default mode just load the dark theme
          return {
            colorScheme: requestedTheme,
            isCustom: false,
            themeValues: darkTheme
          };
        } else if (requestedTheme === "light") {
          // In default mode just load the light theme
          return {
            colorScheme: requestedTheme,
            isCustom: false,
            themeValues: lightTheme
          };
        }
      }
      return currentActiveTheme;
    });
  }, []);
  const resetTheme = useCallback(() => {
    setActiveTheme({
      isCustom: false
    });
  }, []);

  // If the user's color preferences change, update the color scheme accordingly
  useEffect(() => {
    if (!activeTheme.colorScheme) {
      setThemeColor(preferredColorScheme);
    }
  }, [activeTheme.colorScheme, preferredColorScheme, setTheme, setThemeColor]);

  // Must split out the theme helpers, otherwise there is a risk of an infinite look when a new theme is loaded
  const themeHelpers = useMemo(() => ({
    colorScheme,
    isCustomTheme: activeTheme.isCustom,
    resetTheme,
    setTheme,
    setThemeColor
  }), [colorScheme, setTheme, setThemeColor, resetTheme, activeTheme.isCustom]);
  if (themeValues) {
    return <ThemeHelpersContext.Provider value={themeHelpers}>
        <StyledThemeProvider theme={themeValues}>
          <style>{`body { background-color: ${themeValues.brandColors.background}} ${themeValues.fontFaceCss}`}</style>
          {children}
        </StyledThemeProvider>
      </ThemeHelpersContext.Provider>;
  } else {
    return <CpAppLoading />;
  }
};
export default memo(CxTheme);