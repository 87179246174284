import _styled2 from "styled-components";
import _styled from "styled-components";
import React from "react";
import { View } from "react-native";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import { css } from "services/Theme";
import { iconSizes } from "services/Theme/common";
const localStyles = {
  error: css`
    align-items: center;
    color: ${({
    theme
  }) => theme.formColors.fieldError};
    flex-direction: row;
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    padding-left: ${({
    theme
  }) => theme.spacing.single}px;
  `
};

/**
 * A form component for input error message
 */
const CpFormInputError = ({
  children,
  ...otherProps
}) => {
  const {
    formColors
  } = useAppTheme();
  return <_StyledView $_css={localStyles.error}>
      <PrIcon fill={formColors.fieldError} height={iconSizes.xsmall} icon={IconName.error} />
      <_StyledPrText {...otherProps} $_css2={localStyles.error}>
        {children}
      </_StyledPrText>
    </_StyledView>;
};
export default CpFormInputError;
var _StyledView = _styled(View).withConfig({
  displayName: "CpFormInputError___StyledView",
  componentId: "sc-19xw1dv-0"
})(["", ""], p => p.$_css);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpFormInputError___StyledPrText",
  componentId: "sc-19xw1dv-1"
})(["", ""], p => p.$_css2);