import _styled from "styled-components";
import React, { useMemo } from "react";
import CpOfflineIndicator from "components/common/CpOfflineIndicator";
import CtAppInitializers from "components/containers/CtAppInitializers";
import CtErrorBoundary from "components/containers/CtErrorBoundary";
import CtNavigation from "components/containers/CtNavigation";
import CtPatientSession from "components/containers/CtPatientSession";
import NvProviderRoot from "navigators/NvProviderRoot";
import Config from "services/Config";
import Log from "services/Log";
import { styles } from "services/Theme";
Log.info("Starting provider app", Config);

/**
 * This container component wraps the app with the providers it needs to operate in a web environment.
 */
const ProviderApp = () => {
  const appPrefixes = useMemo(() => {
    return ["https://app.imdhealth.com", "https://provider.imdhealth.app", "imdhealth://", "http://localhost:9000"];
  }, []);
  return <CtAppInitializers>
      <_StyledCtErrorBoundary $_css={styles.paddingDouble}>
        <CtNavigation prefixes={appPrefixes}>
          <CtPatientSession>
            <CpOfflineIndicator />
            <NvProviderRoot />
          </CtPatientSession>
        </CtNavigation>
      </_StyledCtErrorBoundary>
    </CtAppInitializers>;
};
export default ProviderApp;
var _StyledCtErrorBoundary = _styled(CtErrorBoundary).withConfig({
  displayName: "ProviderAppweb___StyledCtErrorBoundary",
  componentId: "sc-d32xl3-0"
})(["", ""], p => p.$_css);