import _styled17 from "styled-components";
import _styled16 from "styled-components";
import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
var _CpAccountResetPasswordMutation;
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useMemo, useReducer } from "react";
import { useForm } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { View } from "react-native";
import { useMutation } from "react-relay";
import { graphql } from "relay-runtime";
import * as zod from "zod";
import CpLinkButton from "components/common/CpLinkButton";
import CpModal from "components/common/CpModal";
import CpForm from "components/form/CpForm";
import CpFormNewPassword from "components/form/CpFormNewPassword";
import { PatientSessionActions } from "components/patientSession/PatientSession";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import useFormResolver from "hooks/useFormResolver";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { SubmitStatus } from "services/Constants";
import Log from "services/Log";
import { css, styles } from "services/Theme";
import { passwordValidation } from "services/Validation";
import translations from "translations";
const resetPasswordMutation = _CpAccountResetPasswordMutation !== void 0 ? _CpAccountResetPasswordMutation : _CpAccountResetPasswordMutation = require("./__generated__/CpAccountResetPasswordMutation.graphql");
const localStyles = {
  buttonContainer: css`
    margin-bottom: ${({
    theme
  }) => theme.spacing.single}px;
    margin-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  desktopButtonContainer: css`
    flex-direction: row;
    justify-content: space-between;
  `,
  passwordText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
  `,
  passwordTextContainer: css`
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
  `
};
const localTranslations = defineMessages({
  confirmationText: {
    id: "M/V0hG",
    defaultMessage: "Your password has successfully been changed. Please ensure your Password Manager is updated to use your new password for the next time you sign in to IMD Health."
  },
  goToSigninPage: {
    id: "WaruRu",
    defaultMessage: "Go to sign in page"
  },
  passwordText: {
    id: "XrGCya",
    defaultMessage: "Please enter your new password below."
  },
  returnToSigninPage: {
    id: "pqOFz0",
    defaultMessage: "Return to sign in"
  },
  signinErrorModalText: {
    id: "htdBJ7",
    defaultMessage: "An error occurred while trying to authenticate you with your new password. Please try signing in again."
  }
});
const initialState = {
  confirmationModalOpen: false,
  isSigningIn: false,
  signinError: false,
  submitStatus: SubmitStatus.Waiting,
  userEmail: ""
};
var AccountResetPasswordActions;
(function (AccountResetPasswordActions) {
  AccountResetPasswordActions["ModalClose"] = "MODAL_CLOSE";
  AccountResetPasswordActions["Signin"] = "SIGNIN";
  AccountResetPasswordActions["SigninError"] = "SIGNIN_ERROR";
  AccountResetPasswordActions["SubmitError"] = "SUBMIT_ERROR";
  AccountResetPasswordActions["SubmitSuccess"] = "SUBMIT_SUCCESS";
})(AccountResetPasswordActions || (AccountResetPasswordActions = {}));
const resetPasswordReducer = (state, action) => {
  switch (action.type) {
    case AccountResetPasswordActions.ModalClose:
      return {
        ...state,
        confirmationModalOpen: false,
        submitStatus: SubmitStatus.Waiting
      };
    case AccountResetPasswordActions.Signin:
      return {
        ...state,
        isSigningIn: true
      };
    case AccountResetPasswordActions.SigninError:
      return {
        ...state,
        isSigningIn: false,
        signinError: true,
        submitStatus: SubmitStatus.Error
      };
    case AccountResetPasswordActions.SubmitError:
      return {
        ...state,
        confirmationModalOpen: true,
        submitStatus: SubmitStatus.Error
      };
    case AccountResetPasswordActions.SubmitSuccess:
      return {
        ...state,
        confirmationModalOpen: true,
        submitStatus: SubmitStatus.Successful,
        userEmail: action.payload
      };
    default:
      return state;
  }
};
/**
 * Component that handles the form and the modal for the user reset password
 */
const CpAccountResetPassword = ({
  token
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    navigate
  } = useNavigation();
  const {
    signin
  } = useImdSession();
  const {
    dispatchPatientSession
  } = usePatientSessionContext();
  const [resetPasswordState, resetPasswordDispatch] = useReducer(resetPasswordReducer, initialState);
  const [submitResetPassword, isSubmittingResetPassword] = useMutation(resetPasswordMutation);
  const form = useForm({
    mode: "onBlur",
    resolver: useFormResolver(useMemo(() => zod.object({
      password: zod.string().refine(...passwordValidation({
        minLength: 8,
        requireNumbers: true,
        requireSymbols: true
      }))
    }), []))
  });
  const handleSubmit = useMemo(() => form.handleSubmit(async values => {
    submitResetPassword({
      onCompleted: ({
        resetPassword
      }) => {
        if (resetPassword?.email) {
          Log.info(`CpAccountResetPassword - password reset for: ${resetPassword.email}`);
          resetPasswordDispatch({
            payload: resetPassword.email,
            type: AccountResetPasswordActions.SubmitSuccess
          });
        }
      },
      onError: err => {
        Log.info(err);
        resetPasswordDispatch({
          type: AccountResetPasswordActions.SubmitError
        });
      },
      variables: {
        input: {
          password: values.password,
          passwordResetToken: token
        }
      }
    });
  }), [form, submitResetPassword, token]);

  // Ensure PatientSessions are initialized on sign in
  const handlePatientSessionSignin = useCallback(() => {
    resetPasswordDispatch({
      type: AccountResetPasswordActions.ModalClose
    });
    dispatchPatientSession({
      type: PatientSessionActions.SignIn
    });
  }, [dispatchPatientSession]);
  const handleSignin = useCallback(async () => {
    try {
      resetPasswordDispatch({
        type: AccountResetPasswordActions.Signin
      });
      await signin(resetPasswordState.userEmail, form.getValues("password"), {
        onSigninError: () => resetPasswordDispatch({
          type: AccountResetPasswordActions.SigninError
        }),
        onSigninSuccess: handlePatientSessionSignin
      });
    } catch (err) {
      Log.info(err);
      resetPasswordDispatch({
        type: AccountResetPasswordActions.SigninError
      });
    }
  }, [form, handlePatientSessionSignin, signin, resetPasswordState.userEmail]);
  const handleCloseConfirmationModal = useCallback(() => {
    resetPasswordDispatch({
      type: AccountResetPasswordActions.ModalClose
    });
    if (resetPasswordState.signinError) {
      navigate(Routes.signin);
    }
  }, [navigate, resetPasswordState.signinError]);
  const handleRedirectToSigninPage = useCallback(() => {
    resetPasswordDispatch({
      type: AccountResetPasswordActions.ModalClose
    });
    navigate(Routes.signin);
  }, [navigate]);
  const handleBack = useCallback(() => {
    navigate(Routes.signin);
  }, [navigate]);
  return <>
      <_StyledCpModal onBackgroundPress={resetPasswordState.submitStatus === SubmitStatus.Error ? handleCloseConfirmationModal : handleSignin} visible={resetPasswordState.confirmationModalOpen} $_css={styles.marginHorizontalSingle}>
        <_StyledView $_css2={styles.modalContainer}>
          <_StyledPrText accessibilityRole="header" children={resetPasswordState.submitStatus === SubmitStatus.Successful ? translations.labels.success : translations.labels.error} $_css3={styles.modalHeaderText} />
          {resetPasswordState.submitStatus === SubmitStatus.Successful ? <>
              <_StyledView2 $_css4={[styles.rowAndCenter, styles.paddingVerticalDouble]}>
                <PrText children={formatMessage(localTranslations.confirmationText)} />
              </_StyledView2>
              <_StyledPrButton busy={resetPasswordState.isSigningIn} onPress={handleSignin} testID="CpAccountResetPasswordModalContinue" $_css5={styles.marginTopDouble}>
                <_StyledPrText2 children={translations.buttons.continue} $_css6={styles.buttonText} />
              </_StyledPrButton>
            </> : <>
              {resetPasswordState.signinError ? <>
                  <_StyledView3 $_css7={[styles.rowAndCenter, styles.paddingVerticalDouble]}>
                    <PrText children={formatMessage(localTranslations.signinErrorModalText)} />
                  </_StyledView3>
                  <_StyledPrButton2 onPress={handleRedirectToSigninPage} $_css8={styles.marginTopDouble}>
                    <_StyledPrText3 children={localTranslations.goToSigninPage} $_css9={styles.buttonText} />
                  </_StyledPrButton2>
                </> : <>
                  <_StyledView4 $_css10={[styles.rowAndCenter, styles.paddingVerticalDouble]}>
                    <PrText children={formatMessage(translations.errors.customError, {
                customError: formatMessage(translations.errors.genericError),
                supportEmail: <CpLinkButton url="mailto:support@imdhealth.com">
                            <PrText>support@imdhealth.com</PrText>
                          </CpLinkButton>
              })} />
                  </_StyledView4>
                  <_StyledPrButton3 onPress={handleCloseConfirmationModal} testID="CpAccountResetPasswordModalContinue" $_css11={styles.marginTopDouble}>
                    <_StyledPrText4 children={translations.buttons.continue} $_css12={styles.buttonText} />
                  </_StyledPrButton3>
                </>}
            </>}
        </_StyledView>
      </_StyledCpModal>
      <CpForm {...form} onSubmit={handleSubmit}>
        <_StyledView5 $_css13={localStyles.passwordTextContainer}>
          <_StyledPrText5 children={formatMessage(localTranslations.passwordText)} $_css14={localStyles.passwordText} />
        </_StyledView5>
        <CpFormNewPassword accessibilityLabel={formatMessage(translations.attributes.user.newPassword)} label={translations.attributes.user.newPassword} name="password" onSubmitEditing={handleSubmit} placeholder={translations.attributes.user.newPassword} />
        <_StyledView6 $_css15={[localStyles.buttonContainer, !isNarrowScreen ? localStyles.desktopButtonContainer : undefined]}>
          <PrButton busy={isSubmittingResetPassword} onPress={handleSubmit} testID="CpAccountResetPasswordSubmit">
            <_StyledPrText6 children={translations.buttons.submit} $_css16={styles.buttonText} />
          </PrButton>
          <PrButton onPress={handleBack} type="text">
            <_StyledPrText7 children={localTranslations.returnToSigninPage} $_css17={styles.buttonText} />
          </PrButton>
        </_StyledView6>
      </CpForm>
    </>;
};
export default CpAccountResetPassword;
var _StyledCpModal = _styled(CpModal).withConfig({
  displayName: "CpAccountResetPassword___StyledCpModal",
  componentId: "sc-1d47jbw-0"
})(["", ""], p => p.$_css);
var _StyledView = _styled(View).withConfig({
  displayName: "CpAccountResetPassword___StyledView",
  componentId: "sc-1d47jbw-1"
})(["", ""], p => p.$_css2);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpAccountResetPassword___StyledPrText",
  componentId: "sc-1d47jbw-2"
})(["", ""], p => p.$_css3);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CpAccountResetPassword___StyledView2",
  componentId: "sc-1d47jbw-3"
})(["", ""], p => p.$_css4);
var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpAccountResetPassword___StyledPrButton",
  componentId: "sc-1d47jbw-4"
})(["", ""], p => p.$_css5);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpAccountResetPassword___StyledPrText2",
  componentId: "sc-1d47jbw-5"
})(["", ""], p => p.$_css6);
var _StyledView3 = _styled(View).withConfig({
  displayName: "CpAccountResetPassword___StyledView3",
  componentId: "sc-1d47jbw-6"
})(["", ""], p => p.$_css7);
var _StyledPrButton2 = _styled(PrButton).withConfig({
  displayName: "CpAccountResetPassword___StyledPrButton2",
  componentId: "sc-1d47jbw-7"
})(["", ""], p => p.$_css8);
var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpAccountResetPassword___StyledPrText3",
  componentId: "sc-1d47jbw-8"
})(["", ""], p => p.$_css9);
var _StyledView4 = _styled(View).withConfig({
  displayName: "CpAccountResetPassword___StyledView4",
  componentId: "sc-1d47jbw-9"
})(["", ""], p => p.$_css10);
var _StyledPrButton3 = _styled(PrButton).withConfig({
  displayName: "CpAccountResetPassword___StyledPrButton3",
  componentId: "sc-1d47jbw-10"
})(["", ""], p => p.$_css11);
var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpAccountResetPassword___StyledPrText4",
  componentId: "sc-1d47jbw-11"
})(["", ""], p => p.$_css12);
var _StyledView5 = _styled(View).withConfig({
  displayName: "CpAccountResetPassword___StyledView5",
  componentId: "sc-1d47jbw-12"
})(["", ""], p => p.$_css13);
var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpAccountResetPassword___StyledPrText5",
  componentId: "sc-1d47jbw-13"
})(["", ""], p => p.$_css14);
var _StyledView6 = _styled(View).withConfig({
  displayName: "CpAccountResetPassword___StyledView6",
  componentId: "sc-1d47jbw-14"
})(["", ""], p => p.$_css15);
var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpAccountResetPassword___StyledPrText6",
  componentId: "sc-1d47jbw-15"
})(["", ""], p => p.$_css16);
var _StyledPrText7 = _styled(PrText).withConfig({
  displayName: "CpAccountResetPassword___StyledPrText7",
  componentId: "sc-1d47jbw-16"
})(["", ""], p => p.$_css17);