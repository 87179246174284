import _styled from "styled-components";
var _CpShortcuts_userShortcuts;
import { useNavigation } from "@react-navigation/native";
import React, { useCallback } from "react";
import { View } from "react-native";
import CpOrganizationShortcuts from "components/shortcuts/CpOrganizationShortcuts";
import CpPersonalShortcuts from "components/shortcuts/CpPersonalShortcuts";
import Routes from "navigators/Routes";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { styles } from "services/Theme";
import CpGrid from "../common/CpGrid";
const CpShortcut_userShortcutsFragment = _CpShortcuts_userShortcuts !== void 0 ? _CpShortcuts_userShortcuts : _CpShortcuts_userShortcuts = require("./__generated__/CpShortcuts_userShortcuts.graphql");

/**
 * Component displaying the user's personal shortcuts and recommended shortcuts (from advertisers)
 */
const CpShortcuts = ({
  userShortcutsFragment
}) => {
  const {
    navigate
  } = useNavigation();
  const userShortcuts = useFragment(CpShortcut_userShortcutsFragment, userShortcutsFragment);
  const handleShortcutPress = useCallback((selectedRootResourceNodeRevisionId, selectedShortcutRevisionId) => {
    // Matching root and shortcut ids mean we're linking to a root topic node, which only requires the revisionId param
    let newParams = {
      rootRevisionId: selectedShortcutRevisionId
    };
    if (selectedRootResourceNodeRevisionId !== selectedShortcutRevisionId) {
      // !matching ids means we're navigating to a deeper resource and need the revisionId and selectedRevisionId to properly display the shortcut
      newParams = {
        rootRevisionId: selectedRootResourceNodeRevisionId,
        selectedRevisionId: selectedShortcutRevisionId
      };
    }
    navigate(Routes.topic, {
      ...newParams
    });
  }, [navigate]);
  return <View>
      <_StyledCpGrid $_css={styles.center}>
        {userShortcuts?.currentMembership ? <React.Fragment>
            <CpPersonalShortcuts onPress={handleShortcutPress} userMembershipFragment={userShortcuts.currentMembership} />
            <CpOrganizationShortcuts onPress={handleShortcutPress} userMembershipFragment={userShortcuts.currentMembership} />
          </React.Fragment> : null}
      </_StyledCpGrid>
    </View>;
};
export default CpShortcuts;
var _StyledCpGrid = _styled(CpGrid).withConfig({
  displayName: "CpShortcuts___StyledCpGrid",
  componentId: "sc-16ik7kl-0"
})(["", ""], p => p.$_css);