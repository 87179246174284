var _CxImdSession_currentUserQuery, _CxImdSession_appConfigFragment, _CxImdSession_currentUserFragment, _CxImdSession_currentLicenseeFragment;
import React, { createContext, memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { fetchQuery, useFragment, useRelayEnvironment } from "react-relay";
import { createOperationDescriptor, getRequest, graphql } from "relay-runtime";
import CpAppLoading from "components/app/CpAppLoading";
import CpThemeUpdater from "components/app/CpThemeUpdater";
import CpAppLoadingError from "components/common/CpAppLoadingError";
import { I18nContext } from "contexts/CxI18n";
import useAppBackend from "hooks/useAppBackend";
import useAppRegion from "hooks/useAppRegion";
import usePersistentStorage from "hooks/usePersistentStorage";
import Config from "services/Config";
import { AuthError, BadRequestError } from "services/Error";
import Log from "services/Log";
import SecureStorage, { useAuthData } from "services/SecureStorage";
import translations from "translations";
export const ImdSessionContext = createContext(undefined);

/**
 * The query used to fetch the current user, org, licensee, and appConfig
 */
export const currentUserQuery = _CxImdSession_currentUserQuery !== void 0 ? _CxImdSession_currentUserQuery : _CxImdSession_currentUserQuery = require("./__generated__/CxImdSession_currentUserQuery.graphql");
const appConfigFragment = _CxImdSession_appConfigFragment !== void 0 ? _CxImdSession_appConfigFragment : _CxImdSession_appConfigFragment = require("./__generated__/CxImdSession_appConfigFragment.graphql");
const currentUserFragment = _CxImdSession_currentUserFragment !== void 0 ? _CxImdSession_currentUserFragment : _CxImdSession_currentUserFragment = require("./__generated__/CxImdSession_currentUserFragment.graphql");
const currentLicenseeFragment = _CxImdSession_currentLicenseeFragment !== void 0 ? _CxImdSession_currentLicenseeFragment : _CxImdSession_currentLicenseeFragment = require("./__generated__/CxImdSession_currentLicenseeFragment.graphql");

/**
 * Initializes Imd Context and wraps children with the context provider.
 */
const CxImdSession = ({
  children
}) => {
  // IMPORTANT: CxImdSession is special because it's at the top level of the app, and it handles initialization.
  // Avoid this fetch pattern in other places in the app. Instead prefer to use the `QlQueryName` pattern.
  const [fetchState, setFetchState] = useState({
    busy: false,
    error: undefined,
    fetched: false
  });
  const authData = useAuthData();
  const {
    persistentHeaders: {
      membershipId,
      organizationId
    }
  } = useAppBackend();
  const [, updateDesiredMembershipId] = usePersistentStorage("membershipId");
  const [, updateDesiredOrganizationId] = usePersistentStorage("organizationId");
  const [, setLastSigninEmail] = usePersistentStorage("lastSigninEmail");
  const [fetchKeys, setFetchKeys] = useState({
    appConfigKey: null,
    licenseeKey: null,
    userKey: null
  });
  const [imdSessionData, setImdSessionData] = useState({
    appConfig: null,
    authTokenSignedOut: false,
    initialized: false,
    licensee: null,
    organization: null,
    user: null
  });
  const user = useFragment(currentUserFragment, fetchKeys.userKey);
  const organization = user?.currentOrganization || null;
  const licensee = useFragment(currentLicenseeFragment, fetchKeys.licenseeKey);
  const appConfig = useFragment(appConfigFragment, fetchKeys.appConfigKey);
  const activeMembershipId = user?.currentMembership?._id;
  const activeOrganizationId = user?.currentOrganization?._id;
  const {
    locale
  } = useContext(I18nContext);

  // Get backend for current region
  const {
    currentBackend
  } = useAppBackend();
  const {
    currentRegionSlug
  } = useAppRegion();
  const relayEnvironment = useRelayEnvironment();

  // IMPORTANT: Every time this is updated, the fetch will run again
  // Force a re-fetch of system data.
  const fetchImdContext = useCallback(onSuccess => {
    const variables = {
      licenseeSlug: currentRegionSlug,
      locale
    };
    const queryRequest = getRequest(currentUserQuery);
    const queryDescriptor = createOperationDescriptor(queryRequest, variables);
    const retainedQuery = relayEnvironment.retain(queryDescriptor);
    return fetchQuery(relayEnvironment, currentUserQuery, variables, {
      fetchPolicy: "network-only",
      networkCacheConfig: {
        force: true
      }
    }).subscribe({
      error: async error => {
        if (error instanceof AuthError) {
          Log.warn("CxImdSession - fetch - Auth error", error);

          // On an auth error log the current user out
          // TODO: May be nice to check for expired tokens and stuff
          await SecureStorage.removeAccessToken(); // This will trigger re-render as unauthenticated

          setImdSessionData({
            appConfig: null,
            authTokenSignedOut: true,
            initialized: true,
            licensee: null,
            organization: null,
            user: null
          });
          updateDesiredMembershipId(undefined);
          updateDesiredOrganizationId(undefined);
          setFetchState({
            busy: false,
            fetched: true
          });
        } else {
          Log.error("CxImdSession - fetch - Other error", error);
          setFetchState({
            busy: false,
            error,
            fetched: true
          });
        }
      },
      next: data => {
        setFetchState({
          busy: false,
          fetched: true
        });

        // This is used above to fetch the actual user and licensee data
        const appConfigKey = data.appConfig || null;
        const licenseeKey = data.licensee?.nodes?.[0] || null;
        const userKey = data.currentUser || null;
        setFetchKeys({
          appConfigKey,
          licenseeKey,
          userKey
        });

        // This is used to tell the system a valid IMD session is present
        setImdSessionData(currentImdState => ({
          ...currentImdState,
          initialized: true
        }));

        // This identifies requests to the backend. It needs to happen before the onSuccess, otherwise it
        // may redirect without actually logging the user in.
        if (userKey?.currentMembership) {
          updateDesiredMembershipId(userKey?.currentMembership?._id);
          updateDesiredOrganizationId(userKey?.currentOrganization?._id);
        }
        Log.info("CxImdSession - fetch - New Session", {
          orgId: userKey?.currentOrganization?._id,
          orgName: userKey?.currentOrganization?.name,
          userId: userKey?._id
        });

        // This probably shouldn't, but still may redirect the app. Because of that we need to start all critical
        // login tasks before this point.
        onSuccess?.();
      },
      start: () => {
        setFetchState(currentFetchState => ({
          ...currentFetchState,
          busy: true
        }));
        setImdSessionData(currentImdState => ({
          ...currentImdState,
          user: null
        }));
      },
      unsubscribe: () => {
        retainedQuery.dispose();
      }
    });
  }, [currentRegionSlug, locale, relayEnvironment, updateDesiredMembershipId, updateDesiredOrganizationId]);

  // Do the initial fetch, or retrigger the fetch if the token changes
  useEffect(() => {
    return fetchImdContext().unsubscribe;
  }, [fetchImdContext, user?.isPatient, authData?.access_token]);

  // Refetch the org settings if the membership, organization, or fetch state changes
  useEffect(() => {
    if (membershipId && membershipId !== activeMembershipId || organizationId && organizationId !== activeOrganizationId) {
      return fetchImdContext().unsubscribe;
    }
    return undefined;
  }, [activeMembershipId, activeOrganizationId, membershipId, organizationId, fetchImdContext]);

  // Returns an Oauth bearer token if the provided account credentials are valid.
  const authenticate = useCallback(async ({
    identifier,
    identityType,
    password
  }) => {
    const result = await currentBackend.request({
      data: {
        grant_type: "password",
        identifier,
        identity_type: identityType,
        password
      },
      headers: {
        Accept: "application/json",
        Authorization: `Basic ${Config.OAUTH_CLIENT_ID}`
      },
      method: "POST",
      url: "/oauth/token"
    });
    return result.data;
  }, [currentBackend]);
  const signin = useCallback(async (email, password, options = {}) => {
    try {
      Log.info("CxImdSession - signin - Authenticating user");
      const token = await authenticate({
        identifier: email,
        identityType: "Identity::Email",
        password: password
      });
      Log.info("CxImdSession - signin - Authentication succeeded");
      if (token) {
        Log.info("CxImdSession - signin - Starting session");
        await SecureStorage.setAccessToken({
          ...token,
          source: "login"
        });
        await setLastSigninEmail(email);
        fetchImdContext(() => {
          setImdSessionData(currentImdContext => ({
            ...currentImdContext,
            authTokenSignedOut: false
          }));
          options?.onSigninSuccess?.();
        });
        return true;
      } else {
        throw new Error("No access token after successful authentication");
      }
    } catch (error) {
      if (error instanceof BadRequestError || error instanceof AuthError) {
        await SecureStorage.removeAccessToken(); // This will trigger re-render as unauthenticated

        Log.info("CxImdSession - signin - Authentication error");
        options?.onSigninError?.(translations.errors.invalidSignin);
        return false;
      } else if (error instanceof Error) {
        Log.error("CxImdSession - signin - Other error", error);
        options?.onSigninError?.(error);
        return false;
      } else {
        // The 2nd check should catch all errors, but adding a fallback to be totally safe
        Log.error("CxImdSession - signin - Other other error", new Error(`${error}`));
        options?.onSigninError?.(new Error(`${error}`));
        return false;
      }
    }
  }, [authenticate, fetchImdContext, setLastSigninEmail]);
  const signout = useCallback(async () => {
    // Revert to initial state, but do not show the app loader
    setImdSessionData({
      appConfig: null,
      authTokenSignedOut: true,
      initialized: true,
      licensee: null,
      organization: null,
      user: null
    });
    setFetchKeys({
      appConfigKey: null,
      licenseeKey: null,
      userKey: null
    });
    updateDesiredMembershipId(undefined);
    updateDesiredOrganizationId(undefined);
    if (authData) {
      await SecureStorage.removeAccessToken(); // This will trigger re-render as unauthenticated

      // Asks for the given auth token to be invalidated.
      await currentBackend.request({
        data: {
          token: authData.access_token,
          token_type_hint: "access_token"
        },
        headers: {
          Accept: "application/json",
          Authorization: `Basic ${Config.OAUTH_CLIENT_ID}`
        },
        method: "POST",
        url: "/oauth/revoke"
      });
    }
    fetchImdContext();
  }, [authData, currentBackend, fetchImdContext, updateDesiredMembershipId, updateDesiredOrganizationId]);

  // Clear any errors that might happen during loading
  const handleClearError = useCallback(async () => {
    if (authData) await SecureStorage.removeAccessToken();
    setFetchState(currentFetchState => ({
      ...currentFetchState,
      error: undefined,
      fetched: false
    }));
    setImdSessionData(currentImdState => ({
      ...currentImdState,
      initialized: false,
      organization: null,
      user: null
    }));
    fetchImdContext();
  }, [authData, fetchImdContext]);

  // The actual context value that will be sent to the descendants
  const contextValue = useMemo(() => ({
    ...imdSessionData,
    appConfig,
    authenticate,
    isFetching: fetchState.busy,
    licensee,
    organization,
    signin,
    signout,
    user
  }), [appConfig, authenticate, fetchState.busy, imdSessionData, licensee, organization, signin, signout, user]);
  if (!fetchState.fetched) {
    return <CpAppLoading />;
  } else if (fetchState.error) {
    return <CpAppLoadingError clearError={handleClearError} />;
  } else {
    return <ImdSessionContext.Provider value={contextValue}>
        {appConfig ? <>
            <style>{appConfig.fontFaceCss}</style>
            <CpThemeUpdater appConfig={appConfig} darkColorConfigKey={appConfig.appColors?.find(colorConfig => colorConfig.colorScheme === "dark") ?? null} lightColorConfigKey={appConfig.appColors?.find(colorConfig => colorConfig.colorScheme === "light") ?? null} />
          </> : null}
        {children}
      </ImdSessionContext.Provider>;
  }
};
export default memo(CxImdSession);