import React, { createContext, memo, useEffect, useMemo } from "react";
import CpAppLoading from "components/app/CpAppLoading";
import useAppBackend from "hooks/useAppBackend";
import useAppParams from "hooks/useAppParams";
import translations from "translations";
export const AppRegionContext = createContext(undefined);

// This can not change without the app reloading, so it's safe to do it on startup

/**
 * Manages the regional stuff
 */
const CxAppRegion = ({
  children
}) => {
  const {
    url
  } = useAppParams();
  const {
    changeRegion,
    whereamiResult
  } = useAppBackend();

  // Set up the app context that gets passed down
  const appRegionContext = useMemo(() => {
    const {
      region,
      regionLicenseeSlug,
      regionTranslationKey
    } = whereamiResult.current;
    const currentRegionTranslationKey = translations.regions[regionTranslationKey] ? regionTranslationKey : "us";
    return {
      currentRegion: region,
      currentRegionSlug: regionLicenseeSlug,
      currentRegionTranslation: translations.regions[currentRegionTranslationKey],
      currentRegionTranslationKey
    };
  }, [whereamiResult]);
  const desiredRegion = whereamiResult.supported.find(supportedRegion => url.host.includes(`.${supportedRegion.regionalAppDomainPrefix}.`)) || whereamiResult.current;

  // If the app URL matches a different backend, then set that backend on startup
  useEffect(() => {
    if (whereamiResult.current.region !== desiredRegion.region) {
      changeRegion(desiredRegion.region);
    }
  }, [desiredRegion, whereamiResult, changeRegion]);
  if (whereamiResult.current.region !== desiredRegion.region) {
    return <CpAppLoading />;
  } else {
    return <AppRegionContext.Provider value={appRegionContext}>{children}</AppRegionContext.Provider>;
  }
};
export default memo(CxAppRegion);