import { useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
const placeholderOptions = defineMessages({
  adhd: {
    id: "hLCwi5",
    defaultMessage: "ADHD"
  },
  als: {
    id: "EfXJ8b",
    defaultMessage: "ALS"
  },
  alzheimers: {
    id: "9coYN1",
    defaultMessage: "Alzheimer's"
  },
  anxiety: {
    id: "/8m0nh",
    defaultMessage: "Anxiety"
  },
  arthritis: {
    id: "ovMAjC",
    defaultMessage: "Arthritis"
  },
  asthma: {
    id: "O4EZsb",
    defaultMessage: "Asthma"
  },
  autism: {
    id: "PWdGRe",
    defaultMessage: "Autism"
  },
  brainTumor: {
    id: "moi96g",
    defaultMessage: "Brain Tumor"
  },
  breastCancer: {
    id: "9XdLpP",
    defaultMessage: "Breast Cancer"
  },
  celiacDisease: {
    id: "LZNE/y",
    defaultMessage: "Celiac Disease"
  },
  colonCancer: {
    id: "B4eV84",
    defaultMessage: "Colon Cancer"
  },
  crohnsDisease: {
    id: "F2ZDpd",
    defaultMessage: "Crohn's Disease"
  },
  depression: {
    id: "so7umy",
    defaultMessage: "Depression"
  },
  epilepsy: {
    id: "o9VBR/",
    defaultMessage: "Epilepsy"
  },
  hiv: {
    id: "4HYlMx",
    defaultMessage: "HIV"
  },
  hpv: {
    id: "HDfkyy",
    defaultMessage: "HPV"
  },
  irritableBowelSyndrome: {
    id: "hAo3nF",
    defaultMessage: "Irritable Bowel Syndrome"
  },
  leukemia: {
    id: "wVVnX4",
    defaultMessage: "Leukemia"
  },
  lungCancer: {
    id: "mALbvk",
    defaultMessage: "Lung Cancer"
  },
  lupus: {
    id: "xAl9Rr",
    defaultMessage: "Lupus"
  },
  lymphedema: {
    id: "VbsBhj",
    defaultMessage: "Lymphedema"
  },
  lymphoma: {
    id: "5xHKAw",
    defaultMessage: "Lymphoma"
  },
  melanoma: {
    id: "KVCcwo",
    defaultMessage: "Melanoma"
  },
  migraine: {
    id: "5uB266",
    defaultMessage: "Migraine"
  },
  multipleSclerosis: {
    id: "KluqPe",
    defaultMessage: "Multiple Sclerosis"
  },
  myeloma: {
    id: "srd0N3",
    defaultMessage: "Myeloma"
  },
  obesity: {
    id: "30ch8l",
    defaultMessage: "Obesity"
  },
  osteoporosis: {
    id: "wY4dkZ",
    defaultMessage: "Osteoporosis"
  },
  pancreaticCancer: {
    id: "rOcMdy",
    defaultMessage: "Pancreatic Cancer"
  },
  peanutAllergy: {
    id: "ykiyEP",
    defaultMessage: "Peanut Allergy"
  },
  psoriasis: {
    id: "NIv2lV",
    defaultMessage: "Psoriasis"
  },
  rectalCancer: {
    id: "wGyDcr",
    defaultMessage: "Rectal Cancer"
  },
  seasonalAllergies: {
    id: "5nCjF+",
    defaultMessage: "Seasonal allergies"
  },
  spondylitis: {
    id: "tBsKr2",
    defaultMessage: "Spondylitis"
  },
  type1Diabetes: {
    id: "5NTCo5",
    defaultMessage: "Type 1 Diabetes"
  },
  type2Diabetes: {
    id: "wdK9XD",
    defaultMessage: "Type 2 Diabetes"
  },
  ulcerativeColitis: {
    id: "kSvWDQ",
    defaultMessage: "Ulcerative Colitis"
  }
});
const localTranslations = defineMessages({
  placeholderDoubleWrapper: {
    id: "Tvc/Mf",
    defaultMessage: "Try... \"{option}\" or \"{option2}\""
  },
  placeholderSingleWrapper: {
    id: "wcioI8",
    defaultMessage: "Try... \"{option}\""
  }
});

/**
 * Returns a random placeholder for use within a search bar.
 */
function useRandomSearchPlaceholder(optionCount) {
  const {
    formatMessage
  } = useIntl();
  const options = useMemo(() => Object.keys(placeholderOptions), []);
  const [randomInt] = useState(() => Math.floor(Math.random() * options.length));
  const firstPlaceholder = useMemo(() => {
    const optionTranslation1 = options[randomInt];
    const actualTranslation = placeholderOptions[optionTranslation1];
    return formatMessage(actualTranslation);
  }, [formatMessage, options, randomInt]);
  const secondPlaceholder = useMemo(() => {
    let randomInt2 = Math.floor(Math.random() * options.length);
    if (randomInt === randomInt2) {
      randomInt2 === options.length ? randomInt2 -= 1 : randomInt2 += 1;
    }
    const optionTranslation2 = options[randomInt2];
    const actualTranslation = placeholderOptions[optionTranslation2];
    return formatMessage(actualTranslation);
  }, [formatMessage, options, randomInt]);
  return optionCount === 2 ? formatMessage(localTranslations.placeholderDoubleWrapper, {
    option: firstPlaceholder,
    option2: secondPlaceholder
  }) : formatMessage(localTranslations.placeholderSingleWrapper, {
    option: firstPlaceholder
  });
}
export default useRandomSearchPlaceholder;