// Apparently ES-Lint doesn't consider =0, =1 format to be a valid plural format.

import { defineMessages } from "react-intl";
export const label = defineMessages({
  imd: {
    id: "ebdBm0",
    defaultMessage: "IMD Health"
  },
  imdLogo: {
    id: "T58xNv",
    defaultMessage: "IMD Health {region} logo"
  },
  moreMenuTabButton: {
    id: "SISLZj",
    defaultMessage: "Show more tabs"
  },
  navHeaderLanguageButton: {
    id: "lpQ9+G",
    defaultMessage: "Select Language"
  },
  organizationLogo: {
    id: "YWdBla",
    defaultMessage: "{organizationName} logo"
  },
  progressPackageSession: {
    id: "U5OKhu",
    defaultMessage: "Information package progress"
  },
  progressPasswordStrength: {
    id: "atjKFA",
    defaultMessage: "Password strength meter"
  },
  progressPatientSession: {
    id: "ZfX1KM",
    defaultMessage: "Patient session progress"
  },
  progressPresentation: {
    id: "Fj6f4s",
    defaultMessage: "Presentation progress"
  },
  scrollToTop: {
    id: "QzkLzH",
    defaultMessage: "Scroll to the top of the page."
  },
  search: {
    id: "pZkAN5",
    defaultMessage: "Search IMD Health"
  },
  searchClear: {
    id: "tKTbog",
    defaultMessage: "Clear search query"
  },
  stopPackage: {
    id: "fA91Z1",
    defaultMessage: "Stop building the current patient information package."
  },
  thumbnailFor: {
    id: "Ic9zrc",
    defaultMessage: "Thumbnail image of {thumbnailName}"
  }
});
export const hint = defineMessages({
  changeEmailButton: {
    id: "h10tar",
    defaultMessage: "Opens the confirmation modal for changing your account email."
  },
  changePasswordButton: {
    id: "whJerg",
    defaultMessage: "Opens the confirmation modal for changing your account password."
  },
  clearCanvas: {
    id: "HWPV/g",
    defaultMessage: "Clears the current canvas of any drawings made."
  },
  closeFullscreen: {
    id: "ZZFuE/",
    defaultMessage: "Closes the current content and returns you to the previous page."
  },
  closeModal: {
    id: "6r8NYW",
    defaultMessage: "Closes the current modal screen and returns you to the content."
  },
  draw: {
    id: "/44jR+",
    defaultMessage: "Enables the ability to draw over the current image."
  },
  drawDisable: {
    id: "6QsfHG",
    defaultMessage: "Disables the ability to draw over the current image."
  },
  eraser: {
    id: "sAeME1",
    defaultMessage: "Enables the eraser tool for this drawing."
  },
  eraserDisable: {
    id: "zQ8HP/",
    defaultMessage: "Disables the eraser tool for this drawing."
  },
  expand: {
    id: "f4D9be",
    defaultMessage: "Expands to display more information and/or options."
  },
  externalLink: {
    id: "fqhU2G",
    defaultMessage: "Opens to an external website."
  },
  linkToNode: {
    id: "TD6Ytw",
    defaultMessage: "Navigates to the {nodeName} node for this topic."
  },
  moreTabMenuButton: {
    id: "QhDCXN",
    defaultMessage: "Opens a menu with the remaining tabs that don't fit on screen."
  },
  navHeaderCTA: {
    id: "5bD0Mv",
    defaultMessage: "Navigates you to the IMD Health app for healthcare professionals."
  },
  navHeaderColorModeButton: {
    id: "5iLu4K",
    defaultMessage: "Changes page color scheme between light mode and dark mode"
  },
  navHeaderImdButton: {
    id: "l0FDXb",
    defaultMessage: "Navigates you to the main IMD Health app experience."
  },
  navHeaderLanguageButton: {
    id: "creN/7",
    defaultMessage: "Opens a modal for changing the app language and region."
  },
  navHeaderLogoButton: {
    id: "m1eJGF",
    defaultMessage: "Returns you to the home page of the app."
  },
  navHeaderPackageSessionButton: {
    id: "wfAwfn",
    defaultMessage: "Opens the Patient Information Package modal."
  },
  navHeaderPatientSessionButton: {
    id: "rS+2aM",
    defaultMessage: "Opens the Patient Education Session modal."
  },
  navHeaderPatientSessionLogoButton: {
    id: "yAORLC",
    defaultMessage: "Returns you to your Patient Session."
  },
  navHeaderShortcutButton: {
    id: "ieorHE",
    defaultMessage: "Opens the Shortcut modal."
  },
  navigatesTo: {
    id: "W57hwS",
    defaultMessage: "Navigates to the {pageName} page."
  },
  notificationDismiss: {
    id: "fxbhx7",
    defaultMessage: "Dismisses the notification."
  },
  openDrawingMode: {
    id: "8qDYA+",
    defaultMessage: "Opens the image to view full screen, and enables the ability to draw over it."
  },
  openDrugInformationLeaflet: {
    id: "b4D65A",
    defaultMessage: "Opens drug information leaflet"
  },
  openFullScreen: {
    id: "Z5q1DC",
    defaultMessage: "Opens the image to view full screen."
  },
  openInNewTab: {
    id: "3CHkcQ",
    defaultMessage: "{baseHint} Opens in a new tab."
  },
  openMonograph: {
    id: "aMKjOH",
    defaultMessage: "Opens drug monograph"
  },
  packageDelete: {
    id: "vL22md",
    defaultMessage: "Opens a confirmation modal to delete this template."
  },
  packageSave: {
    id: "yaPIZK",
    defaultMessage: "Opens the screen to save your current package item selections as a template."
  },
  packageStartStop: {
    id: "A1GjFN",
    defaultMessage: "{state} building a patient information package."
  },
  packageTemplates: {
    id: "NBC+eK",
    defaultMessage: "Opens the screen to view existing patient package templates, or save a new one."
  },
  palette: {
    id: "sCoJIk",
    defaultMessage: "Displays the colors and stroke weights available to select from for drawing."
  },
  reviewAndSend: {
    id: "uUi7dv",
    defaultMessage: "Opens the screen for reviewing your selection prior to sending."
  },
  tableOfContentsButton: {
    id: "EkNjnz",
    defaultMessage: "Expands or hides the table of contents for the topic."
  },
  thumbnailOpenFullscreen: {
    id: "I8i1ZX",
    defaultMessage: "Opens the thumbnail in a full screen modal, and displays an image caption if available."
  }
});