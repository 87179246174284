import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { Image, Linking, Pressable, View } from "react-native";
import CpImdLogo from "components/common/CpImdLogo";
import CpImdLogoCanada from "components/common/CpImdLogoCanada";
import CpImdLogoUnitedStates from "components/common/CpImdLogoUnitedStates";
import useAnalytics from "hooks/useAnalytics";
import useAppRegion from "hooks/useAppRegion";
import useAppTheme from "hooks/useAppTheme";
import useImdSession from "hooks/useImdSession";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { AnalyticsEventName } from "services/Analytics";
import { css, styles } from "services/Theme";
import { logoSize } from "services/Theme/common";
import translations from "translations";
/**
 * Logo part of the app header
 * Can take an optional logoAction prop that customizes the logo and its actions
 */
const CpHeaderLogo = ({
  logoAction,
  onLayout,
  position
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    user
  } = useImdSession();
  const {
    name,
    textColors
  } = useAppTheme();
  const {
    trackEvent
  } = useAnalytics();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    currentRegionSlug,
    currentRegionTranslation
  } = useAppRegion();
  const navigation = useNavigation();
  const customHeaderLogoAction = useMemo(() => {
    switch (logoAction?.action) {
      case "GO_TO_URL":
        return () => {
          if (logoAction?.actionPayload) {
            return Linking.openURL(logoAction.actionPayload);
          }
          return null;
        };
      case "NAVIGATE_TO":
        return () => {
          if (logoAction?.actionPayload) {
            return navigation.navigate(logoAction.actionPayload);
          }
          return null;
        };
      // TODO: Implement when someone asks for it
      case "SEND_IFRAME_MESSAGE":
        return () => null;
      default:
        return null;
    }
  }, [logoAction?.action, logoAction?.actionPayload, navigation]);
  const logoAccessibilityHint = useMemo(() => {
    switch (logoAction?.action) {
      case "GO_TO_URL":
        return formatMessage(translations.accessibility.hint.externalLink);
      case "NAVIGATE_TO":
        return formatMessage(translations.accessibility.hint.navigatesTo, {
          pageName: logoAction?.actionPayload
        });
      case "SEND_IFRAME_MESSAGE":
        return undefined;
      default:
        return formatMessage(translations.accessibility.hint.navHeaderLogoButton);
    }
  }, [formatMessage, logoAction?.action, logoAction?.actionPayload]);

  // IMD Logo selection and callback
  const handleLogoPress = useCallback(async () => {
    if (customHeaderLogoAction) {
      await customHeaderLogoAction();
      return;
    }

    // Default behaviour
    navigation.navigate(Routes.home);
    trackEvent({
      data: {},
      eventType: AnalyticsEventName.nav_logo
    });
  }, [customHeaderLogoAction, navigation, trackEvent]);
  const logoAlignment = useMemo(() => {
    switch (position) {
      case "LEFT":
        return "flex-start";
      case "MIDDLE":
        return isNarrowScreen ? "end" : "center";
      case "RIGHT":
        return "flex-end";
      default:
        return "flex-start";
    }
  }, [isNarrowScreen, position]);
  const logo = useMemo(() => {
    const currentOrganization = user?.currentOrganization;
    const orgIcon = currentOrganization?.icon;
    if (currentOrganization && orgIcon) {
      return <_StyledImage accessibilityLabel={formatMessage(translations.accessibility.label.organizationLogo, {
        organizationName: user.currentOrganization.name
      })}
      /* TODO: Can the actual dimensions be determined somehow? This is a guesstimate of decent logo width, but each logo is different */ resizeMode="contain" source={{
        uri: orgIcon
      }} $_css={logoSize * 1.5} />;
    } else {
      const currentRegion = formatMessage(currentRegionTranslation);
      const accessibilityLabel = formatMessage(translations.accessibility.label.imdLogo, {
        region: currentRegion
      });
      switch (currentRegionSlug) {
        case "ca":
          return <CpImdLogoCanada accessibilityLabel={accessibilityLabel} accessibilityRole="image" fill={name === "dark" ? textColors.default : undefined} height={logoSize} />;
          break;
        // case "latam":
        //   return <CpImdLogoLatAm height={logoSize} />
        //   break
        case "us":
          return <CpImdLogoUnitedStates accessibilityLabel={accessibilityLabel} accessibilityRole="image" fill={name === "dark" ? textColors.default : undefined} height={logoSize} />;
          break;
        default:
          return <CpImdLogo accessibilityLabel={accessibilityLabel} accessibilityRole="image" fill={name === "dark" ? textColors.default : undefined} height={logoSize} />;
          break;
      }
    }
  }, [currentRegionSlug, currentRegionTranslation, formatMessage, name, textColors.default, user?.currentOrganization]);
  return <_StyledPressable accessibilityHint={logoAccessibilityHint} accessibilityRole="imagebutton" onLayout={onLayout} onPress={handleLogoPress} testID="CpHeaderLogo" $_css2={[styles.navHeaderStyles.logo, css`
          align-self: ${logoAlignment};
        `]}>
      <_StyledView $_css3={logoAlignment}>
        {logo}
      </_StyledView>
    </_StyledPressable>;
};
export default CpHeaderLogo;
var _StyledImage = _styled(Image).withConfig({
  displayName: "CpHeaderLogo___StyledImage",
  componentId: "sc-1h3t7d4-0"
})(["height:", "px;min-width:", "px;"], logoSize, p => p.$_css);
var _StyledPressable = _styled(Pressable).withConfig({
  displayName: "CpHeaderLogo___StyledPressable",
  componentId: "sc-1h3t7d4-1"
})(["", ""], p => p.$_css2);
var _StyledView = _styled(View).withConfig({
  displayName: "CpHeaderLogo___StyledView",
  componentId: "sc-1h3t7d4-2"
})(["align-self:", ";"], p => p.$_css3);