var _CpHomeTabView_tabsConfig;
import React, { useCallback, useMemo } from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import CpTabView from "components/common/CpTabView";
import CpLegacyHomeTab from "components/home/tabs/CpLegacyHomeTab";
import CpResourceNodeViewTab from "components/home/tabs/CpResourceNodeViewTab";
import CpTopicNodeTab from "components/home/tabs/CpTopicNodeTab";
import useAnalytics from "hooks/useAnalytics";
import useImdSession from "hooks/useImdSession";
import { useIntl } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { AnalyticsEventName } from "services/Analytics";
import { screenTranslations } from "translations/screens";
import CpResourceNodeSearchTab from "./tabs/CpResourceNodeSearchTab";
import CpResourceNodeTab from "./tabs/CpResourceNodeTab";
const tabsConfigFragment = _CpHomeTabView_tabsConfig !== void 0 ? _CpHomeTabView_tabsConfig : _CpHomeTabView_tabsConfig = require("./__generated__/CpHomeTabView_tabsConfig.graphql");

/**
 * Renders the home screen content tabs based on the current organization's settings.
 */
const CpHomeTabView = ({
  style
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    setParams
  } = useNavigation();
  const {
    params
  } = useRoute();
  const {
    appConfig
  } = useImdSession();
  const {
    trackEvent
  } = useAnalytics();
  const defaultTabRules = useMemo(() => [{
    invertMatch: null,
    position: "TOP",
    rank: 1,
    screenHeightIs: [],
    screenWidthIs: []
  }], []);
  const defaultTabConfig = useMemo(() => ({
    hideBarIfOneTab: false,
    tabPositionRules: defaultTabRules,
    tabs: [{
      _id: "fallback",
      isDefault: true,
      tabLabel: formatMessage(screenTranslations.home),
      type: null
    }]
  }), [defaultTabRules, formatMessage]);
  const homeTabsConfig = useFragment(tabsConfigFragment, appConfig?.appHome ?? null) ?? defaultTabConfig;
  const handleChangeTab = useCallback((tab, index) => {
    setParams({
      tab: `${index}`
    });
    trackEvent({
      data: {
        tabIndex: index,
        tabName: tab
      },
      eventType: AnalyticsEventName.nav_home_tab
    });
  }, [trackEvent, setParams]);
  return <CpTabView hideTabs={homeTabsConfig?.hideBarIfOneTab} onPress={handleChangeTab} style={style}
  // This controls the tab labels that get rendered, and the components that are rendered for each tab
  tabConfig={useMemo(() => {
    return (homeTabsConfig?.tabs ?? []).map((tab, index) => {
      if (
      // tab number is in query string. Report name of tab with this number
      params?.tab && parseInt(params?.tab, 10) === index ||
      // No tab number in query string. Report name of default (0) tab
      !params?.tab && index === 0) {
        trackGoogleEvent({
          name: "TabOpened",
          action: tab.tabLabel || "No Label"
        });
      }
      switch (tab.type) {
        case "ResourceNodeSearchTab":
          return {
            component: <CpResourceNodeSearchTab key={tab._id} tab={tab.type ? tab : null} />,
            key: tab._id,
            label: tab.tabLabel ?? ""
          };
        case "ResourceNodeTab":
          return {
            component: <CpResourceNodeTab tab={tab.type ? tab : null} />,
            key: tab._id,
            label: tab.tabLabel ?? ""
          };
        case "ResourceNodeViewTab":
          return {
            component: <CpResourceNodeViewTab tab={tab} />,
            key: tab._id,
            label: tab.tabLabel ?? ""
          };
        case "TopicNodeTab":
          return {
            component: <CpTopicNodeTab key={tab._id} tab={tab} />,
            key: tab._id,
            label: tab.tabLabel ?? ""
          };
        default:
          // Fallback logic for missing tab config
          return {
            component: <CpLegacyHomeTab key={tab._id} />,
            key: tab._id,
            label: tab.tabLabel ?? ""
          };
      }
    });
  }, [homeTabsConfig?.tabs, params?.tab, trackGoogleEvent])} tabIndex={params?.tab ? parseInt(params?.tab, 10) : undefined} tabRules={homeTabsConfig.tabPositionRules} />;
};
export default CpHomeTabView;