import _styled from "styled-components";
import React from "react";
import { View } from "react-native";
import WebView from "react-native-webview";
import { useFragment } from "react-relay/hooks";
import PDFObject from "pdfobject";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpPdfResourceViewer from "components/topic/CpPdfResourceViewer";
import { resourceFragment } from "components/topic/CpResourceCommon";
import { getNameVariant } from "services/Graphql";
import { css } from "services/Theme";
const localStyles = {
  pdfView: css`
    flex: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `
};

/**
 * Checks whether we can support displaying PDFs in an iframe.
 * Note that navigator.pdfViewerEnabled doesn't work for this because Apple is filled with a bunch
 * of liars that set this value to true even though Safari on iPad doesn't support multipage PDFs in
 * an iframe >:(
 */
function supportsIframePdf() {
  return PDFObject.supportsPDFs;
}

/**
 * Renders a PDF resource
 */
const CpPdfResource = ({
  resource,
  style
}) => {
  const data = useFragment(resourceFragment, resource);
  const uri = data?.url;
  if (!uri) return null;
  const view = supportsIframePdf() ? <WebView originWhitelist={["*"]} source={{
    uri
  }} /> : <CpPdfResourceViewer uri={uri} />;
  return <_StyledView style={style} testID="CpPdfResource" $_css={localStyles.pdfView}>
      <CpUpdatePageTitle pageType="article" title={getNameVariant(data)} />
      {view}
    </_StyledView>;
};
export default CpPdfResource;
var _StyledView = _styled(View).withConfig({
  displayName: "CpPdfResource___StyledView",
  componentId: "sc-1yzb4rx-0"
})(["", ""], p => p.$_css);