import _styled from "styled-components";
import React from "react";
import { View } from "react-native";
import CpError from "components/common/CpError";
import { ExtendableError } from "services/Error";
import Log from "services/Log";
import { styles } from "services/Theme";

// Clear error and error are optional in order to allow those props to be merged into a base element

const DefaultFallback = ({
  clearError,
  error,
  style
}) => {
  Log.info("CtErrorBoundary - rendering default fallback");
  return <View style={style}>
      <_StyledCpError error={error} onPress={clearError} $_css={styles.errorBox} />
    </View>;
};

/**
 * Wraps components that throw errors during rendering and displays whatever is passed via the fallback prop.
 */
class CtErrorBoundary extends React.Component {
  state = {};
  static getDerivedStateFromError(error) {
    Log.info("CtErrorBoundary - caught error", {
      error
    });
    if (error instanceof ExtendableError) {
      return {
        error
      };
    } else {
      Log.error("CtErrorBoundary - unrecognized error", error);
      const customError = new ExtendableError(error.message);
      customError.stack = error.stack;
      return {
        error: customError
      };
    }
  }
  clearError = () => {
    this.setState({
      error: undefined
    });
  };
  render() {
    const {
      FallbackComponent = DefaultFallback,
      children,
      style
    } = this.props;
    const {
      error
    } = this.state;
    if (error) {
      const extraProps = {
        clearError: this.clearError,
        error,
        style
      };
      return React.isValidElement(FallbackComponent) ? React.cloneElement(FallbackComponent, extraProps) : React.createElement(FallbackComponent, extraProps);
    }
    return <>{children}</>;
  }
}
export default CtErrorBoundary;
var _StyledCpError = _styled(CpError).withConfig({
  displayName: "CtErrorBoundary___StyledCpError",
  componentId: "sc-1uybxdx-0"
})(["", ""], p => p.$_css);