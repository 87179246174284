import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { Image, View } from "react-native";
import CpItem from "components/common/CpItem";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import { css, rnStyles } from "services/Theme";
import translations from "translations";
export const thumbnailButtonSizes = {
  // NB: Large thumbnail buttons have an additional 8px margin on either side
  largeButton: {
    height: 200,
    imageHeight: 120,
    width: 180
  },
  mediumButton: {
    height: 140,
    imageHeight: 82,
    width: 120
  },
  smallButton: {
    height: 70,
    imageWidth: 80
  }
};
const commonStyles = {
  buttonContainer: css`
    background-color: ${({
    theme
  }) => theme.buttonColors.surface};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
  `,
  expandIconContainer: css`
    background-color: ${({
    theme
  }) => theme.brandColors.secondaryBackground};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    padding: ${({
    theme
  }) => theme.spacing.third}px;
    position: absolute;
    right: ${({
    theme
  }) => theme.spacing.single}px;
    top: 0px;
    z-index: ${({
    theme
  }) => theme.layers.secondLayer};
  `,
  imageContainer: css`
    align-items: center;
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
  `,
  text: css`
    /* Overriding inherent line-height to ensure longer text fits somewhat legibly  */
    line-height: 105%;
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
  `,
  textContainer: css`
    justify-content: center;
  `
};

// Styles specifically for displaying on larger screens (i.e. desktop and tablet)
const largeStyles = {
  buttonContainer: css`
    ${commonStyles.buttonContainer};
    height: ${thumbnailButtonSizes.largeButton.height}px;
    margin-bottom: ${({
    theme
  }) => theme.spacing.double}px;
    margin-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
    width: ${thumbnailButtonSizes.largeButton.width}px;
  `,
  image: css`
    border-top-left-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    border-top-right-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    height: 100%;
    width: 100%;
  `,
  imageContainer: css`
    ${commonStyles.imageContainer};
    height: ${thumbnailButtonSizes.largeButton.imageHeight}px;
    justify-content: center;
    padding: ${({
    theme
  }) => theme.spacing.half}px;
    width: ${thumbnailButtonSizes.largeButton.width}px;
  `,
  innerContainer: css`
    align-items: center;
  `,
  text: css`
    ${commonStyles.text};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    overflow: hidden;
    text-align: center;
  `,
  textContainer: css`
    ${commonStyles.textContainer};
    height: ${thumbnailButtonSizes.largeButton.height - thumbnailButtonSizes.largeButton.imageHeight}px;
    padding-bottom: ${({
    theme
  }) => theme.spacing.single}px;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
    padding-top: ${({
    theme
  }) => theme.spacing.half}px;
    width: ${thumbnailButtonSizes.largeButton.width}px;
  `
};

// Styles specifically for displaying on larger screens (i.e. desktop and tablet) but as in a more compact form (e.g. carousel ribbon of siblings when viewing a diagram)
const mediumStyles = {
  buttonContainer: css`
    ${commonStyles.buttonContainer};
    height: ${thumbnailButtonSizes.mediumButton.height}px;
    margin-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
    margin-vertical: ${({
    theme
  }) => theme.spacing.single}px;
    width: ${thumbnailButtonSizes.mediumButton.width}px;
  `,
  image: css`
    border-top-left-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    border-top-right-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    height: 100%;
    width: 100%;
  `,
  imageContainer: css`
    ${commonStyles.imageContainer};
    height: ${thumbnailButtonSizes.mediumButton.imageHeight}px;
    justify-content: center;
    padding: ${({
    theme
  }) => theme.spacing.half}px;
    width: ${thumbnailButtonSizes.mediumButton.width}px;
  `,
  innerContainer: css`
    align-items: center;
  `,
  text: css`
    ${commonStyles.text};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    overflow: hidden;
    text-align: center;
  `,
  textContainer: css`
    ${commonStyles.textContainer};
    height: ${thumbnailButtonSizes.mediumButton.height - thumbnailButtonSizes.mediumButton.imageHeight}px;
    padding-bottom: ${({
    theme
  }) => theme.spacing.single}px;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
    padding-top: ${({
    theme
  }) => theme.spacing.half}px;
    width: ${thumbnailButtonSizes.mediumButton.width}px;
  `
};

// Styles specifically for displaying on smaller screens (i.e. mobile)
// Renders as horizontal tile, spanning full width of screen
// TODO: Fix buttons with shorter labels since they don't take up full width of screen
const smallStyles = {
  buttonContainer: css`
    ${commonStyles.buttonContainer};
    height: ${thumbnailButtonSizes.smallButton.height}px;
    justify-content: center;
    margin-vertical: ${({
    theme
  }) => theme.spacing.single}px;
    width: 100%;
  `,
  image: css`
    border-bottom-right-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    border-top-right-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    height: 100%;
    width: 100%;
  `,
  imageContainer: css`
    ${commonStyles.imageContainer};
    height: ${({
    theme
  }) => thumbnailButtonSizes.smallButton.height - theme.spacing.single}px;
    margin-right: ${({
    theme
  }) => theme.spacing.single}px;
    width: ${thumbnailButtonSizes.smallButton.imageWidth}px;
  `,
  innerContainer: css`
    align-items: center;
    flex-direction: row;
  `,
  text: css`
    ${commonStyles.text};
    margin-left: ${({
    theme
  }) => theme.spacing.double}px;
    margin-right: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  textContainer: css`
    ${commonStyles.textContainer};
    flex: 1;
    height: ${({
    theme
  }) => thumbnailButtonSizes.smallButton.height - theme.spacing.single}px;
  `
};
/**
 * Renders thumbnail and label as a list item. Tiles for larger screens or narrower button for small screens
 */
const CpThumbnailItem = ({
  icon,
  name,
  selected = false,
  showExpandableIcon = false,
  size,
  testID,
  uri,
  ...otherProps
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    brandColors,
    iconSizes
  } = useAppTheme();
  const componentStyles = useMemo(() => {
    switch (size) {
      case "Large":
        return largeStyles;
      case "Medium":
        return mediumStyles;
      default:
        return smallStyles;
    }
  }, [size]);
  const thumbnail = useCallback(() => {
    if (uri) {
      return <_StyledView $_css={componentStyles.imageContainer}>
          {showExpandableIcon && <_StyledView2 $_css2={commonStyles.expandIconContainer}>
              <PrIcon fill={brandColors.primary} icon={IconName.fullscreen} size={size === "Large" ? iconSizes.medium : iconSizes.small} />
            </_StyledView2>}
          <_StyledImage accessibilityLabel={formatMessage(translations.accessibility.label.thumbnailFor, {
          thumbnailName: name
        })} accessibilityRole="image" resizeMode="cover" source={{
          uri
        }} $_css3={componentStyles.image} />
        </_StyledView>;
    } else if (icon) {
      return <_StyledView3 $_css4={componentStyles.imageContainer}>
          <_StyledPrIcon accessibilityLabel="" accessibilityRole="none" fill={brandColors.primary} icon={icon} $_css5={componentStyles.image} />
        </_StyledView3>;
    } else {
      return <_StyledView4 $_css6={componentStyles.imageContainer}>
          <_StyledPrIcon2 accessibilityLabel="" accessibilityRole="none" fill={brandColors.primary} icon={IconName.iconImd} $_css7={componentStyles.image} />
        </_StyledView4>;
    }
  }, [brandColors.primary, componentStyles.image, componentStyles.imageContainer, formatMessage, icon, iconSizes, name, showExpandableIcon, size, uri]);
  return <_StyledCpItem style={[selected ? rnStyles.buttonShadowSelected : rnStyles.buttonShadow, {
    shadowColor: brandColors.primary
  }]} testID={testID} {...otherProps} $_css8={componentStyles.buttonContainer}>
      <_StyledView5 $_css9={componentStyles.innerContainer}>
        {size !== "Small" && thumbnail()}
        <_StyledView6 $_css10={componentStyles.textContainer}>
          <_StyledPrText children={name} numberOfLines={size === "Small" ? 2 : 3} $_css11={componentStyles.text} />
        </_StyledView6>
        {size === "Small" && thumbnail()}
      </_StyledView5>
    </_StyledCpItem>;
};
export default CpThumbnailItem;
var _StyledView = _styled(View).withConfig({
  displayName: "CpThumbnailItem___StyledView",
  componentId: "sc-3bjase-0"
})(["", ""], p => p.$_css);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CpThumbnailItem___StyledView2",
  componentId: "sc-3bjase-1"
})(["", ""], p => p.$_css2);
var _StyledImage = _styled(Image).withConfig({
  displayName: "CpThumbnailItem___StyledImage",
  componentId: "sc-3bjase-2"
})(["", ""], p => p.$_css3);
var _StyledView3 = _styled(View).withConfig({
  displayName: "CpThumbnailItem___StyledView3",
  componentId: "sc-3bjase-3"
})(["", ""], p => p.$_css4);
var _StyledPrIcon = _styled(PrIcon).withConfig({
  displayName: "CpThumbnailItem___StyledPrIcon",
  componentId: "sc-3bjase-4"
})(["", ""], p => p.$_css5);
var _StyledView4 = _styled(View).withConfig({
  displayName: "CpThumbnailItem___StyledView4",
  componentId: "sc-3bjase-5"
})(["", ""], p => p.$_css6);
var _StyledPrIcon2 = _styled(PrIcon).withConfig({
  displayName: "CpThumbnailItem___StyledPrIcon2",
  componentId: "sc-3bjase-6"
})(["", ""], p => p.$_css7);
var _StyledCpItem = _styled(CpItem).withConfig({
  displayName: "CpThumbnailItem___StyledCpItem",
  componentId: "sc-3bjase-7"
})(["", ""], p => p.$_css8);
var _StyledView5 = _styled(View).withConfig({
  displayName: "CpThumbnailItem___StyledView5",
  componentId: "sc-3bjase-8"
})(["", ""], p => p.$_css9);
var _StyledView6 = _styled(View).withConfig({
  displayName: "CpThumbnailItem___StyledView6",
  componentId: "sc-3bjase-9"
})(["", ""], p => p.$_css10);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpThumbnailItem___StyledPrText",
  componentId: "sc-3bjase-10"
})(["", ""], p => p.$_css11);