import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from "react";
import { View } from "react-native";
import PrPressable from "components/primitives/PrPressable";
import { css, styles } from "services/Theme";
const localStyles = {
  bottomContent: css`
    color: ${({
    theme
  }) => theme.textColors.muted};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `,
  centerContent: css`
    flex: 1;
  `,
  content: css`
    flex-direction: row;
    align-items: center;
    min-height: ${({
    theme
  }) => theme.buttonHeights.large}px;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  leftContent: css`
    margin-right: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  rightContent: css`
    margin-left: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  separator: css`
    border-bottom-color: ${({
    theme
  }) => theme.textColors.default};
    border-bottom-width: 1px;
    height: 1px;
    margin-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
    opacity: 0.1;
  `,
  topContent: css`
    font-size: ${({
    theme
  }) => theme.fontSize("xlarge")};
    font-weight: bold;
  `
};
/**
 * A generic component for rendering items that typically appear in a list and/or are pressable.
 * Its intended as a starting point for building more specific list item components and will take care of some of the boilerplate setup.
 *
 * It provides some convenience features for commonly used patterns. e.g.
 * left - for icons or status bars
 * top - for titles
 * bottom - for footers
 * right - for action buttons
 */
const CpItem = ({
  accessibilityHint,
  accessibilityRole = "button",
  bottom,
  children,
  disabled,
  left,
  onAuxCallback,
  onPress,
  onPressCallback,
  onPressValue,
  right,
  separator,
  top,
  ...otherProps
}) => {
  const handlePress = useCallback(event => {
    onPressCallback && onPressValue && onPressCallback(onPressValue);
    onPress && onPress(event);
  }, [onPressCallback, onPressValue, onPress]);
  const handleAuxClick = useCallback(() => {
    if (onAuxCallback) {
      if (onPressValue) onAuxCallback(onPressValue);
    }
  }, [onAuxCallback, onPressValue]);
  return <_StyledPrPressable accessibilityHint={accessibilityHint ? accessibilityHint : undefined} accessibilityRole={accessibilityRole} disabled={disabled || !onPress && !onPressCallback} onAuxClick={handleAuxClick} onPress={handlePress} {...otherProps} $_css={styles.paddingNone}>
      <_StyledView $_css2={localStyles.content}>
        {left && <_StyledView2 $_css3={localStyles.leftContent}>{left}</_StyledView2>}
        <_StyledView3 $_css4={localStyles.centerContent}>
          {top && <_StyledView4 $_css5={localStyles.topContent}>{top}</_StyledView4>}
          {children}
          {bottom && <_StyledView5 $_css6={localStyles.bottomContent}>{bottom}</_StyledView5>}
        </_StyledView3>
        {right && <_StyledView6 $_css7={localStyles.rightContent}>{right}</_StyledView6>}
      </_StyledView>
      {separator && <_StyledView7 $_css8={localStyles.separator} />}
    </_StyledPrPressable>;
};
export default CpItem;
var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpItem___StyledPrPressable",
  componentId: "sc-awag17-0"
})(["", ""], p => p.$_css);
var _StyledView = _styled(View).withConfig({
  displayName: "CpItem___StyledView",
  componentId: "sc-awag17-1"
})(["", ""], p => p.$_css2);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CpItem___StyledView2",
  componentId: "sc-awag17-2"
})(["", ""], p => p.$_css3);
var _StyledView3 = _styled(View).withConfig({
  displayName: "CpItem___StyledView3",
  componentId: "sc-awag17-3"
})(["", ""], p => p.$_css4);
var _StyledView4 = _styled(View).withConfig({
  displayName: "CpItem___StyledView4",
  componentId: "sc-awag17-4"
})(["", ""], p => p.$_css5);
var _StyledView5 = _styled(View).withConfig({
  displayName: "CpItem___StyledView5",
  componentId: "sc-awag17-5"
})(["", ""], p => p.$_css6);
var _StyledView6 = _styled(View).withConfig({
  displayName: "CpItem___StyledView6",
  componentId: "sc-awag17-6"
})(["", ""], p => p.$_css7);
var _StyledView7 = _styled(View).withConfig({
  displayName: "CpItem___StyledView7",
  componentId: "sc-awag17-7"
})(["", ""], p => p.$_css8);