import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import camelcase from "camelcase";
import React from "react";
import { Image, View } from "react-native";
import isUrlString from "../../helpers/isUrlString";
import useAppTheme from "../../hooks/useAppTheme";
import { iconTypeMapping } from "../../services/Graphql";
import { css, styles } from "../../services/Theme";
import PrIcon, { IconName } from "../primitives/PrIcon";
import PrPressable from "../primitives/PrPressable";
import PrText from "../primitives/PrText";
const localStyles = {
  dropdownChevron: css`
    margin-left: auto;
  `,
  flexContainer: css`
    ${styles.rowAndCenter}
    gap: 1em;
  `,
  iconContainer: {
    large: css`
      height: ${({
      theme
    }) => theme.buttonHeights.xxlarge}px;
    `,
    small: css`
      height: ${({
      theme
    }) => theme.buttonHeights.small}px;
    `
  },
  imageIcon: css`
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    height: 100%;
    width: 100%;
  `,
  itemLabel: css`
    flex: 3;
  `
};
function parseIconName(icon) {
  const camelCaseName = camelcase(icon ?? "");
  if (Object.values(IconName).includes(camelCaseName)) {
    return camelCaseName;
  }
  return null;
}
function getIconToDisplay(icon, isLeaf, resourceType) {
  let displayedIcon = IconName.iconImd;
  if (!isLeaf) {
    displayedIcon = IconName.folderOpen;
  } else {
    const parsedIcon = parseIconName(icon);
    if (parsedIcon) {
      displayedIcon = parsedIcon;
    } else if (resourceType && Object.keys(iconTypeMapping).includes(resourceType)) {
      // If no logo or associated icon is assigned, try to assign an icon by the resource type
      displayedIcon = iconTypeMapping[resourceType];
    }
  }
  return displayedIcon;
}

/**
 * Displays an item within a CpTopicCard.
 */
const CpTopicCardItemView = ({
  depth,
  expanded = false,
  icon = null,
  isLeaf = false,
  label,
  onAuxSelect,
  onSelect,
  resourceType = null
}) => {
  const {
    buttonColors,
    iconSizes
  } = useAppTheme();

  // If the parent passes in an image URL in "icon", use this parameter to display the image
  let imageUri;
  // If the parent does not pass in an image URL, use this parameter to display an SVG icon
  let displayedIcon = IconName.iconImd;
  if (icon && isUrlString(icon) && !depth) {
    imageUri = icon;
  } else {
    displayedIcon = getIconToDisplay(icon, isLeaf, resourceType);
  }

  // The icon to display on the side
  let sideIconName = null;
  // Only display something if the main icon is an image (brand logo)
  if (imageUri) {
    sideIconName = getIconToDisplay(icon, isLeaf, resourceType);
  } else if (!isLeaf) {
    sideIconName = expanded ? IconName.keyboardArrowUp : IconName.keyboardArrowDown;
  }
  const iconContainer = <_StyledView $_css={[styles.flexSingle, depth ? localStyles.iconContainer.small : localStyles.iconContainer.large, css`
          margin-left: ${depth}em;
        `]}>
      {imageUri ? <_StyledImage resizeMode="contain" source={{
      uri: imageUri
    }} $_css2={localStyles.imageIcon} /> : <PrIcon fill={buttonColors.secondary} height="100%" icon={displayedIcon} />}
    </_StyledView>;
  const itemLabel = <_StyledPrText numberOfLines={2} $_css3={localStyles.itemLabel}>
      {label}
    </_StyledPrText>;

  // A smaller sub-icon displayed to the right of the label
  // Can indicate if the item is an open/closed folder, PDF, video, etc.
  // Only used if the main icon does not display the same information
  const sideIcon = sideIconName ? <_StyledPrIcon fill={buttonColors.secondary} icon={sideIconName} size={iconSizes.medium} $_css4={localStyles.dropdownChevron} /> : null;
  return <_StyledPrPressable onAuxClick={onAuxSelect} onPress={onSelect} $_css5={localStyles.flexContainer}>
      {iconContainer}
      {itemLabel}
      {sideIcon}
    </_StyledPrPressable>;
};
export default CpTopicCardItemView;
var _StyledView = _styled(View).withConfig({
  displayName: "CpTopicCardItemView___StyledView",
  componentId: "sc-fhhur6-0"
})(["", ""], p => p.$_css);
var _StyledImage = _styled(Image).withConfig({
  displayName: "CpTopicCardItemView___StyledImage",
  componentId: "sc-fhhur6-1"
})(["", ""], p => p.$_css2);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpTopicCardItemView___StyledPrText",
  componentId: "sc-fhhur6-2"
})(["", ""], p => p.$_css3);
var _StyledPrIcon = _styled(PrIcon).withConfig({
  displayName: "CpTopicCardItemView___StyledPrIcon",
  componentId: "sc-fhhur6-3"
})(["", ""], p => p.$_css4);
var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpTopicCardItemView___StyledPrPressable",
  componentId: "sc-fhhur6-4"
})(["", ""], p => p.$_css5);