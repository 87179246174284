import _styled from "styled-components";
import React, { useCallback, useState } from "react";
import { View } from "react-native";
import CpRadioButton from "components/form/CpRadioButton";
import { DisplayDirection } from "components/form/types";
import { css } from "services/Theme";
const localStyles = {
  container: css`
    background-color: ${({
    theme
  }) => theme.formColors.fieldBackground};
    border-color: ${({
    theme
  }) => theme.formColors.fieldLabelActive};
    border-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    border-width: 1px;
    flex-direction: column;
    flex-wrap: wrap;
  `,
  horizontalContainer: css`
    flex-direction: row;
  `
};
/**
 * Radio Segmented Button component
 */
const PrRadioSegmented = ({
  displayDirection,
  onChangeOption,
  radioOptions,
  testID,
  value = "",
  ...otherProps
}) => {
  const [isHorizontal, setIsHorizontal] = useState(displayDirection === "horizontal");
  const handleRadioLayout = useCallback(event => {
    const {
      nativeEvent: {
        layout
      }
    } = event;
    const radioOptionsAmount = radioOptions?.length;
    // If we have at least 200px of width to each option, we display horizontally, otherwise display vertically
    if (layout.width / radioOptionsAmount > 200 && displayDirection !== "vertical" || displayDirection === "horizontal") {
      setIsHorizontal(true);
    }
  }, [displayDirection, radioOptions?.length]);
  const onPress = useCallback(option => {
    onChangeOption && onChangeOption(option);
  }, [onChangeOption]);
  return <_StyledView accessibilityRole="radiogroup" {...otherProps} onLayout={handleRadioLayout} $_css={[localStyles.container, isHorizontal && localStyles.horizontalContainer]}>
      {radioOptions?.map((option, index) => <CpRadioButton displayDirection={isHorizontal ? DisplayDirection.Horizontal : DisplayDirection.Vertical} isFirst={index === 0} isLast={index === radioOptions.length - 1} key={option.value} onPress={onPress} selectedOption={value} testID={testID ? `${testID}-${option.value}` : undefined} value={option.value}>
          {option.name}
        </CpRadioButton>)}
    </_StyledView>;
};
export default PrRadioSegmented;
var _StyledView = _styled(View).withConfig({
  displayName: "PrRadioSegmented___StyledView",
  componentId: "sc-5zfonz-0"
})(["", ""], p => p.$_css);