import _styled17 from "styled-components";
import _styled16 from "styled-components";
import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
/* eslint-disable no-restricted-imports */
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ScrollView, StyleSheet, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import CpAppFooter from "components/app/CpAppFooter";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpBackgroundHeader from "components/common/CpBackgroundHeader";
import PrButton from "components/primitives/PrButton";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useAppBackend from "hooks/useAppBackend";
import useAppRegion from "hooks/useAppRegion";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import Config from "services/Config";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  buttonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
  `,
  container: css`
    ${styles.paddingDouble};
    padding-top: ${({
    theme
  }) => theme.spacing.single}px;
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  fieldContainer: css`
    margin-vertical: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  paddingBottomHalf: css`
    padding-bottom: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  paddingBottomSingle: css`
    padding-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  pageHeader: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("xxlarge")};
    font-weight: bold;
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  selectorContainer: css`
    background-color: ${({
    theme
  }) => theme.brandColors.background};
  `,
  selectorInsert: css`
    background-color: ${({
    theme
  }) => theme.buttonColors.primary};
    height: 100%;
    width: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  selectorLabel: css`
    ${styles.uppercase};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    padding-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  selectorText: css`
    padding-left: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  selectorTextSelected: css`
    margin-left: -${({
    theme
  }) => theme.spacing.half}px;
  `,
  selectorVerticalBase: css`
    align-items: center;
    border: 1px solid ${({
    theme
  }) => theme.buttonColors.primary};
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    flex-direction: row;
    height: ${({
    theme
  }) => theme.buttonHeights.medium}px;
    margin-bottom: -1px;
    overflow: hidden;
    padding: 0px;
  `,
  selectorVerticalBottom: css`
    border-bottom-left-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border-bottom-right-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    margin-bottom: 0px;
  `,
  selectorVerticalTop: css`
    border-top-left-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border-top-right-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
  `,
  topMargin: css`
    margin-top: ${({
    theme
  }) => theme.spacing.single}px;
  `
};
const rnStyles = StyleSheet.create({
  scrollView: {
    alignItems: "center",
    flex: 1
  }
});
/**
 * Screen for changing the region and the starting language upon change
 */
const ScChangeRegion = ({
  route
}) => {
  const {
    contentMinHeight,
    isNarrowScreen
  } = useScreenLayout();
  // Get backend for current region
  const {
    whereamiResult
  } = useAppBackend();
  const {
    currentRegionTranslation
  } = useAppRegion();
  const [desiredRegionConfig, setDesiredRegionConfig] = useState(whereamiResult.current);
  const currentRegionConfig = whereamiResult.current;
  const supportedRegionConfigs = whereamiResult.supported;
  const {
    navigate
  } = useNavigation();
  const handleConfirm = useCallback(() => {
    if (currentRegionConfig.regionTranslationKey === desiredRegionConfig.regionTranslationKey) {
      navigate(Routes.home);
    } else {
      window.location.href = Config.APP_URL_PREFIX ? `${window.location.protocol}//${Config.APP_URL_PREFIX}.${desiredRegionConfig.regionalAppDomainPrefix}` : `${window.location.protocol}//${desiredRegionConfig.regionalAppDomainPrefix}`;
    }
  }, [currentRegionConfig.regionTranslationKey, desiredRegionConfig.regionTranslationKey, desiredRegionConfig.regionalAppDomainPrefix, navigate]);
  const handleCancel = useCallback(() => {
    navigate(Routes.home);
  }, [navigate]);
  return <_StyledSafeAreaView $_css={[styles.flexSingle, styles.screen]}>
      <CpUpdatePageTitle title={translations.screens[route.name]} />
      <ScrollView contentContainerStyle={rnStyles.scrollView}>
        <CpBackgroundHeader header={<FormattedMessage id="x/2/VK" defaultMessage="Current Region: {region}" values={{
        region: <_StyledPrText children={currentRegionTranslation} $_css2={styles.italicText} />
      }} />}>
          <_StyledView $_css3={[localStyles.container, !isNarrowScreen ? styles.limitedLargeScreenWidth : undefined, css`
                min-height: ${contentMinHeight}px;
              `]}>
            <_StyledPrText2 $_css4={[styles.paddingTopDouble, localStyles.paddingBottomHalf]}>
              <FormattedMessage id="Nore2G" defaultMessage="To ensure you’re viewing the most relevant information, please select your region or country." />
            </_StyledPrText2>
            <_StyledPrText3 $_css5={[localStyles.paddingBottomSingle, styles.italicText]}>
              <FormattedMessage id="tGiqvn" defaultMessage="Please note that some content is exclusive to certain regions." />
            </_StyledPrText3>

            {/* Region/Country Selection */}
            <_StyledView2 $_css6={localStyles.fieldContainer}>
              <_StyledPrText4 $_css7={localStyles.selectorLabel}>
                <FormattedMessage id="M3uiWx" defaultMessage="Select a Country/Region" />
              </_StyledPrText4>
              <_StyledView3 $_css8={localStyles.selectorContainer}>
                {supportedRegionConfigs.map((regionConfig, index) => {
                const isSelected = regionConfig.regionTranslationKey === desiredRegionConfig.regionTranslationKey;
                const regionTranslation = regionConfig.regionTranslationKey in translations.regions ? translations.regions[regionConfig.regionTranslationKey] : null;
                return <_StyledPrPressable disabled={isSelected} key={regionConfig.region} onPress={() => setDesiredRegionConfig(regionConfig)} $_css9={[localStyles.selectorVerticalBase, index === 0 && localStyles.selectorVerticalTop, index === supportedRegionConfigs.length - 1 && localStyles.selectorVerticalBottom, isSelected && styles.selected]}>
                      {isSelected && <_StyledView4 $_css10={localStyles.selectorInsert} />}
                      <_StyledPrText5 children={regionTranslation} $_css11={[localStyles.selectorText, isSelected && localStyles.selectorTextSelected]} />
                    </_StyledPrPressable>;
              })}
              </_StyledView3>
            </_StyledView2>

            <_StyledPrText6 $_css12={styles.paddingTopDouble}>
              <FormattedMessage id="A9O1Tf" defaultMessage="Note: Changing your current country or region will redirect you to a the correct IMD website for that region." />
            </_StyledPrText6>
            <_StyledPrButton disabled={desiredRegionConfig.regionTranslationKey === currentRegionConfig.regionTranslationKey} onPress={handleConfirm} type="raised" $_css13={localStyles.topMargin}>
              <_StyledPrText7 $_css14={localStyles.buttonText}>
                <FormattedMessage id="fWcc+J" defaultMessage="Change Country/Region" />
              </_StyledPrText7>
            </_StyledPrButton>
            <_StyledPrButton2 onPress={handleCancel} type="outline" $_css15={localStyles.topMargin}>
              <_StyledPrText8 children={translations.buttons.cancel} $_css16={localStyles.buttonText} />
            </_StyledPrButton2>
          </_StyledView>
          <_StyledCpAppFooter $_css17={styles.fullWidth} />
        </CpBackgroundHeader>
      </ScrollView>
    </_StyledSafeAreaView>;
};
export default ScChangeRegion;
var _StyledSafeAreaView = _styled(SafeAreaView).withConfig({
  displayName: "ScChangeRegion___StyledSafeAreaView",
  componentId: "sc-1ino3fa-0"
})(["", ""], p => p.$_css);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "ScChangeRegion___StyledPrText",
  componentId: "sc-1ino3fa-1"
})(["", ""], p => p.$_css2);
var _StyledView = _styled(View).withConfig({
  displayName: "ScChangeRegion___StyledView",
  componentId: "sc-1ino3fa-2"
})(["", ""], p => p.$_css3);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "ScChangeRegion___StyledPrText2",
  componentId: "sc-1ino3fa-3"
})(["", ""], p => p.$_css4);
var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "ScChangeRegion___StyledPrText3",
  componentId: "sc-1ino3fa-4"
})(["", ""], p => p.$_css5);
var _StyledView2 = _styled(View).withConfig({
  displayName: "ScChangeRegion___StyledView2",
  componentId: "sc-1ino3fa-5"
})(["", ""], p => p.$_css6);
var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "ScChangeRegion___StyledPrText4",
  componentId: "sc-1ino3fa-6"
})(["", ""], p => p.$_css7);
var _StyledView3 = _styled(View).withConfig({
  displayName: "ScChangeRegion___StyledView3",
  componentId: "sc-1ino3fa-7"
})(["", ""], p => p.$_css8);
var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "ScChangeRegion___StyledPrPressable",
  componentId: "sc-1ino3fa-8"
})(["", ""], p => p.$_css9);
var _StyledView4 = _styled(View).withConfig({
  displayName: "ScChangeRegion___StyledView4",
  componentId: "sc-1ino3fa-9"
})(["", ""], p => p.$_css10);
var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "ScChangeRegion___StyledPrText5",
  componentId: "sc-1ino3fa-10"
})(["", ""], p => p.$_css11);
var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "ScChangeRegion___StyledPrText6",
  componentId: "sc-1ino3fa-11"
})(["", ""], p => p.$_css12);
var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "ScChangeRegion___StyledPrButton",
  componentId: "sc-1ino3fa-12"
})(["", ""], p => p.$_css13);
var _StyledPrText7 = _styled(PrText).withConfig({
  displayName: "ScChangeRegion___StyledPrText7",
  componentId: "sc-1ino3fa-13"
})(["", ""], p => p.$_css14);
var _StyledPrButton2 = _styled(PrButton).withConfig({
  displayName: "ScChangeRegion___StyledPrButton2",
  componentId: "sc-1ino3fa-14"
})(["", ""], p => p.$_css15);
var _StyledPrText8 = _styled(PrText).withConfig({
  displayName: "ScChangeRegion___StyledPrText8",
  componentId: "sc-1ino3fa-15"
})(["", ""], p => p.$_css16);
var _StyledCpAppFooter = _styled(CpAppFooter).withConfig({
  displayName: "ScChangeRegion___StyledCpAppFooter",
  componentId: "sc-1ino3fa-16"
})(["", ""], p => p.$_css17);