import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useContext } from "react";
import { View } from "react-native";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import CxPresentationSupport from "contexts/CxPresentationSupport";
import useAppTheme from "hooks/useAppTheme";
import useScreenLayout from "hooks/useScreenLayout";
import useTopicContext from "hooks/useTopicContext";
import Routes from "navigators/Routes";
import { css, styles } from "services/Theme";
import translations from "translations";
const buttonBase = css`
  align-items: center;
  background-color: ${({
  theme
}) => theme.brandColors.surface};
  border: 1px solid ${({
  theme
}) => theme.buttonColors.primary};
  flex: 1;
`;
const buttonNextBase = css`
  border-left-width: 0px;
`;
const buttonPreviousBase = css`
  border-right-width: 0px;
`;
const localStyles = {
  container: css`
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
    padding-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  disabled: css`
    color: ${({
    theme
  }) => theme.buttonColors.textInputDisabled};
  `
};
const largeScreenStyles = {
  buttonContainer: css`
    ${styles.rowAndCenter};
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
    margin-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
    margin-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  buttonNext: css`
    ${buttonBase};
    ${buttonNextBase};
    flex-direction: row;
    height: ${({
    theme
  }) => theme.buttonHeights.large}px;
    border-top-right-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border-bottom-right-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    justify-content: flex-end;
    padding-right: ${({
    theme
  }) => theme.spacing.quadruple}px;
  `,
  buttonPrevious: css`
    ${buttonBase};
    ${buttonPreviousBase};
    flex-direction: row;
    height: ${({
    theme
  }) => theme.buttonHeights.large}px;
    border-top-left-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border-bottom-left-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    justify-content: flex-start;
    padding-left: ${({
    theme
  }) => theme.spacing.quadruple}px;
  `,
  buttonText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    line-height: 105%;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
    text-align: center;
  `
};
const smallScreenStyles = {
  buttonContainer: css`
    ${styles.rowAndCenter};
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
    margin-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
    margin-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  buttonNext: css`
    ${buttonBase};
    ${buttonNextBase};
    flex-direction: row;
    height: ${({
    theme
  }) => theme.buttonHeights.large}px;
    border-top-right-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border-bottom-right-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    justify-content: flex-end;
    padding-right: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  buttonPrevious: css`
    ${buttonBase};
    ${buttonPreviousBase};
    flex-direction: row;
    height: ${({
    theme
  }) => theme.buttonHeights.large}px;
    border-top-left-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border-bottom-left-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    justify-content: flex-start;
    padding-left: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  buttonText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    line-height: 105%;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
    text-align: center;
  `,
  icon: css`
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
  `
};

/**
 * Wraps CpResourceNode to provide next/previous navigation within a presentation/training topic.
 * Relies on CxPresentationSupport being available.
 */
const CpPresentationNavigator = ({
  children,
  style
}) => {
  const {
    currentIndex = 0,
    presentationIds
  } = useContext(CxPresentationSupport);
  const {
    rootRevisionId
  } = useTopicContext();
  const {
    iconSizes
  } = useAppTheme();
  const {
    isNarrowScreen,
    isWideScreen
  } = useScreenLayout();
  const navigation = useNavigation();
  const handleOnPressNext = useCallback(() => {
    navigation.navigate(Routes.topic, {
      rootRevisionId,
      selectedRevisionId: presentationIds[currentIndex + 1]
    });
  }, [currentIndex, navigation, presentationIds, rootRevisionId]);
  const handleOnPressPrevious = useCallback(() => {
    navigation.navigate(Routes.topic, {
      rootRevisionId,
      selectedRevisionId: presentationIds[currentIndex - 1]
    });
  }, [currentIndex, navigation, presentationIds, rootRevisionId]);
  const previousDisabled = currentIndex === 0;
  const nextDisabled = currentIndex === presentationIds.length - 1;
  const componentStyle = isWideScreen ? largeScreenStyles : smallScreenStyles;
  return <View style={style}>
      {children}
      <_StyledView $_css={componentStyle.buttonContainer}>
        <_StyledPrPressable disabled={previousDisabled} onPress={handleOnPressPrevious} $_css2={componentStyle.buttonPrevious}>
          {!isNarrowScreen && <PrIcon icon={IconName.keyboardArrowLeft} size={iconSizes.medium} />}
          <_StyledPrText children={translations.buttons.previous} $_css3={[componentStyle.buttonText, previousDisabled && localStyles.disabled]} />
        </_StyledPrPressable>
        <_StyledPrPressable2 disabled={nextDisabled} onPress={handleOnPressNext} $_css4={componentStyle.buttonNext}>
          <_StyledPrText2 children={translations.buttons.next} $_css5={[componentStyle.buttonText, nextDisabled && localStyles.disabled]} />
          {!isNarrowScreen && <PrIcon icon={IconName.keyboardArrowRight} size={iconSizes.medium} />}
        </_StyledPrPressable2>
      </_StyledView>
    </View>;
};
export default CpPresentationNavigator;
var _StyledView = _styled(View).withConfig({
  displayName: "CpPresentationNavigator___StyledView",
  componentId: "sc-n3u0kx-0"
})(["", ""], p => p.$_css);
var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpPresentationNavigator___StyledPrPressable",
  componentId: "sc-n3u0kx-1"
})(["", ""], p => p.$_css2);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpPresentationNavigator___StyledPrText",
  componentId: "sc-n3u0kx-2"
})(["", ""], p => p.$_css3);
var _StyledPrPressable2 = _styled(PrPressable).withConfig({
  displayName: "CpPresentationNavigator___StyledPrPressable2",
  componentId: "sc-n3u0kx-3"
})(["", ""], p => p.$_css4);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpPresentationNavigator___StyledPrText2",
  componentId: "sc-n3u0kx-4"
})(["", ""], p => p.$_css5);