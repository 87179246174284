var _CpOrganizationShortcuts_data;
import React, { useMemo } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { View } from "react-native";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import CpGridItem from "components/home/tabs/CpGridItem";
import PrText from "components/primitives/PrText";
import { CpShortcutSectionStyles, buildShortcutSections } from "components/shortcuts/Shortcuts";
import useScreenLayout from "hooks/useScreenLayout";
import { getNameVariant } from "services/Graphql";
import CpSectionHeader from "../topic/CpSectionHeader";
const CpOrganizationShortcuts_dataFragment = _CpOrganizationShortcuts_data !== void 0 ? _CpOrganizationShortcuts_data : _CpOrganizationShortcuts_data = require("./__generated__/CpOrganizationShortcuts_data.graphql");
const localTranslations = defineMessages({
  recommendedForMe: {
    id: "7ghfe7",
    defaultMessage: "Recommended for Me"
  }
});

/**
 * Compiles and renders the content for shortcuts recommended to users by advertisers. Organizations
 * used to sue this to add shortcuts to their users, but we use custom homepages now instead.
 */
const CpOrganizationShortcuts = ({
  onPress,
  userMembershipFragment
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    organizationShortcuts
  } = useFragment(CpOrganizationShortcuts_dataFragment, userMembershipFragment);

  // Memo to extract organization shortcut information and sort them by type. Alternatively returns a "no items found" message
  const compiledOrganizationShortcuts = useMemo(() => {
    // Set fallback content if no organization shortcuts exist
    let tabContents = <View css={CpShortcutSectionStyles.noticeContainer}>
        <PrText>
          <FormattedMessage id="M206+D" defaultMessage="You do not have any recommended shortcuts." />
        </PrText>
      </View>;
    if (organizationShortcuts?.length) {
      // Results will be an array of ShortcutType's and grouped by Type
      // [ResourceNode::Type]: Array<ShortcutItem>
      const groupedOrganizationShortcutItems = organizationShortcuts?.reduce((returnValue, shortcut) => {
        const type = shortcut.resourceNode.type;
        returnValue[type] = returnValue[type] || [];
        const shortcutComponent = <CpGridItem color="primary" icon={shortcut.icon} key={`CpOrganizationShortcuts-${shortcut._id}`} link={{
          link_type: "topic",
          value: ""
        }} onPress={() => {
          onPress(shortcut.rootResourceNodeRevisionId, shortcut.resourceNode.revisionId);
        }} title={getNameVariant(shortcut)} />;
        returnValue[type].push(shortcutComponent);
        return returnValue;
      }, {}) ?? {};
      tabContents = buildShortcutSections(groupedOrganizationShortcutItems, isNarrowScreen);
    }
    return tabContents;
  }, [isNarrowScreen, onPress, organizationShortcuts]);
  return <View css={CpShortcutSectionStyles.contentContainer}>
      <CpSectionHeader label={formatMessage(localTranslations.recommendedForMe)} />
      {compiledOrganizationShortcuts}
    </View>;
};
export default CpOrganizationShortcuts;