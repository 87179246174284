import _styled from "styled-components";
import Fuse from "fuse.js";
import React, { useCallback, useMemo } from "react";
import CpSearchBar from "components/common/CpSearchBar";
import { styles } from "services/Theme";
/**
 * Component for filtering data based on text input. Wraps a fuzzy search library.
 * @param data - expected to be a flat array of content to filter through
 * @param filterKeys - array of strings that will be used as the field(s) to filter against
 * @param options - modifiers to the filter algorithms (see: https://fusejs.io/api/options.html)
 */
const CpFilterBar = ({
  data,
  filterKeys,
  onQueryChanged,
  options,
  placeholder
}) => {
  const fuse = useMemo(() => new Fuse(data, {
    keys: filterKeys,
    // Decreasing threshold ensures results are more closely matching input
    threshold: 0.3,
    ...options
  }), [data, filterKeys, options]);
  const handleFilterQuery = useCallback(queryText => onQueryChanged(queryText ? fuse.search(queryText) : undefined), [fuse, onQueryChanged]);
  return <_StyledCpSearchBar onQueryChanged={handleFilterQuery} placeholder={placeholder} $_css={styles.marginSingle} />;
};
export default CpFilterBar;
var _StyledCpSearchBar = _styled(CpSearchBar).withConfig({
  displayName: "CpFilterBar___StyledCpSearchBar",
  componentId: "sc-168xmif-0"
})(["", ""], p => p.$_css);