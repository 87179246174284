import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React from "react";
import { useIntl } from "react-intl";
import { SafeAreaView, ScrollView, View } from "react-native";
import CpError from "components/common/CpError";
import CpImdLogo from "components/common/CpImdLogo";
import { styles } from "services/Theme";
import { logoSize } from "services/Theme/common";
import translations from "translations";

/**
 * Error to show when the token passed in the query params has expired
 */
const CpTokenExpired = () => {
  const {
    formatMessage
  } = useIntl();
  return <_StyledSafeAreaView testID="CpTokenExpired" $_css={styles.flexSingle}>
      <_StyledScrollView $_css2={styles.flexSingle}>
        <_StyledView $_css3={[styles.screen, styles.paddingDouble, styles.center]}>
          <CpImdLogo accessibilityLabel={formatMessage(translations.accessibility.label.imdLogo, {
          region: ""
        })} accessibilityRole="image" height={logoSize} />
          <_StyledView2 $_css4={styles.paddingQuadruple}>
            <_StyledCpError error={translations.errors.tokenExpired} $_css5={styles.errorBox} />
          </_StyledView2>
        </_StyledView>
      </_StyledScrollView>
    </_StyledSafeAreaView>;
};
export default CpTokenExpired;
var _StyledSafeAreaView = _styled(SafeAreaView).withConfig({
  displayName: "CpTokenExpired___StyledSafeAreaView",
  componentId: "sc-1o01aky-0"
})(["", ""], p => p.$_css);
var _StyledScrollView = _styled(ScrollView).withConfig({
  displayName: "CpTokenExpired___StyledScrollView",
  componentId: "sc-1o01aky-1"
})(["", ""], p => p.$_css2);
var _StyledView = _styled(View).withConfig({
  displayName: "CpTokenExpired___StyledView",
  componentId: "sc-1o01aky-2"
})(["", ""], p => p.$_css3);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CpTokenExpired___StyledView2",
  componentId: "sc-1o01aky-3"
})(["", ""], p => p.$_css4);
var _StyledCpError = _styled(CpError).withConfig({
  displayName: "CpTokenExpired___StyledCpError",
  componentId: "sc-1o01aky-4"
})(["", ""], p => p.$_css5);