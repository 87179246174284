import _styled16 from "styled-components";
import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
var _CtForgotPasswordMutation;
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { View } from "react-native";
import { graphql, useMutation } from "react-relay";
import * as zod from "zod";
import CpBackgroundHeader from "components/common/CpBackgroundHeader";
import CpError from "components/common/CpError";
import CpModal from "components/common/CpModal";
import CpForm from "components/form/CpForm";
import CpFormTextInput from "components/form/CpFormTextInput";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import useFormResolver from "hooks/useFormResolver";
import usePersistentStorage from "hooks/usePersistentStorage";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { maxEmailLength } from "services/Constants";
import Log from "services/Log";
import { css, styles } from "services/Theme";
import { iconSizes } from "services/Theme/common";
import { emailValidation } from "services/Validation";
import translations from "translations";
const forgotPasswordMutation = _CtForgotPasswordMutation !== void 0 ? _CtForgotPasswordMutation : _CtForgotPasswordMutation = require("./__generated__/CtForgotPasswordMutation.graphql");
const localStyles = {
  buttonContainer: css`
    ${styles.rowAndCenter};
    justify-content: space-between;
  `,
  emailInfo: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    margin-bottom: ${({
    theme
  }) => theme.spacing.double}px;
  `
};
/**
 * Container that manages the layout and form that allows a user to request a password reset token to be sent to their email
 */
const CtForgotPassword = () => {
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    formatMessage
  } = useIntl();
  const {
    navigate
  } = useNavigation();
  const {
    buttonColors
  } = useAppTheme();
  const [submitForgotPassword, isSubmittingForgotPassword] = useMutation(forgotPasswordMutation);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [formError, setFormError] = useState();
  const form = useForm({
    mode: "onBlur",
    resolver: useFormResolver(useMemo(() => zod.object({
      email: zod.string().refine(...emailValidation())
    }), []))
  });
  const [lastSigninEmail] = usePersistentStorage("lastSigninEmail");

  // Hack for the async storage to work, see CpSignin for explanation
  useEffect(() => {
    if (lastSigninEmail) {
      form.reset({
        email: lastSigninEmail
      });
    }
  }, [form.reset, lastSigninEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenConfirmationModal = useCallback(() => {
    setConfirmationModalOpen(true);
  }, []);
  const handleCloseConfirmationModal = useCallback(() => {
    setConfirmationModalOpen(false);
    form.reset();
    navigate(Routes.signin);
  }, [form, navigate]);
  const handleSubmit = useMemo(() => form.handleSubmit(async values => {
    submitForgotPassword({
      onCompleted: ({
        forgotPassword
      }) => {
        Log.info(`CtForgotPassword - ${forgotPassword?.email}`);
        setFormError("");
        handleOpenConfirmationModal();
      },
      onError: err => {
        Log.info(err);
        setFormError(err);
      },
      variables: {
        input: values
      }
    });
  }), [form, handleOpenConfirmationModal, submitForgotPassword]);
  const handleBack = useCallback(() => {
    navigate(Routes.signin);
  }, [navigate]);
  return <CpBackgroundHeader childStyle={styles.flexSingle} header={translations.screens.forgot_password}>
      <_StyledCpModal onBackgroundPress={handleCloseConfirmationModal} visible={confirmationModalOpen} $_css={styles.marginHorizontalSingle}>
        <_StyledView $_css2={styles.modalContainer}>
          <_StyledPrText accessibilityRole="header" $_css3={styles.modalHeaderText}>
            <FormattedMessage id="UWiHiN" defaultMessage="Password Reset Email Sent" />
          </_StyledPrText>
          <_StyledView2 $_css4={[styles.rowAndCenter, styles.paddingVerticalDouble]}>
            <_StyledPrIcon fill={buttonColors.primary} icon={IconName.mail} size={iconSizes.large} $_css5={styles.modalIcon} />
            <PrText>
              <FormattedMessage id="W7cQO0" defaultMessage="We have successfully created a password reset token for {email}. You should receive an email shortly with further instructions on resetting your password." values={{
              email: <_StyledPrText2 children={form.getValues("email")} $_css6={styles.modalEmailText} />
            }} />
            </PrText>
          </_StyledView2>

          <_StyledPrButton onPress={handleCloseConfirmationModal} testID="CtForgotPasswordContinue" $_css7={styles.marginTopDouble}>
            <_StyledPrText3 children={translations.buttons.continue} $_css8={styles.buttonText} />
          </_StyledPrButton>
        </_StyledView>
      </_StyledCpModal>
      <_StyledView3 $_css9={[styles.paddingDouble, !isNarrowScreen ? styles.limitedLargeScreenWidth : undefined]}>
        <_StyledPrText4 $_css10={localStyles.emailInfo}>
          <FormattedMessage id="KzqOJC" defaultMessage="Please enter your account email address below to request an email with instructions on how to reset your IMD account password." />
        </_StyledPrText4>
        <CpForm {...form} onSubmit={handleSubmit}>
          <CpFormTextInput accessibilityLabel={formatMessage(translations.attributes.user.email)} autoCapitalize="none" autoComplete="email" autoCorrect={false} defaultValue={lastSigninEmail} keyboardType="email-address" label={translations.labels.accountEmailLabel} maxLength={maxEmailLength} name="email" placeholder={translations.labels.placeholderSigninEmail} returnKeyType="next" textContentType="emailAddress" />
          <_StyledCpError error={formError} $_css11={[styles.marginVerticalSingle, styles.errorBox]} />
          <_StyledView4 $_css12={!isNarrowScreen ? localStyles.buttonContainer : undefined}>
            <_StyledPrButton2 busy={isSubmittingForgotPassword} onPress={handleSubmit} testID="CtForgotPasswordSubmit" $_css13={styles.marginTopDouble}>
              <_StyledPrText5 $_css14={styles.buttonText}>
                <FormattedMessage id="cSv2J6" defaultMessage="Request password reset" />
              </_StyledPrText5>
            </_StyledPrButton2>
            <_StyledPrButton3 onPress={handleBack} type="text" $_css15={styles.marginVerticalSingle}>
              <_StyledPrText6 children={translations.buttons.cancel} $_css16={styles.buttonText} />
            </_StyledPrButton3>
          </_StyledView4>
        </CpForm>
      </_StyledView3>
    </CpBackgroundHeader>;
};
export default CtForgotPassword;
var _StyledCpModal = _styled(CpModal).withConfig({
  displayName: "CtForgotPassword___StyledCpModal",
  componentId: "sc-1gk8fxn-0"
})(["", ""], p => p.$_css);
var _StyledView = _styled(View).withConfig({
  displayName: "CtForgotPassword___StyledView",
  componentId: "sc-1gk8fxn-1"
})(["", ""], p => p.$_css2);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CtForgotPassword___StyledPrText",
  componentId: "sc-1gk8fxn-2"
})(["", ""], p => p.$_css3);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CtForgotPassword___StyledView2",
  componentId: "sc-1gk8fxn-3"
})(["", ""], p => p.$_css4);
var _StyledPrIcon = _styled(PrIcon).withConfig({
  displayName: "CtForgotPassword___StyledPrIcon",
  componentId: "sc-1gk8fxn-4"
})(["", ""], p => p.$_css5);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CtForgotPassword___StyledPrText2",
  componentId: "sc-1gk8fxn-5"
})(["", ""], p => p.$_css6);
var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CtForgotPassword___StyledPrButton",
  componentId: "sc-1gk8fxn-6"
})(["", ""], p => p.$_css7);
var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CtForgotPassword___StyledPrText3",
  componentId: "sc-1gk8fxn-7"
})(["", ""], p => p.$_css8);
var _StyledView3 = _styled(View).withConfig({
  displayName: "CtForgotPassword___StyledView3",
  componentId: "sc-1gk8fxn-8"
})(["", ""], p => p.$_css9);
var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CtForgotPassword___StyledPrText4",
  componentId: "sc-1gk8fxn-9"
})(["", ""], p => p.$_css10);
var _StyledCpError = _styled(CpError).withConfig({
  displayName: "CtForgotPassword___StyledCpError",
  componentId: "sc-1gk8fxn-10"
})(["", ""], p => p.$_css11);
var _StyledView4 = _styled(View).withConfig({
  displayName: "CtForgotPassword___StyledView4",
  componentId: "sc-1gk8fxn-11"
})(["", ""], p => p.$_css12);
var _StyledPrButton2 = _styled(PrButton).withConfig({
  displayName: "CtForgotPassword___StyledPrButton2",
  componentId: "sc-1gk8fxn-12"
})(["", ""], p => p.$_css13);
var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CtForgotPassword___StyledPrText5",
  componentId: "sc-1gk8fxn-13"
})(["", ""], p => p.$_css14);
var _StyledPrButton3 = _styled(PrButton).withConfig({
  displayName: "CtForgotPassword___StyledPrButton3",
  componentId: "sc-1gk8fxn-14"
})(["", ""], p => p.$_css15);
var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CtForgotPassword___StyledPrText6",
  componentId: "sc-1gk8fxn-15"
})(["", ""], p => p.$_css16);