import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { FormNecessity } from "components/form/types";
import PrText from "components/primitives/PrText";
import translations from "translations";
/**
 * A hook that returns the label for a form component. Adds '(Required)' or '(Optional)' to fields with the corresponding 'necessity' or the label if the necessity is set to 'None' (default).
 */
export const useFormLabel = ({
  label,
  necessity
}) => {
  const {
    formatMessage
  } = useIntl();
  return useMemo(() => {
    if (label && necessity !== FormNecessity.None) {
      // Returns (Optional) if it's optional or (Required) if required
      return necessity === FormNecessity.Optional ? formatMessage(translations.labels.optionalField, {
        label: <PrText children={label} />
      }) : formatMessage(translations.labels.requiredField, {
        label: <PrText children={label} />
      });
    }
    return label;
  }, [formatMessage, label, necessity]);
};