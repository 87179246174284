import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { View } from "react-native";
import CpReadonlyInput from "components/common/CpReadonlyInput";
import CpFormInputError from "components/form/CpFormInputError";
import { DisplayDirection, FormNecessity } from "components/form/types";
import PrRadioSegmented from "components/primitives/PrRadioSegmented";
import PrText from "components/primitives/PrText";
import { useFormLabel } from "hooks/useFormLabel";
import { css, styles } from "services/Theme";
const localStyles = {
  container: css`
    background-color: ${({
    theme
  }) => theme.formColors.fieldBackground};
    padding-vertical: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  label: css`
    color: ${({
    theme
  }) => theme.formColors.fieldLabelActive};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    font-weight: bold;
    padding-bottom: ${({
    theme
  }) => theme.spacing.half}px;
    padding-left: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  labelFocus: css`
    color: ${({
    theme
  }) => theme.formColors.fieldLabelActive};
  `,
  labelFocusError: css`
    color: ${({
    theme
  }) => theme.formColors.fieldBorderError};
  `
};
/**
 * A form component for radio input.
 * You can force vertical or horizontal display by passing displayDirection "vertical" or "horizontal"
 */
const CpFormRadioSegmented = ({
  defaultValue = "",
  displayDirection = DisplayDirection.Auto,
  fallbackReadonlyValue,
  label,
  name,
  necessity = FormNecessity.None,
  radioOptions,
  readonly,
  style,
  ...otherProps
}) => {
  const formattedLabel = useFormLabel({
    label,
    necessity
  });
  const formContext = useFormContext();
  const {
    formState: {
      errors
    },
    getValues
  } = formContext;
  const error = errors[name];
  const renderRadioInput = useCallback(({
    field
  }) => {
    return <PrRadioSegmented displayDirection={displayDirection} onChangeOption={field.onChange} radioOptions={radioOptions} value={field.value} {...otherProps} />;
  }, [displayDirection, otherProps, radioOptions]);
  const currentValueName = useMemo(() => {
    return radioOptions.find(option => option.value === getValues(name))?.name;
  }, [getValues, name, radioOptions]);
  if (readonly) {
    return <CpReadonlyInput fallbackValue={fallbackReadonlyValue} label={label} value={currentValueName} />;
  }
  return <>
      <_StyledView style={style} $_css={[styles.formField, localStyles.container]}>
        <_StyledPrText children={formattedLabel} $_css2={[localStyles.label, error ? localStyles.labelFocusError : undefined]} />
        <Controller defaultValue={defaultValue} name={name} render={renderRadioInput} />
      </_StyledView>
      {error && <CpFormInputError>{error.message}</CpFormInputError>}
    </>;
};
export default CpFormRadioSegmented;
var _StyledView = _styled(View).withConfig({
  displayName: "CpFormRadioSegmented___StyledView",
  componentId: "sc-1xs4i13-0"
})(["", ""], p => p.$_css);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpFormRadioSegmented___StyledPrText",
  componentId: "sc-1xs4i13-1"
})(["", ""], p => p.$_css2);