import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React from "react";
import { View } from "react-native";
import { css } from "services/Theme";
const localStyles = {
  container: css`
    align-items: center;
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    /* This bottom margin accommodates for the progress indicator "ball" to make sure it doesn't overlap other components if not provided external styling */
    margin-bottom: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  progressBarBase: css`
    background-color: ${({
    theme
  }) => theme.brandColors.secondaryBackground};
    border: 1px solid ${({
    theme
  }) => theme.brandColors.primary};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    height: 12px;
    justify-content: center;
    width: 100%;
    z-index: 0;
  `,
  // TODO: This should be added to color theme
  progressBarFill: css`
    background-image: linear-gradient(
      90deg,
      #0f1e73 0%,
      #003d92 13%,
      #0059aa 26%,
      #0073b8 38%,
      #008dbf 51%,
      #00a7bf 63%,
      #00bfba 74%,
      #14d7b4 94%
    );
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    height: 10px;
    left: 0;
    z-index: 1;
  `,
  progressIndicator: css`
    border: 1px solid ${({
    theme
  }) => theme.brandColors.primary};
    border-radius: 10.5px;
    height: 20px;
    width: 20px;
    position: absolute;
    z-index: 99;
  `,
  progressIndicatorTransition: css`
    background-color: ${({
    theme
  }) => theme.brandColors.secondary};
    height: 10px;
    position: absolute;
    /* 4px will cover 2px width of progressIndicator border + curves for seemless look */
    width: 4px;
    z-index: 100;
  `
};
/**
 * Renders a progress bar depicting the progress of a presentation/training topic.
 * Relies on CxPresentationSupport being available.
 */
const PrProgressTracker = ({
  accessibilityLabel,
  accessibilityValue,
  completedSteps,
  style,
  totalSteps
}) => {
  const progress = Math.floor(completedSteps / totalSteps * 100);

  // progressIndicator offset: min 2px <--> max 19px
  // Staying within this range ensures the progressIndicator doesn't render outside the confines of the component
  let progressIndicatorOffset = 0.19 * progress;
  if (progressIndicatorOffset < 2) {
    progressIndicatorOffset = 2;
  } else if (progressIndicatorOffset > 19) {
    // Offset should theoretically never exceed 100%, but just in case...
    progressIndicatorOffset = 19;
  }
  const progressStyle = css`
    width: ${progress}%;
  `;
  const progressIndicatorStyle = css`
    background-color: ${({
    theme
  }) => progress > 0 ? theme.brandColors.secondary : theme.brandColors.surface};
    /* Shaving a few px off of 'left' to ensure progressIndicator overlaps progress bar */
    left: calc(${progress}% - ${progressIndicatorOffset}px);
  `;
  const transitionStyle = css`
    /* Ensuring transition piece maintains cover over the left edge of progressIndicator */
    left: calc(${progress}% - ${progressIndicatorOffset + 1}px);
  `;
  const localAccessibilityValue = accessibilityValue ? accessibilityValue : {
    max: 100,
    min: 0,
    now: progress
  };
  return <_StyledView accessibilityLabel={accessibilityLabel} accessibilityRole="progressbar" accessibilityValue={localAccessibilityValue} style={style} $_css={localStyles.progressBarBase}>
      <_StyledView2 $_css2={[localStyles.progressBarFill, progressStyle]} />
      {progress > 0 && <_StyledView3 $_css3={[localStyles.progressIndicatorTransition, transitionStyle]} />}
      <_StyledView4 $_css4={[localStyles.progressIndicator, progressIndicatorStyle]} />
    </_StyledView>;
};
export default PrProgressTracker;
var _StyledView = _styled(View).withConfig({
  displayName: "PrProgressTracker___StyledView",
  componentId: "sc-pb0shb-0"
})(["", ""], p => p.$_css);
var _StyledView2 = _styled(View).withConfig({
  displayName: "PrProgressTracker___StyledView2",
  componentId: "sc-pb0shb-1"
})(["", ""], p => p.$_css2);
var _StyledView3 = _styled(View).withConfig({
  displayName: "PrProgressTracker___StyledView3",
  componentId: "sc-pb0shb-2"
})(["", ""], p => p.$_css3);
var _StyledView4 = _styled(View).withConfig({
  displayName: "PrProgressTracker___StyledView4",
  componentId: "sc-pb0shb-3"
})(["", ""], p => p.$_css4);