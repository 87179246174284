var _QlHomeSearch_SearchQuery;
import CtApiSuspense from "components/containers/CtApiSuspense";
import CtGraphqlNode from "components/containers/CtGraphqlNode";
import React from "react";
import { graphql } from "react-relay";
/**
 * Used fetch a root resource node in the context of the current organization
 */
const QlHomeSearch_SearchQueryRequest = _QlHomeSearch_SearchQuery !== void 0 ? _QlHomeSearch_SearchQuery : _QlHomeSearch_SearchQuery = require("./__generated__/QlHomeSearch_SearchQuery.graphql");
const QlHomeSearch = ({
  children,
  variables
}) => {
  return <CtApiSuspense>
      <CtGraphqlNode query={QlHomeSearch_SearchQueryRequest} render={response => children(response)} variables={variables} />
    </CtApiSuspense>;
};
export default QlHomeSearch;