import _styled20 from "styled-components";
import _styled19 from "styled-components";
import _styled18 from "styled-components";
import _styled17 from "styled-components";
import _styled16 from "styled-components";
import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ScrollView, View } from "react-native";
import CpPatientSessionHistorySelect from "components/patientSession/CpPatientSessionHistorySelect";
import CpSessionProgress from "components/patientSession/CpSessionProgress";
import { ModalSteps, PatientSessionActions } from "components/patientSession/PatientSession";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import useAnalytics from "hooks/useAnalytics";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import QlPatientSession from "query/QlPatientSession";
import { GoogleAnalyticsDimension } from "services/GoogleAnalytics";
import { useBackendSupportedLocale } from "services/Intl";
import { getSelectedResourcesFromViewedResources } from "services/PatientSession";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  disclaimerContainer: css`
    background-color: ${({
    theme
  }) => theme.brandColors.background};
    border: 1px solid ${({
    theme
  }) => theme.brandColors.separator};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  disclaimerText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `
};
/**
 * Renders the most recent Topic(s) viewed by the user to select as a starting point for a PatientSession not explicitly started.
 */
const CpPatientSessionHistory = ({
  onClose,
  style
}) => {
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    organization,
    user
  } = useImdSession();
  const navigation = useNavigation();
  const {
    formatMessage
  } = useIntl();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const locale = useBackendSupportedLocale();
  const {
    dispatchPatientSession,
    patientSessionData: {
      selectedResources,
      viewedResources
    }
  } = usePatientSessionContext();
  const queryVariables = useMemo(() => ({
    locale,
    revisionIds: viewedResources.map(resource => resource.revisionId)
  }), [locale, viewedResources]);
  const handleClearAndReturn = useCallback(() => {
    // RESET_SESSION will close the modal for us
    dispatchPatientSession({
      type: PatientSessionActions.ResetSession
    });
    trackGoogleEvent({
      action: "Cleared",
      name: "patientSession",
      options: {
        dimensions: {
          [GoogleAnalyticsDimension.organizationId]: organization?._id,
          [GoogleAnalyticsDimension.practitionerType]: user?.practitionerProfile?.type || undefined
        }
      }
    });
    navigation.navigate(Routes.home);
  }, [dispatchPatientSession, navigation, organization?._id, trackGoogleEvent, user]);
  const handleBack = useCallback(() => {
    dispatchPatientSession({
      payload: {
        modalStep: ModalSteps.SessionStart
      },
      type: PatientSessionActions.SetModal
    });
  }, [dispatchPatientSession]);
  const handleSelectFromHistory = useCallback((selectedRevisionId, nodes) => {
    // Compile a subset of selectedResources from viewedResources
    const localSelectedResources = getSelectedResourcesFromViewedResources({
      nodes,
      selectedResources,
      selectedRevisionId,
      viewedResources
    });
    dispatchPatientSession({
      payload: {
        modalStep: ModalSteps.SessionReview,
        selectedResources: localSelectedResources
      },
      type: PatientSessionActions.SetSelectedResources
    });
    // reporting Start here when Review & Send clicked without Patient Session Start
    trackGoogleEvent({
      action: "Started",
      name: "patientSession",
      options: {
        dimensions: {
          [GoogleAnalyticsDimension.organizationId]: organization?._id,
          [GoogleAnalyticsDimension.practitionerType]: user?.practitionerProfile?.type || undefined
        }
      }
    });
  }, [dispatchPatientSession, selectedResources, viewedResources, organization?._id, trackGoogleEvent, user]);
  return <QlPatientSession variables={queryVariables}>
      {response => {
      if (response.resourceNodesByRevision?.nodes) {
        return <_StyledScrollView style={style} $_css={styles.sessionStyles.scrollView}>
              <CpSessionProgress accessibilityLabel={formatMessage(translations.accessibility.label.progressPatientSession)} accessibilityValue={{
            text: formatMessage(translations.labels.start)
          }} completedSteps={0} showStart={true} totalSteps={2} />
              <_StyledPrText accessibilityRole="header" $_css2={[styles.modalHeaderText, styles.paddingHorizontalSingle]}>
                <FormattedMessage id="TVx5lQ" defaultMessage="No Session Found" />
              </_StyledPrText>
              <_StyledView $_css3={styles.paddingHorizontalSingle}>
                <View>
                  <_StyledPrText2 $_css4={styles.paddingTopSingle}>
                    <FormattedMessage id="FVAoKT" defaultMessage="No worries though, we can retroactively start one for you! Select your starting point from your topic history below." />
                  </_StyledPrText2>
                  <_StyledPrText4 children={formatMessage(translations.patientSessionTranslations.patientPrivacy, {
                showMore: <_StyledPrText3 children={translations.buttons.showMore} $_css5={styles.italicText} />
              })} $_css6={styles.sessionStyles.subtext} />
                </View>

                <CpPatientSessionHistorySelect nodes={response.resourceNodesByRevision?.nodes} onPress={handleSelectFromHistory} />

                {/* Buttons */}
                <_StyledView2 $_css7={[styles.sessionStyles.buttonContainer, !isNarrowScreen ? styles.sessionStyles.buttonContainerLarge : undefined]}>
                  <_StyledPrButton onPress={onClose} testID="CpPatientSessionHistory-continue" type="outline" $_css8={[styles.sessionStyles.button, isNarrowScreen && styles.fullWidth]}>
                    <_StyledPrText5 children={translations.buttons.continueSession} $_css9={styles.sessionStyles.buttonText} />
                  </_StyledPrButton>
                  <_StyledPrButton2 color="destructive" onPress={handleClearAndReturn} testID="CpPatientSessionHistory-end" type="text" $_css10={[styles.sessionStyles.button, isNarrowScreen && styles.fullWidth]}>
                    <_StyledPrText6 children={translations.buttons.clearAndReturnHome} $_css11={styles.sessionStyles.buttonText} />
                  </_StyledPrButton2>
                </_StyledView2>

                {/* Disclaimer about clearing history */}
                {/* TODO: Should probably convert these to an actual list somehow? */}
                <_StyledView3 $_css12={localStyles.disclaimerContainer}>
                  <_StyledPrText7 $_css13={localStyles.disclaimerText}>
                    <FormattedMessage id="3wfXBL" defaultMessage="History is cleared in the following scenarios:" />
                  </_StyledPrText7>
                  <_StyledPrText8 $_css14={localStyles.disclaimerText}>
                    <FormattedMessage id="dX8SeW" defaultMessage="- Explicitly starting a new Patient Session." />
                  </_StyledPrText8>
                  <_StyledPrText9 $_css15={localStyles.disclaimerText}>
                    <FormattedMessage id="TxfbwF" defaultMessage="- After successful completion of a Patient Session." />
                  </_StyledPrText9>
                  <_StyledPrText10 $_css16={localStyles.disclaimerText}>
                    <FormattedMessage id="fyF5gA" defaultMessage="- After {delay} minutes of app inactivity.*" values={{
                  delay: 7
                }} // We can eventually update this use a value set by the user
                />
                  </_StyledPrText10>
                  <_StyledPrText11 $_css17={[localStyles.disclaimerText, styles.paddingTopSingle, styles.italicText]}>
                    <FormattedMessage id="S+L8gq" defaultMessage="* Inactivity timeout can be modified in your account settings." />
                  </_StyledPrText11>
                </_StyledView3>

                <_StyledView4 $_css18={isNarrowScreen ? styles.fullWidth : styles.flexEnd}>
                  <_StyledPrButton3 onPress={handleBack} testID="CpPackageSessionSend-back" type="outline" $_css19={styles.marginTopDouble}>
                    <_StyledPrText12 children={translations.buttons.back} $_css20={styles.sessionStyles.buttonText} />
                  </_StyledPrButton3>
                </_StyledView4>
              </_StyledView>
            </_StyledScrollView>;
      } else {
        return null;
      }
    }}
    </QlPatientSession>;
};
export default CpPatientSessionHistory;
var _StyledScrollView = _styled(ScrollView).withConfig({
  displayName: "CpPatientSessionHistory___StyledScrollView",
  componentId: "sc-7g15x8-0"
})(["", ""], p => p.$_css);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpPatientSessionHistory___StyledPrText",
  componentId: "sc-7g15x8-1"
})(["", ""], p => p.$_css2);
var _StyledView = _styled(View).withConfig({
  displayName: "CpPatientSessionHistory___StyledView",
  componentId: "sc-7g15x8-2"
})(["", ""], p => p.$_css3);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionHistory___StyledPrText2",
  componentId: "sc-7g15x8-3"
})(["", ""], p => p.$_css4);
var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionHistory___StyledPrText3",
  componentId: "sc-7g15x8-4"
})(["", ""], p => p.$_css5);
var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionHistory___StyledPrText4",
  componentId: "sc-7g15x8-5"
})(["", ""], p => p.$_css6);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CpPatientSessionHistory___StyledView2",
  componentId: "sc-7g15x8-6"
})(["", ""], p => p.$_css7);
var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpPatientSessionHistory___StyledPrButton",
  componentId: "sc-7g15x8-7"
})(["", ""], p => p.$_css8);
var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionHistory___StyledPrText5",
  componentId: "sc-7g15x8-8"
})(["", ""], p => p.$_css9);
var _StyledPrButton2 = _styled(PrButton).withConfig({
  displayName: "CpPatientSessionHistory___StyledPrButton2",
  componentId: "sc-7g15x8-9"
})(["", ""], p => p.$_css10);
var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionHistory___StyledPrText6",
  componentId: "sc-7g15x8-10"
})(["", ""], p => p.$_css11);
var _StyledView3 = _styled(View).withConfig({
  displayName: "CpPatientSessionHistory___StyledView3",
  componentId: "sc-7g15x8-11"
})(["", ""], p => p.$_css12);
var _StyledPrText7 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionHistory___StyledPrText7",
  componentId: "sc-7g15x8-12"
})(["", ""], p => p.$_css13);
var _StyledPrText8 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionHistory___StyledPrText8",
  componentId: "sc-7g15x8-13"
})(["", ""], p => p.$_css14);
var _StyledPrText9 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionHistory___StyledPrText9",
  componentId: "sc-7g15x8-14"
})(["", ""], p => p.$_css15);
var _StyledPrText10 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionHistory___StyledPrText10",
  componentId: "sc-7g15x8-15"
})(["", ""], p => p.$_css16);
var _StyledPrText11 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionHistory___StyledPrText11",
  componentId: "sc-7g15x8-16"
})(["", ""], p => p.$_css17);
var _StyledView4 = _styled(View).withConfig({
  displayName: "CpPatientSessionHistory___StyledView4",
  componentId: "sc-7g15x8-17"
})(["", ""], p => p.$_css18);
var _StyledPrButton3 = _styled(PrButton).withConfig({
  displayName: "CpPatientSessionHistory___StyledPrButton3",
  componentId: "sc-7g15x8-18"
})(["", ""], p => p.$_css19);
var _StyledPrText12 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionHistory___StyledPrText12",
  componentId: "sc-7g15x8-19"
})(["", ""], p => p.$_css20);