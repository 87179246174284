var _CpTopicDepthOneAncestorButton_resourceNode;
import React, { useMemo } from "react";
import { graphql, useFragment } from "react-relay/hooks";
import { getNameVariant } from "../../../services/Graphql";
import CpTopicBackButton from "./CpTopicBackButton";
export const CpTopicDepthOneAncestorButton_nodeFragment = _CpTopicDepthOneAncestorButton_resourceNode !== void 0 ? _CpTopicDepthOneAncestorButton_resourceNode : _CpTopicDepthOneAncestorButton_resourceNode = require("./__generated__/CpTopicDepthOneAncestorButton_resourceNode.graphql");
/**
 * A button that navigates to the ancestor of the current node with depth one (a direct child of the subtree)
 */
const CpTopicDepthOneAncestorButton = ({
  resourceNodeKey,
  style
}) => {
  const data = useFragment(CpTopicDepthOneAncestorButton_nodeFragment, resourceNodeKey);
  const depthOneAncestor = useMemo(() => {
    const selectedAncestor = data.ancestors.find(ancestor => ancestor.parentRevisionId === data.rootRevisionId);
    if (!selectedAncestor) {
      return null;
    }
    return {
      label: getNameVariant(selectedAncestor),
      rootRevisionId: data.rootRevisionId,
      selectedRevisionId: selectedAncestor.revisionId
    };
  }, [data]);
  return depthOneAncestor ? <CpTopicBackButton label={depthOneAncestor.label} rootRevisionId={depthOneAncestor.rootRevisionId} selectedRevisionId={depthOneAncestor.selectedRevisionId} style={style} /> : null;
};
export default CpTopicDepthOneAncestorButton;