// Apparently ES-Lint doesn't consider =0, =1 format to be a valid plural format.
/* eslint-disable formatjs/enforce-plural-rules */
import { defineMessages } from "react-intl";
export default defineMessages({
  accessDenied: {
    id: "rYX7Rs",
    defaultMessage: "Access Denied"
  },
  accountEmailLabel: {
    id: "7Mpvrs",
    defaultMessage: "Account email"
  },
  accountPasswordPlaceholder: {
    id: "pBdzyL",
    defaultMessage: "Your account password"
  },
  additionalEmails: {
    id: "zZ9eXe",
    defaultMessage: "Additional emails"
  },
  advertisement: {
    id: "vrLDb9",
    defaultMessage: "Advertisement"
  },
  ago: {
    id: "TZV+rI",
    defaultMessage: "{timePeriod} ago"
  },
  altImdLogo: {
    id: "F/cOYA",
    defaultMessage: "IMD Health Logo"
  },
  appTagline: {
    id: "eAC+Xo",
    defaultMessage: "Engage, inform and educate patients"
  },
  breadcrumbsMissing: {
    id: "ODsEL4",
    defaultMessage: "Open menu to select a resource"
  },
  breadcrumbsMissingDesktop: {
    id: "eaNHJa",
    defaultMessage: "Select resource from menu"
  },
  children: {
    id: "1v0Otc",
    defaultMessage: "{num, plural, one {# child} other {# children}}"
  },
  colorModeButton: {
    id: "MrBwB/",
    defaultMessage: "Mode"
  },
  colorScheme: {
    id: "xSP18r",
    defaultMessage: "Color Scheme"
  },
  commonTopics: {
    id: "b3UVHZ",
    defaultMessage: "Common Topics"
  },
  confirmIdentity: {
    id: "3K5pax",
    defaultMessage: "Confirm account email"
  },
  confirmIdentityFormInstructionWithPassword: {
    id: "0l9l8K",
    defaultMessage: "A new confirmation code has been sent to {email}. Enter the code below to confirm receipt."
  },
  confirmIdentityFormInstructionWithoutPassword: {
    id: "IhbG2k",
    defaultMessage: "Enter the confirmation code that was sent to {email}. The code is only good for 24 hours. If it has expired, use the button below to request a new code."
  },
  content: {
    id: "4JOzpA",
    defaultMessage: "Content"
  },
  darkMode: {
    id: "HB8gDB",
    defaultMessage: "Dark Mode"
  },
  default: {
    id: "eZtKZH",
    defaultMessage: "Default"
  },
  description: {
    id: "/rVjTV",
    defaultMessage: "Description"
  },
  details: {
    id: "zD+Mkx",
    defaultMessage: "Details"
  },
  disabled: {
    id: "EyP2Jg",
    defaultMessage: "Disabled"
  },
  disclaimer: {
    id: "hcCmGV",
    defaultMessage: "Disclaimer"
  },
  dontShowWarning: {
    id: "yie4g7",
    defaultMessage: "Do not show me this warning again"
  },
  drawing: {
    id: "ghKazw",
    defaultMessage: "{name} (Drawing)"
  },
  emailInfo: {
    id: "/vLrbJ",
    defaultMessage: "Emails are used for identification purposes only. We will never spam you."
  },
  enabled: {
    id: "ZWU5zp",
    defaultMessage: "Enabled"
  },
  error: {
    id: "IPBrwZ",
    defaultMessage: "Error"
  },
  examplePlaceholder: {
    id: "znWGuO",
    defaultMessage: "E.g. {name}"
  },
  flashEmailConfirmationSent: {
    id: "4TUqDn",
    defaultMessage: "Confirmation code sent to {email}"
  },
  flashPasswordResetSent: {
    id: "+XJK2I",
    defaultMessage: "Password reset code sent to {email}"
  },
  help: {
    id: "ue1Xbg",
    defaultMessage: "Help"
  },
  hide: {
    id: "T4mp1F",
    defaultMessage: "Hide"
  },
  missingResourceTitle: {
    id: "uXzCxF",
    defaultMessage: "Missing resource"
  },
  mode: {
    id: "MrBwB/",
    defaultMessage: "Mode"
  },
  myShortcuts: {
    id: "/xZuKD",
    defaultMessage: "My Shortcuts"
  },
  name: {
    id: "SEo1vi",
    defaultMessage: "Name"
  },
  nextPage: {
    id: "5IB7KF",
    defaultMessage: "Next Page"
  },
  no: {
    id: "qYUJZQ",
    defaultMessage: "No"
  },
  noData: {
    id: "dbzLch",
    defaultMessage: "No Data"
  },
  noItems: {
    id: "8V5Vg0",
    defaultMessage: "There is no content available for this category."
  },
  noMore: {
    id: "N8y94E",
    defaultMessage: "No more"
  },
  noResults: {
    id: "A0sDz4",
    defaultMessage: "Your search for {searchText} returned no results. Modify your search and try again."
  },
  noResultsFound: {
    id: "dizAaI",
    defaultMessage: "No results found."
  },
  none: {
    id: "WKv/dx",
    defaultMessage: "None"
  },
  notProvided: {
    id: "zryuDv",
    defaultMessage: "Not provided"
  },
  notesToPatient: {
    id: "vUKtCI",
    defaultMessage: "Notes to patient"
  },
  now: {
    id: "Vjl8eq",
    defaultMessage: "Now"
  },
  optional: {
    id: "o0Mpca",
    defaultMessage: "Optional"
  },
  optionalField: {
    id: "YtVxJP",
    defaultMessage: "{label} (Optional)"
  },
  organizations: {
    id: "38QtUP",
    defaultMessage: "Organizations"
  },
  otherPrefix: {
    id: "+QSsZg",
    defaultMessage: "Other prefix"
  },
  passwordResetFormInstruction: {
    id: "hZNsur",
    defaultMessage: "A password reset code has been sent to the account email. Enter the code and a new account password."
  },
  patientEducationSession: {
    id: "JyHBke",
    defaultMessage: "Patient Education Session"
  },
  patientEmail: {
    id: "1I8uoQ",
    defaultMessage: "Patient email"
  },
  patientEmails: {
    id: "Ct3VSU",
    defaultMessage: "Patient emails"
  },
  patientInformationPackage: {
    id: "VHunM3",
    defaultMessage: "Patient Information Package"
  },
  patientSupportPrograms: {
    id: "8sfq7m",
    defaultMessage: "Patient Support Programs - {partner}"
  },
  placeholderConfirmationCode: {
    id: "Fqr29b",
    defaultMessage: "Confirmation code"
  },
  placeholderFilter: {
    id: "82Yzf1",
    defaultMessage: "Filter..."
  },
  placeholderPassword: {
    id: "C7tHCI",
    defaultMessage: "We encrypt it to keep you safe"
  },
  placeholderPasswordResetCode: {
    id: "4r09es",
    defaultMessage: "Reset code"
  },
  placeholderPatientEmail: {
    id: "bKOWxP",
    defaultMessage: "patient@email.com"
  },
  placeholderSigninEmail: {
    id: "HeSgog",
    defaultMessage: "healer@healthco.com"
  },
  placeholderSigninPassword: {
    id: "u06M+d",
    defaultMessage: "We suggest using a password manager"
  },
  poweredBy: {
    id: "HdgkIz",
    defaultMessage: "Powered by"
  },
  prevPage: {
    id: "x9dzYm",
    defaultMessage: "Previous Page"
  },
  privacyPolicy: {
    id: "kLv5gY",
    defaultMessage: "Privacy Policy"
  },
  province: {
    id: "0Rne/S",
    defaultMessage: "Province"
  },
  requiredField: {
    id: "0yLmnl",
    defaultMessage: "{label} (Required)"
  },
  resetPassword: {
    id: "1SArK1",
    defaultMessage: "Reset password"
  },
  results: {
    id: "UF0WIR",
    defaultMessage: "{num, plural, =0 {# results} =1 {# result} other {# results}}"
  },
  review: {
    id: "qm5b8e",
    defaultMessage: "Review"
  },
  searchClinicsPlaceholder: {
    id: "m3AOcj",
    defaultMessage: "Search clinics..."
  },
  selectLanguage: {
    id: "b8luva",
    defaultMessage: "Select Language"
  },
  selectOne: {
    id: "5j0B7e",
    defaultMessage: "Select One"
  },
  selectedForSharing: {
    id: "40h5jq",
    defaultMessage: "Selected for sharing:"
  },
  send: {
    id: "uBjM88",
    defaultMessage: "Send"
  },
  sessionLength: {
    id: "ZWXqK8",
    defaultMessage: "Session Length"
  },
  signedInAs: {
    id: "Y60lCB",
    defaultMessage: "Signed in as {email}"
  },
  signinAccountIdentification: {
    id: "Y3n/mR",
    defaultMessage: "Account: {email}"
  },
  signinFormHeader: {
    id: "POhsSt",
    defaultMessage: "Enter your account password"
  },
  signout: {
    id: "qwRdID",
    defaultMessage: "Sign out"
  },
  signupFormHeader: {
    id: "GakIOG",
    defaultMessage: "Create new account for {email}"
  },
  start: {
    id: "aRKbLO",
    defaultMessage: "Start"
  },
  state: {
    id: "o2J793",
    defaultMessage: "State"
  },
  success: {
    id: "MA4klt",
    defaultMessage: "Success"
  },
  supportLink: {
    id: "BOmyxI",
    defaultMessage: "Support"
  },
  tableOfContents: {
    id: "UFv7jn",
    defaultMessage: "Table of Contents"
  },
  termsOfUse: {
    id: "BikOzX",
    defaultMessage: "Terms of Use"
  },
  unknown: {
    id: "3wPiJg",
    defaultMessage: "Unknown"
  },
  yes: {
    id: "KBHc29",
    defaultMessage: "Yes"
  },
  youAreViewing: {
    id: "yDHSEQ",
    defaultMessage: "You are viewing IMD Health for {region}"
  }
});