var _QlShortcuts_currentUserQuery;
import CtGraphqlQuery from "components/containers/CtGraphqlQuery";
import React from "react";
import { graphql } from "relay-runtime";
const currentUserQuery = _QlShortcuts_currentUserQuery !== void 0 ? _QlShortcuts_currentUserQuery : _QlShortcuts_currentUserQuery = require("./__generated__/QlShortcuts_currentUserQuery.graphql");

/**
 * Loads data used to display the current user's shortcuts
 */
const QlShortcuts = ({
  children,
  variables
}) => {
  return <CtGraphqlQuery query={currentUserQuery} variables={variables}>
      {children}
    </CtGraphqlQuery>;
};
export default QlShortcuts;