import _styled19 from "styled-components";
import _styled18 from "styled-components";
import _styled17 from "styled-components";
import _styled16 from "styled-components";
import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { useNavigation } from "@react-navigation/native";
import { selectAll, selectOne } from "css-select";
import renderDom from "dom-serializer";
import { DomUtils, parseDocument } from "htmlparser2";
import React, { useCallback, useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Image, View } from "react-native";
import { useFragment } from "react-relay/hooks";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpDivHtmlWeb from "components/common/CpDivHtmlWeb";
import CpModal from "components/common/CpModal";
import CpThumbnailItem from "components/common/CpThumbnailItem";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import CpLinkToNode from "components/topic/CpLinkToNode";
import { resourceFragment } from "components/topic/CpResourceCommon";
import useAppTheme from "hooks/useAppTheme";
import useImdSession from "hooks/useImdSession";
import useScreenLayout from "hooks/useScreenLayout";
import useTopicContext from "hooks/useTopicContext";
import Routes from "navigators/Routes";
import { AppTool } from "services/Constants";
import { getNameVariant } from "services/Graphql";
import Log from "services/Log";
import { parseMediaShortcodes } from "services/QuickResource";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  bottomPadding: css`
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  button: css`
    border-color: ${({
    theme
  }) => theme.buttonColors.primary};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border-width: 1px;
    margin-right: ${({
    theme
  }) => theme.spacing.double}px;
    padding-left: ${({
    theme
  }) => theme.spacing.double}px;
    padding-right: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  buttonContainer: css`
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
  `,
  buttonText: css`
    color: ${({
    theme
  }) => theme.textColors.default};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    font-weight: normal;
  `,
  fullscreenButtonContainer: css`
    align-self: flex-start;
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    border-bottom-color: ${({
    theme
  }) => theme.brandColors.separator};
    border-bottom-width: 1px;
    width: 100%;
  `,
  fullscreenCaption: css`
    align-self: center;
    font-style: italic;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
    text-align: center;
  `,
  fullscreenCloseButton: css`
    ${styles.rowAndCenter};
    align-self: flex-end;
  `,
  fullscreenCloseText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    margin-left: ${({
    theme
  }) => theme.spacing.single}px;
    margin-right: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  fullscreenContainer: css`
    background-color: ${({
    theme
  }) => theme.brandColors.background};
    flex: 1;
  `,
  topPadding: css`
    padding-top: ${({
    theme
  }) => theme.spacing.quadruple}px;
  `
};
const largeScreenStyles = {
  fullscreenCaption: css`
    ${localStyles.fullscreenCaption};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
  `,
  fullscreenCloseText: css`
    ${localStyles.fullscreenCloseText};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
  `,
  sectionHeaderLabel: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("xlarge")};
    font-weight: bold;
  `,
  sectionHeaderLineAccent: css`
    background-color: ${({
    theme
  }) => theme.brandColors.primary};
    height: 2px;
    margin-top: ${({
    theme
  }) => theme.spacing.single}px;
    width: 100px;
  `
};
const smallScreenStyles = {
  bottomBorder: css`
    border-bottom-width: 1px;
  `,
  fullscreenCaption: css`
    ${localStyles.fullscreenCaption};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
  `,
  fullscreenCloseText: css`
    ${localStyles.fullscreenCloseText};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
  `,
  sectionAccordion: css`
    align-items: center;
    border-color: ${({
    theme
  }) => theme.brandColors.primary};
    flex-direction: row;
    justify-content: space-between;
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
    height: ${({
    theme
  }) => theme.buttonHeights.xxlarge}px;
  `,
  sectionAccordionLabel: css`
    color: ${({
    theme
  }) => theme.brandColors.primary};
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-weight: bold;
  `,
  topBorder: css`
    border-top-width: 1px;
  `
};
const localTranslations = defineMessages({
  linkToMedicationsNode: {
    id: "IL/QM2",
    defaultMessage: "View Medications"
  }
});
const TreatmentNameTranslations = ["Treatment", "Traitement",
// French not translated by Mayo yet, but including either way
"Tratamiento"];
/**
 * This component will render the contents of a QuickResource for larger screens
 * Any images will render to the right of the text content
 */
const LargeScreenSections = ({
  onPressMedia,
  onPressMedicationsLink,
  resourceSections,
  thumbnailHint
}) => {
  return <>
      {resourceSections.map(section => {
      const {
        content,
        label,
        media,
        showLinkToMedications
      } = section;
      return <View key={label}>
            <_StyledPrText accessibilityRole="header" aria-level="2" children={label} $_css={[largeScreenStyles.sectionHeaderLabel, localStyles.topPadding]} />
            <_StyledView $_css2={largeScreenStyles.sectionHeaderLineAccent} />
            <View>
              <CpDivHtmlWeb content={content} />
              <_StyledView2 $_css3={styles.rowAndWrap}>
                {media.map(mediaDetails => {
              const {
                _id,
                name,
                url
              } = mediaDetails;
              return <CpThumbnailItem accessibilityHint={thumbnailHint} key={url} name={name} onPressCallback={onPressMedia} onPressValue={mediaDetails} showExpandableIcon={true} size="Large" testID={`CpQuickResource-${_id}`} uri={url} />;
            })}
                {showLinkToMedications && <CpLinkToNode icon={IconName.iconTreatment} label={localTranslations.linkToMedicationsNode} onPress={onPressMedicationsLink} size="Thumbnail" />}
              </_StyledView2>
            </View>
          </View>;
    })}
    </>;
};

/**
 * This component will render the contents of a QuickResource for smaller screens
 * Any images will render beneath the text content
 */
const SmallScreenSections = ({
  brandColors,
  iconSizes,
  isNarrowScreen,
  onPressMedia,
  onPressMedicationsLink,
  resourceSections,
  thumbnailHint
}) => {
  const [showSectionContents, setShowSectionContents] = useState(() => {
    const showContents = {};
    resourceSections.forEach((section, index) => {
      showContents[section.label] = index === 0;
    });
    return showContents;
  });
  const handleSetShowSectionContents = useCallback(sectionLabel => {
    const currentState = showSectionContents;
    currentState[sectionLabel] = !currentState[sectionLabel];
    setShowSectionContents({
      ...currentState
    });
  }, [showSectionContents]);
  return <>
      {resourceSections.map((section, index) => {
      const {
        content,
        label,
        media,
        showLinkToMedications
      } = section;
      const sectionVisible = showSectionContents[label];
      const isFirstItem = index === 0;
      const isLastItem = resourceSections.length - 1 === index;
      return <View key={label}>
            <_StyledPrPressable accessibilityState={{
          expanded: sectionVisible
        }} onPress={() => handleSetShowSectionContents(label)} $_css4={[smallScreenStyles.sectionAccordion, !isFirstItem && smallScreenStyles.topBorder, isLastItem && !sectionVisible && smallScreenStyles.bottomBorder]}>
              <_StyledPrText2 accessibilityRole="header" aria-level="2" children={label} $_css5={smallScreenStyles.sectionAccordionLabel} />
              <PrIcon accessibilityLabel="" accessibilityRole="none" fill={brandColors.primary} icon={sectionVisible ? IconName.keyboardArrowUp : IconName.keyboardArrowDown} size={iconSizes.medium} />
            </_StyledPrPressable>
            {sectionVisible && <_StyledView3 $_css6={styles.paddingHorizontalDouble}>
                <_StyledCpDivHtmlWeb content={content} $_css7={localStyles.bottomPadding} />
                <_StyledView4 $_css8={localStyles.bottomPadding} $_css9={!isNarrowScreen && styles.rowAndWrap}>
                  {media.map(mediaDetails => {
              const {
                name,
                url
              } = mediaDetails;
              return <CpThumbnailItem accessibilityHint={thumbnailHint} key={url} name={name} onPressCallback={onPressMedia} onPressValue={mediaDetails} showExpandableIcon={!isNarrowScreen} size={isNarrowScreen ? "Small" : "Medium"} uri={url} />;
            })}
                  {showLinkToMedications && <CpLinkToNode icon={IconName.iconTreatment} label={localTranslations.linkToMedicationsNode} onPress={onPressMedicationsLink} size={isNarrowScreen ? "Small" : "Medium"} />}
                </_StyledView4>
              </_StyledView3>}
          </View>;
    })}
    </>;
};

/**
 * Renders an HTML resource and any media tags (i.e. images) included
 * Smaller screens will display sections within collapsible accordions
 * Larger screens will display all sections at once
 */
const CpQuickResource = ({
  medicationsNodeRevisionId,
  resource,
  shortcodeMapping,
  style
}) => {
  const {
    appConfig,
    user
  } = useImdSession();
  const {
    rootRevisionId
  } = useTopicContext();
  const {
    isNarrowScreen,
    isWideScreen
  } = useScreenLayout();
  const {
    brandColors,
    buttonColors,
    fontFamilies,
    iconSizes
  } = useAppTheme();
  const {
    formatMessage
  } = useIntl();
  const navigation = useNavigation();
  const handlePressMedicationsLink = useCallback(() => {
    if (medicationsNodeRevisionId) {
      navigation.navigate(Routes.topic, {
        rootRevisionId,
        selectedRevisionId: medicationsNodeRevisionId
      });
    }
  }, [medicationsNodeRevisionId, navigation, rootRevisionId]);
  const data = useFragment(resourceFragment, resource);
  const [mediaContent, setMediaContent] = useState();
  const [fullscreenImageAspectRatio, setFullscreenImageAspectRatio] = useState(1);
  const handleSetMediaContent = useCallback(selectedContent => {
    Image.getSize(selectedContent.url, (width, height) => {
      // TODO: What breakpoints do we want to use for max screen width and height?
      const adjustedAspectRatio = Math.min(1200 / width, 1000 / height);
      setFullscreenImageAspectRatio(adjustedAspectRatio);
    });
    setMediaContent(selectedContent);
  }, []);
  const handleSetHideMediaContent = useCallback(() => {
    setMediaContent(undefined);
  }, [setMediaContent]);
  const rawHtml = data.embedMarkup || "";

  // Extract html and images, and arrange them into their respective sections for rendering
  const resourceSections = useMemo(() => {
    const sectionsToReturn = [];
    const parsedDom = parseDocument(rawHtml);

    // Find all the sections within a quickResource
    const quickSections = selectAll("section.section-level-1", parsedDom);
    const embeddedResources = data.embeddedResources;
    quickSections.forEach(section => {
      // Extract title for the current section
      const headerNode = selectOne("h2.section-title", section);
      const sectionTitleNode = headerNode?.childNodes[0];
      const sectionTitle = sectionTitleNode?.nodeValue ?? "";
      if (headerNode) {
        // removing header element from section html to prevent duplicate rendering
        DomUtils.removeElement(headerNode);
      }

      // Extract any media from source html, returning separated media shortcodes as well as curated html for rendering
      const {
        html: scrubbedSection,
        media
      } = parseMediaShortcodes(renderDom(section));

      // Take the curated html, and wrap it with in our default font
      const sectionContent = `
        <section class="section-level-1" style="font-family: ${fontFamilies.default};>
          ${scrubbedSection}
        </section>
      `;

      // Take any extracted media, compare with embeddedResources to make sure we have the matching url, name and caption, and organize them for rendering
      const sectionMedia = [];
      if (media.length > 0 && embeddedResources) {
        media.forEach(mediaItem => {
          const {
            resourceId,
            type
          } = mediaItem;
          const embeddedResource = embeddedResources.find(embedded => embedded._id === resourceId);
          if (!embeddedResource || !embeddedResource?.url) {
            Log.warn("Unable to find matching embeddedResource for shortcode:", {
              data,
              mediaResourceId: resourceId
            });
            return;
          } else if (type !== "image") {
            Log.warn("Html Resource Media tag not supported:", {
              resourceId,
              type
            });
            return;
          }
          const {
            _id,
            caption,
            url
          } = embeddedResource;
          const {
            default: name
          } = embeddedResource.names;
          sectionMedia.push({
            _id,
            caption: caption ?? "",
            name,
            url
          });
        });
      }
      sectionsToReturn.push({
        content: sectionContent,
        label: sectionTitle,
        media: sectionMedia,
        // Is it the 'Treatment' section? Add a link to the medication node
        // Should only show medications if we have a user to show them to
        showLinkToMedications: Boolean(user) && TreatmentNameTranslations.includes(sectionTitle) && Boolean(medicationsNodeRevisionId)
      });
    });
    return sectionsToReturn;
  }, [data, fontFamilies.default, medicationsNodeRevisionId, rawHtml, user]);
  const componentStyles = isWideScreen ? largeScreenStyles : smallScreenStyles;
  const thumbnailHint = formatMessage(translations.accessibility.hint.thumbnailOpenFullscreen);
  const patientEducationTool = appConfig?.appTools?.find(tool => tool.name === AppTool.Educate);
  const isPatientEducationDisabled = patientEducationTool?.status === "inactive";
  const drawingTool = appConfig?.appTools?.find(tool => tool.name === AppTool.Draw);
  const isDrawingDisabled = drawingTool?.status !== "active";
  const handleDrawRedirect = useCallback(resourceId => {
    navigation.navigate(Routes.topic, {
      draw: "true",
      rootRevisionId,
      selectedRevisionId: shortcodeMapping?.[resourceId]
    });
  }, [navigation, rootRevisionId, shortcodeMapping]);
  return <View style={style}>
      <CpUpdatePageTitle pageType="article" title={getNameVariant(data)} />
      {isWideScreen ? <LargeScreenSections onPressMedia={handleSetMediaContent} onPressMedicationsLink={handlePressMedicationsLink} resourceSections={resourceSections} thumbnailHint={thumbnailHint} /> : <SmallScreenSections brandColors={brandColors} iconSizes={iconSizes} isNarrowScreen={isNarrowScreen} onPressMedia={handleSetMediaContent} onPressMedicationsLink={handlePressMedicationsLink} resourceSections={resourceSections} thumbnailHint={thumbnailHint} />}
      {!!mediaContent && <CpModal fullScreen={true} onRequestClose={handleSetHideMediaContent} visible={!!mediaContent}>
          <_StyledView5 $_css10={localStyles.fullscreenContainer}>
            <_StyledView6 $_css11={localStyles.fullscreenButtonContainer}>
              <_StyledView7 $_css12={localStyles.buttonContainer}>
                {!isPatientEducationDisabled && !isDrawingDisabled ? <_StyledPrButton onPress={() => handleDrawRedirect(mediaContent._id)} testID="CpQuickResourceDrawButton" type="text" $_css13={localStyles.button}>
                    <_StyledView8 $_css14={[styles.center, isWideScreen ? styles.row : undefined]}>
                      <_StyledPrIcon fill={buttonColors.primary} icon={IconName.brush} size={iconSizes.small} $_css15={styles.marginHorizontalSingle} />
                      <_StyledPrText3 children="Draw" $_css16={localStyles.buttonText} />
                    </_StyledView8>
                  </_StyledPrButton> : null}
                <_StyledPrPressable2 accessibilityHint={formatMessage(translations.accessibility.hint.closeFullscreen)} onPress={handleSetHideMediaContent} $_css17={localStyles.fullscreenCloseButton}>
                  <PrIcon accessibilityLabel="" accessibilityRole="none" fill={buttonColors.primary} icon={IconName.fullscreenExit} size={isWideScreen ? iconSizes.large : iconSizes.medium} />
                  <_StyledPrText4 children={translations.buttons.fullscreenClose} $_css18={componentStyles.fullscreenCloseText} />
                </_StyledPrPressable2>
              </_StyledView7>
            </_StyledView6>

            <_StyledImage accessibilityLabel={mediaContent.name} resizeMode="contain" source={{
          uri: mediaContent?.url
        }} $_css19={styles.flexSingle} $_css20={fullscreenImageAspectRatio} />
            {mediaContent.caption && <_StyledCpDivHtmlWeb2 content={mediaContent.caption} $_css21={componentStyles.fullscreenCaption} />}
          </_StyledView5>
        </CpModal>}
    </View>;
};
export default CpQuickResource;
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpQuickResource___StyledPrText",
  componentId: "sc-1y6271p-0"
})(["", ""], p => p.$_css);
var _StyledView = _styled(View).withConfig({
  displayName: "CpQuickResource___StyledView",
  componentId: "sc-1y6271p-1"
})(["", ""], p => p.$_css2);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CpQuickResource___StyledView2",
  componentId: "sc-1y6271p-2"
})(["", ""], p => p.$_css3);
var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpQuickResource___StyledPrPressable",
  componentId: "sc-1y6271p-3"
})(["", ""], p => p.$_css4);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpQuickResource___StyledPrText2",
  componentId: "sc-1y6271p-4"
})(["", ""], p => p.$_css5);
var _StyledView3 = _styled(View).withConfig({
  displayName: "CpQuickResource___StyledView3",
  componentId: "sc-1y6271p-5"
})(["", ""], p => p.$_css6);
var _StyledCpDivHtmlWeb = _styled(CpDivHtmlWeb).withConfig({
  displayName: "CpQuickResource___StyledCpDivHtmlWeb",
  componentId: "sc-1y6271p-6"
})(["", ""], p => p.$_css7);
var _StyledView4 = _styled(View).withConfig({
  displayName: "CpQuickResource___StyledView4",
  componentId: "sc-1y6271p-7"
})(["", ";", ""], p => p.$_css8, p => p.$_css9);
var _StyledView5 = _styled(View).withConfig({
  displayName: "CpQuickResource___StyledView5",
  componentId: "sc-1y6271p-8"
})(["", ""], p => p.$_css10);
var _StyledView6 = _styled(View).withConfig({
  displayName: "CpQuickResource___StyledView6",
  componentId: "sc-1y6271p-9"
})(["", ""], p => p.$_css11);
var _StyledView7 = _styled(View).withConfig({
  displayName: "CpQuickResource___StyledView7",
  componentId: "sc-1y6271p-10"
})(["", ""], p => p.$_css12);
var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpQuickResource___StyledPrButton",
  componentId: "sc-1y6271p-11"
})(["", ""], p => p.$_css13);
var _StyledView8 = _styled(View).withConfig({
  displayName: "CpQuickResource___StyledView8",
  componentId: "sc-1y6271p-12"
})(["", ""], p => p.$_css14);
var _StyledPrIcon = _styled(PrIcon).withConfig({
  displayName: "CpQuickResource___StyledPrIcon",
  componentId: "sc-1y6271p-13"
})(["", ""], p => p.$_css15);
var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpQuickResource___StyledPrText3",
  componentId: "sc-1y6271p-14"
})(["", ""], p => p.$_css16);
var _StyledPrPressable2 = _styled(PrPressable).withConfig({
  displayName: "CpQuickResource___StyledPrPressable2",
  componentId: "sc-1y6271p-15"
})(["", ""], p => p.$_css17);
var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpQuickResource___StyledPrText4",
  componentId: "sc-1y6271p-16"
})(["", ""], p => p.$_css18);
var _StyledImage = _styled(Image).withConfig({
  displayName: "CpQuickResource___StyledImage",
  componentId: "sc-1y6271p-17"
})(["", ";aspect-ratio:", ";"], p => p.$_css19, p => p.$_css20);
var _StyledCpDivHtmlWeb2 = _styled(CpDivHtmlWeb).withConfig({
  displayName: "CpQuickResource___StyledCpDivHtmlWeb2",
  componentId: "sc-1y6271p-18"
})(["", ""], p => p.$_css21);