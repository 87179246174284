import _styled from "styled-components";
var _CpResourceNodeViewTab_tabData;
import React from "react";
import CpTopic from "components/topic/CpTopic";
import CxTopic from "contexts/CxTopic";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { styles } from "services/Theme";
const tabDataFragment = _CpResourceNodeViewTab_tabData !== void 0 ? _CpResourceNodeViewTab_tabData : _CpResourceNodeViewTab_tabData = require("./__generated__/CpResourceNodeViewTab_tabData.graphql");
/**
 * Render a home screen tab that shows the content of a single resource node
 */
const CpResourceNodeViewTab = ({
  tab
}) => {
  const tabData = useFragment(tabDataFragment, tab);
  const rootRevisionId = tabData?.resourceNode?.rootRevisionId;
  const selectedRevisionId = tabData?.resourceNode?.revisionId;
  return <>
      {rootRevisionId ? <CxTopic rootRevisionId={rootRevisionId} selectedRevisionId={selectedRevisionId}>
          <_StyledCpTopic hideFooter={true} hideSiblingNavigator={true} hideToc={true} preventScroll={true} rootRevisionId={rootRevisionId} selectedRevisionId={selectedRevisionId} $_css={styles.flexSingle} />
        </CxTopic> : null}
    </>;
};
export default CpResourceNodeViewTab;
var _StyledCpTopic = _styled(CpTopic).withConfig({
  displayName: "CpResourceNodeViewTab___StyledCpTopic",
  componentId: "sc-73k807-0"
})(["", ""], p => p.$_css);