import { useNavigation } from "@react-navigation/native";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import CpHeaderButton from "components/common/CpHeaderButton";
import { ActionButtonUserType } from "components/primitives/PrActionButton/PrActionButton";
import { IconName } from "components/primitives/PrIcon";
import useImdSession from "hooks/useImdSession";
import Routes from "navigators/Routes";
import translations from "translations";

/**
 * Change Clinic header action button
 */
const ChangeClinicButton = ({
  type = ActionButtonUserType.AccountMenu
}) => {
  const {
    navigate
  } = useNavigation();
  const {
    user
  } = useImdSession();
  const {
    formatMessage
  } = useIntl();
  const userExists = Boolean(user);
  const handleOrganizationNavigation = useCallback(() => {
    navigate(Routes.change_clinic);
  }, [navigate]);
  if (!userExists || user?.isPatient) {
    return null;
  }
  return <CpHeaderButton accessibilityHint={formatMessage(translations.accessibility.hint.navigatesTo, {
    pageName: formatMessage(translations.screens.change_clinic)
  })} icon={IconName.organization} label={translations.screens.change_clinic} onPress={handleOrganizationNavigation} shortLabel={translations.screens.change_clinic} testID={type === ActionButtonUserType.AccountMenu ? "ChangeClinicButton-accountMenu" : "ChangeClinicButton-header"} type={type} />;
};
export default ChangeClinicButton;