import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Platform, View } from "react-native";
import CpLinkButton from "components/common/CpLinkButton";
import CpFormTextInput from "components/form/CpFormTextInput";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrProgressTracker from "components/primitives/PrProgressTracker";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import useScreenLayout from "hooks/useScreenLayout";
import { useFormContext } from "react-hook-form";
import { maxPasswordLength } from "services/Constants";
import { css, styles } from "services/Theme";
import { iconSizes } from "services/Theme/common";
import { validatePassword } from "services/Validation";
import translations from "translations";

// Sets the left and right container height so they both have the same amount on large screen
// So it won't bounce when password is secure
const largeScreenPasswordContainerHeight = 180;
const localStyles = {
  bottomLink: css`
    color: ${({
    theme
  }) => theme.formColors.fieldPasswordSecureText};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    font-weight: normal;
    text-decoration: underline;
    text-decoration-color: ${({
    theme
  }) => theme.formColors.fieldPasswordSecureText};
  `,
  bottomText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    font-style: italic;
    text-align: center;
  `,
  bottomTextContainer: css`
    flex-direction: flex-end;
    text-align: center;
  `,
  containers: {
    horizontal: css`
      flex-direction: row;
    `,
    leftContainer: css`
      height: 180px;
      justify-content: space-between;
      padding-bottom: ${({
      theme
    }) => theme.spacing.single}px;
      padding-right: ${({
      theme
    }) => theme.spacing.single}px;
      width: 50%;
    `,
    leftContainerLargeScreen: css`
      height: ${largeScreenPasswordContainerHeight}px;
    `,
    progressBar: css`
      padding-vertical: ${({
      theme
    }) => theme.spacing.single}px;
    `,
    rightContainer: css`
      height: ${largeScreenPasswordContainerHeight}px;
      padding: ${({
      theme
    }) => theme.spacing.single}px;
      padding-left: ${({
      theme
    }) => theme.spacing.double}px;
      padding-top: ${({
      theme
    }) => theme.spacing.half}px;
      width: 50%;
    `,
    vertical: css`
      flex-direction: column;
    `
  },
  passwordStrengthText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `,
  requirementContainer: css`
    align-items: center;
    flex-direction: row;
    padding-vertical: ${({
    theme
  }) => theme.spacing.quarter}px;
  `,
  requirementMainText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  requirementText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    padding-left: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  securePassword: css`
    align-items: center;
    background-color: ${({
    theme
  }) => theme.formColors.fieldPasswordSecureBox};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    flex-direction: row;
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    margin-top: ${({
    theme
  }) => theme.spacing.single}px;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  securePasswordText: css`
    color: ${({
    theme
  }) => theme.formColors.fieldPasswordSecureText};
    font-weight: bold;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  secureText: css`
    font-weight: bold;
  `,
  smallScreenPadding: css`
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
  `
};
const {
  validations
} = translations;
var RequirementKey;
(function (RequirementKey) {
  RequirementKey["LowerCase"] = "lowerCase";
  RequirementKey["MinLength"] = "minLength";
  RequirementKey["Number"] = "number";
  RequirementKey["SpecialChar"] = "specialChar";
  RequirementKey["UpperCase"] = "upperCase";
})(RequirementKey || (RequirementKey = {}));
const requirementConfigs = [[RequirementKey.LowerCase, validations.passwordLowerCase], [RequirementKey.MinLength, validations.passwordMinLength], [RequirementKey.Number, validations.passwordNumber], [RequirementKey.SpecialChar, validations.passwordSpecialChar], [RequirementKey.UpperCase, validations.passwordUpperCase]];
const requirementConfigLength = requirementConfigs.length;
/**
 * Wraps the password input for new user password
 * Shows minimum password requirements and percentageCompletedSteps bar
 */
const CpFormNewPassword = ({
  label,
  name,
  onSubmitEditing,
  ...otherProps
}) => {
  const {
    formColors
  } = useAppTheme();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    formatMessage
  } = useIntl();
  const formContext = useFormContext();
  const {
    formState: {
      errors
    },
    watch
  } = formContext;
  // Watches for password value changes to update our requirements
  const passwordValue = watch(name);
  const passwordErrors = errors[name];
  const [requirements, setRequirements] = useState({
    [RequirementKey.LowerCase]: false,
    [RequirementKey.MinLength]: false,
    [RequirementKey.Number]: false,
    [RequirementKey.SpecialChar]: false,
    [RequirementKey.UpperCase]: false
  });
  const [percentageCompletedSteps, setPercentageCompletedSteps] = useState(0);
  // Validates password and compares our requirements state with the current passed checks to change UI changes
  useEffect(() => {
    const passedChecks = validatePassword(passwordValue || "", {
      requireNumbers: true,
      requireSymbols: true
    });
    setRequirements(currentRequirements => ({
      ...Object.fromEntries(Object.keys(currentRequirements).map(checkName => [checkName, false])),
      ...Object.fromEntries(passedChecks.map(passedCheckName => [passedCheckName, true]))
    }));
    setPercentageCompletedSteps(passedChecks.length);
  }, [passwordErrors, name, passwordValue]);
  const renderInfoText = () => {
    return <_StyledView $_css={[localStyles.bottomTextContainer, isNarrowScreen ? localStyles.smallScreenPadding : null]}>
        <_StyledPrText $_css2={localStyles.bottomText}>
          <FormattedMessage id="/zfHaA" defaultMessage="To maintain password security, we recommend using a {passwordManager}" values={{
          passwordManager: <_StyledPrText2 $_css3={styles.boldText}>
                  <FormattedMessage id="rEWOx5" defaultMessage="password manager!" />
                </_StyledPrText2>
        }} />
        </_StyledPrText>
        <CpLinkButton url="https://wikipedia.org/wiki/List_of_password_managers">
          <_StyledPrText3 $_css4={localStyles.bottomLink}>
            <FormattedMessage id="wgHqM9" defaultMessage="Click to see popular password managers" />
          </_StyledPrText3>
        </CpLinkButton>
      </_StyledView>;
  };
  return <_StyledView2 $_css5={isNarrowScreen ? localStyles.containers.vertical : localStyles.containers.horizontal}>
      <_StyledView3 $_css6={!isNarrowScreen ? localStyles.containers.leftContainer : localStyles.containers.vertical}>
        <CpFormTextInput autoCapitalize="none" autoComplete="password" autoCorrect={false} keyboardType={Platform.OS === "android" ? "visible-password" : "default"} label={label} maxLength={maxPasswordLength} name={name} onSubmitEditing={onSubmitEditing} password={true}
      // 'passwordRules' lets Apple know our requirements to generate an adequate password
      // Increasing to min length of 10, because security
      passwordRules={"minlength: 10; required: lower; required: upper; required: digit; required: [{[(<!@#$%^&*?`~,.\"'-=+_>)]}];"} returnKeyType="go" skipError={true} textContentType="newPassword" {...otherProps} />
        {!isNarrowScreen && renderInfoText()}
      </_StyledView3>
      <_StyledView4 $_css7={!isNarrowScreen ? localStyles.containers.rightContainer : localStyles.containers.vertical}>
        <_StyledPrText4 $_css8={localStyles.passwordStrengthText}>
          <FormattedMessage id="F90JwL" defaultMessage="Password strength: {strength}" values={{
          strength: <_StyledPrText5 $_css9={localStyles.secureText}>
                  {Object.values(requirements).every(requirement => requirement) && passwordValue ? formatMessage(validations.secure) : formatMessage(validations.insecure)}
                </_StyledPrText5>
        }} />
        </_StyledPrText4>
        <_StyledView5 $_css10={localStyles.containers.progressBar}>
          <PrProgressTracker accessibilityLabel={formatMessage(translations.accessibility.label.progressPasswordStrength)} accessibilityValue={{
          min: 0,
          max: 100,
          now: percentageCompletedSteps
        }} completedSteps={percentageCompletedSteps} totalSteps={requirementConfigLength} />
        </_StyledView5>
        {Object.values(requirements).every(requirement => requirement) && passwordValue ? <_StyledView6 $_css11={localStyles.securePassword}>
            <PrIcon fill={formColors.fieldPasswordSecureText} icon={IconName.checkCircle} size={iconSizes.small} />
            <_StyledPrText6 $_css12={localStyles.securePasswordText}>
              <FormattedMessage id="cnXc3y" defaultMessage="Your password is secure!" />
            </_StyledPrText6>
          </_StyledView6> : <>
            <_StyledPrText7 $_css13={localStyles.requirementMainText}>
              <FormattedMessage id="TbOBrJ" defaultMessage="Password requirements:" />
            </_StyledPrText7>
            {requirementConfigs.map(([requirementName, requirementTranslation]) => <_StyledView7 key={requirementName} $_css14={localStyles.requirementContainer}>
                {requirements[requirementName] ? <PrIcon fill={formColors.fieldLabelActive} icon={IconName.checkCircle} size={iconSizes.xsmall} /> : <PrIcon fill={formColors.fieldError} icon={IconName.cancelCircle} size={iconSizes.xsmall} />}
                <_StyledPrText8 $_css15={localStyles.requirementText}>{requirementTranslation}</_StyledPrText8>
              </_StyledView7>)}
          </>}
        {isNarrowScreen && renderInfoText()}
      </_StyledView4>
    </_StyledView2>;
};
export default CpFormNewPassword;
var _StyledView = _styled(View).withConfig({
  displayName: "CpFormNewPassword___StyledView",
  componentId: "sc-d4yrz9-0"
})(["", ""], p => p.$_css);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpFormNewPassword___StyledPrText",
  componentId: "sc-d4yrz9-1"
})(["", ""], p => p.$_css2);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpFormNewPassword___StyledPrText2",
  componentId: "sc-d4yrz9-2"
})(["", ""], p => p.$_css3);
var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpFormNewPassword___StyledPrText3",
  componentId: "sc-d4yrz9-3"
})(["", ""], p => p.$_css4);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CpFormNewPassword___StyledView2",
  componentId: "sc-d4yrz9-4"
})(["", ""], p => p.$_css5);
var _StyledView3 = _styled(View).withConfig({
  displayName: "CpFormNewPassword___StyledView3",
  componentId: "sc-d4yrz9-5"
})(["", ""], p => p.$_css6);
var _StyledView4 = _styled(View).withConfig({
  displayName: "CpFormNewPassword___StyledView4",
  componentId: "sc-d4yrz9-6"
})(["", ""], p => p.$_css7);
var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpFormNewPassword___StyledPrText4",
  componentId: "sc-d4yrz9-7"
})(["", ""], p => p.$_css8);
var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpFormNewPassword___StyledPrText5",
  componentId: "sc-d4yrz9-8"
})(["", ""], p => p.$_css9);
var _StyledView5 = _styled(View).withConfig({
  displayName: "CpFormNewPassword___StyledView5",
  componentId: "sc-d4yrz9-9"
})(["", ""], p => p.$_css10);
var _StyledView6 = _styled(View).withConfig({
  displayName: "CpFormNewPassword___StyledView6",
  componentId: "sc-d4yrz9-10"
})(["", ""], p => p.$_css11);
var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpFormNewPassword___StyledPrText6",
  componentId: "sc-d4yrz9-11"
})(["", ""], p => p.$_css12);
var _StyledPrText7 = _styled(PrText).withConfig({
  displayName: "CpFormNewPassword___StyledPrText7",
  componentId: "sc-d4yrz9-12"
})(["", ""], p => p.$_css13);
var _StyledView7 = _styled(View).withConfig({
  displayName: "CpFormNewPassword___StyledView7",
  componentId: "sc-d4yrz9-13"
})(["", ""], p => p.$_css14);
var _StyledPrText8 = _styled(PrText).withConfig({
  displayName: "CpFormNewPassword___StyledPrText8",
  componentId: "sc-d4yrz9-14"
})(["", ""], p => p.$_css15);