var _QlResourceTreeSectionQuery;
import React from "react";
import { graphql } from "react-relay";
import CtGraphqlQuery from "components/containers/CtGraphqlQuery";
/**
 * Used to fetch the resource node along with its children to render tabs
 */
const resourceTreeTabQuery = _QlResourceTreeSectionQuery !== void 0 ? _QlResourceTreeSectionQuery : _QlResourceTreeSectionQuery = require("./__generated__/QlResourceTreeSectionQuery.graphql");

/**
 * Loads data used to display a resource node section
 */
const QlResourceTreeSection = ({
  children,
  variables
}) => {
  return <CtGraphqlQuery query={resourceTreeTabQuery} variables={variables}>
      {children}
    </CtGraphqlQuery>;
};
export default QlResourceTreeSection;