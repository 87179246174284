import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React from "react";
import { View } from "react-native";
import CpItem from "components/common/CpItem";
import { ActionButtonUserType } from "components/primitives/PrActionButton/PrActionButton";
import PrActivityIndicator from "components/primitives/PrActivityIndicator";
import PrButton from "components/primitives/PrButton";
import PrIcon from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import { css, styles } from "services/Theme";
const localStyles = {
  menuButtonContent: css`
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  menuButtonIcon: css`
    min-width: ${({
    theme
  }) => theme.iconSizes.medium}px;
  `,
  menuButtonText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    padding-left: ${({
    theme
  }) => theme.spacing.double}px;
  `
};
/**
 * Renders an account menu button
 */
const CpHeaderButton = ({
  busy,
  icon,
  label,
  onLayout,
  onPress,
  shortLabel,
  testID,
  type = ActionButtonUserType.AccountMenu,
  ...buttonOptions
}) => {
  const {
    brandColors,
    buttonColors,
    iconSizes
  } = useAppTheme();
  if (type === ActionButtonUserType.AccountMenu) {
    return <CpItem {...buttonOptions} onLayout={onLayout} onPress={onPress} testID={testID}>
        <_StyledView $_css={localStyles.menuButtonContent}>
          {busy ? <PrActivityIndicator /> : <_StyledPrIcon fill={buttonColors.primary} icon={icon} size={iconSizes.medium} $_css2={localStyles.menuButtonIcon} />}
          <_StyledPrText children={label} numberOfLines={2} $_css3={localStyles.menuButtonText} />
        </_StyledView>
      </CpItem>;
  } else {
    return <_StyledPrButton {...buttonOptions} onLayout={onLayout} onPress={onPress} testID={testID} type="text" $_css4={styles.navHeaderStyles.navButton}>
        <_StyledView2 $_css5={styles.center}>
          <PrIcon fill={brandColors.primary} icon={icon} size={iconSizes.medium} />
          <_StyledPrText2 $_css6={styles.navHeaderStyles.navButtonText}>{shortLabel ?? label}</_StyledPrText2>
        </_StyledView2>
      </_StyledPrButton>;
  }
};
export default CpHeaderButton;
var _StyledView = _styled(View).withConfig({
  displayName: "CpHeaderButton___StyledView",
  componentId: "sc-1q2uz35-0"
})(["", ""], p => p.$_css);
var _StyledPrIcon = _styled(PrIcon).withConfig({
  displayName: "CpHeaderButton___StyledPrIcon",
  componentId: "sc-1q2uz35-1"
})(["", ""], p => p.$_css2);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpHeaderButton___StyledPrText",
  componentId: "sc-1q2uz35-2"
})(["", ""], p => p.$_css3);
var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpHeaderButton___StyledPrButton",
  componentId: "sc-1q2uz35-3"
})(["", ""], p => p.$_css4);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CpHeaderButton___StyledView2",
  componentId: "sc-1q2uz35-4"
})(["", ""], p => p.$_css5);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpHeaderButton___StyledPrText2",
  componentId: "sc-1q2uz35-5"
})(["", ""], p => p.$_css6);