import _styled2 from "styled-components";
import _styled from "styled-components";
import React from "react";
import { View } from "react-native";
import PrPressable from "components/primitives/PrPressable";
import { css, rnStyles } from "services/Theme";
const localStyles = {
  selected: css`
    left: unset;
    right: 0;
  `,
  selectedWrapper: css`
    background-color: ${({
    theme
  }) => theme.buttonColors.primary};
    border-color: ${({
    theme
  }) => theme.buttonColors.primary};
  `,
  switchWrapper: css`
    border: 2px solid #c4cccf;
    background-color: #c4cccf;
    border-radius: ${({
    theme
  }) => theme.borderRadii.switch}px;
    position: relative;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.triple}px;
    padding-vertical: ${({
    theme
  }) => theme.spacing.single + theme.spacing.half}px;
  `,
  toggleCircle: css`
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    border-radius: 50%;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 50%;
  `
};
/**
 * Toggle Switch Container
 */
const CpToggleSwitch = ({
  onToggle,
  style,
  toggled
}) => {
  return <PrPressable hoveredBackgroundColor="surface" onPress={onToggle} pressedBackgroundColor="surface" style={style}>
      <_StyledView $_css={[localStyles.switchWrapper, toggled ? localStyles.selectedWrapper : null]}>
        <_StyledView2 style={rnStyles.buttonShadow} $_css2={[localStyles.toggleCircle, toggled ? localStyles.selected : null]} />
      </_StyledView>
    </PrPressable>;
};
export default CpToggleSwitch;
var _StyledView = _styled(View).withConfig({
  displayName: "CpToggleSwitch___StyledView",
  componentId: "sc-k8p6n9-0"
})(["", ""], p => p.$_css);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CpToggleSwitch___StyledView2",
  componentId: "sc-k8p6n9-1"
})(["", ""], p => p.$_css2);