import { defineMessages } from "react-intl";
import { AppHeaderActions, AppHeaderPlacements, AppName, AppSearchTypes, AppTabPositions, AppTabTypes, AppTool } from "services/Constants";
export const adSlots = defineMessages({
  hcpBox: {
    id: "Cv0jkD",
    defaultMessage: "HCP Box"
  },
  largeHcpLeaderboard: {
    id: "xefBCJ",
    defaultMessage: "Large HCP Leaderboard"
  },
  largePatientLeaderboard: {
    id: "vASDVe",
    defaultMessage: "Large Patient Leaderboard"
  },
  smallPatientLeaderboard: {
    id: "rqntL2",
    defaultMessage: "Small Patient Leaderboard"
  }
});
export const appColorThemes = defineMessages({
  dark: {
    id: "jOwQMm",
    defaultMessage: "Dark"
  },
  light: {
    id: "8MQkO5",
    defaultMessage: "Light"
  }
});
export const appHeaderActions = defineMessages({
  [AppHeaderActions.ChangeClinic]: {
    id: "Cp0/tg",
    defaultMessage: "Change Clinic"
  },
  [AppHeaderActions.Educate]: {
    id: "0tZGWV",
    defaultMessage: "Patient Education"
  },
  [AppHeaderActions.Help]: {
    id: "2xrWK4",
    defaultMessage: "Help"
  },
  [AppHeaderActions.ImdForProfessionals]: {
    id: "MOKdIv",
    defaultMessage: "Imd For Professionals"
  },
  [AppHeaderActions.ManageAccount]: {
    id: "sWZg0M",
    defaultMessage: "Manage Account"
  },
  [AppHeaderActions.Mode]: {
    id: "8ybZdI",
    defaultMessage: "Colour Scheme"
  },
  [AppHeaderActions.Navigate]: {
    id: "eC/a5A",
    defaultMessage: "Navigate"
  },
  [AppHeaderActions.Packages]: {
    id: "wmNEZk",
    defaultMessage: "Information Packages"
  },
  [AppHeaderActions.PickLanguageAndRegion]: {
    id: "/V4/yH",
    defaultMessage: "Pick Language and Region"
  },
  [AppHeaderActions.PickLanguage]: {
    id: "WcAHCv",
    defaultMessage: "Pick Language"
  },
  [AppHeaderActions.PickRegion]: {
    id: "/dz91v",
    defaultMessage: "Pick Region"
  },
  [AppHeaderActions.Shortcuts]: {
    id: "An3HaP",
    defaultMessage: "Shortcuts"
  },
  [AppHeaderActions.SignOut]: {
    id: "+bRHh4",
    defaultMessage: "Sign Out"
  },
  [AppHeaderActions.Submenu]: {
    id: "i899yN",
    defaultMessage: "Submenu"
  }
});
export const appHeaderPlacements = defineMessages({
  [AppHeaderPlacements.Common]: {
    id: "BHwbTw",
    defaultMessage: "Quick Action and Menu"
  },
  [AppHeaderPlacements.Menu]: {
    id: "JQp6fn",
    defaultMessage: "Menu Only"
  }
});
export const appNames = defineMessages({
  [AppName.ImdAdmin]: {
    id: "ZSrnLJ",
    defaultMessage: "Admin"
  },
  [AppName.ImdProvider]: {
    id: "zFI1UI",
    defaultMessage: "Provider"
  },
  [AppName.ImdPublic]: {
    id: "JC2jFV",
    defaultMessage: "Public"
  },
  [AppName.Unspecified]: {
    id: "NX7TGW",
    defaultMessage: "IMD"
  }
});
export const appSearchTypes = defineMessages({
  [AppSearchTypes.AllTabs]: {
    id: "y4rdl9",
    defaultMessage: "All Tab Content"
  },
  [AppSearchTypes.FilterContent]: {
    id: "EdadQt",
    defaultMessage: "Filtered Content"
  },
  [AppSearchTypes.OrgAccessible]: {
    id: "dh/oWO",
    defaultMessage: "Organization Accessible Content"
  },
  [AppSearchTypes.PublicAccessible]: {
    id: "fLPkjb",
    defaultMessage: "Publicly Accessible Content"
  },
  [AppSearchTypes.SingleTab]: {
    id: "xadA2y",
    defaultMessage: "Single Tab Content"
  }
});
export const appTabPositions = defineMessages({
  [AppTabPositions.Bottom]: {
    id: "Su207A",
    defaultMessage: "Bottom"
  },
  [AppTabPositions.Left]: {
    id: "ggumDM",
    defaultMessage: "Left"
  },
  [AppTabPositions.Right]: {
    id: "RcqEPG",
    defaultMessage: "Right"
  },
  [AppTabPositions.Top]: {
    id: "tmdO8g",
    defaultMessage: "Top"
  }
});
export const appTabTypes = defineMessages({
  [AppTabTypes.DefaultTab]: {
    id: "/yY9Ef",
    defaultMessage: "Default Tab (Do Not Use)"
  },
  [AppTabTypes.ResourceNodeSearchTab]: {
    id: "zdlws8",
    defaultMessage: "Resource Node Search"
  },
  [AppTabTypes.ResourceNodeTab]: {
    id: "xvuNci",
    defaultMessage: "Resource Node"
  },
  [AppTabTypes.ResourceNodeViewTab]: {
    id: "OASzJw",
    defaultMessage: "Resource Node Viewer"
  },
  [AppTabTypes.TopicNodeTab]: {
    id: "CT95Lp",
    defaultMessage: "Topic Node"
  },
  [AppTabTypes.VideoShowcaseTab]: {
    id: "uPM2sV",
    defaultMessage: "Video Showcase"
  }
});
export const appTools = defineMessages({
  [AppTool.Draw]: {
    id: "9mthbS",
    defaultMessage: "Drawing"
  },
  [AppTool.Educate]: {
    id: "d8+UaQ",
    defaultMessage: "Patient Education Sessions"
  },
  [AppTool.Packages]: {
    id: "ZkrIwh",
    defaultMessage: "Patient Information Packages"
  },
  [AppTool.Shortcuts]: {
    id: "ADyJ3o",
    defaultMessage: "Shortcuts"
  }
});
export const booleans = defineMessages({
  false: {
    id: "seTi+f",
    defaultMessage: "False"
  },
  true: {
    id: "/A6qtV",
    defaultMessage: "True"
  }
});
export const locales = defineMessages({
  en: {
    id: "UTUafm",
    defaultMessage: "English"
  },
  es: {
    id: "LxEN47",
    defaultMessage: "Espa\xF1ol"
  },
  fr: {
    id: "LUnWkg",
    defaultMessage: "Fran\xE7ais"
  }
});
export const nationalities = defineMessages({
  ca: {
    id: "TLttVh",
    defaultMessage: "Canadian"
  },
  us: {
    id: "K+yamm",
    defaultMessage: "American"
  }
});

// List of organization types
export const organizationTypes = defineMessages({
  "Organization::EducationalInstitution": {
    id: "vzCTb0",
    defaultMessage: "Educational Institution"
  },
  "Organization::Licensee": {
    id: "sT6k11",
    defaultMessage: "Licensee"
  },
  "Organization::Practice": {
    id: "p9y2u9",
    defaultMessage: "Practice"
  },
  "Organization::Practice::Hospital": {
    id: "Qv5D5t",
    defaultMessage: "Hospital"
  },
  "Organization::Practice::MedicalGroup": {
    id: "o3wrSE",
    defaultMessage: "Medical Group"
  },
  "Organization::Practice::MedicalIndependent": {
    id: "mNQQwW",
    defaultMessage: "Medical Independent"
  },
  "Organization::Practice::Pharmacy": {
    id: "ByUef0",
    defaultMessage: "Pharmacy"
  },
  "Organization::Publisher": {
    id: "ZuZOom",
    defaultMessage: "Publisher"
  },
  "Organization::Publisher::CpgManufacturer": {
    id: "sXhjpb",
    defaultMessage: "CPG Manufacturer"
  },
  "Organization::Publisher::HealthAssociation": {
    id: "nV7ugf",
    defaultMessage: "Health Association"
  },
  "Organization::Publisher::OtcManufacturer": {
    id: "dOHIAn",
    defaultMessage: "OTC Manufacturer"
  },
  "Organization::Publisher::PharmaceuticalManufacturer": {
    id: "GfsTyD",
    defaultMessage: "Pharmaceutical Manufacturer"
  },
  "Organization::SystemManager": {
    id: "jJcDSV",
    defaultMessage: "System Manager"
  },
  "Organization::Virtual": {
    id: "0pBbbY",
    defaultMessage: "Virtual Organization"
  }
});
export const resourceTypes = defineMessages({
  "Resource::Form": {
    id: "s7LRJo",
    defaultMessage: "Form"
  },
  "Resource::Handlebars": {
    id: "ZnYB/j",
    defaultMessage: "Article"
  },
  "Resource::Html": {
    id: "ZnYB/j",
    defaultMessage: "Article"
  },
  "Resource::Iframe": {
    id: "ZnYB/j",
    defaultMessage: "Article"
  },
  "Resource::Image": {
    id: "6TcQg9",
    defaultMessage: "Image"
  },
  "Resource::Pdf": {
    id: "r5yNVc",
    defaultMessage: "PDF"
  },
  "Resource::Video": {
    id: "s9ASX6",
    defaultMessage: "Video"
  }
});

// List of resource node types pluralized for use as list headers
export const resourceNodeTypes = defineMessages({
  "ResourceNode::Anatomy": {
    id: "BZYd44",
    defaultMessage: "Anatomy"
  },
  "ResourceNode::Condition": {
    id: "xR0WTl",
    defaultMessage: "Conditions"
  },
  "ResourceNode::Presentation": {
    id: "fqwDyh",
    defaultMessage: "Presentations"
  },
  "ResourceNode::Procedure": {
    id: "QnJ3Oy",
    defaultMessage: "Procedures"
  },
  "ResourceNode::ProfessionalTool": {
    id: "hxBj46",
    defaultMessage: "Professional Tools"
  },
  "ResourceNode::Program": {
    id: "tc4TYt",
    defaultMessage: "Programs"
  },
  "ResourceNode::Relation": {
    id: "fxml5L",
    defaultMessage: "Relations"
  },
  "ResourceNode::Taggable": {
    id: "vV8RQO",
    defaultMessage: "Taggables"
  },
  "ResourceNode::Taggable::Resource": {
    id: "RfDVhR",
    defaultMessage: "Taggable Resources"
  },
  "ResourceNode::Taggable::Tree": {
    id: "1Q4rJ/",
    defaultMessage: "Taggable Resource Trees"
  },
  "ResourceNode::Training": {
    id: "hSxsOA",
    defaultMessage: "Training"
  },
  "ResourceNode::Treatment": {
    id: "SaYS9X",
    defaultMessage: "Treatments"
  },
  "ResourceNode::Treatment::Otc": {
    id: "3y3F4v",
    defaultMessage: "Treatments (OTC)"
  },
  "ResourceNode::Treatment::Placeholder": {
    id: "Y07KVa",
    defaultMessage: "Treatments (Placeholder)"
  },
  "ResourceNode::Treatment::Rx": {
    id: "d6b9Gk",
    defaultMessage: "Treatments (Rx)"
  }
});
export const status = defineMessages({
  active: {
    id: "3fjjA5",
    defaultMessage: "Active"
  },
  inactive: {
    id: "nLSOBW",
    defaultMessage: "Inactive"
  }
});