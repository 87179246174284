import _styled20 from "styled-components";
import _styled19 from "styled-components";
import _styled18 from "styled-components";
import _styled17 from "styled-components";
import _styled16 from "styled-components";
import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
var _CpPackageSessionSendMutation;
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { ScrollView, View } from "react-native";
import { graphql, useMutation } from "react-relay";
import * as zod from "zod";
import CpForm from "components/form/CpForm";
import CpFormTextInput from "components/form/CpFormTextInput";
import { FormNecessity } from "components/form/types";
import CpSessionDisclaimerAndError from "components/patientSession/CpSessionDisclaimerAndError";
import CpSessionProgress from "components/patientSession/CpSessionProgress";
import { ModalSteps, PatientSessionActions } from "components/patientSession/PatientSession";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import useAnalytics from "hooks/useAnalytics";
import useAppTheme from "hooks/useAppTheme";
import useFormResolver from "hooks/useFormResolver";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { maxEmailLength } from "services/Constants";
import { GoogleAnalyticsDimension } from "services/GoogleAnalytics";
import Log from "services/Log";
import { getPackageInputGroups } from "services/PatientSession";
import { css, styles } from "services/Theme";
import { emailListValidation } from "services/Validation";
import translations from "translations";
const packageToPatientSessionCreateMutation = _CpPackageSessionSendMutation !== void 0 ? _CpPackageSessionSendMutation : _CpPackageSessionSendMutation = require("./__generated__/CpPackageSessionSendMutation.graphql");
const localTranslations = defineMessages({
  emailError: {
    id: "yVPXp0",
    defaultMessage: "Please enter at least one email"
  },
  sendToPatient: {
    id: "4ZA+Lj",
    defaultMessage: "Send to patients"
  }
});
const localStyles = {
  buttonContainer: css`
    align-items: center;
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  buttonText: css`
    /* Overriding line height as without this text is overly spread out on smaller screens */
    line-height: 105%;
  `,
  buttonTextDisabled: css`
    color: ${({
    theme
  }) => theme.textColors.muted};
  `,
  subtext: css`
    color: ${({
    theme
  }) => theme.textColors.muted};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `,
  subtextContainer: css`
    margin-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
    margin-top: -${({
    theme
  }) => theme.spacing.half}px;
    margin-bottom: ${({
    theme
  }) => 3 * theme.spacing.half}px;
  `
};
/**
 * Renders the form for creating a PatientSession record for sending to a patient.
 */
const CpPackageSessionSend = ({
  onClose,
  style
}) => {
  const {
    trackGoogleEvent
  } = useAnalytics();
  const navigation = useNavigation();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    formatMessage
  } = useIntl();
  const {
    organization,
    user
  } = useImdSession();
  const {
    buttonColors,
    iconSizes
  } = useAppTheme();
  const {
    dispatchPatientSession,
    patientSessionData: {
      packageResources,
      packageTemplate
    }
  } = usePatientSessionContext();

  // PatientSessionDispatch callbacks
  const handleSaveTemplate = useCallback(() => {
    dispatchPatientSession({
      payload: {
        modalStep: ModalSteps.PackageSaveTemplate
      },
      type: PatientSessionActions.SetModal
    });
  }, [dispatchPatientSession]);
  const handleConfirmSelection = useCallback(() => {
    trackGoogleEvent({
      action: "Sent",
      name: "packageSession",
      options: {
        dimensions: {
          [GoogleAnalyticsDimension.organizationId]: organization?._id,
          [GoogleAnalyticsDimension.practitionerType]: user?.practitionerProfile?.type || undefined
        }
      }
    });
    dispatchPatientSession({
      payload: {
        modalStep: ModalSteps.PackageSuccess
      },
      type: PatientSessionActions.SetModal
    });
  }, [dispatchPatientSession, organization, trackGoogleEvent, user]);
  const handleClearAndReturn = useCallback(() => {
    // RESET_SESSION will close the modal for us
    dispatchPatientSession({
      type: PatientSessionActions.ResetPackage
    });
    trackGoogleEvent({
      action: "Cleared",
      name: "packageSession",
      options: {
        dimensions: {
          [GoogleAnalyticsDimension.organizationId]: organization?._id,
          [GoogleAnalyticsDimension.practitionerType]: user?.practitionerProfile?.type || undefined
        }
      }
    });
    navigation.navigate(Routes.home);
  }, [dispatchPatientSession, navigation, organization?._id, trackGoogleEvent, user]);
  const handleBack = useCallback(() => {
    dispatchPatientSession({
      payload: {
        modalStep: ModalSteps.PackageReview
      },
      type: PatientSessionActions.SetModal
    });
  }, [dispatchPatientSession]);

  // Mutation and form submit handlers
  const [formError, setFormError] = useState();
  const [submitPatientSession, isSubmittingPatientSession] = useMutation(packageToPatientSessionCreateMutation);
  const form = useForm({
    mode: "onBlur",
    resolver: useFormResolver(useMemo(() => zod.object({
      // This may not be the best solution for a boolean, but this at least allows us to provide a proper error message - see useFormResolver `invalid_literal`
      disclaimer: zod.literal(true),
      emails: zod.string().min(1, {
        message: formatMessage(localTranslations.emailError)
      }).refine(...emailListValidation()),
      note: zod.string().optional()
    }), [formatMessage]))
  });
  const handleSubmit = useMemo(() => form.handleSubmit(values => {
    const {
      emails,
      note
    } = values;
    Log.info("CpPackageSessionSend - Submitting Package as PatientSession");
    const {
      commonTopicsInput,
      topicNodesInput
    } = getPackageInputGroups(packageResources, organization?._id || "");
    // Clean up emails string, splitting at commas and clear spaces
    const emailArray = emails.split(",").filter(String).map(email => email.trim());
    const submit = async () => {
      submitPatientSession({
        onCompleted: ({
          patientPackageToSessionCreate
        }) => {
          Log.info(`CpPackageSessionSend - ${patientPackageToSessionCreate?.patientSessions.length} PatientSession(s) successfully created: `, patientPackageToSessionCreate?.patientSessions.map(patientSession => patientSession.id));
          handleConfirmSelection();
        },
        onError: setFormError,
        variables: {
          input: {
            commonTopicsInput,
            emails: emailArray,
            note,
            patientPackageId: packageTemplate?.id,
            topicNodesInput
          }
        }
      });
    };
    submit();
  }), [form, handleConfirmSelection, organization?._id, packageResources, packageTemplate?.id, submitPatientSession]);
  return <_StyledScrollView style={style} $_css={styles.sessionStyles.scrollView}>
      <CpSessionProgress accessibilityLabel={formatMessage(translations.accessibility.label.progressPackageSession)} accessibilityValue={{
      text: formatMessage(translations.labels.send)
    }} completedSteps={1} totalSteps={1} />
      <_StyledPrText accessibilityRole="header" $_css2={[styles.modalHeaderText, styles.paddingHorizontalSingle]}>
        <FormattedMessage id="2zAVYY" defaultMessage="Send Package" />
      </_StyledPrText>

      <_StyledView $_css3={styles.paddingHorizontalSingle}>
        {/* Save template button */}
        <_StyledPrButton accessibilityHint={formatMessage(translations.accessibility.hint.packageSave)} accessibilityRole="link" onPress={handleSaveTemplate} testID="CpPackageSessionSend-saveTemplate" type="text" $_css4={styles.marginVerticalSingle}>
          <_StyledView2 $_css5={[styles.rowAndCenter, styles.flexSingle]}>
            <_StyledPrIcon fill={buttonColors.primary} icon={IconName.medicalSave} size={iconSizes.medium} $_css6={styles.paddingHorizontalSingle} />
            <_StyledPrText2 children={translations.buttons.saveAsTemplate} $_css7={[styles.sessionStyles.buttonText, localStyles.buttonText]} />
          </_StyledView2>
        </_StyledPrButton>

        {/* Form */}
        <CpForm onSubmit={handleSubmit} {...form}>
          <CpFormTextInput accessibilityLabel={formatMessage(translations.labels.patientEmails)} autoCapitalize="none" autoComplete="off" autoCorrect={false} autoFocus={true} keyboardType="email-address" label={translations.labels.patientEmails} maxLength={maxEmailLength} multiline={true} name="emails" numberOfLines={2} returnKeyType="next" testID="CpPackageSessionSend-emailField" textContentType="emailAddress" />
          {/* Need to wrap subtext in a View with the required horizontal margin to ensure margin is respected when the line breaks on smaller screens */}
          <_StyledView3 $_css8={localStyles.subtextContainer}>
            <_StyledPrText3 $_css9={localStyles.subtext}>
              <FormattedMessage id="0EOhKU" defaultMessage="Include as many patient emails as required by adding them separated by commas." />
            </_StyledPrText3>
          </_StyledView3>
          <CpFormTextInput accessibilityLabel={formatMessage(translations.labels.notesToPatient)} autoCapitalize="sentences" autoComplete="off" autoCorrect={true} keyboardType="default" label={translations.labels.notesToPatient} multiline={true} name="note" necessity={FormNecessity.Optional} numberOfLines={3} onSubmitEditing={handleSubmit} returnKeyType="next" testID="CpPackageSessionSend-noteField" textContentType="none" />

          <CpSessionDisclaimerAndError formError={formError} />

          {/* Buttons */}
          <_StyledView4 $_css10={[localStyles.buttonContainer, !isNarrowScreen ? styles.sessionStyles.buttonContainerLarge : undefined]}>
            <_StyledView5 $_css11={isNarrowScreen ? styles.fullWidth : [styles.rowAndCenter, styles.marginRightDouble]}>
              <_StyledPrButton2 accessibilityLabel={formatMessage(localTranslations.sendToPatient)} busy={isSubmittingPatientSession} disabled={isSubmittingPatientSession} onPress={handleSubmit} testID="CpPackageSessionSend-submit" type="raised" $_css12={[styles.sessionStyles.button, isNarrowScreen ? styles.fullWidth : styles.marginRightDouble]}>
                <_StyledPrText4 children={localTranslations.sendToPatient} $_css13={styles.sessionStyles.buttonText} />
              </_StyledPrButton2>
              <_StyledPrButton3 accessibilityLabel={formatMessage(translations.buttons.continue)} onPress={onClose} testID="CpPackageSessionSend-continue" type="outline" $_css14={[styles.sessionStyles.button, isNarrowScreen ? styles.fullWidth : undefined]}>
                <_StyledPrText5 children={translations.buttons.continueSession} $_css15={styles.sessionStyles.buttonText} />
              </_StyledPrButton3>
            </_StyledView5>
            <_StyledPrButton4 accessibilityLabel={formatMessage(translations.buttons.clearAndReturnHome)} color="destructive" onPress={handleClearAndReturn} testID="CpPackageSessionSend-end" type="text" $_css16={[styles.sessionStyles.button, isNarrowScreen ? styles.fullWidth : undefined]}>
              <_StyledPrText6 children={translations.buttons.clearAndReturnHome} $_css17={styles.sessionStyles.buttonText} />
            </_StyledPrButton4>
          </_StyledView4>

          <_StyledView6 $_css18={isNarrowScreen ? styles.fullWidth : styles.flexEnd}>
            <_StyledPrButton5 onPress={handleBack} testID="CpPackageSessionSend-back" type="outline" $_css19={styles.marginTopDouble}>
              <_StyledPrText7 children={translations.buttons.back} $_css20={styles.sessionStyles.buttonText} />
            </_StyledPrButton5>
          </_StyledView6>
        </CpForm>
      </_StyledView>
    </_StyledScrollView>;
};
export default CpPackageSessionSend;
var _StyledScrollView = _styled(ScrollView).withConfig({
  displayName: "CpPackageSessionSend___StyledScrollView",
  componentId: "sc-160m9jw-0"
})(["", ""], p => p.$_css);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpPackageSessionSend___StyledPrText",
  componentId: "sc-160m9jw-1"
})(["", ""], p => p.$_css2);
var _StyledView = _styled(View).withConfig({
  displayName: "CpPackageSessionSend___StyledView",
  componentId: "sc-160m9jw-2"
})(["", ""], p => p.$_css3);
var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpPackageSessionSend___StyledPrButton",
  componentId: "sc-160m9jw-3"
})(["", ""], p => p.$_css4);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CpPackageSessionSend___StyledView2",
  componentId: "sc-160m9jw-4"
})(["", ""], p => p.$_css5);
var _StyledPrIcon = _styled(PrIcon).withConfig({
  displayName: "CpPackageSessionSend___StyledPrIcon",
  componentId: "sc-160m9jw-5"
})(["", ""], p => p.$_css6);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionSend___StyledPrText2",
  componentId: "sc-160m9jw-6"
})(["", ""], p => p.$_css7);
var _StyledView3 = _styled(View).withConfig({
  displayName: "CpPackageSessionSend___StyledView3",
  componentId: "sc-160m9jw-7"
})(["", ""], p => p.$_css8);
var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionSend___StyledPrText3",
  componentId: "sc-160m9jw-8"
})(["", ""], p => p.$_css9);
var _StyledView4 = _styled(View).withConfig({
  displayName: "CpPackageSessionSend___StyledView4",
  componentId: "sc-160m9jw-9"
})(["", ""], p => p.$_css10);
var _StyledView5 = _styled(View).withConfig({
  displayName: "CpPackageSessionSend___StyledView5",
  componentId: "sc-160m9jw-10"
})(["", ""], p => p.$_css11);
var _StyledPrButton2 = _styled(PrButton).withConfig({
  displayName: "CpPackageSessionSend___StyledPrButton2",
  componentId: "sc-160m9jw-11"
})(["", ""], p => p.$_css12);
var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionSend___StyledPrText4",
  componentId: "sc-160m9jw-12"
})(["", ""], p => p.$_css13);
var _StyledPrButton3 = _styled(PrButton).withConfig({
  displayName: "CpPackageSessionSend___StyledPrButton3",
  componentId: "sc-160m9jw-13"
})(["", ""], p => p.$_css14);
var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionSend___StyledPrText5",
  componentId: "sc-160m9jw-14"
})(["", ""], p => p.$_css15);
var _StyledPrButton4 = _styled(PrButton).withConfig({
  displayName: "CpPackageSessionSend___StyledPrButton4",
  componentId: "sc-160m9jw-15"
})(["", ""], p => p.$_css16);
var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionSend___StyledPrText6",
  componentId: "sc-160m9jw-16"
})(["", ""], p => p.$_css17);
var _StyledView6 = _styled(View).withConfig({
  displayName: "CpPackageSessionSend___StyledView6",
  componentId: "sc-160m9jw-17"
})(["", ""], p => p.$_css18);
var _StyledPrButton5 = _styled(PrButton).withConfig({
  displayName: "CpPackageSessionSend___StyledPrButton5",
  componentId: "sc-160m9jw-18"
})(["", ""], p => p.$_css19);
var _StyledPrText7 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionSend___StyledPrText7",
  componentId: "sc-160m9jw-19"
})(["", ""], p => p.$_css20);