import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useState } from "react";
import { FormattedDate, FormattedMessage, defineMessages } from "react-intl";
import { Modal, ScrollView, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import WebView from "react-native-webview";
import CpAppFooter from "components/app/CpAppFooter";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpBackgroundHeader from "components/common/CpBackgroundHeader";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { css, rnStyles, styles } from "services/Theme";
import translations from "translations";
import TermsOfUsePatient from "pdfs/TermsOfUsePatient.pdf";
import TermsOfUseProvider from "pdfs/TermsOfUseProvider.pdf";
const localTranslations = defineMessages({
  termsOfUsePatient: {
    id: "M7YrUn",
    defaultMessage: "Terms of Use - Patient"
  },
  termsOfUsePatientBlurb: {
    id: "dqfbJg",
    defaultMessage: "IMD Health provides a web site and internet based service offering a secure, private channel for healthcare communications with your healthcare provider, online healthcare information, patient education, and related products, services and content..."
  },
  termsOfUseProvider: {
    id: "kFHJUz",
    defaultMessage: "Terms of Use - Provider"
  },
  termsOfUseProviderBlurb: {
    id: "k3NQWe",
    defaultMessage: "IMD Health Global Corp maintains the IMD Health platform as a service to its customers. By using the IMD Health platform owned and operated by IMD Health Global Corp or its affiliates, you are agreeing to comply with and be bound by these terms of use, which may change at any time. You must carefully read and fully understand this notice before using the IMD Health platform..."
  }
});
const localStyles = {
  termsOfUseButton: css`
    justify-content: center;
  `,
  termsOfUseContainer: css`
    min-height: 90vh;
    padding-top: ${({
    theme
  }) => theme.spacing.single}px;
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  termsOfUseDetailsContainer: css`
    flex-direction: row;
    justify-content: space-between;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  viewText: css`
    color: ${({
    theme
  }) => theme.brandColors.primary};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("xsmall")};
    font-weight: bold;
    padding-top: ${({
    theme
  }) => theme.spacing.half}px;
    ${styles.uppercase};
  `
};
/**
 * Mapping all terms of use and their related content
 * NB: If uploading a new TOU, ensure the updated date is changed to match the document, and maybe even the blurb if necessary
 */
const termsOfUseData = {
  touPatient: {
    blurb: localTranslations.termsOfUsePatientBlurb,
    label: localTranslations.termsOfUsePatient,
    pdf: TermsOfUsePatient,
    updated: new Date("January 1, 2020")
  },
  touProvider: {
    blurb: localTranslations.termsOfUseProviderBlurb,
    label: localTranslations.termsOfUseProvider,
    pdf: TermsOfUseProvider,
    updated: new Date("January 1, 2020")
  }
};
const termsOfUseDataOrder = ["touPatient", "touProvider"];

/**
 * Show the app's terms of use. Selected TOU will open in a fullscreen modal.
 */
const ScTermsOfUse = ({
  navigation,
  route
}) => {
  const {
    brandColors,
    buttonColors,
    iconSizes
  } = useAppTheme();
  const {
    contentMinHeight,
    isNarrowScreen,
    isWideScreen
  } = useScreenLayout();
  const [selectedTermsOfUse, setSelectedTermsOfUse] = useState();
  const handleSetSelectedTermsOfUse = useCallback(touPdf => setSelectedTermsOfUse(touPdf), []);
  const handleHideSelectedTermsOfUse = useCallback(() => setSelectedTermsOfUse(undefined), []);
  const navigateToHome = useCallback(() => navigation.navigate(Routes.home), [navigation]);
  return <_StyledSafeAreaView $_css={[styles.flexSingle, styles.screen]}>
      <CpUpdatePageTitle title={translations.screens[route.name]} />
      <ScrollView contentContainerStyle={rnStyles.flexSingle}>
        <CpBackgroundHeader header={translations.screens.terms_of_use}>
          <_StyledView $_css2={[localStyles.termsOfUseContainer, !isNarrowScreen ? styles.limitedLargeScreenWidth : undefined, css`
                min-height: ${contentMinHeight}px;
              `]}>
            {termsOfUseDataOrder.map(tou => {
            const {
              blurb,
              label,
              pdf,
              updated
            } = termsOfUseData[tou];
            return <_StyledPrPressable key={tou} onPress={() => handleSetSelectedTermsOfUse(pdf)} $_css3={localStyles.termsOfUseButton}>
                  {({
                hovered
              }) => <_StyledView2 $_css4={localStyles.termsOfUseDetailsContainer}>
                      <_StyledView3 $_css5={styles.flexSingle}>
                        <_StyledPrText children={label} $_css6={[styles.textHeader2, hovered && styles.termsAndPoliciesStyles.hoveredText]} />
                        <View>
                          <PrText children={blurb} />
                          <_StyledPrText2 $_css7={styles.termsAndPoliciesStyles.date}>
                            <FormattedDate day="2-digit" month="long" value={updated} year="numeric" />
                          </_StyledPrText2>
                        </View>
                      </_StyledView3>
                      <_StyledView4 $_css8={styles.termsAndPoliciesStyles.iconContainer}>
                        <PrIcon fill={brandColors.primary} icon={IconName.pdf} size={isNarrowScreen ? iconSizes.medium : iconSizes.large} />
                      </_StyledView4>
                    </_StyledView2>}
                </_StyledPrPressable>;
          })}

            <_StyledPrButton onPress={navigateToHome} type="outline" $_css9={styles.marginDouble}>
              <_StyledPrText3 children={translations.buttons.home} $_css10={styles.termsAndPoliciesStyles.homeButtonText} />
            </_StyledPrButton>
          </_StyledView>
          <CpAppFooter />
        </CpBackgroundHeader>
      </ScrollView>

      {!!selectedTermsOfUse && <Modal onRequestClose={handleHideSelectedTermsOfUse} transparent={false} visible={!!selectedTermsOfUse}>
          <_StyledView5 $_css11={styles.termsAndPoliciesStyles.pdfModalContainer}>
            <_StyledView6 $_css12={styles.termsAndPoliciesStyles.fullscreenButtonContainer}>
              <_StyledPrPressable2 onPress={handleHideSelectedTermsOfUse} $_css13={styles.termsAndPoliciesStyles.fullscreenCloseButton}>
                <_StyledPrText4 $_css14={styles.termsAndPoliciesStyles.fullscreenCloseText}>
                  <FormattedMessage {...translations.buttons.backTo} values={{
                label: <FormattedMessage {...translations.screens.terms_of_use} />
              }} />
                </_StyledPrText4>
                <PrIcon fill={buttonColors.primary} icon={IconName.back} size={isWideScreen ? iconSizes.large : iconSizes.medium} />
              </_StyledPrPressable2>
            </_StyledView6>
            <WebView source={{
          uri: selectedTermsOfUse
        }} />
          </_StyledView5>
        </Modal>}
    </_StyledSafeAreaView>;
};
export default ScTermsOfUse;
var _StyledSafeAreaView = _styled(SafeAreaView).withConfig({
  displayName: "ScTermsOfUse___StyledSafeAreaView",
  componentId: "sc-3atg3b-0"
})(["", ""], p => p.$_css);
var _StyledView = _styled(View).withConfig({
  displayName: "ScTermsOfUse___StyledView",
  componentId: "sc-3atg3b-1"
})(["", ""], p => p.$_css2);
var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "ScTermsOfUse___StyledPrPressable",
  componentId: "sc-3atg3b-2"
})(["", ""], p => p.$_css3);
var _StyledView2 = _styled(View).withConfig({
  displayName: "ScTermsOfUse___StyledView2",
  componentId: "sc-3atg3b-3"
})(["", ""], p => p.$_css4);
var _StyledView3 = _styled(View).withConfig({
  displayName: "ScTermsOfUse___StyledView3",
  componentId: "sc-3atg3b-4"
})(["", ""], p => p.$_css5);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "ScTermsOfUse___StyledPrText",
  componentId: "sc-3atg3b-5"
})(["", ""], p => p.$_css6);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "ScTermsOfUse___StyledPrText2",
  componentId: "sc-3atg3b-6"
})(["", ""], p => p.$_css7);
var _StyledView4 = _styled(View).withConfig({
  displayName: "ScTermsOfUse___StyledView4",
  componentId: "sc-3atg3b-7"
})(["", ""], p => p.$_css8);
var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "ScTermsOfUse___StyledPrButton",
  componentId: "sc-3atg3b-8"
})(["", ""], p => p.$_css9);
var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "ScTermsOfUse___StyledPrText3",
  componentId: "sc-3atg3b-9"
})(["", ""], p => p.$_css10);
var _StyledView5 = _styled(View).withConfig({
  displayName: "ScTermsOfUse___StyledView5",
  componentId: "sc-3atg3b-10"
})(["", ""], p => p.$_css11);
var _StyledView6 = _styled(View).withConfig({
  displayName: "ScTermsOfUse___StyledView6",
  componentId: "sc-3atg3b-11"
})(["", ""], p => p.$_css12);
var _StyledPrPressable2 = _styled(PrPressable).withConfig({
  displayName: "ScTermsOfUse___StyledPrPressable2",
  componentId: "sc-3atg3b-12"
})(["", ""], p => p.$_css13);
var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "ScTermsOfUse___StyledPrText4",
  componentId: "sc-3atg3b-13"
})(["", ""], p => p.$_css14);