import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useState } from "react";
import { View } from "react-native";
import { useFragment } from "react-relay/hooks";
import CpTopicSubtreeHeader from "components/topic/CpTopicSubtreeHeader";
import CpTopicSubtreeResourceItem from "components/topic/CpTopicSubtreeResourceItem";
import CpTopicSubtreeResourceNodeItem from "components/topic/CpTopicSubtreeResourceNodeItem";
import useScreenLayout from "hooks/useScreenLayout";
import { QlTopicSubtreeFragment } from "query/QlTopicSubtree";
import { getNameVariant } from "services/Graphql";
import { styles } from "services/Theme";
import CpGrid from "../common/CpGrid";

/**
 * Returns whether a node should be displayed as a leaf. This is if it has no children and does not
 * have any tagged resources.
 */
function isDisplayLeaf(node) {
  return !node.children.length && node.type !== "ResourceNode::Taggable::Resource";
}

/**
 * Splits a group of nodes by whether a node is a leaf and whether its neighbours in the array are
 * leaves. This is used for properly splitting a root node's children into sections, with each
 * section being rendered properly. Any adjacent leaves are combined into one section, while
 * non-leaves are their own section.
 *
 * Suppose our root node has children in the order [a, b, C, D, e], wth lower-case letters denoting
 * leaves and upper-case letters denoting non-leaves. To split this properly, [a, b] would be its
 * own section because both are adjacent leaves. C and D would each be in their own arrays since
 * they are non-leaves. e would be in its own array too since it does not have any adjacent leaves.
 * The proper result would be [[a, b], [C], [D], [e]]
 */
function splitNodesIntoSections(nodes) {
  const output = [];
  const window = [];
  nodes.forEach(node => {
    if (!isDisplayLeaf(node)) {
      if (window.length) {
        output.push([...window]);
        window.length = 0;
      }
      output.push([node]);
    } else {
      window.push(node);
    }
  });
  if (window.length) {
    output.push([...window]);
  }
  return output;
}

/**
 * Renders the current layer of the subtree
 */
const CpTopicSubtree = ({
  depth = 0,
  isFirstChild = false,
  isLastChild = false,
  node
}) => {
  const {
    isWideScreen
  } = useScreenLayout();
  const nodeData = useFragment(QlTopicSubtreeFragment, node.fragmentKey);
  const taggedResources = nodeData.taggedResources;
  const renderNodeAsHeading = Boolean(depth === 1 && isWideScreen && (!nodeData?.isLeaf || taggedResources?.length));
  const expandFirstChildByDefault = depth === 1 && !isWideScreen && (!nodeData?.isLeaf || taggedResources?.length) && isFirstChild;
  const [expanded, setExpanded] = useState(() => Boolean(depth === 0 || renderNodeAsHeading || expandFirstChildByDefault));
  const handleSelect = useCallback(() => {
    setExpanded(currentExpanded => !currentExpanded);
  }, []);
  const childCount = taggedResources?.length ?? node.children.length;
  if (!depth) {
    if (taggedResources) {
      return <_StyledCpGrid $_css={styles.alignItemsCenter}>
          <CpTopicSubtreeGridContainer nodeRevisionId={nodeData.revisionId} taggedResources={taggedResources} />
        </_StyledCpGrid>;
    }
    const gridSections = splitNodesIntoSections(node.children);
    return <_StyledCpGrid2 $_css2={styles.alignItemsCenter}>
        {gridSections.map(section => {
        // If this section has only leaves, render all leaves together
        if (isDisplayLeaf(section[0])) {
          return <CpTopicSubtreeGridContainer nodes={section} />;
        }
        return <CpTopicSubtree depth={1} isFirstChild={true} isLastChild={true} node={section[0]} />;
      })}
      </_StyledCpGrid2>;
  } else if (childCount) {
    return <>
        <CpTopicSubtreeHeader expanded={expanded} isFirstChild={isFirstChild} isHeading={renderNodeAsHeading} isLastChild={isLastChild} label={getNameVariant(nodeData) ?? ""} onSelect={handleSelect} />
        {expanded && <CpTopicSubtreeGridContainer nodeRevisionId={nodeData.revisionId} nodes={node.children} taggedResources={taggedResources} />}
      </>;
  } else {
    return null;
  }
};

/**
 * Represents a section of the page that should be displayed as a grid of items
 */
const CpTopicSubtreeGridContainer = ({
  nodeRevisionId,
  nodes,
  taggedResources
}) => {
  const taggedResourceCards = nodeRevisionId && taggedResources?.map(taggedResource => {
    return <CpTopicSubtreeResourceItem depth={0} key={taggedResource._id} resourceKey={taggedResource} revisionId={nodeRevisionId} />;
  });
  return <_StyledView $_css3={styles.rowAndWrap}>
      {nodes?.map(node => {
      return <CpTopicSubtreeResourceNodeItem depth={0} key={node.id} node={node} />;
    })}
      {taggedResourceCards}
    </_StyledView>;
};
export default CpTopicSubtree;
var _StyledCpGrid = _styled(CpGrid).withConfig({
  displayName: "CpTopicSubtree___StyledCpGrid",
  componentId: "sc-isz2ps-0"
})(["", ""], p => p.$_css);
var _StyledCpGrid2 = _styled(CpGrid).withConfig({
  displayName: "CpTopicSubtree___StyledCpGrid2",
  componentId: "sc-isz2ps-1"
})(["", ""], p => p.$_css2);
var _StyledView = _styled(View).withConfig({
  displayName: "CpTopicSubtree___StyledView",
  componentId: "sc-isz2ps-2"
})(["", ""], p => p.$_css3);