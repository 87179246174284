import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { View } from "react-native";
import { useFragment } from "react-relay";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import { mediaNodeLayoutFragment } from "components/topic/CpMediaNodeNavigatorCommon";
import useAppTheme from "hooks/useAppTheme";
import useScreenLayout from "hooks/useScreenLayout";
import useTopicContext from "hooks/useTopicContext";
import Routes from "navigators/Routes";
import { css, styles } from "services/Theme";
import translations from "translations";
const mobileBottomButtonBase = css`
  align-items: center;
  background-color: ${({
  theme
}) => theme.brandColors.surface};
  border: 1px solid ${({
  theme
}) => theme.buttonColors.primary};
  flex: 1;
  justify-content: center;
`;
const localStyles = {
  bottomButtonBack: css`
    ${mobileBottomButtonBase};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    height: ${({
    theme
  }) => theme.buttonHeights.xxlarge}px;
  `,
  bottomButtonNext: css`
    ${mobileBottomButtonBase};
    flex-direction: row;
    height: ${({
    theme
  }) => theme.buttonHeights.large}px;
    border-top-right-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border-bottom-right-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    /* Subtracting a pixel to better line up alongside centre 'Back' button */
    margin-left: -1px;
  `,
  bottomButtonPrevious: css`
    ${mobileBottomButtonBase};
    flex-direction: row;
    height: ${({
    theme
  }) => theme.buttonHeights.large}px;
    border-top-left-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border-bottom-left-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    /* Subtracting a pixel to better line up alongside centre 'Back' button */
    margin-right: -1px;
  `,
  bottomButtonText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    line-height: 105%;
    text-align: center;
  `,
  disabled: css`
    color: ${({
    theme
  }) => theme.buttonColors.textInputDisabled};
  `,
  mobileBottomButtonContainer: css`
    ${styles.rowAndCenter};
    background-color: ${({
    theme
  }) => theme.brandColors.background};
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
    padding-vertical: ${({
    theme
  }) => theme.spacing.double}px;
  `
};
const localTranslations = defineMessages({
  // labelCount required for translating, anything other than a value of 1 will provide a plural translation
  Diagram: {
    id: "f6QYIZ",
    defaultMessage: "{labelCount, plural, one {Diagram} other {Diagrams}}"
  },
  Video: {
    id: "zeRKZe",
    defaultMessage: "{labelCount, plural, one {Video} other {Videos}}"
  }
});

/**
 * Renders provided Media for small (mobile) screen layouts, adding prev/back/next buttons beneath the content for cycling through the provided data array
 */
const CpMediaNodeNavigatorSmall = ({
  children,
  resourceId,
  resourceNodeKey,
  style,
  type
}) => {
  const navigation = useNavigation();
  const {
    rootRevisionId
  } = useTopicContext();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    iconSizes
  } = useAppTheme();
  const {
    formatMessage
  } = useIntl();
  const resourceNode = useFragment(mediaNodeLayoutFragment, resourceNodeKey);
  const isTaggedResourceNode = resourceNode.type === "ResourceNode::Taggable::Resource";
  const siblings = isTaggedResourceNode ? resourceNode.taggedResources : resourceNode.siblings;
  const currentId = isTaggedResourceNode ? resourceId : resourceNode._id;

  // Callbacks
  const handleOnPressBack = useCallback(() => {
    const ancestorNodes = resourceNode.ancestors ?? [];
    const parent = ancestorNodes[ancestorNodes.length - 1];
    navigation.navigate(Routes.topic, {
      rootRevisionId,
      selectedRevisionId: parent?.revisionId
    });
  }, [navigation, resourceNode, rootRevisionId]);
  const handleOnPressPrevious = useCallback(() => {
    if (!siblings) return;
    const selectedIndex = siblings.findIndex(dataItem => dataItem?._id === currentId);
    const previousIndex = selectedIndex === 0 ? siblings.length - 1 : selectedIndex - 1;
    const previousDataItem = siblings[previousIndex];
    isTaggedResourceNode ? navigation.navigate(Routes.topic, {
      resourceId: previousDataItem?._id,
      rootRevisionId,
      selectedRevisionId: resourceNode.revisionId
    }) : navigation.navigate(Routes.topic, {
      rootRevisionId,
      selectedRevisionId: previousDataItem.revisionId
    });
  }, [currentId, isTaggedResourceNode, resourceNode, navigation, rootRevisionId, siblings]);
  const handleOnPressNext = useCallback(() => {
    if (!siblings) return;
    const selectedIndex = siblings.findIndex(dataItem => dataItem?._id === currentId);
    const nextIndex = selectedIndex === siblings.length - 1 ? 0 : selectedIndex + 1;
    const nextDataItem = siblings[nextIndex];
    isTaggedResourceNode ? navigation.navigate(Routes.topic, {
      resourceId: nextDataItem?._id,
      rootRevisionId,
      selectedRevisionId: resourceNode.revisionId
    }) : navigation.navigate(Routes.topic, {
      rootRevisionId,
      selectedRevisionId: nextDataItem.revisionId
    });
  }, [currentId, isTaggedResourceNode, resourceNode, navigation, rootRevisionId, siblings]);
  const buttonsDisabled = (resourceNode.siblings?.length ?? 0) <= 1;
  return <View style={style}>
      {children}

      <_StyledView $_css={localStyles.mobileBottomButtonContainer}>
        <_StyledPrPressable disabled={buttonsDisabled} onPress={handleOnPressPrevious} $_css2={localStyles.bottomButtonPrevious}>
          {!isNarrowScreen && <PrIcon icon={IconName.keyboardArrowLeft} size={iconSizes.medium} />}
          <_StyledPrText children={<FormattedMessage id="Vrxnys" defaultMessage="Previous {type}" values={{
          type: formatMessage(localTranslations[type], {
            labelCount: 1
          })
        }} />} $_css3={[localStyles.bottomButtonText, buttonsDisabled && localStyles.disabled]} />
        </_StyledPrPressable>
        <_StyledPrPressable2 onPress={handleOnPressBack} $_css4={localStyles.bottomButtonBack}>
          <_StyledPrText2 children={<FormattedMessage {...translations.buttons.backTo} values={{
          label: formatMessage(localTranslations[type], {
            labelCount: 10
          })
        }} />} $_css5={localStyles.bottomButtonText} />
        </_StyledPrPressable2>
        <_StyledPrPressable3 disabled={buttonsDisabled} onPress={handleOnPressNext} $_css6={localStyles.bottomButtonNext}>
          <_StyledPrText3 children={<FormattedMessage id="r/GHdl" defaultMessage="Next {type}" values={{
          type: formatMessage(localTranslations[type], {
            labelCount: 1
          })
        }} />} $_css7={[localStyles.bottomButtonText, buttonsDisabled && localStyles.disabled]} />
          {!isNarrowScreen && <PrIcon icon={IconName.keyboardArrowRight} size={iconSizes.medium} />}
        </_StyledPrPressable3>
      </_StyledView>
    </View>;
};
export default CpMediaNodeNavigatorSmall;
var _StyledView = _styled(View).withConfig({
  displayName: "CpMediaNodeNavigatorSmall___StyledView",
  componentId: "sc-34orxa-0"
})(["", ""], p => p.$_css);
var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpMediaNodeNavigatorSmall___StyledPrPressable",
  componentId: "sc-34orxa-1"
})(["", ""], p => p.$_css2);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpMediaNodeNavigatorSmall___StyledPrText",
  componentId: "sc-34orxa-2"
})(["", ""], p => p.$_css3);
var _StyledPrPressable2 = _styled(PrPressable).withConfig({
  displayName: "CpMediaNodeNavigatorSmall___StyledPrPressable2",
  componentId: "sc-34orxa-3"
})(["", ""], p => p.$_css4);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpMediaNodeNavigatorSmall___StyledPrText2",
  componentId: "sc-34orxa-4"
})(["", ""], p => p.$_css5);
var _StyledPrPressable3 = _styled(PrPressable).withConfig({
  displayName: "CpMediaNodeNavigatorSmall___StyledPrPressable3",
  componentId: "sc-34orxa-5"
})(["", ""], p => p.$_css6);
var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpMediaNodeNavigatorSmall___StyledPrText3",
  componentId: "sc-34orxa-6"
})(["", ""], p => p.$_css7);