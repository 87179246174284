import _styled21 from "styled-components";
import _styled20 from "styled-components";
import _styled19 from "styled-components";
import _styled18 from "styled-components";
import _styled17 from "styled-components";
import _styled16 from "styled-components";
import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { useRoute } from "@react-navigation/native";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Image, Modal, View } from "react-native";
import CpDivHtmlWeb from "components/common/CpDivHtmlWeb";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import CpDrawingCanvas from "components/topic/drawingTools/CpDrawingCanvas";
import CpDrawingToolsButtons from "components/topic/drawingTools/CpDrawingToolsButtons";
import { DrawingToolsActions } from "components/topic/drawingTools/drawingTools";
import useAnalytics from "hooks/useAnalytics";
import useAppTheme from "hooks/useAppTheme";
import useDrawingToolsContext from "hooks/useDrawingToolsContext";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useScreenLayout from "hooks/useScreenLayout";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  annotationToggleButton: css`
    border: 1px solid ${({
    theme
  }) => theme.buttonColors.primary};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    margin-right: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  button: css`
    border-radius: 0;
    padding-bottom: ${({
    theme
  }) => theme.spacing.half}px;
    padding-left: ${({
    theme
  }) => theme.spacing.double}px;
    padding-right: ${({
    theme
  }) => theme.spacing.double}px;
    padding-top: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  buttonText: css`
    color: ${({
    theme
  }) => theme.textColors.default};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    font-weight: normal;
  `,
  cardCaption: css`
    align-self: center;
    color: ${({
    theme
  }) => theme.textColors.default};
    font-family: ${({
    theme
  }) => theme.fontFamilies.default};
    font-style: italic;
    padding: ${({
    theme
  }) => theme.spacing.double}px;
    text-align: center;
  `,
  cardContainer: css`
    align-self: center;
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border: 1px solid ${({
    theme
  }) => theme.brandColors.separator};
    margin: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  cardLabel: css`
    color: ${({
    theme
  }) => theme.brandColors.primary};
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-weight: bold;
    padding-right: ${({
    theme
  }) => theme.spacing.single}px;
    padding-vertical: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  cardLabelContainer: css`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  disclaimerText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `,
  drawAndFullScreen: css`
    align-items: center;
    flex-direction: row;
  `,
  drawContainer: css`
    border: 1px solid ${({
    theme
  }) => theme.buttonColors.primary};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    flex-direction: row;
    margin-right: ${({
    theme
  }) => theme.spacing.single}px;
    margin-vertical: ${({
    theme
  }) => theme.spacing.half}px;
    padding: 0;
  `,
  fullscreenButtonContainer: css`
    align-items: center;
    align-self: center;
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    z-index: ${({
    theme
  }) => theme.layers.secondLayer}px;
  `,
  fullscreenCloseButton: css`
    ${styles.rowAndCenter};
    align-self: flex-end;
  `,
  fullscreenCloseText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    margin-left: ${({
    theme
  }) => theme.spacing.single}px;
    margin-right: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  fullscreenContainer: css`
    background-color: ${({
    theme
  }) => theme.brandColors.background};
    flex: 1;
  `,
  fullscreenImage: css`
    flex: 1;
    height: 100%;
    margin: ${({
    theme
  }) => theme.spacing.double}px;
    width: 100%;
  `,
  fullscreenText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
  `,
  image: css`
    align-self: center;
    margin-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `
};
const largeScreenStyles = {
  cardCaption: css`
    ${localStyles.cardCaption};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
  `,
  cardContainer: css`
    ${localStyles.cardContainer};
    margin-top: 0;
  `,
  cardLabel: css`
    ${localStyles.cardLabel};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
  `,
  fullscreenCloseText: css`
    ${localStyles.fullscreenCloseText};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
  `,
  fullscreenText: css`
    ${localStyles.fullscreenText};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `
};
const smallScreenStyles = {
  cardCaption: css`
    ${localStyles.cardCaption};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `,
  cardContainer: css`
    ${localStyles.cardContainer};
    margin-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  cardLabel: css`
    ${localStyles.cardLabel};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
  `,
  fullscreenCloseText: css`
    ${localStyles.fullscreenCloseText};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
  `,
  fullscreenText: css`
    ${localStyles.fullscreenText};
    font-size: ${({
    theme
  }) => theme.fontSize("xsmall")};
  `
};
/**
 * Renders an Image in a card format. Displays a label, and an optional caption. Has built in functionality for expanding to fullscreen (for larger screens).
 */
const CpImageCard = ({
  caption,
  label,
  style,
  testID,
  uri
}) => {
  const {
    isNarrowScreen,
    isWideScreen
  } = useScreenLayout();
  const {
    buttonColors,
    iconSizes,
    spacing
  } = useAppTheme();
  const {
    formatMessage
  } = useIntl();
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    appConfig,
    user
  } = useImdSession();
  const drawingTool = appConfig?.appTools?.find(tool => tool.name === "Draw");
  const isDrawingDisabled = drawingTool?.status !== "active";
  const isPatient = user?.isPatient;
  const allowAnnotations = Boolean(user) && !isPatient && !isDrawingDisabled;
  const {
    dispatchDrawingTools,
    drawingToolsData: {
      drawingEnabled,
      uploadedImage
    }
  } = useDrawingToolsContext();
  const {
    patientSessionData: {
      annotations,
      isViewingSession
    }
  } = usePatientSessionContext();
  const route = useRoute();
  const [viewOriginal, setViewOriginal] = useState(false);
  const [hasAnnotationImage, setHasAnnotationImage] = useState(false);
  const handleToggleViewOriginal = useCallback(() => {
    setViewOriginal(isOriginalImage => !isOriginalImage);
  }, []);

  // Clears context when we change routes
  useEffect(() => {
    dispatchDrawingTools({
      type: DrawingToolsActions.ClearContext
    });
  }, [dispatchDrawingTools, route]);

  // Decides which image to display
  const displayedImage = useMemo(() => {
    // Resets to default state of not having an annotation
    setHasAnnotationImage(false);
    if (viewOriginal) {
      return uri;
    }
    if (uploadedImage) {
      return uploadedImage;
    }
    if (!route.params?.selectedRevisionId) {
      return null;
    }
    const hasAnnotation = annotations.find(annotation => annotation.resourceNodeRevisionId === route.params.selectedRevisionId);
    if (hasAnnotation) {
      setHasAnnotationImage(true);
      return hasAnnotation.url;
    }
    return null;
  }, [annotations, route.params.selectedRevisionId, uploadedImage, uri, viewOriginal]);
  const [imageSize, setImageSize] = useState({
    aspectRatio: 1,
    height: 0,
    width: 0
  });
  const componentStyles = isNarrowScreen ? smallScreenStyles : largeScreenStyles;
  const [showFullscreen, setShowFullscreen] = useState(false);
  const handleSetShowFullscreen = useCallback(() => setShowFullscreen(true), []);
  const handleSetHideFullscreen = useCallback(() => setShowFullscreen(false), []);
  const {
    params: {
      draw
    }
  } = route;
  const handleOpenDrawingMode = useCallback(() => {
    dispatchDrawingTools({
      payload: {
        drawingEnabled: true,
        isDrawing: true
      },
      type: DrawingToolsActions.SetDrawingOptions
    });
    trackGoogleEvent({
      action: "enabled drawing tools",
      name: "drawingTools"
    });
    setShowFullscreen(true);
  }, [dispatchDrawingTools, trackGoogleEvent]);

  // Opens drawing mode if the drawing route param is present
  useEffect(() => {
    if (draw) {
      handleOpenDrawingMode();
    }
  }, [draw, handleOpenDrawingMode]);

  // Determine the optimal size to render the image
  const handleLayout = useCallback(({
    nativeEvent: {
      layout
    }
  }) => {
    if (uri) {
      Image.getSize(uri, (srcWidth, srcHeight) => {
        const aspectRatio = srcWidth / srcHeight;
        // In order to make sure the image fills the card properly, we use the width of the card component as the final size so long as it doesn't exceed our max width
        // Remove quadruple spacing to accommodate spacing on either side of the image and card
        // TODO: What is a good MAX image height/width? Should it be different per screen size?
        const maxWidth = Math.min(layout.width - spacing.double, 1200);
        const maxHeight = 1000;
        const scaleFactor = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
        const adjustedHeight = scaleFactor * srcHeight;
        const adjustedWidth = scaleFactor * srcWidth;
        setImageSize({
          aspectRatio,
          height: adjustedHeight,
          width: adjustedWidth
        });
      });
    }
  }, [spacing, uri]);

  // Show the toggle annotation/original button if these conditions are met
  const showToggleAnnotationButton = displayedImage && displayedImage !== uri || uploadedImage || hasAnnotationImage || viewOriginal;
  const renderToggleAnnotationButton = useMemo(() => {
    return <_StyledPrButton onPress={handleToggleViewOriginal} testID="CpImageCardToggleAnnotationButton" type="text" $_css={[localStyles.button, localStyles.annotationToggleButton]}>
        <_StyledView $_css2={[styles.center, styles.row]}>
          <_StyledPrText $_css3={[localStyles.buttonText]}>
            {viewOriginal ? <FormattedMessage id="cm6nkZ" defaultMessage="View drawing" /> : <FormattedMessage id="HUo9w4" defaultMessage="View original" />}
          </_StyledPrText>
        </_StyledView>
      </_StyledPrButton>;
  }, [handleToggleViewOriginal, viewOriginal]);
  return <_StyledView2 onLayout={handleLayout} style={style} testID={testID} $_css4={localStyles.cardContainer}>
      {/* Fullscreen Image View - displays in edge-to-edge modal */}
      {showFullscreen && <Modal onRequestClose={handleSetHideFullscreen} transparent={true} visible={showFullscreen}>
          <_StyledView3 $_css5={localStyles.fullscreenContainer}>
            <_StyledView4 $_css6={localStyles.fullscreenButtonContainer}>
              {isViewingSession && showToggleAnnotationButton ? renderToggleAnnotationButton : null}
              <CpDrawingToolsButtons onCloseFullScreen={handleSetHideFullscreen} />
            </_StyledView4>
            {allowAnnotations && drawingEnabled ? <CpDrawingCanvas imageSize={imageSize} uri={uri} /> : <_StyledImage resizeMode="contain" source={{
          uri: displayedImage ? displayedImage : uri
        }} $_css7={localStyles.fullscreenImage} $_css8={imageSize.aspectRatio} />}
          </_StyledView3>
        </Modal>}

      {/* Card label and fullscreen button for non-mobile screens */}
      <_StyledView5 $_css9={localStyles.cardLabelContainer}>
        <_StyledPrText2 children={label} $_css10={componentStyles.cardLabel} />
        {!isNarrowScreen && <_StyledView6 $_css11={localStyles.drawAndFullScreen}>
            {showToggleAnnotationButton ? renderToggleAnnotationButton : null}
            {allowAnnotations && <_StyledView7 $_css12={localStyles.drawContainer}>
                <_StyledPrButton2 accessibilityHint={formatMessage(translations.accessibility.hint.openDrawingMode)} onPress={handleOpenDrawingMode} testID="CpImageCardDraw" type="text" $_css13={[styles.flexSingle, localStyles.button]}>
                  <_StyledView8 $_css14={[styles.center, styles.row]}>
                    <_StyledPrIcon fill={buttonColors.primary} icon={IconName.brush} size={iconSizes.small} $_css15={styles.marginHorizontalSingle} />
                    <_StyledPrText3 children={translations.buttons.draw} $_css16={[localStyles.buttonText]} />
                  </_StyledView8>
                </_StyledPrButton2>
              </_StyledView7>}
            <_StyledPrPressable accessibilityHint={formatMessage(translations.accessibility.hint.openFullScreen)} accessibilityRole="button" onPress={handleSetShowFullscreen} $_css17={styles.center}>
              <PrIcon fill={buttonColors.primary} icon={IconName.fullscreen} size={isWideScreen ? iconSizes.large : iconSizes.medium} />
              <_StyledPrText4 children={translations.buttons.fullscreen} $_css18={componentStyles.fullscreenText} />
            </_StyledPrPressable>
          </_StyledView6>}
      </_StyledView5>
      <_StyledImage2 resizeMode="contain" source={{
      uri: displayedImage ? displayedImage : uri
    }} testID="CpImageCardImage" $_css19={localStyles.image} $_css20={imageSize.aspectRatio} $_css21={imageSize.height} $_css22={imageSize.width} />
      {!!caption &&
    // Captions may include html tags that would require special parsing/handling
    <_StyledCpDivHtmlWeb content={caption} $_css23={[componentStyles.cardCaption,
    // Limiting width as longer captions on large screens will push caption beyond screen edges
    isWideScreen && css`
                max-width: ${imageSize.width}px;
              `]} />}
      {/* Only show disclaimer if: It's a patient session view, there is an annotation, and it's not original view mode */}
      {isViewingSession && hasAnnotationImage && !viewOriginal && <_StyledView9 $_css24={[styles.paddingHorizontalSingle, styles.marginBottomSingle]}>
          <_StyledPrText5 $_css25={localStyles.disclaimerText}>
            <FormattedMessage id="SyxDtn" defaultMessage="This image content might be changed in the future, we recommend you to keep your own copy by downloading or printing the image." />
          </_StyledPrText5>
        </_StyledView9>}
    </_StyledView2>;
};
export default CpImageCard;
var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpImageCard___StyledPrButton",
  componentId: "sc-1czi2zp-0"
})(["", ""], p => p.$_css);
var _StyledView = _styled(View).withConfig({
  displayName: "CpImageCard___StyledView",
  componentId: "sc-1czi2zp-1"
})(["", ""], p => p.$_css2);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpImageCard___StyledPrText",
  componentId: "sc-1czi2zp-2"
})(["", ""], p => p.$_css3);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CpImageCard___StyledView2",
  componentId: "sc-1czi2zp-3"
})(["", ""], p => p.$_css4);
var _StyledView3 = _styled(View).withConfig({
  displayName: "CpImageCard___StyledView3",
  componentId: "sc-1czi2zp-4"
})(["", ""], p => p.$_css5);
var _StyledView4 = _styled(View).withConfig({
  displayName: "CpImageCard___StyledView4",
  componentId: "sc-1czi2zp-5"
})(["", ""], p => p.$_css6);
var _StyledImage = _styled(Image).withConfig({
  displayName: "CpImageCard___StyledImage",
  componentId: "sc-1czi2zp-6"
})(["", ";aspect-ratio:", ";"], p => p.$_css7, p => p.$_css8);
var _StyledView5 = _styled(View).withConfig({
  displayName: "CpImageCard___StyledView5",
  componentId: "sc-1czi2zp-7"
})(["", ""], p => p.$_css9);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpImageCard___StyledPrText2",
  componentId: "sc-1czi2zp-8"
})(["", ""], p => p.$_css10);
var _StyledView6 = _styled(View).withConfig({
  displayName: "CpImageCard___StyledView6",
  componentId: "sc-1czi2zp-9"
})(["", ""], p => p.$_css11);
var _StyledView7 = _styled(View).withConfig({
  displayName: "CpImageCard___StyledView7",
  componentId: "sc-1czi2zp-10"
})(["", ""], p => p.$_css12);
var _StyledPrButton2 = _styled(PrButton).withConfig({
  displayName: "CpImageCard___StyledPrButton2",
  componentId: "sc-1czi2zp-11"
})(["", ""], p => p.$_css13);
var _StyledView8 = _styled(View).withConfig({
  displayName: "CpImageCard___StyledView8",
  componentId: "sc-1czi2zp-12"
})(["", ""], p => p.$_css14);
var _StyledPrIcon = _styled(PrIcon).withConfig({
  displayName: "CpImageCard___StyledPrIcon",
  componentId: "sc-1czi2zp-13"
})(["", ""], p => p.$_css15);
var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpImageCard___StyledPrText3",
  componentId: "sc-1czi2zp-14"
})(["", ""], p => p.$_css16);
var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpImageCard___StyledPrPressable",
  componentId: "sc-1czi2zp-15"
})(["", ""], p => p.$_css17);
var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpImageCard___StyledPrText4",
  componentId: "sc-1czi2zp-16"
})(["", ""], p => p.$_css18);
var _StyledImage2 = _styled(Image).withConfig({
  displayName: "CpImageCard___StyledImage2",
  componentId: "sc-1czi2zp-17"
})(["", ";aspect-ratio:", ";height:", "px;width:", "px;"], p => p.$_css19, p => p.$_css20, p => p.$_css21, p => p.$_css22);
var _StyledCpDivHtmlWeb = _styled(CpDivHtmlWeb).withConfig({
  displayName: "CpImageCard___StyledCpDivHtmlWeb",
  componentId: "sc-1czi2zp-18"
})(["", ""], p => p.$_css23);
var _StyledView9 = _styled(View).withConfig({
  displayName: "CpImageCard___StyledView9",
  componentId: "sc-1czi2zp-19"
})(["", ""], p => p.$_css24);
var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpImageCard___StyledPrText5",
  componentId: "sc-1czi2zp-20"
})(["", ""], p => p.$_css25);