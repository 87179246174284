import _styled from "styled-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { fetchQuery, useRelayEnvironment } from "react-relay";
import { createOperationDescriptor, getRequest } from "relay-runtime";
import CpError from "components/common/CpError";
import Log from "services/Log";
import { styles } from "services/Theme";
/**
 * Fetches a graphql query in the background.
 *   Return undefined until data is available
 *   For all subsequent loads shows old content until new content is available
 */
function CtGraphqlQueryWithoutIndicator(props) {
  const {
    children,
    fetchEveryTime = false,
    noActivityIndicator,
    process,
    query,
    variables
  } = props;
  const relayEnvironment = useRelayEnvironment();

  // Helper to trigger refetching on error
  const [refetchNumber, setFetchNumber] = useState(0);
  const [lastRefetch, setLastRefetch] = useState(0);

  // Response tracking
  const [queryResponse, setQueryResponse] = useState(null);

  // Error Tracking
  const [queryError, setQueryError] = useState(null);
  const clearError = useCallback(() => {
    setQueryError(null);
    setFetchNumber(currentFetchNumber => currentFetchNumber + 1);
  }, []);
  const refresh = useCallback(() => {
    setFetchNumber(currentFetchNumber => currentFetchNumber + 1);
  }, []);
  const defautProcessFunction = useCallback(incomingQueryResponse => incomingQueryResponse, []);
  const processedQueryResponse = useMemo(() => process ? process(queryResponse) : defautProcessFunction(queryResponse), [defautProcessFunction, process, queryResponse]);

  // Fetch logic
  useEffect(() => {
    if (refetchNumber > 0) {
      Log.info("CtGraphqlQueryWithoutIndicator triggering refetch", variables);
    }
    const subscription = fetchQuery(relayEnvironment, query, variables, fetchEveryTime ? {
      fetchPolicy: "network-only",
      networkCacheConfig: {
        force: true
      }
    } : {
      fetchPolicy: "store-or-network"
    }).subscribe({
      complete: () => null,
      error: error => setQueryError(error),
      next: data => {
        setQueryResponse(data);
      },
      start: () => {
        noActivityIndicator ? setQueryResponse(currentValue => currentValue) : setQueryResponse(null);
      },
      unsubscribe: () => null
    });
    const queryRequest = getRequest(query);
    const queryDescriptor = createOperationDescriptor(queryRequest, variables);
    const retained = relayEnvironment.retain(queryDescriptor);

    // refetches the query when the refetchNumber changes
    if (refetchNumber > lastRefetch) {
      subscription.unsubscribe();
      relayEnvironment.execute({
        operation: queryDescriptor
      }).subscribe({
        complete: () => null,
        error: error => setQueryError(error),
        next: data => {
          setQueryResponse(data);
        },
        start: () => {
          noActivityIndicator ? setQueryResponse(currentValue => currentValue) : setQueryResponse(null);
        },
        unsubscribe: () => null
      });
      setLastRefetch(refetchNumber);
    }
    return () => {
      subscription.unsubscribe();
      retained.dispose();
    };
  }, [fetchEveryTime, lastRefetch, noActivityIndicator, query, refetchNumber, relayEnvironment, variables]);

  // We have all the info necessary to render
  if (queryError) {
    return <_StyledCpError error={queryError} onPress={clearError} $_css={styles.errorBox} />;
  } else {
    return children(processedQueryResponse, refresh);
  }
}
export default CtGraphqlQueryWithoutIndicator;
var _StyledCpError = _styled(CpError).withConfig({
  displayName: "CtGraphqlQueryWithoutIndicator___StyledCpError",
  componentId: "sc-1b1m2lh-0"
})(["", ""], p => p.$_css);