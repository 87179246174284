import _styled from "styled-components";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CtChangeAccountPassword from "components/containers/CtChangeAccountPassword";
import { styles } from "services/Theme";
import translations from "translations";
/**
 * The change account password screen
 */
const ScChangeAccountPassword = ({
  route
}) => {
  return <_StyledSafeAreaView $_css={styles.flexSingle}>
      <CpUpdatePageTitle title={translations.screens[route.name]} />
      <CtChangeAccountPassword />
    </_StyledSafeAreaView>;
};
export default ScChangeAccountPassword;
var _StyledSafeAreaView = _styled(SafeAreaView).withConfig({
  displayName: "ScChangeAccountPassword___StyledSafeAreaView",
  componentId: "sc-1smuhn-0"
})(["", ""], p => p.$_css);