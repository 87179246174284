import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
var _CpAccountPractitionerProfileWizardProfileMutation;
import { useNavigation } from "@react-navigation/native";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { View } from "react-native";
import { graphql, useMutation } from "react-relay";
import * as zod from "zod";
import CpPractitionerProfileForm from "components/account/CpPractitionerProfileForm";
import CpError from "components/common/CpError";
import CpLinkButton from "components/common/CpLinkButton";
import CpForm from "components/form/CpForm";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import useAppRegion from "hooks/useAppRegion";
import useFormResolver from "hooks/useFormResolver";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import Log from "services/Log";
import { css, styles } from "services/Theme";
import translations from "translations";
const sendPractitionerProfileMutation = _CpAccountPractitionerProfileWizardProfileMutation !== void 0 ? _CpAccountPractitionerProfileWizardProfileMutation : _CpAccountPractitionerProfileWizardProfileMutation = require("./__generated__/CpAccountPractitionerProfileWizardProfileMutation.graphql");
const localStyles = {
  buttonContainer: css`
    margin-vertical: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  buttonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    font-weight: normal;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  desktopButtonContainer: css`
    flex-direction: row;
    justify-content: space-between;
  `,
  profileText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  supportText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
    padding-left: ${({
    theme
  }) => theme.spacing.single}px;
    padding-top: ${({
    theme
  }) => theme.spacing.half}px;
  `
};
/**
 * The Practitioner Profile Wizard (Step 2)
 */
const CpAccountPractitionerProfileWizard = () => {
  const {
    navigate
  } = useNavigation();
  const {
    formatMessage
  } = useIntl();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    currentRegionSlug
  } = useAppRegion();
  const [submitPractitionerProfile, isSubmittingPractitionerProfile] = useMutation(sendPractitionerProfileMutation);
  const [formError, setFormError] = useState();
  const form = useForm({
    mode: "onBlur",
    resolver: useFormResolver(useMemo(() => zod.object({
      externalId: zod.string().min(5),
      licenseSubdivisionCode: zod.string().min(1),
      signupOrganizationName: zod.string().min(1),
      specialties: zod.array(zod.string()),
      type: zod.string().min(1)
    }), []))
  });
  const handleSubmit = useMemo(() => form.handleSubmit(values => {
    const submitProfile = async () => {
      const otherValue = values.type.match(/Other::(.*)$/);
      const otherType = otherValue && otherValue[1] ? otherValue[1] || "Other" : null;
      submitPractitionerProfile({
        onCompleted: ({
          practitionerProfileCreate
        }) => {
          Log.info(`CpAccountPractitionerProfileWizard - profile updated for ${practitionerProfileCreate?.user.practitionerProfile?.type}`);
          navigate(Routes.home);
        },
        onError: err => {
          setFormError(err);
          Log.error(`CpAccountPractitionerProfileWizard - ${err}`);
        },
        variables: {
          input: {
            ...values,
            licenseCountryCode: currentRegionSlug.toUpperCase(),
            otherType,
            type: `PractitionerProfile::${values.type}`
          }
        }
      });
    };
    submitProfile();
  }), [form, navigate, currentRegionSlug, submitPractitionerProfile]);
  return <View>
      <_StyledPrText $_css={localStyles.profileText}>
        <FormattedMessage id="pgK4mb" defaultMessage="To validate your {nationality} Healthcare Practitioner Account we require some more information." values={{
        nationality: <_StyledPrText2 $_css2={styles.italicText}>
                {formatMessage(translations.enums.nationalities[currentRegionSlug] ?? translations.labels.unknown)}
              </_StyledPrText2>
      }} />
      </_StyledPrText>
      <_StyledPrText3 $_css3={localStyles.supportText}>
        <FormattedMessage id="1SJoC9" defaultMessage="Did you mean to make an account for a different region? Contact {supportEmail}." values={{
        supportEmail: <CpLinkButton url="mailto:support@imdhealth.com">
                <PrText>support@imdhealth.com</PrText>
              </CpLinkButton>
      }} />
      </_StyledPrText3>
      <CpForm {...form} onSubmit={handleSubmit}>
        <CpPractitionerProfileForm />
        <_StyledCpError error={formError} $_css4={[styles.formField, styles.errorBox]} />
        <_StyledView $_css5={[localStyles.buttonContainer, !isNarrowScreen ? localStyles.desktopButtonContainer : undefined]}>
          <PrButton busy={isSubmittingPractitionerProfile} onPress={handleSubmit} testID="CpAccountPractitionerProfileWizardComplete">
            <_StyledPrText4 $_css6={localStyles.buttonText}>
              <FormattedMessage id="xXYtOI" defaultMessage="Complete my profile" />
            </_StyledPrText4>
          </PrButton>
        </_StyledView>
      </CpForm>
    </View>;
};
export default CpAccountPractitionerProfileWizard;
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpAccountPractitionerProfileWizard___StyledPrText",
  componentId: "sc-1dilou-0"
})(["", ""], p => p.$_css);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpAccountPractitionerProfileWizard___StyledPrText2",
  componentId: "sc-1dilou-1"
})(["", ""], p => p.$_css2);
var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpAccountPractitionerProfileWizard___StyledPrText3",
  componentId: "sc-1dilou-2"
})(["", ""], p => p.$_css3);
var _StyledCpError = _styled(CpError).withConfig({
  displayName: "CpAccountPractitionerProfileWizard___StyledCpError",
  componentId: "sc-1dilou-3"
})(["", ""], p => p.$_css4);
var _StyledView = _styled(View).withConfig({
  displayName: "CpAccountPractitionerProfileWizard___StyledView",
  componentId: "sc-1dilou-4"
})(["", ""], p => p.$_css5);
var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpAccountPractitionerProfileWizard___StyledPrText4",
  componentId: "sc-1dilou-5"
})(["", ""], p => p.$_css6);