import _styled19 from "styled-components";
import _styled18 from "styled-components";
import _styled17 from "styled-components";
import _styled16 from "styled-components";
import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ScrollView, View } from "react-native";
import CpError from "components/common/CpError";
import CpPackageSessionReviewSelect from "components/patientSession/CpPackageSessionReviewSelect";
import CpSessionProgress from "components/patientSession/CpSessionProgress";
import { ModalSteps, PatientSessionActions } from "components/patientSession/PatientSession";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import useAnalytics from "hooks/useAnalytics";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import QlPackageSessionData from "query/QlPackageSessionData";
import { GoogleAnalyticsDimension } from "services/GoogleAnalytics";
import { useBackendSupportedLocale } from "services/Intl";
import { getPackageInputGroups } from "services/PatientSession";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  marginRightDouble: css`
    margin-right: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  resourceCountLabel: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
  `,
  resourceCountValue: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    font-weight: bold;
  `
};
/**
 * Renders the list of 'packagesResources' for approval by the user to send to a patient.
 */
const CpPackageSessionReview = ({
  onClose,
  style
}) => {
  const {
    trackGoogleEvent
  } = useAnalytics();
  const navigation = useNavigation();
  const {
    organization,
    user
  } = useImdSession();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const locale = useBackendSupportedLocale();
  const {
    formatMessage
  } = useIntl();
  const {
    dispatchPatientSession,
    patientSessionData: {
      packageResources
    }
  } = usePatientSessionContext();
  const resourceCount = useMemo(() => packageResources.reduce((acc, resource) => resource.selected ? acc + 1 : acc, 0), [packageResources]);
  const [reviewError, setReviewError] = useState();
  useEffect(() => {
    if (reviewError && packageResources.some(resource => resource.selected)) {
      // Reset error if we select something
      setReviewError(null);
    }
  }, [reviewError, packageResources]);
  const queryVariables = useMemo(() => {
    if (!organization?._id) {
      return null;
    }
    // Map through and collect the ids for any common topics that may have been selected
    const {
      commonTopicsInput,
      topicNodesInput
    } = getPackageInputGroups(packageResources, organization._id);
    return {
      commonTopicsInput,
      locale,
      topicNodesInput
    };
  }, [locale, organization?._id, packageResources]);
  const handleClearAndReturn = useCallback(() => {
    // RESET_SESSION will close the modal for us
    dispatchPatientSession({
      type: PatientSessionActions.ResetPackage
    });
    trackGoogleEvent({
      action: "Cleared",
      name: "packageSession",
      options: {
        dimensions: {
          [GoogleAnalyticsDimension.organizationId]: organization?._id,
          [GoogleAnalyticsDimension.practitionerType]: user?.practitionerProfile?.type || undefined
        }
      }
    });
    navigation.navigate(Routes.home);
  }, [dispatchPatientSession, navigation, organization?._id, trackGoogleEvent, user]);
  const handleConfirmSelection = useCallback(() => {
    if (packageResources.every(resource => !resource.selected)) {
      // Nothing is selected, so we can't let the user proceed
      setReviewError(translations.errors.nothingSelected);
    } else {
      dispatchPatientSession({
        payload: {
          modalStep: ModalSteps.PackageSend
        },
        type: PatientSessionActions.SetModal
      });
    }
  }, [dispatchPatientSession, packageResources]);
  const handleBack = useCallback(() => {
    dispatchPatientSession({
      payload: {
        modalStep: ModalSteps.PackageStart
      },
      type: PatientSessionActions.SetModal
    });
  }, [dispatchPatientSession]);
  if (!queryVariables) {
    // Guard - Ensure we have query variable to load anything
    return null;
  }
  if (!queryVariables) {
    // Guard - Ensure we have query variable to load anything
    return null;
  }
  return <QlPackageSessionData variables={queryVariables}>
      {response => {
      if (response.packageSessionData) {
        return <_StyledScrollView style={style} $_css={styles.sessionStyles.scrollView}>
              <CpSessionProgress accessibilityLabel={formatMessage(translations.accessibility.label.progressPatientSession)} accessibilityValue={{
            text: formatMessage(translations.labels.review)
          }} completedSteps={0} totalSteps={1} />
              <_StyledPrText accessibilityRole="header" $_css2={[styles.modalHeaderText, styles.paddingHorizontalSingle]}>
                <FormattedMessage id="AoWitO" defaultMessage="Review Package" />
              </_StyledPrText>
              <_StyledView $_css3={styles.center}>
                <_StyledPrText2 children={formatMessage(translations.entities.resource, {
              count: 2
            })} $_css4={localStyles.resourceCountLabel} />
                <_StyledPrText3 children={resourceCount} $_css5={localStyles.resourceCountValue} />
              </_StyledView>

              <_StyledView2 $_css6={styles.paddingHorizontalSingle}>
                <_StyledPrText4 $_css7={styles.paddingVerticalDouble}>
                  <FormattedMessage id="sj9mJo" defaultMessage="Confirm your selection of resources prior to sending them to your patient(s)." />
                </_StyledPrText4>
                <CpPackageSessionReviewSelect data={response.packageSessionData} />

                <_StyledCpError error={reviewError} $_css8={styles.marginTopDouble} />

                {/* Buttons */}
                <_StyledView3 $_css9={[styles.sessionStyles.buttonContainer, !isNarrowScreen ? styles.sessionStyles.buttonContainerLarge : undefined]}>
                  <_StyledView4 $_css10={isNarrowScreen ? styles.fullWidth : [styles.rowAndCenter, localStyles.marginRightDouble]}>
                    <_StyledPrButton onPress={handleConfirmSelection} testID="CpPackageSessionReview-confirm" type="raised" $_css11={[styles.sessionStyles.button, isNarrowScreen ? styles.fullWidth : localStyles.marginRightDouble]}>
                      <_StyledPrText5 children={translations.buttons.next} $_css12={styles.sessionStyles.buttonText} />
                    </_StyledPrButton>
                    <_StyledPrButton2 onPress={onClose} testID="CpPackageSessionReview-continue" type="outline" $_css13={[styles.sessionStyles.button, isNarrowScreen ? styles.fullWidth : undefined]}>
                      <_StyledPrText6 children={translations.buttons.continueSession} $_css14={styles.sessionStyles.buttonText} />
                    </_StyledPrButton2>
                  </_StyledView4>
                  <_StyledPrButton3 color="destructive" onPress={handleClearAndReturn} testID="CpPackageSessionReview-end" type="text" $_css15={[styles.sessionStyles.button, isNarrowScreen ? styles.fullWidth : undefined]}>
                    <_StyledPrText7 children={translations.buttons.clearAndReturnHome} $_css16={styles.sessionStyles.buttonText} />
                  </_StyledPrButton3>
                </_StyledView3>

                <_StyledView5 $_css17={isNarrowScreen ? styles.fullWidth : styles.flexEnd}>
                  <_StyledPrButton4 onPress={handleBack} testID="CpPackageSessionSend-back" type="outline" $_css18={styles.marginTopDouble}>
                    <_StyledPrText8 children={translations.buttons.back} $_css19={styles.sessionStyles.buttonText} />
                  </_StyledPrButton4>
                </_StyledView5>
              </_StyledView2>
            </_StyledScrollView>;
      } else {
        return null;
      }
    }}
    </QlPackageSessionData>;
};
export default CpPackageSessionReview;
var _StyledScrollView = _styled(ScrollView).withConfig({
  displayName: "CpPackageSessionReview___StyledScrollView",
  componentId: "sc-n19r5o-0"
})(["", ""], p => p.$_css);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpPackageSessionReview___StyledPrText",
  componentId: "sc-n19r5o-1"
})(["", ""], p => p.$_css2);
var _StyledView = _styled(View).withConfig({
  displayName: "CpPackageSessionReview___StyledView",
  componentId: "sc-n19r5o-2"
})(["", ""], p => p.$_css3);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionReview___StyledPrText2",
  componentId: "sc-n19r5o-3"
})(["", ""], p => p.$_css4);
var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionReview___StyledPrText3",
  componentId: "sc-n19r5o-4"
})(["", ""], p => p.$_css5);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CpPackageSessionReview___StyledView2",
  componentId: "sc-n19r5o-5"
})(["", ""], p => p.$_css6);
var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionReview___StyledPrText4",
  componentId: "sc-n19r5o-6"
})(["", ""], p => p.$_css7);
var _StyledCpError = _styled(CpError).withConfig({
  displayName: "CpPackageSessionReview___StyledCpError",
  componentId: "sc-n19r5o-7"
})(["", ""], p => p.$_css8);
var _StyledView3 = _styled(View).withConfig({
  displayName: "CpPackageSessionReview___StyledView3",
  componentId: "sc-n19r5o-8"
})(["", ""], p => p.$_css9);
var _StyledView4 = _styled(View).withConfig({
  displayName: "CpPackageSessionReview___StyledView4",
  componentId: "sc-n19r5o-9"
})(["", ""], p => p.$_css10);
var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpPackageSessionReview___StyledPrButton",
  componentId: "sc-n19r5o-10"
})(["", ""], p => p.$_css11);
var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionReview___StyledPrText5",
  componentId: "sc-n19r5o-11"
})(["", ""], p => p.$_css12);
var _StyledPrButton2 = _styled(PrButton).withConfig({
  displayName: "CpPackageSessionReview___StyledPrButton2",
  componentId: "sc-n19r5o-12"
})(["", ""], p => p.$_css13);
var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionReview___StyledPrText6",
  componentId: "sc-n19r5o-13"
})(["", ""], p => p.$_css14);
var _StyledPrButton3 = _styled(PrButton).withConfig({
  displayName: "CpPackageSessionReview___StyledPrButton3",
  componentId: "sc-n19r5o-14"
})(["", ""], p => p.$_css15);
var _StyledPrText7 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionReview___StyledPrText7",
  componentId: "sc-n19r5o-15"
})(["", ""], p => p.$_css16);
var _StyledView5 = _styled(View).withConfig({
  displayName: "CpPackageSessionReview___StyledView5",
  componentId: "sc-n19r5o-16"
})(["", ""], p => p.$_css17);
var _StyledPrButton4 = _styled(PrButton).withConfig({
  displayName: "CpPackageSessionReview___StyledPrButton4",
  componentId: "sc-n19r5o-17"
})(["", ""], p => p.$_css18);
var _StyledPrText8 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionReview___StyledPrText8",
  componentId: "sc-n19r5o-18"
})(["", ""], p => p.$_css19);