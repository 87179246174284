import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React from "react";
import { View } from "react-native";
import PrText from "components/primitives/PrText";
import { styles } from "services/Theme";
import translations from "translations";
/**
 * Renders a form field label and value in readonly format.
 */
const CpReadonlyInput = ({
  fallbackValue,
  label,
  style,
  value
}) => {
  // Defaults to a fallback value is fallback value exists and value do not, otherwise defaults to 'Unknown'
  const fallback = fallbackValue ? fallbackValue : translations.labels.unknown;
  const fieldValue = value ? value : fallback;
  return <_StyledView style={style} $_css={styles.paddingVerticalSingle}>
      <_StyledPrText $_css2={styles.formStyles.readonlyLabel}>{label}</_StyledPrText>
      <_StyledPrText2 $_css3={styles.formStyles.readonlyText}>{fieldValue}</_StyledPrText2>
    </_StyledView>;
};
export default CpReadonlyInput;
var _StyledView = _styled(View).withConfig({
  displayName: "CpReadonlyInput___StyledView",
  componentId: "sc-rjvadp-0"
})(["", ""], p => p.$_css);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpReadonlyInput___StyledPrText",
  componentId: "sc-rjvadp-1"
})(["", ""], p => p.$_css2);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpReadonlyInput___StyledPrText2",
  componentId: "sc-rjvadp-2"
})(["", ""], p => p.$_css3);