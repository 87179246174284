import _styled from "styled-components";
var _CpTopicTableOfContents_rootResourceNode;
import { useNavigation } from "@react-navigation/native";
import React, { useCallback } from "react";
import { ScrollView } from "react-native";
import { graphql, useFragment } from "react-relay/hooks";
import CpTopicTableOfContentsItem from "components/topic/CpTopicTableOfContentsItem";
import useDescendantsAsTree, { isAncestorRevisionId } from "hooks/useDescendantsAsTree";
import useTopicContext from "hooks/useTopicContext";
import Routes from "navigators/Routes";
import { css } from "services/Theme";
const localStyles = {
  container: css`
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    border-color: ${({
    theme
  }) => theme.brandColors.nodeNavigatorBase};
    border-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
  `
};
const rootResourceNodeFragment = _CpTopicTableOfContents_rootResourceNode !== void 0 ? _CpTopicTableOfContents_rootResourceNode : _CpTopicTableOfContents_rootResourceNode = require("./__generated__/CpTopicTableOfContents_rootResourceNode.graphql");
/**
 * Renders the first level of children in a topic as a table of contents given a ResourceNode.
 */
const CpTopicTableOfContents = ({
  onSelect,
  rootResourceNodeKey,
  style
}) => {
  const navigation = useNavigation();
  const rootResourceNodeData = useFragment(rootResourceNodeFragment, rootResourceNodeKey);
  const defaultRevisionId = rootResourceNodeData.defaultResourceNode?.revisionId;
  let {
    selectedRevisionId
  } = useTopicContext();
  selectedRevisionId = selectedRevisionId || defaultRevisionId;
  const rootNodeData = useDescendantsAsTree(rootResourceNodeData);
  const {
    children
  } = rootNodeData;
  const filteredChildren = children.filter(child => {
    const hasTaggableType = ["ResourceNode::Taggable::Resource", "ResourceNode::Taggable"].includes(child.type);
    const hasTaggedResource = child.type === "ResourceNode::Taggable::Resource" && child.hasTaggedResources;
    const hasTaggableChild = child.type === "ResourceNode::Taggable" && child.children.find(node => node.type !== "ResourceNode::Taggable::Resource" || node.hasTaggedResources);
    return !hasTaggableType || hasTaggedResource || hasTaggableChild;
  });
  const handlePress = useCallback(node => {
    navigation.navigate(Routes.topic,
    // Is it a related topic node?
    node.resourceNodeRevisionId ? {
      resourceId: undefined,
      rootRevisionId: node.resourceNodeRevisionId,
      selectedRevisionId: undefined
    } : {
      resourceId: node.resourceId ?? undefined,
      rootRevisionId: rootResourceNodeData.revisionId,
      selectedRevisionId: node.revisionId
    });
    onSelect && onSelect(node);
  }, [rootResourceNodeData.revisionId, navigation, onSelect]);
  return <_StyledScrollView style={style} testID="CpTopicTableOfContentsContainer" $_css={localStyles.container}>
      {filteredChildren.map((child, index) => {
      const hasTaggableResourceType = child.type === "ResourceNode::Taggable::Resource";
      const hasTaggedResources = child.hasTaggedResources;
      return child && (!hasTaggableResourceType || hasTaggableResourceType && hasTaggedResources) ? <CpTopicTableOfContentsItem isSelected={selectedRevisionId ? child.revisionId === selectedRevisionId || isAncestorRevisionId(selectedRevisionId, child) : index === 0} key={child.revisionId} node={child} onPress={handlePress} /> : null;
    })}
    </_StyledScrollView>;
};
export default CpTopicTableOfContents;
var _StyledScrollView = _styled(ScrollView).withConfig({
  displayName: "CpTopicTableOfContents___StyledScrollView",
  componentId: "sc-1o52yb2-0"
})(["", ""], p => p.$_css);