import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useMemo } from "react";
import { Modal, Pressable, View } from "react-native";
import { css } from "services/Theme";
const localStyles = {
  background: css`
    background-color: ${({
    theme
  }) => theme.brandColors.screenOverlay};
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  container: css`
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    border-color: ${({
    theme
  }) => theme.brandColors.separator};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border-style: solid;
    border-width: 1px;
    margin: ${({
    theme
  }) => theme.spacing.single}px;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  explicitDismiss: css`
    cursor: default;
  `,
  fullScreenContainer: css`
    border-radius: 0px;
    height: 100vh;
    margin: 0px;
    padding: 0px;
    width: 100vw;
  `
};
/**
 * Renders a modal with a background overlay
 * Passing the fullScreen property will allow you to create a fullscreen modal, like the one we have with image resources
 */
const CpModal = ({
  children,
  dismissAnywhere = false,
  fullScreen = false,
  onBackgroundPress,
  style,
  ...otherProps
}) => {
  const content = useMemo(() => {
    const contentStyle = [...localStyles.container, fullScreen && localStyles.fullScreenContainer];
    return dismissAnywhere ? <_StyledView style={style} $_css={contentStyle}>
        {children}
      </_StyledView> :
    // By changing the inner view to a pressable component, we can swallow the event handler to prevent clicks from bubbling up to dismiss the modal
    <_StyledPressable onPress={event => event.stopPropagation()} style={style} $_css2={[contentStyle, localStyles.explicitDismiss]}>
        {children}
      </_StyledPressable>;
  }, [children, dismissAnywhere, fullScreen, style]);
  return <Modal onRequestClose={onBackgroundPress} transparent={true} {...otherProps}>
      <_StyledPressable2 onPress={onBackgroundPress} testID="CpModal-backgroundPress" $_css3={localStyles.background}>
        {content}
      </_StyledPressable2>
    </Modal>;
};
export default CpModal;
var _StyledView = _styled(View).withConfig({
  displayName: "CpModal___StyledView",
  componentId: "sc-72mutl-0"
})(["", ""], p => p.$_css);
var _StyledPressable = _styled(Pressable).withConfig({
  displayName: "CpModal___StyledPressable",
  componentId: "sc-72mutl-1"
})(["", ""], p => p.$_css2);
var _StyledPressable2 = _styled(Pressable).withConfig({
  displayName: "CpModal___StyledPressable2",
  componentId: "sc-72mutl-2"
})(["", ""], p => p.$_css3);