import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useState } from "react";
import { View } from "react-native";
import { useFragment } from "react-relay";
import CpVideoCard from "components/common/CpVideoCard";
import { resourceFragment } from "components/topic/CpResourceCommon";
import useAppTheme from "hooks/useAppTheme";
import { getNameVariant } from "services/Graphql";
import { css } from "services/Theme";
const localStyles = {
  container: css`
    background-color: ${({
    theme
  }) => theme.brandColors.background};
  `
};
/**
 * Renders a video from a resource within a card
 */
const CpVideoResource = ({
  resource,
  style
}) => {
  const {
    spacing
  } = useAppTheme();
  const data = useFragment(resourceFragment, resource);
  const embedMarkup = data?.embedMarkup;
  const aspectRatio = data?.aspectRatio;
  const [layoutWidth, setLayoutWidth] = useState(0);
  const handleLayoutWidth = useCallback(({
    nativeEvent: {
      layout
    }
  }) => {
    setLayoutWidth(layout.width);
  }, [setLayoutWidth]);
  if (!embedMarkup) return null;
  return <_StyledView onLayout={handleLayoutWidth} style={style} $_css={localStyles.container}>
      <_StyledCpVideoCard aspectRatio={aspectRatio} embedMarkup={embedMarkup} label={getNameVariant(data)} testID="CpVideoResource" $_css2={layoutWidth - spacing.double} />
    </_StyledView>;
};
export default CpVideoResource;
var _StyledView = _styled(View).withConfig({
  displayName: "CpVideoResource___StyledView",
  componentId: "sc-1cezta-0"
})(["", ""], p => p.$_css);
var _StyledCpVideoCard = _styled(CpVideoCard).withConfig({
  displayName: "CpVideoResource___StyledCpVideoCard",
  componentId: "sc-1cezta-1"
})(["width:", "px;"], p => p.$_css2);