import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
var _CtCreateAccountMutation;
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { ScrollView, View } from "react-native";
import { graphql, useMutation } from "react-relay/hooks";
import * as zod from "zod";
import CpBackgroundHeader from "components/common/CpBackgroundHeader";
import CpError from "components/common/CpError";
import CpLinkButton from "components/common/CpLinkButton";
import CpForm from "components/form/CpForm";
import CpFormNewPassword from "components/form/CpFormNewPassword";
import CpFormTextInput from "components/form/CpFormTextInput";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import useAnalytics from "hooks/useAnalytics";
import useAppRegion from "hooks/useAppRegion";
import useFormResolver from "hooks/useFormResolver";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { maxEmailLength } from "services/Constants";
import { isGraphqlError } from "services/Error";
import { useBackendSupportedLocale } from "services/Intl";
import Log from "services/Log";
import { css, rnStyles, styles } from "services/Theme";
import { emailValidation, passwordValidation } from "services/Validation";
import translations from "translations";
const userCreateMutation = _CtCreateAccountMutation !== void 0 ? _CtCreateAccountMutation : _CtCreateAccountMutation = require("./__generated__/CtCreateAccountMutation.graphql");
const localStyles = {
  buttonContainer: css`
    ${styles.rowAndCenter};
    justify-content: space-between;
    margin-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  buttonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    padding-horizontal: ${({
    theme
  }) => theme.spacing.quadruple}px;
  `,
  heading: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    padding-bottom: ${({
    theme
  }) => theme.spacing.half}px;
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  linkButton: css`
    align-self: center;
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  linkText: css`
    color: ${({
    theme
  }) => theme.textColors.link};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    font-weight: 400;
    text-align: center;
    text-decoration-color: ${({
    theme
  }) => theme.textColors.link};
  `
};
const localTranslations = defineMessages({
  userAlreadyExists: {
    id: "YaRHp9",
    defaultMessage: "An account with this email address already exists. Please sign in instead."
  }
});
/**
 * Container that manages the layout and form that allows a user to create their own account in the provider app.
 */
const CtCreateAccount = ({
  onCreate,
  referralCode
}) => {
  const {
    currentRegionTranslation
  } = useAppRegion();
  const {
    formatMessage
  } = useIntl();
  const navigation = useNavigation();
  const locale = useBackendSupportedLocale();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    trackGoogleEvent
  } = useAnalytics();

  // Form and Mutation Handling
  const [submitUser, isSubmittingUser] = useMutation(userCreateMutation);
  const form = useForm({
    mode: "onBlur",
    resolver: useFormResolver(useMemo(() => zod.object({
      email: zod.string().refine(...emailValidation()),
      password: zod.string().refine(...passwordValidation({
        minLength: 8,
        requireNumbers: true,
        requireSymbols: true
      }))
    }), []))
  });
  const [formError, setFormError] = useState();
  const handleSubmit = useMemo(() => form.handleSubmit(values => {
    const {
      email,
      password
    } = values;
    Log.info("CtCreateAccount - Submitting form to create account for: ", {
      email
    });
    submitUser({
      onCompleted: ({
        userCreate
      }) => {
        const unconfirmedEmail = userCreate?.unconfirmedEmail;
        if (!unconfirmedEmail) {
          // How could this successfully complete without returning an email?
          Log.error(`CtCreateAccount - New account did not return an unconfirmed email. Submitted email: ${email}`);
          setFormError(translations.errors.default);
        } else {
          Log.info(`CtCreateAccount - Successfully created account for "${unconfirmedEmail}${referralCode && ` using referral code: "${referralCode}"`}"`);
          onCreate?.(unconfirmedEmail, password);
          trackGoogleEvent({
            action: "Signup request completed",
            name: "signup"
          });
        }
      },
      onError: error => {
        if (isGraphqlError(error)) {
          // Graphql will log its own error if it gets to this point
          const result = error.result?.[0];
          const isInvalid = result?.extensions?.code === "invalid";
          if (isInvalid) {
            Log.error("CtCreateAccount - Email already exists", error);
            setFormError(formatMessage(localTranslations.userAlreadyExists));
            return;
          }
          setFormError(error);
        } else {
          Log.error("CtCreateAccount - Error occurred", error);
          setFormError(error);
        }
      },
      variables: {
        input: {
          password,
          preferredLocale: locale,
          referralCode,
          unconfirmedEmail: email
        }
      }
    });
  }), [form, formatMessage, locale, onCreate, referralCode, submitUser, trackGoogleEvent]);
  const handleCancel = useCallback(() => {
    navigation.canGoBack() ? navigation.goBack() : navigation.navigate(Routes.home);
  }, [navigation]);
  const passwordFieldRef = useRef(null);
  return <ScrollView contentContainerStyle={rnStyles.flexSingle}>
      <CpBackgroundHeader childStyle={styles.flexSingle} header={<FormattedMessage id="fFAQv7" defaultMessage="Create Account" />}>
        <_StyledView $_css={[styles.paddingDouble, !isNarrowScreen ? styles.limitedLargeScreenWidth : undefined]}>
          <_StyledPrText $_css2={localStyles.heading}>
            <FormattedMessage id="WZ98AA" defaultMessage="Are you a clinician in {region} who is ready to deliver best-in-class patient experiences?" values={{
            region: <_StyledPrText2 children={currentRegionTranslation} $_css3={styles.boldText} />
          }} />
          </_StyledPrText>
          <_StyledCpLinkButton route={Routes.change_region} $_css4={localStyles.linkButton}>
            <_StyledPrText3 $_css5={localStyles.linkText}>
              <FormattedMessage id="WBifbJ" defaultMessage="Change your Country/Region" />
            </_StyledPrText3>
          </_StyledCpLinkButton>

          {/* Create Account Form */}
          <CpForm onSubmit={handleSubmit} {...form}>
            <CpFormTextInput accessibilityLabel={formatMessage(translations.attributes.user.email)} autoCapitalize="none" autoComplete="email" autoCorrect={false} keyboardType="email-address" label={translations.attributes.user.email} maxLength={maxEmailLength} name="email" onSubmitEditing={passwordFieldRef.current?.focus} placeholder={translations.labels.placeholderSigninEmail} returnKeyType="next" textContentType="emailAddress" />
            <CpFormNewPassword accessibilityLabel={formatMessage(translations.attributes.user.password)} forwardRef={passwordFieldRef} label={translations.attributes.user.password} name="password" onSubmitEditing={handleSubmit} placeholder={translations.attributes.user.newPassword} />
            <_StyledCpError error={formError} $_css6={[styles.formField, styles.errorBox]} />
            <_StyledView2 $_css7={!isNarrowScreen ? localStyles.buttonContainer : styles.marginVerticalDouble}>
              <_StyledPrButton busy={isSubmittingUser} disabled={isSubmittingUser} onPress={handleSubmit} testID="CtCreateAccount-create" $_css8={styles.formField}>
                <_StyledPrText4 children={translations.buttons.createAccount} $_css9={localStyles.buttonText} />
              </_StyledPrButton>
              <_StyledPrButton2 onPress={handleCancel} type="text" $_css10={styles.formField}>
                <_StyledPrText5 children={translations.buttons.cancel} $_css11={localStyles.buttonText} />
              </_StyledPrButton2>
            </_StyledView2>
          </CpForm>
        </_StyledView>
      </CpBackgroundHeader>
    </ScrollView>;
};
export default CtCreateAccount;
var _StyledView = _styled(View).withConfig({
  displayName: "CtCreateAccount___StyledView",
  componentId: "sc-8keesq-0"
})(["", ""], p => p.$_css);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CtCreateAccount___StyledPrText",
  componentId: "sc-8keesq-1"
})(["", ""], p => p.$_css2);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CtCreateAccount___StyledPrText2",
  componentId: "sc-8keesq-2"
})(["", ""], p => p.$_css3);
var _StyledCpLinkButton = _styled(CpLinkButton).withConfig({
  displayName: "CtCreateAccount___StyledCpLinkButton",
  componentId: "sc-8keesq-3"
})(["", ""], p => p.$_css4);
var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CtCreateAccount___StyledPrText3",
  componentId: "sc-8keesq-4"
})(["", ""], p => p.$_css5);
var _StyledCpError = _styled(CpError).withConfig({
  displayName: "CtCreateAccount___StyledCpError",
  componentId: "sc-8keesq-5"
})(["", ""], p => p.$_css6);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CtCreateAccount___StyledView2",
  componentId: "sc-8keesq-6"
})(["", ""], p => p.$_css7);
var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CtCreateAccount___StyledPrButton",
  componentId: "sc-8keesq-7"
})(["", ""], p => p.$_css8);
var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CtCreateAccount___StyledPrText4",
  componentId: "sc-8keesq-8"
})(["", ""], p => p.$_css9);
var _StyledPrButton2 = _styled(PrButton).withConfig({
  displayName: "CtCreateAccount___StyledPrButton2",
  componentId: "sc-8keesq-9"
})(["", ""], p => p.$_css10);
var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CtCreateAccount___StyledPrText5",
  componentId: "sc-8keesq-10"
})(["", ""], p => p.$_css11);