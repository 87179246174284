import _styled from "styled-components";
import React, { Suspense } from "react";
import CtErrorBoundary from "components/containers/CtErrorBoundary";
import PrActivityIndicator from "components/primitives/PrActivityIndicator";
import { styles } from "services/Theme";

/**
 * Catches errors and suspense from its children, typically components that make API calls.
 */
const CtApiSuspense = ({
  children,
  ...props
}) => {
  return <Suspense fallback={<PrActivityIndicator {...props} />}>
      <_StyledCtErrorBoundary $_css={styles.paddingDouble}>{children}</_StyledCtErrorBoundary>
    </Suspense>;
};
export default CtApiSuspense;
var _StyledCtErrorBoundary = _styled(CtErrorBoundary).withConfig({
  displayName: "CtApiSuspense___StyledCtErrorBoundary",
  componentId: "sc-11vgugc-0"
})(["", ""], p => p.$_css);