import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
var _CpAccountPersonalProfileWizardMutation;
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { View } from "react-native";
import { graphql, useMutation } from "react-relay";
import * as zod from "zod";
import CpPersonalProfileForm from "components/account/CpPersonalProfileForm";
import CpError from "components/common/CpError";
import CpForm from "components/form/CpForm";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import useFormResolver from "hooks/useFormResolver";
import useScreenLayout from "hooks/useScreenLayout";
import Log from "services/Log";
import { css, styles } from "services/Theme";
import translations from "translations";
const sendPersonalProfileMutation = _CpAccountPersonalProfileWizardMutation !== void 0 ? _CpAccountPersonalProfileWizardMutation : _CpAccountPersonalProfileWizardMutation = require("./__generated__/CpAccountPersonalProfileWizardMutation.graphql");
const localStyles = {
  buttonContainer: css`
    margin-bottom: ${({
    theme
  }) => theme.spacing.single}px;
    margin-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  buttonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    font-weight: normal;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  desktopButtonContainer: css`
    flex-direction: row;
    justify-content: space-between;
  `,
  profileText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
  `,
  profileTextContainer: css`
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
    padding-vertical: ${({
    theme
  }) => theme.spacing.double}px;
  `
};
/**
 * The Personal Profile Wizard (Step 1)
 */
const CpAccountPersonalProfileWizard = ({
  onSubmit
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const [submitPersonalProfile, isSubmittingPersonalProfile] = useMutation(sendPersonalProfileMutation);
  const [formError, setFormError] = useState();
  const form = useForm({
    mode: "onBlur",
    resolver: useFormResolver(useMemo(() => zod.object({
      firstName: zod.string().min(1),
      lastName: zod.string().min(1),
      otherPrefix: zod.string().optional(),
      preferredLocale: zod.string().min(1),
      prefix: zod.string()
    }), []))
  });
  const handleSubmit = useMemo(() => form.handleSubmit(values => {
    const {
      otherPrefix,
      ...input
    } = values;
    input.prefix = values.prefix === formatMessage(translations.prefixes.other) ? otherPrefix : values.prefix;
    const submitProfile = async () => {
      submitPersonalProfile({
        onCompleted: ({
          personalProfileCreate
        }) => {
          Log.info(`CpAccountPersonalProfileWizard - profile created for ${personalProfileCreate?.user.name}`);
          onSubmit();
        },
        onError: err => {
          setFormError(err);
          Log.error(`CpAccountPersonalProfileWizard - ${err}`);
        },
        variables: {
          input
        }
      });
    };
    submitProfile();
  }), [form, formatMessage, submitPersonalProfile, onSubmit]);
  return <View>
      <_StyledView $_css={localStyles.profileTextContainer}>
        <_StyledPrText $_css2={localStyles.profileText}>
          <FormattedMessage id="7LdfpU" defaultMessage="To complete your profile we just need to ask you some quick questions. This shouldn’t take more than a couple minutes." />
        </_StyledPrText>
      </_StyledView>
      <CpForm {...form} onSubmit={handleSubmit}>
        <CpPersonalProfileForm />
        <_StyledCpError error={formError} $_css3={[styles.formField, styles.errorBox]} />
        <_StyledView2 $_css4={[localStyles.buttonContainer, !isNarrowScreen ? localStyles.desktopButtonContainer : undefined]}>
          <PrButton busy={isSubmittingPersonalProfile} onPress={handleSubmit} testID="CpAccountPersonalProfileWizardContinue">
            <_StyledPrText2 $_css5={localStyles.buttonText}>
              <FormattedMessage id="RrwZZR" defaultMessage="Continue to next page" />
            </_StyledPrText2>
          </PrButton>
        </_StyledView2>
      </CpForm>
    </View>;
};
export default CpAccountPersonalProfileWizard;
var _StyledView = _styled(View).withConfig({
  displayName: "CpAccountPersonalProfileWizard___StyledView",
  componentId: "sc-1yn3w88-0"
})(["", ""], p => p.$_css);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpAccountPersonalProfileWizard___StyledPrText",
  componentId: "sc-1yn3w88-1"
})(["", ""], p => p.$_css2);
var _StyledCpError = _styled(CpError).withConfig({
  displayName: "CpAccountPersonalProfileWizard___StyledCpError",
  componentId: "sc-1yn3w88-2"
})(["", ""], p => p.$_css3);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CpAccountPersonalProfileWizard___StyledView2",
  componentId: "sc-1yn3w88-3"
})(["", ""], p => p.$_css4);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpAccountPersonalProfileWizard___StyledPrText2",
  componentId: "sc-1yn3w88-4"
})(["", ""], p => p.$_css5);