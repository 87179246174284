import queryString from "query-string";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { v4 as uuidv4 } from "uuid";
import { minutesToMilliseconds } from "date-fns";
import useAppBackend from "hooks/useAppBackend";
import useImdSession from "hooks/useImdSession";
import { useIntl } from "react-intl";
import { AnalyticsEventName, trackEvent } from "services/Analytics";
import Config from "services/Config";
import { GoogleAnalyticsDimension, trackGoogleAnalyticsEvent } from "services/GoogleAnalytics";
import { getBuildNumber, getCodepushBundle, getVersion } from "services/Version";
export const initialAnalyticsContextData = {};
export const AnalyticsContext = createContext({
  ...initialAnalyticsContextData,
  trackEvent: () => new Promise(resolve => resolve()),
  trackGoogleEvent: () => {
    return;
  }
});
const IDLE_TIMEOUT = minutesToMilliseconds(30);

/**
 * Provides AnalyticsContext for the app and tracks user idle time to manage the analytics session
 */
const CtAnalytics = ({
  children
}) => {
  // Get backend for current region
  const {
    setSessionHeader
  } = useAppBackend();
  const [analyticsContext, setAnalyticsContext] = useState({
    ...initialAnalyticsContextData,
    trackEvent: () => new Promise(resolve => resolve()),
    trackGoogleEvent: () => {
      return;
    }
  });
  const {
    locale
  } = useIntl();
  const {
    licensee,
    organization,
    user
  } = useImdSession();
  const analyticsOrg = organization ?? licensee;
  const localTrackEvent = useCallback(async event => {
    let sessionId = analyticsContext.sessionId ?? "needs initialization";
    if (sessionId === "needs initialization") {
      sessionId = uuidv4();
      setSessionHeader("analyticsSessionId", sessionId);
      setAnalyticsContext(previous => ({
        ...previous,
        sessionId
      }));
    }

    // Filter the query params down to only the supported utm params
    const queryParams = queryString.parse(location.search);
    const utmParams = Object.keys(queryParams).reduce((acc, key) => {
      if (["utm_campaign", "utm_content", "utm_medium", "utm_source", "utm_term"].includes(key)) {
        const value = queryParams[key];
        if (value) {
          acc[key] = typeof value === "string" ? value : value.toString();
        }
      }
      return acc;
    }, {});
    trackEvent({
      ...event,
      metadata: {
        agentLocale: navigator.language,
        appBuild: getBuildNumber(),
        appCodepushBundle: getCodepushBundle(),
        appLocale: locale,
        appName: Config.APP_NAME,
        appVersion: getVersion(),
        hostname: document.location.hostname,
        organizationCountry: analyticsOrg?.countryCode,
        organizationId: analyticsOrg?._id,
        organizationSubdivision: analyticsOrg?.subdivisionCode ?? undefined,
        // pageTitle: document.title,
        pathname: document.location.pathname,
        referrer: document.referrer,
        screenHeight: window.screen.height,
        screenWidth: window.screen.width,
        url: document.location.href,
        userAgent: navigator.userAgent,
        ...utmParams
      },
      sessionId
    });
  }, [analyticsContext.sessionId, locale, analyticsOrg?.countryCode, analyticsOrg?._id, analyticsOrg?.subdivisionCode, setSessionHeader]);
  const trackGoogleEvent = useCallback(event => {
    if (event.options?.dimensions) {
      // injects sessionId and if is professional on every event that has dimensions
      event.options.dimensions = {
        ...event.options.dimensions,
        [GoogleAnalyticsDimension.isProfessional]: user?.practitionerProfile ? "professional" : "not_professional",
        [GoogleAnalyticsDimension.kioskId]: user?.kioskId || undefined,
        [GoogleAnalyticsDimension.licenseeId]: licensee?._id,
        [GoogleAnalyticsDimension.sessionId]: analyticsContext.sessionId ?? ""
      };
    }
    const {
      action,
      name,
      options
    } = event;
    trackGoogleAnalyticsEvent(name, action, options);
  }, [analyticsContext.sessionId, user?.practitionerProfile, user?.kioskId, licensee?._id]);
  const handleIdle = useCallback(async () => {
    await localTrackEvent({
      data: {},
      eventType: AnalyticsEventName.session_end
    });
    setSessionHeader("analyticsSessionId", undefined);
    setAnalyticsContext(previous => ({
      ...previous,
      sessionId: undefined
    }));
  }, [localTrackEvent, setSessionHeader]);
  useIdleTimer({
    crossTab: false,
    onIdle: handleIdle,
    timeout: IDLE_TIMEOUT
  });
  useEffect(() => {
    const trackExternalNav = event => {
      if (event?.target?.nodeName === "A") {
        const anchor = event.target;
        localTrackEvent({
          data: {
            targetUrl: anchor.href
          },
          eventType: AnalyticsEventName.nav_external
        });
      }
    };
    document.addEventListener("click", trackExternalNav);
    return () => document.removeEventListener("click", trackExternalNav);
  }, [localTrackEvent]);
  return <AnalyticsContext.Provider value={{
    ...analyticsContext,
    trackEvent: localTrackEvent,
    trackGoogleEvent
  }}>
      {children}
    </AnalyticsContext.Provider>;
};
export default CtAnalytics;