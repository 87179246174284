import _styled from "styled-components";
import React, { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { View } from "react-native";
import CpDarkMode from "components/common/CpDarkMode";
import CpHeaderButton from "components/common/CpHeaderButton";
import CpPopup from "components/common/CpPopup";
import { ActionButtonUserType } from "components/primitives/PrActionButton/PrActionButton";
import { IconName } from "components/primitives/PrIcon";
import useImdSession from "hooks/useImdSession";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  darkModeContainer: css`
    padding-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `
};

/**
 * Dark Mode switch for our dark mode
 */
const DarkModeButton = ({
  type = ActionButtonUserType.AccountMenu
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    appConfig
  } = useImdSession();

  // Theme menu state and callbacks
  const [showThemePopup, setShowThemePopup] = useState(false);
  const handleHideThemePopup = useCallback(() => setShowThemePopup(false), [setShowThemePopup]);
  const handleShowThemePopup = useCallback(() => setShowThemePopup(true), [setShowThemePopup]);
  const darkColorModePresent = useMemo(() => {
    return appConfig?.appColors?.some(color => color.colorScheme === "dark") || !appConfig?.appColors;
  }, [appConfig?.appColors]);

  // Dark Mode Popup Layout
  const [modePopupLayout, setModePopupLayout] = useState({
    width: 0,
    x: 0,
    y: 0
  });
  const handleModeLayout = useCallback(({
    nativeEvent: {
      layout
    }
  }) => {
    setModePopupLayout({
      width: 200,
      x: layout.left + layout.width - 200,
      y: layout.top + layout.height
    });
  }, []);
  if (!darkColorModePresent) return null;
  return <>
      {/* Theme picker */}
      {type === ActionButtonUserType.NavHeader ? <>
          <CpHeaderButton accessibilityHint={formatMessage(translations.accessibility.hint.navHeaderColorModeButton)} icon={IconName.moon} label={translations.labels.colorModeButton} onLayout={handleModeLayout} onPress={handleShowThemePopup} testID="CpDarkModeButton-header" type={type} />

          <CpPopup layout={modePopupLayout} onBackgroundPress={handleHideThemePopup} popupStyle={[styles.popupBorder, styles.navHeaderStyles.darkModePopup]} visible={showThemePopup}>
            <CpDarkMode />
          </CpPopup>
        </> : <_StyledView $_css={localStyles.darkModeContainer}>
          <CpDarkMode />
        </_StyledView>}
    </>;
};
export default DarkModeButton;
var _StyledView = _styled(View).withConfig({
  displayName: "DarkModeButton___StyledView",
  componentId: "sc-1wd65nd-0"
})(["", ""], p => p.$_css);