import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ScrollView, View } from "react-native";
import { ModalSteps, PatientSessionActions } from "components/patientSession/PatientSession";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  description: css`
    padding-bottom: ${({
    theme
  }) => theme.spacing.single}px;
    padding-top: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  header: css`
    font-weight: bold;
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
  `
};
/**
 * Renders a screen providing information about PatientSessions and what a Topic is vs a Resource
 */
const CpPatientSessionInfo = ({
  style
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    dispatchPatientSession
  } = usePatientSessionContext();
  const handleBack = useCallback(() => {
    dispatchPatientSession({
      payload: {
        modalStep: ModalSteps.SessionReview
      },
      type: PatientSessionActions.SetModal
    });
  }, [dispatchPatientSession]);
  return <_StyledScrollView style={style} $_css={styles.sessionStyles.scrollView}>
      <_StyledPrText accessibilityRole="header" $_css2={[styles.modalHeaderText, styles.paddingHorizontalSingle]}>
        <FormattedMessage id="ykG4az" defaultMessage="Patient Session Information" />
      </_StyledPrText>

      <_StyledView $_css3={styles.paddingHorizontalSingle}>
        <_StyledPrText2 $_css4={styles.paddingVerticalSingle}>
          <FormattedMessage id="RfanFB" defaultMessage="Patient Sessions are quick and convenient ways to provide your patients with valuable information specific to their needs." />
        </_StyledPrText2>

        <_StyledPrText3 $_css5={styles.paddingVerticalSingle}>
          <FormattedMessage id="S974SM" defaultMessage="Sending resources is limited to either a set of Resources, or the entire Topic." />
        </_StyledPrText3>

        <_StyledPrText4 children={formatMessage(translations.entities.topic, {
        count: 2
      })} $_css6={localStyles.header} />
        <_StyledPrText5 $_css7={localStyles.description}>
          <FormattedMessage id="D3v+4K" defaultMessage="When sending a Topic as part of a Patient Session, the patient will have access to view the topic in its entirety. This means that all content normally found within that topic will be accessible." />
        </_StyledPrText5>

        <_StyledPrText6 children={formatMessage(translations.entities.resource, {
        count: 2
      })} $_css8={localStyles.header} />
        <_StyledPrText7 $_css9={localStyles.description}>
          <FormattedMessage id="4ZPZTZ" defaultMessage="When sending individual Resources as part of a Patient Session, the patient will have access restricted to the specified content. This means that they will not have access to any resources but the ones you select to share with them." />
        </_StyledPrText7>

        <_StyledPrButton onPress={handleBack} testID="CpPatientSessionInfo-back" type="raised" $_css10={styles.marginTopDouble}>
          <_StyledPrText8 children={translations.buttons.back} $_css11={styles.sessionStyles.buttonText} />
        </_StyledPrButton>
      </_StyledView>
    </_StyledScrollView>;
};
export default CpPatientSessionInfo;
var _StyledScrollView = _styled(ScrollView).withConfig({
  displayName: "CpPatientSessionInfo___StyledScrollView",
  componentId: "sc-1yys7a0-0"
})(["", ""], p => p.$_css);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpPatientSessionInfo___StyledPrText",
  componentId: "sc-1yys7a0-1"
})(["", ""], p => p.$_css2);
var _StyledView = _styled(View).withConfig({
  displayName: "CpPatientSessionInfo___StyledView",
  componentId: "sc-1yys7a0-2"
})(["", ""], p => p.$_css3);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionInfo___StyledPrText2",
  componentId: "sc-1yys7a0-3"
})(["", ""], p => p.$_css4);
var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionInfo___StyledPrText3",
  componentId: "sc-1yys7a0-4"
})(["", ""], p => p.$_css5);
var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionInfo___StyledPrText4",
  componentId: "sc-1yys7a0-5"
})(["", ""], p => p.$_css6);
var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionInfo___StyledPrText5",
  componentId: "sc-1yys7a0-6"
})(["", ""], p => p.$_css7);
var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionInfo___StyledPrText6",
  componentId: "sc-1yys7a0-7"
})(["", ""], p => p.$_css8);
var _StyledPrText7 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionInfo___StyledPrText7",
  componentId: "sc-1yys7a0-8"
})(["", ""], p => p.$_css9);
var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpPatientSessionInfo___StyledPrButton",
  componentId: "sc-1yys7a0-9"
})(["", ""], p => p.$_css10);
var _StyledPrText8 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionInfo___StyledPrText8",
  componentId: "sc-1yys7a0-10"
})(["", ""], p => p.$_css11);