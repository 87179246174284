import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback } from "react";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import { css } from "services/Theme";
const localStyles = {
  paginationItem: css`
    align-items: center;
    border-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    flex: 1;
    justify-content: center;
    max-width: ${({
    theme
  }) => theme.buttonHeights.xxlarge}px;
    min-width: ${({
    theme
  }) => theme.buttonHeights.medium}px;
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  paginationText: css`
    color: ${({
    theme
  }) => theme.buttonColors.primary};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    font-weight: bold;
  `,
  selected: css`
    background-color: ${({
    theme
  }) => theme.buttonColors.paginationSelected};
  `
};
/**
 * Renders a pagination item
 */
const CpPaginationItem = ({
  isSelected,
  onPress,
  page
}) => {
  const handlePressPage = useCallback(() => {
    onPress(page);
  }, [onPress, page]);
  return <_StyledPrPressable onPress={handlePressPage} $_css={[localStyles.paginationItem, isSelected ? localStyles.selected : undefined]}>
      <_StyledPrText $_css2={localStyles.paginationText}>{page}</_StyledPrText>
    </_StyledPrPressable>;
};
export default CpPaginationItem;
var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpPaginationItem___StyledPrPressable",
  componentId: "sc-1gv0ot9-0"
})(["", ""], p => p.$_css);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpPaginationItem___StyledPrText",
  componentId: "sc-1gv0ot9-1"
})(["", ""], p => p.$_css2);