import React, { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import CpHeaderButton from "components/common/CpHeaderButton";
import { ActionButtonUserType } from "components/primitives/PrActionButton/PrActionButton";
import { IconName } from "components/primitives/PrIcon";
import useImdSession from "hooks/useImdSession";
import useScreenLayout from "hooks/useScreenLayout";
import { styles } from "services/Theme";
import translations from "translations";
import QlShortcuts from "../../../../query/QlShortcuts";
import CpPopup from "../../../common/CpPopup";
import CpScroll from "../../../common/CpScroll";
import CpShortcuts from "../../../shortcuts/CpShortcuts";
function getPopupWidth(isNarrow, isWide) {
  if (isNarrow) {
    return 300;
  }
  if (!isWide) {
    return 600;
  }
  return 1000;
}

/**
 * Action button for shortcuts
 */
const ShortcutButton = ({
  type = ActionButtonUserType.AccountMenu
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    appConfig,
    user
  } = useImdSession();
  const {
    isNarrowScreen,
    isWideScreen,
    width: screenWidth
  } = useScreenLayout();
  const userExists = Boolean(user);
  const shortcutTool = appConfig?.appTools?.find(tool => tool.name === "Shortcuts");
  const isShortcutsDisabled = shortcutTool?.status !== "active";
  const shortcutVariables = useMemo(() => ({}), []);
  const [showShortcutPopup, setShowShortcutPopup] = useState(false);
  const handleShowShortcutPopup = useCallback(() => setShowShortcutPopup(true), [setShowShortcutPopup]);
  const handleHideShortcutPopup = useCallback(() => setShowShortcutPopup(false), [setShowShortcutPopup]);

  // Layout setup and callback
  const [popupLayout, setPopupLayout] = useState({
    width: 0,
    x: 0,
    y: 0
  });
  const handlePopupLayout = useCallback(({
    nativeEvent: {
      layout
    }
  }) => {
    const width = getPopupWidth(isNarrowScreen, isWideScreen);

    // Center the modal if viewing on a narrow screen
    const x = isNarrowScreen ? screenWidth / 2 - width / 2 : layout.left + layout.width - width;
    setPopupLayout({
      maxHeight: "50%",
      width,
      x,
      y: layout.top + layout.height
    });
  }, [isNarrowScreen, isWideScreen, screenWidth]);

  // Guard - shortcuts should only be available if:
  // - It is an authenticated non-kiosk/patient user
  // - The shortcut tool is not disabled
  if (!userExists || user?.isPatient || isShortcutsDisabled) {
    return null;
  }
  return <React.Fragment>
      <CpHeaderButton accessibilityHint={formatMessage(translations.accessibility.hint.navHeaderShortcutButton)} icon={IconName.star} label={translations.labels.myShortcuts} onLayout={handlePopupLayout} onPress={handleShowShortcutPopup} testID={type === ActionButtonUserType.AccountMenu ? "ShortcutButton-accountMenu" : "ShortcutButton-header"} type={type} />
      <CpPopup layout={popupLayout} onBackgroundPress={handleHideShortcutPopup} popupStyle={styles.popupBorder} visible={showShortcutPopup}>
        <QlShortcuts variables={shortcutVariables}>
          {response => response?.currentUser ? <CpScroll>
                <CpShortcuts userShortcutsFragment={response.currentUser} />
              </CpScroll> : null}
        </QlShortcuts>
      </CpPopup>
    </React.Fragment>;
};
export default ShortcutButton;