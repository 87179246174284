import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
var _CpTaggedResourceNodeQuery;
import { useNavigation, useRoute } from "@react-navigation/native";
import React, { useCallback, useEffect } from "react";
import { View } from "react-native";
import { graphql, usePreloadedQuery, useQueryLoader } from "react-relay";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpThumbnailItem from "components/common/CpThumbnailItem";
import CtTopicSubtreeNavigator from "components/containers/CtTopicSubtreeNavigator";
import { IconName } from "components/primitives/PrIcon";
import CpMediaNodeNavigatorLarge from "components/topic/CpMediaNodeNavigatorLarge";
import CpMediaNodeNavigatorSmall from "components/topic/CpMediaNodeNavigatorSmall";
import CpMissingResource from "components/topic/CpMissingResource";
import CpResource from "components/topic/CpResource";
import useScreenLayout from "hooks/useScreenLayout";
import useTopicContext from "hooks/useTopicContext";
import Routes from "navigators/Routes";
import { getNameVariant } from "services/Graphql";
import { useBackendSupportedLocale } from "services/Intl";
import Log from "services/Log";
import { styles } from "services/Theme";
const resourceNodeQuery = _CpTaggedResourceNodeQuery !== void 0 ? _CpTaggedResourceNodeQuery : _CpTaggedResourceNodeQuery = require("./__generated__/CpTaggedResourceNodeQuery.graphql");

/**
 * Renders a TaggedResource as content
 */
const TaggedResourceNode = ({
  queryReference,
  resourceId
}) => {
  const navigation = useNavigation();
  const {
    rootRevisionId
  } = useTopicContext();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const resourceNode = usePreloadedQuery(resourceNodeQuery, queryReference).node;
  const handleSelect = useCallback(selectedResourceId => {
    if (!resourceNode) return;
    navigation.navigate(Routes.topic, {
      resourceId: selectedResourceId,
      rootRevisionId,
      selectedRevisionId: resourceNode.revisionId
    });
  }, [navigation, resourceNode, rootRevisionId]);
  const renderRibbonItem = useCallback(({
    item
  }) => {
    return <CpThumbnailItem icon={IconName.video} name={getNameVariant(item) ?? ""} onPressCallback={handleSelect} onPressValue={item._id} selected={item._id === resourceId} size="Medium" uri={item.thumbnailUrl} />;
  }, [handleSelect, resourceId]);
  const ribbonItemKeyExtractor = useCallback(item => {
    return item._id;
  }, []);
  if (!resourceNode) return null;
  if (!resourceId) {
    // No specific resource to refer to for rendering - so let's show them all
    return <_StyledView $_css={styles.paddingTopDouble}>
        <_StyledCtTopicSubtreeNavigator resourceNodeKey={resourceNode} $_css2={styles.fullWidth} />
      </_StyledView>;
  } else {
    const resource = resourceNode.taggedResources?.find(taggedResource => taggedResource._id === resourceId);
    if (!resourceNode.taggedResources || !resource) {
      // No resource to render
      Log.warn("CpTaggedResourceNode - no tagged resource(s) found", resourceNode);
      return <_StyledCpMissingResource $_css3={styles.paddingDouble} />;
    }
    // We have TaggedResources and a specific resource to render
    const belongsToVideoNode = ["Videos", "Vidéos"].includes(getNameVariant(resourceNode) ?? "");
    const MediaNodeNavigator = isNarrowScreen ? CpMediaNodeNavigatorSmall : CpMediaNodeNavigatorLarge;
    return <_StyledView2 $_css4={styles.fullHeight}>
        <CpUpdatePageTitle pageType="article" title={getNameVariant(resource, ["brand", "default"])} />
        {belongsToVideoNode ? <MediaNodeNavigator keyExtractor={ribbonItemKeyExtractor} renderRibbonItem={renderRibbonItem} resourceId={resourceId} resourceNodeKey={resourceNode} type="Video">
            <_StyledCpResource resource={resource} resourceType={resource.type} $_css5={[styles.fullHeight, isNarrowScreen ? undefined : styles.flexSingle]} />
          </MediaNodeNavigator> : <_StyledCpResource2 resource={resource} resourceType={resource.type} $_css6={styles.fullHeight} />}
      </_StyledView2>;
  }
};
/**
 * Renders a TaggedResource node as content
 */
const CpTaggedResourceNode = ({
  resourceId,
  resourceNodeId,
  style
}) => {
  const locale = useBackendSupportedLocale();
  const route = useRoute();
  const {
    params: {
      viewResourceId
    }
  } = route;
  const [taggedResourceNodeQueryReference, loadTaggedResourceNodeQuery, disposeTaggedResourceNodeQuery] = useQueryLoader(resourceNodeQuery);
  useEffect(() => {
    loadTaggedResourceNodeQuery({
      extraResourceId: viewResourceId,
      id: resourceNodeId,
      locale
    });
    return disposeTaggedResourceNodeQuery;
  }, [disposeTaggedResourceNodeQuery, loadTaggedResourceNodeQuery, locale, resourceNodeId, viewResourceId]);
  return <View style={style}>
      {taggedResourceNodeQueryReference && <TaggedResourceNode queryReference={taggedResourceNodeQueryReference} resourceId={resourceId} />}
    </View>;
};
export default CpTaggedResourceNode;
var _StyledView = _styled(View).withConfig({
  displayName: "CpTaggedResourceNode___StyledView",
  componentId: "sc-12hhmbl-0"
})(["", ""], p => p.$_css);
var _StyledCtTopicSubtreeNavigator = _styled(CtTopicSubtreeNavigator).withConfig({
  displayName: "CpTaggedResourceNode___StyledCtTopicSubtreeNavigator",
  componentId: "sc-12hhmbl-1"
})(["", ""], p => p.$_css2);
var _StyledCpMissingResource = _styled(CpMissingResource).withConfig({
  displayName: "CpTaggedResourceNode___StyledCpMissingResource",
  componentId: "sc-12hhmbl-2"
})(["", ""], p => p.$_css3);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CpTaggedResourceNode___StyledView2",
  componentId: "sc-12hhmbl-3"
})(["", ""], p => p.$_css4);
var _StyledCpResource = _styled(CpResource).withConfig({
  displayName: "CpTaggedResourceNode___StyledCpResource",
  componentId: "sc-12hhmbl-4"
})(["", ""], p => p.$_css5);
var _StyledCpResource2 = _styled(CpResource).withConfig({
  displayName: "CpTaggedResourceNode___StyledCpResource2",
  componentId: "sc-12hhmbl-5"
})(["", ""], p => p.$_css6);