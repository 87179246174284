import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback } from "react";
import { View } from "react-native";
import { useFragment } from "react-relay";
import CpThumbnailRibbon from "components/common/CpThumbnailRibbon";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import { mediaNodeLayoutFragment } from "components/topic/CpMediaNodeNavigatorCommon";
import useAppTheme from "hooks/useAppTheme";
import useScreenLayout from "hooks/useScreenLayout";
import useTopicContext from "hooks/useTopicContext";
import Routes from "navigators/Routes";
import { css, styles } from "services/Theme";
const sideButtonBaseStyle = css`
  align-items: center;
  background-color: ${({
  theme
}) => theme.brandColors.surface};
  border: 1px solid ${({
  theme
}) => theme.brandColors.separator};
  height: 100%;
  justify-content: center;
  width: ${({
  theme
}) => theme.buttonHeights.small}px;
`;
const localStyles = {
  container: css`
    ${styles.row};
    background-color: ${({
    theme
  }) => theme.brandColors.background};
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
    width: 100%;
  `,
  disabled: css`
    color: ${({
    theme
  }) => theme.buttonColors.textInputDisabled};
  `,
  sideNextButton: css`
    ${sideButtonBaseStyle};
    border-bottom-left-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border-top-left-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
  `,
  sidePreviousButton: css`
    ${sideButtonBaseStyle};
    border-bottom-right-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border-top-right-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
  `,
  thumbnailRibbon: css`
    padding-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `
};

/**
 * Renders provided Media for large (tablet/desktop) screen layouts. Includes a top ribbon of siblings as thumbnail items for direct selection, and full height buttons on either side of the current media for stepping through siblings
 */
const CpMediaNodeNavigatorLarge = ({
  children,
  keyExtractor,
  renderRibbonItem,
  resourceId,
  resourceNodeKey,
  style
}) => {
  const navigation = useNavigation();
  const {
    rootRevisionId
  } = useTopicContext();
  const {
    isMediumScreen
  } = useScreenLayout();
  const {
    buttonColors,
    iconSizes
  } = useAppTheme();
  const resourceNode = useFragment(mediaNodeLayoutFragment, resourceNodeKey);
  const isTaggedResourceNode = resourceNode.type === "ResourceNode::Taggable::Resource";
  const siblings = isTaggedResourceNode ? resourceNode.taggedResources : resourceNode.siblings;
  const currentId = isTaggedResourceNode ? resourceId : resourceNode._id;

  // Callbacks
  const handleOnPressPrevious = useCallback(() => {
    if (!siblings) return;
    const selectedIndex = siblings.findIndex(dataItem => dataItem?._id === currentId);
    const previousIndex = selectedIndex === 0 ? siblings.length - 1 : selectedIndex - 1;
    const previousDataItem = siblings[previousIndex];
    isTaggedResourceNode ? navigation.navigate(Routes.topic, {
      resourceId: previousDataItem?._id,
      rootRevisionId,
      selectedRevisionId: resourceNode.revisionId
    }) : navigation.navigate(Routes.topic, {
      rootRevisionId,
      selectedRevisionId: previousDataItem.revisionId
    });
  }, [currentId, isTaggedResourceNode, resourceNode, navigation, rootRevisionId, siblings]);
  const handleOnPressNext = useCallback(() => {
    if (!siblings) return;
    const selectedIndex = siblings.findIndex(dataItem => dataItem?._id === currentId);
    const nextIndex = selectedIndex === siblings.length - 1 ? 0 : selectedIndex + 1;
    const nextDataItem = siblings[nextIndex];
    isTaggedResourceNode ? navigation.navigate(Routes.topic, {
      resourceId: nextDataItem?._id,
      rootRevisionId,
      selectedRevisionId: resourceNode.revisionId
    }) : navigation.navigate(Routes.topic, {
      rootRevisionId,
      selectedRevisionId: nextDataItem.revisionId
    });
  }, [currentId, isTaggedResourceNode, resourceNode, navigation, rootRevisionId, siblings]);
  const buttonsDisabled = false;
  return <View style={style}>
      <_StyledView $_css={localStyles.container}>
        <_StyledPrPressable disabled={buttonsDisabled} onPress={handleOnPressPrevious} $_css2={localStyles.sidePreviousButton}>
          <PrIcon fill={buttonsDisabled ? buttonColors.textInputDisabled : buttonColors.primary} icon={IconName.keyboardArrowLeft} size={isMediumScreen ? iconSizes.medium : iconSizes.large} />
        </_StyledPrPressable>

        {children}
        <_StyledPrPressable2 disabled={buttonsDisabled} onPress={handleOnPressNext} $_css3={localStyles.sideNextButton}>
          <PrIcon fill={buttonsDisabled ? buttonColors.textInputDisabled : buttonColors.primary} icon={IconName.keyboardArrowRight} size={isMediumScreen ? iconSizes.medium : iconSizes.large} />
        </_StyledPrPressable2>
      </_StyledView>

      <_StyledCpThumbnailRibbon data={siblings} keyExtractor={keyExtractor} renderItem={renderRibbonItem} $_css4={localStyles.thumbnailRibbon} />
    </View>;
};
export default CpMediaNodeNavigatorLarge;
var _StyledView = _styled(View).withConfig({
  displayName: "CpMediaNodeNavigatorLarge___StyledView",
  componentId: "sc-pe41vh-0"
})(["", ""], p => p.$_css);
var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpMediaNodeNavigatorLarge___StyledPrPressable",
  componentId: "sc-pe41vh-1"
})(["", ""], p => p.$_css2);
var _StyledPrPressable2 = _styled(PrPressable).withConfig({
  displayName: "CpMediaNodeNavigatorLarge___StyledPrPressable2",
  componentId: "sc-pe41vh-2"
})(["", ""], p => p.$_css3);
var _StyledCpThumbnailRibbon = _styled(CpThumbnailRibbon).withConfig({
  displayName: "CpMediaNodeNavigatorLarge___StyledCpThumbnailRibbon",
  componentId: "sc-pe41vh-3"
})(["", ""], p => p.$_css4);