import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, FormattedPlural } from "react-intl";
import { Pressable, ScrollView, View } from "react-native";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import PrTextInput from "components/primitives/PrTextInput";
import useAppTheme from "hooks/useAppTheme";
import { css } from "services/Theme";
import { iconSizes } from "services/Theme/common";
const localStyles = {
  container: css`
    flex-direction: column;
    justify-content: space-between;
  `,
  dropdownItem: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    padding-left: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  dropdownMenu: css`
    border-color: ${({
    theme
  }) => theme.brandColors.separator};
    border-top-width: 1px;
    max-height: 250px;
    z-index: ${({
    theme
  }) => theme.layers.secondLayer};
  `,
  dropdownOpen: css`
    border-bottom-width: 1px;
  `,
  dropdownOption: css`
    align-items: center;
    flex-direction: row;
  `,
  icon: css`
    cursor: pointer;
  `,
  overlay: css`
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    left: 0;
    height: 100vh;
    opacity: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: ${({
    theme
  }) => theme.layers.firstLayer};
  `,
  placeholderText: css`
    color: ${({
    theme
  }) => theme.textColors.textInputPlaceholder};
  `,
  pressable: css`
    min-height: ${({
    theme
  }) => theme.buttonHeights.medium}px;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  pressableSearchable: css`
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  selectedValue: css`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  `,
  textInput: css`
    flex: 1;
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    padding: 0;
  `
};
/**
 * Select Component for multiple options
 */
const PrSelectMulti = ({
  onChangeOption,
  onFocus,
  placeholder,
  searchable = false,
  selectOptions,
  testID,
  value = [],
  ...otherProps
}) => {
  const {
    buttonColors
  } = useAppTheme();
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(selectOptions);
  const [searchInput, setSearchInput] = useState("");
  const textInputRef = useRef(null);
  const filterOptions = useCallback(filterInput => {
    setFilteredOptions(selectOptions.filter(option => option.name.toLocaleLowerCase().includes(filterInput)));
  }, [selectOptions]);
  const handleChangeText = useCallback(text => {
    setSearchInput(text);
    filterOptions(text.toLowerCase());
    setDropDownOpen(true);
  }, [filterOptions]);
  const handleFocusTextInput = useCallback(() => {
    textInputRef?.current?.focus();
  }, []);
  const handleChangeOption = useCallback(option => {
    const handleSelectOption = newOption => {
      const currentOptions = value;
      const isRemove = currentOptions && currentOptions.find(currentOption => currentOption === newOption);
      return isRemove ? currentOptions.filter(currentOption => currentOption !== newOption) : [...currentOptions, newOption];
    };
    onChangeOption && onChangeOption(handleSelectOption(option));
    handleFocusTextInput();
  }, [handleFocusTextInput, onChangeOption, value]);
  const findOptionName = useCallback(optionValue => {
    return selectOptions.find(option => option.value === optionValue)?.name;
  }, [selectOptions]);
  const handleFocus = useCallback(() => {
    setDropDownOpen(true);
  }, []);
  const handleCloseDropdown = useCallback(() => {
    setDropDownOpen(false);
  }, []);
  const handleToggleDropdown = useCallback(() => {
    setDropDownOpen(!dropDownOpen);
  }, [dropDownOpen]);

  // Focus on input on dropdown open in case it's searchable
  useEffect(() => {
    if (dropDownOpen && searchable) {
      handleFocusTextInput();
    }
  }, [dropDownOpen, handleFocusTextInput, searchable]);
  useEffect(() => {
    onFocus && onFocus(dropDownOpen);
  }, [dropDownOpen, onFocus]);
  const formattedMessage = useMemo(() => {
    if (value.length === 1) {
      return findOptionName(value[0]);
    } else if (value.length < 1) {
      return placeholder;
    } else {
      return <FormattedMessage id="DtIviB" defaultMessage="{firstOption}, and {otherOptionsTotal} {otherPlural} selected" values={{
        firstOption: findOptionName(value[0]),
        otherOptionsTotal: value.length - 1,
        otherPlural: <FormattedPlural value={value.length - 1} one="other" other="others" />
      }} />;
    }
  }, [findOptionName, placeholder, value]);
  return <_StyledView {...otherProps} $_css={localStyles.container}>
      <_StyledPrPressable focusedBackgroundColor="surface" hoveredBackgroundColor="surface" onPress={handleToggleDropdown} pressedBackgroundColor="surface" testID={testID} $_css2={localStyles.pressable}>
        <_StyledView2 $_css3={localStyles.selectedValue}>
          {searchable && dropDownOpen ? <_StyledPrTextInput onChangeText={handleChangeText} onClear={handleFocusTextInput} onFocus={handleFocus} placeholder={placeholder} ref={textInputRef} value={searchInput} $_css4={localStyles.textInput} /> : <_StyledPrText children={formattedMessage} numberOfLines={1} $_css5={[localStyles.textInput, !value.length ? localStyles.placeholderText : undefined]} />}
          <_StyledPrIcon icon={dropDownOpen ? "arrowDropUp" : "arrowDropDown"} size={iconSizes.small} $_css6={localStyles.icon} />
        </_StyledView2>
      </_StyledPrPressable>
      {dropDownOpen && <>
          <_StyledScrollView $_css7={localStyles.dropdownMenu}>
            {filteredOptions?.map(option => <_StyledPrPressable2 key={option.value} onPress={() => handleChangeOption(option.value)} testID={`SelectOption${option.value}`} $_css8={localStyles.dropdownOption}>
                {value.includes(option.value) ? <PrIcon fill={buttonColors.primary} icon={IconName.checkboxChecked} size={iconSizes.xsmall} /> : <PrIcon fill={buttonColors.primary} icon={IconName.checkboxUnchecked} size={iconSizes.xsmall} />}
                <_StyledPrText2 $_css9={localStyles.dropdownItem}>{option.name}</_StyledPrText2>
              </_StyledPrPressable2>)}
          </_StyledScrollView>
          <_StyledPressable onPress={handleCloseDropdown} $_css10={localStyles.overlay} />
        </>}
    </_StyledView>;
};
export default PrSelectMulti;
var _StyledView = _styled(View).withConfig({
  displayName: "PrSelectMulti___StyledView",
  componentId: "sc-v6lir4-0"
})(["", ""], p => p.$_css);
var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "PrSelectMulti___StyledPrPressable",
  componentId: "sc-v6lir4-1"
})(["", ""], p => p.$_css2);
var _StyledView2 = _styled(View).withConfig({
  displayName: "PrSelectMulti___StyledView2",
  componentId: "sc-v6lir4-2"
})(["", ""], p => p.$_css3);
var _StyledPrTextInput = _styled(PrTextInput).withConfig({
  displayName: "PrSelectMulti___StyledPrTextInput",
  componentId: "sc-v6lir4-3"
})(["", ""], p => p.$_css4);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "PrSelectMulti___StyledPrText",
  componentId: "sc-v6lir4-4"
})(["", ""], p => p.$_css5);
var _StyledPrIcon = _styled(PrIcon).withConfig({
  displayName: "PrSelectMulti___StyledPrIcon",
  componentId: "sc-v6lir4-5"
})(["", ""], p => p.$_css6);
var _StyledScrollView = _styled(ScrollView).withConfig({
  displayName: "PrSelectMulti___StyledScrollView",
  componentId: "sc-v6lir4-6"
})(["", ""], p => p.$_css7);
var _StyledPrPressable2 = _styled(PrPressable).withConfig({
  displayName: "PrSelectMulti___StyledPrPressable2",
  componentId: "sc-v6lir4-7"
})(["", ""], p => p.$_css8);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "PrSelectMulti___StyledPrText2",
  componentId: "sc-v6lir4-8"
})(["", ""], p => p.$_css9);
var _StyledPressable = _styled(Pressable).withConfig({
  displayName: "PrSelectMulti___StyledPressable",
  componentId: "sc-v6lir4-9"
})(["", ""], p => p.$_css10);