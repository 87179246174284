import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
const useAsString = value => {
  const {
    formatMessage
  } = useIntl();
  if (value) {
    return typeof value === "string" ? value : formatMessage(value);
  } else {
    return;
  }
};
/**
 * Updates the document head for the current page to support SEO and linking on social networks.
 *
 * The intent is to set all values as the defaults for the site/app as a whole high in the component hierarchy
 * and then have each scene override the title at a minimum, plus any other values as required.
 */
const CpUpdatePageTitle = ({
  description,
  image,
  imageAlt,
  pageType,
  title
}) => {
  const canonicalUrl = `${document.location.origin}${document.location.pathname}`;
  const descriptionText = useAsString(description);
  const imageAltText = useAsString(imageAlt);
  const titleText = useAsString(title);
  return <Helmet>
      {descriptionText && <meta content={descriptionText} name="description" />}
      {descriptionText && <meta content={descriptionText} property="og:description" />}
      {image && <meta content={image} property="og:image" />}
      {imageAltText && <meta content={imageAltText} property="og:image:alt" />}
      {titleText && <title>{titleText}</title>}
      {titleText && <meta content={titleText} property="og:title" />}
      {pageType && <meta content={pageType} property="og:type" />}
      <link href={canonicalUrl} rel="canonical" />
      <meta content={canonicalUrl} property="og:url" />
    </Helmet>;
};
export default CpUpdatePageTitle;