import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import useImdSession from "hooks/useImdSession";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { View } from "react-native";
import CpMembershipItem from "components/organization/CpMembershipItem";
import { css, styles } from "services/Theme";
import buttons from "translations/buttons";
const localStyles = {
  container: css`
    margin-bottom: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  topSpacing: css`
    margin-top: ${({
    theme
  }) => theme.spacing.double}px;
  `
};
/**
 * This component renders the users memberships to select from
 */
const CpSelectMembership = ({
  currentMembership,
  memberships,
  onSelect
}) => {
  const {
    signout
  } = useImdSession();
  const handleSignOut = useCallback(() => signout(), [signout]);
  const hasMemberships = memberships && memberships.length > 0;
  return <_StyledView $_css={hasMemberships ? localStyles.container : undefined}>
      {memberships?.map((membership, index) => {
      // Each membership contains its associated organization with name and address
      return <CpMembershipItem
      // User Assist - if there is only one membership to select from, select it automatically
      autoSelect={index === 0 && !currentMembership && memberships.length === 1} currentMembership={currentMembership} isFirst={index === 0} isLast={index === memberships.length - 1} key={membership.__id} membership={membership} onSelect={onSelect} />;
    })}
      {!hasMemberships &&
    // User has no memberships to select - have them contact support and sign out to continue
    <>
          <_StyledPrText $_css2={localStyles.topSpacing}>
            <FormattedMessage id="tWEKWg" defaultMessage="It looks like you don't have any memberships to select from! Contact {supportEmail} to proceed. In the meantime, you can continue browsing anonymously by signing out." values={{
          supportEmail: <_StyledPrText2 children="support@imdhealth.com" $_css3={styles.boldText} />
        }} />
          </_StyledPrText>
          <_StyledPrButton children={buttons.signout} onPress={handleSignOut} $_css4={localStyles.topSpacing} />
        </>}
    </_StyledView>;
};
export default CpSelectMembership;
var _StyledView = _styled(View).withConfig({
  displayName: "CpSelectMembership___StyledView",
  componentId: "sc-h63fkb-0"
})(["", ""], p => p.$_css);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpSelectMembership___StyledPrText",
  componentId: "sc-h63fkb-1"
})(["", ""], p => p.$_css2);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpSelectMembership___StyledPrText2",
  componentId: "sc-h63fkb-2"
})(["", ""], p => p.$_css3);
var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpSelectMembership___StyledPrButton",
  componentId: "sc-h63fkb-3"
})(["", ""], p => p.$_css4);