import _styled17 from "styled-components";
import _styled16 from "styled-components";
import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { View } from "react-native";
import CpError from "components/common/CpError";
import { ModalSteps, PatientSessionActions } from "components/patientSession/PatientSession";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useAnalytics from "hooks/useAnalytics";
import useAppTheme from "hooks/useAppTheme";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import Routes from "navigators/Routes";
import { GoogleAnalyticsDimension } from "services/GoogleAnalytics";
import { styles } from "services/Theme";
import translations from "translations";
/**
 * Renders the entrypoint modal screen for a PackageSession with options for users to start a session, view saved templates, or review their session.
 */
const CpPackageSessionStart = ({
  onClose,
  style
}) => {
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    organization,
    user
  } = useImdSession();
  const {
    formatMessage
  } = useIntl();
  const {
    buttonColors,
    iconSizes
  } = useAppTheme();
  const navigation = useNavigation();
  const {
    dispatchPatientSession,
    patientSessionData: {
      isPackageSession,
      isPatientSession,
      packageResources
    }
  } = usePatientSessionContext();
  const [error, setError] = useState();
  const handleReviewAndSend = useCallback(() => {
    if (isPatientSession) {
      setError(formatMessage(translations.errors.otherSessionInProgress, {
        otherSession: formatMessage(translations.labels.patientEducationSession)
      }));
    } else if (packageResources.length === 0) {
      setError(formatMessage(translations.errors.nothingToReview));
    } else {
      dispatchPatientSession({
        payload: {
          modalStep: ModalSteps.PackageReview
        },
        type: PatientSessionActions.SetModal
      });
    }
  }, [dispatchPatientSession, formatMessage, isPatientSession, packageResources.length]);
  const handlePressStart = useCallback(() => {
    if (isPatientSession) {
      setError(formatMessage(translations.errors.otherSessionInProgress, {
        otherSession: formatMessage(translations.labels.patientEducationSession)
      }));
    } else {
      // PackageSession startBuilding
      trackGoogleEvent({
        action: "Started",
        name: "packageSession",
        options: {
          dimensions: {
            [GoogleAnalyticsDimension.organizationId]: organization?._id,
            [GoogleAnalyticsDimension.practitionerType]: user?.practitionerProfile?.type || undefined
          }
        }
      });
      // Dispatching START will close the modal
      dispatchPatientSession({
        type: PatientSessionActions.StartPackage
      });
      const routes = navigation.getState().routes;
      if (routes.length > 1 || routes.length === 1 && routes[0].name !== Routes.home) {
        // Since you can only start a package from the home screen, navigate the user there directly to be able to start building
        navigation.navigate(Routes.home);
      }
    }
  }, [dispatchPatientSession, formatMessage, isPatientSession, navigation, organization?._id, trackGoogleEvent, user]);
  const handlePressStop = useCallback(() => {
    // GA event option: PackageSession:stopBuilding
    dispatchPatientSession({
      type: PatientSessionActions.StopPackage
    });
  }, [dispatchPatientSession]);
  const handlePressSelectTemplate = useCallback(() => {
    // GA event option: PackageSession:loadOrSavePackages
    dispatchPatientSession({
      payload: {
        modalStep: ModalSteps.PackageSelectTemplate
      },
      type: PatientSessionActions.SetModal
    });
  }, [dispatchPatientSession]);
  return <View style={style}>
      <_StyledPrText accessibilityRole="header" children={translations.labels.patientInformationPackage} $_css={[styles.modalHeaderText, styles.paddingHorizontalSingle]} />
      <_StyledView $_css2={styles.paddingHorizontalSingle}>
        <View>
          <PrText>
            <FormattedMessage id="ROWN+P" defaultMessage="Click {start} to begin adding resources to your package." values={{
            start: <_StyledPrText2 children={translations.buttons.start} $_css3={styles.italicText} />
          }} />
          </PrText>
          <_StyledPrText3 $_css4={styles.paddingTopSingle}>
            <FormattedMessage id="3Xqr8w" defaultMessage="Any existing package templates you have saved can be found under {saved}." values={{
            saved: <_StyledPrText4 children={translations.buttons.savedPackages} $_css5={styles.italicText} />
          }} />
          </_StyledPrText3>
          <_StyledPrText5 $_css6={styles.paddingVerticalSingle}>
            <FormattedMessage id="s408Om" defaultMessage="Once you're ready, select {reviewAndSend}." values={{
            reviewAndSend: <_StyledPrText6 children={translations.buttons.reviewAndSend} $_css7={styles.italicText} />
          }} />
          </_StyledPrText5>
        </View>

        {/* Buttons to Start/Stop, View Templates and Review & Send */}
        <_StyledView2 $_css8={styles.sessionStyles.start.buttonContainer}>
          <_StyledPrPressable accessibilityHint={formatMessage(translations.accessibility.hint.packageStartStop, {
          state: formatMessage(isPackageSession ? translations.buttons.stop : translations.buttons.start)
        })} accessibilityRole={isPackageSession ? "button" : "link"} onPress={isPackageSession ? handlePressStop : handlePressStart} testID={`CpPackageSessionStart-${isPackageSession ? "stop" : "start"}`} $_css9={styles.sessionStyles.start.button}>
            <PrIcon fill={buttonColors.primary} icon={isPackageSession ? IconName.iconPackageClosed : IconName.iconPackageOpen} size={iconSizes.large} />
            <_StyledPrText7 $_css10={styles.sessionStyles.start.buttonText}>
              <FormattedMessage id="JvtvOO" defaultMessage="{whatDo} building" values={{
              whatDo: <PrText children={isPackageSession ? translations.buttons.stop : translations.buttons.start} />
            }} />
            </_StyledPrText7>
          </_StyledPrPressable>
          <_StyledPrPressable2 accessibilityHint={formatMessage(translations.accessibility.hint.packageTemplates)} accessibilityRole="link" onPress={handlePressSelectTemplate} testID="CpPackageSessionStart-packages" $_css11={styles.sessionStyles.start.button}>
            <PrIcon fill={buttonColors.primary} icon={IconName.medicalSave} size={iconSizes.large} />
            <_StyledPrText8 children={translations.buttons.loadOrSavePackages} $_css12={styles.sessionStyles.start.buttonText} />
          </_StyledPrPressable2>
          <_StyledPrPressable3 accessibilityHint={formatMessage(translations.accessibility.hint.reviewAndSend)} accessibilityRole="link" onPress={handleReviewAndSend} testID="CpPackageSessionStart-review" $_css13={styles.sessionStyles.start.button}>
            <PrIcon fill={buttonColors.primary} icon={IconName.mail} size={iconSizes.large} />
            <_StyledPrText9 children={translations.buttons.reviewAndSend} $_css14={styles.sessionStyles.start.buttonText} />
          </_StyledPrPressable3>
        </_StyledView2>
        <_StyledCpError error={error} $_css15={styles.paddingVerticalSingle} />

        <_StyledView3 $_css16={styles.sessionStyles.start.closeButtonContainer}>
          <PrButton accessibilityHint={formatMessage(translations.accessibility.hint.closeModal)} onPress={onClose} testID="CpPackageSessionStart-close" type="text">
            <_StyledPrText10 children={translations.buttons.close} $_css17={styles.sessionStyles.start.closeButtonText} />
          </PrButton>
        </_StyledView3>
      </_StyledView>
    </View>;
};
export default CpPackageSessionStart;
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpPackageSessionStart___StyledPrText",
  componentId: "sc-cfvfcc-0"
})(["", ""], p => p.$_css);
var _StyledView = _styled(View).withConfig({
  displayName: "CpPackageSessionStart___StyledView",
  componentId: "sc-cfvfcc-1"
})(["", ""], p => p.$_css2);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionStart___StyledPrText2",
  componentId: "sc-cfvfcc-2"
})(["", ""], p => p.$_css3);
var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionStart___StyledPrText3",
  componentId: "sc-cfvfcc-3"
})(["", ""], p => p.$_css4);
var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionStart___StyledPrText4",
  componentId: "sc-cfvfcc-4"
})(["", ""], p => p.$_css5);
var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionStart___StyledPrText5",
  componentId: "sc-cfvfcc-5"
})(["", ""], p => p.$_css6);
var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionStart___StyledPrText6",
  componentId: "sc-cfvfcc-6"
})(["", ""], p => p.$_css7);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CpPackageSessionStart___StyledView2",
  componentId: "sc-cfvfcc-7"
})(["", ""], p => p.$_css8);
var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpPackageSessionStart___StyledPrPressable",
  componentId: "sc-cfvfcc-8"
})(["", ""], p => p.$_css9);
var _StyledPrText7 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionStart___StyledPrText7",
  componentId: "sc-cfvfcc-9"
})(["", ""], p => p.$_css10);
var _StyledPrPressable2 = _styled(PrPressable).withConfig({
  displayName: "CpPackageSessionStart___StyledPrPressable2",
  componentId: "sc-cfvfcc-10"
})(["", ""], p => p.$_css11);
var _StyledPrText8 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionStart___StyledPrText8",
  componentId: "sc-cfvfcc-11"
})(["", ""], p => p.$_css12);
var _StyledPrPressable3 = _styled(PrPressable).withConfig({
  displayName: "CpPackageSessionStart___StyledPrPressable3",
  componentId: "sc-cfvfcc-12"
})(["", ""], p => p.$_css13);
var _StyledPrText9 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionStart___StyledPrText9",
  componentId: "sc-cfvfcc-13"
})(["", ""], p => p.$_css14);
var _StyledCpError = _styled(CpError).withConfig({
  displayName: "CpPackageSessionStart___StyledCpError",
  componentId: "sc-cfvfcc-14"
})(["", ""], p => p.$_css15);
var _StyledView3 = _styled(View).withConfig({
  displayName: "CpPackageSessionStart___StyledView3",
  componentId: "sc-cfvfcc-15"
})(["", ""], p => p.$_css16);
var _StyledPrText10 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionStart___StyledPrText10",
  componentId: "sc-cfvfcc-16"
})(["", ""], p => p.$_css17);