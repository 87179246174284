import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
var _CpDrawingToolsButtons_PresignedUrlMutation;
import { useRoute } from "@react-navigation/native";
import React, { useCallback, useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { View } from "react-native";
import { graphql, useMutation } from "react-relay";
import { PatientSessionActions } from "components/patientSession/PatientSession";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import CpDrawingModal, { CpDrawingModalState } from "components/topic/drawingTools/CpDrawingModal";
import CpDrawingStrokeOptions from "components/topic/drawingTools/CpDrawingStrokeOptions";
import { DrawingMode, DrawingToolsActions } from "components/topic/drawingTools/drawingTools";
import useAnalytics from "hooks/useAnalytics";
import useAppTheme from "hooks/useAppTheme";
import useDrawingToolsContext from "hooks/useDrawingToolsContext";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useScreenLayout from "hooks/useScreenLayout";
import useTopicContext from "hooks/useTopicContext";
import { AppTool } from "services/Constants";
import Log from "services/Log";
import { css, styles } from "services/Theme";
import { iconSizes } from "services/Theme/common";
import { convertBase64ToFile, uploadToS3 } from "services/Upload";
import translations from "translations";
const getPresignedUrlMutation = _CpDrawingToolsButtons_PresignedUrlMutation !== void 0 ? _CpDrawingToolsButtons_PresignedUrlMutation : _CpDrawingToolsButtons_PresignedUrlMutation = require("./__generated__/CpDrawingToolsButtons_PresignedUrlMutation.graphql");
const localStyles = {
  button: css`
    border-bottom-width: 1px;
    border-color: ${({
    theme
  }) => theme.buttonColors.primary};
    border-radius: 0;
    border-top-width: 1px;
    padding-left: ${({
    theme
  }) => theme.spacing.double}px;
    padding-right: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  buttonBigger: css`
    height: ${({
    theme
  }) => theme.buttonHeights.xlarge}px;
    padding-bottom: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  buttonSelected: css`
    background-color: ${({
    theme
  }) => theme.buttonColors.paginationSelected};
  `,
  buttonText: css`
    color: ${({
    theme
  }) => theme.textColors.default};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    font-weight: normal;
  `,
  clearButton: css`
    background-color: ${({
    theme
  }) => theme.buttonColors.destructive};
    border-bottom-left-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border-color: ${({
    theme
  }) => theme.buttonColors.destructive};
    border-radius: 0;
    border-top-left-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    padding-left: ${({
    theme
  }) => theme.spacing.double}px;
    padding-right: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  clearButtonText: css`
    color: ${({
    theme
  }) => theme.buttonColors.text};
  `,
  drawButton: css`
    border: 1px solid ${({
    theme
  }) => theme.buttonColors.primary};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
  `,
  drawButtonOpen: css`
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  `,
  drawContainer: css`
    align-items: center;
    flex-direction: row;
    margin-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
    margin-vertical: ${({
    theme
  }) => theme.spacing.half}px;
    padding: 0;
  `,
  fullscreenCloseText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    margin-left: ${({
    theme
  }) => theme.spacing.single}px;
    margin-right: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  selected: css`
    background-color: ${({
    theme
  }) => theme.buttonColors.primary};
  `,
  selectedText: css`
    color: ${({
    theme
  }) => theme.buttonColors.text};
  `
};
const localTranslations = defineMessages({
  clear: {
    id: "7viITA",
    defaultMessage: "Clear"
  },
  eraser: {
    id: "A4RDJk",
    defaultMessage: "Eraser"
  },
  palette: {
    id: "5JS1Q0",
    defaultMessage: "Palette"
  },
  redo: {
    id: "D7bG7b",
    defaultMessage: "Redo"
  },
  save: {
    id: "uUeNjN",
    defaultMessage: "Save"
  },
  undo: {
    id: "FuOizg",
    defaultMessage: "Undo"
  }
});
/**
 * Generic button styled for the drawing tool actions bar
 */
const DrawingToolButton = ({
  busy = false,
  icon,
  label,
  selected = false,
  ...otherProps
}) => {
  const {
    buttonColors
  } = useAppTheme();
  const {
    isMediumScreen,
    isWideScreen
  } = useScreenLayout();
  return <_StyledPrButton busy={busy} type="text" {...otherProps} $_css={[styles.flexSingle, localStyles.button, isMediumScreen ? localStyles.buttonBigger : undefined, selected ? localStyles.buttonSelected : undefined]}>
      <_StyledView $_css2={[styles.center, isWideScreen ? styles.row : undefined]}>
        <_StyledPrIcon fill={buttonColors.primary} icon={icon} size={iconSizes.small} $_css3={styles.marginHorizontalSingle} />
        <_StyledPrText children={label} $_css4={localStyles.buttonText} />
      </_StyledView>
    </_StyledPrButton>;
};
/**
 * Renders the canvas buttons for drawing options and the functionality associated with them, and the fullscreen close button.
 */
const CpDrawingToolsButtons = ({
  onCloseFullScreen
}) => {
  const {
    buttonColors
  } = useAppTheme();
  const {
    formatMessage
  } = useIntl();
  const {
    dispatchPatientSession,
    patientSessionData: {
      isPatientSession
    }
  } = usePatientSessionContext();
  const {
    isMediumScreen,
    isWideScreen
  } = useScreenLayout();
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    appConfig,
    user
  } = useImdSession();
  const drawingTool = appConfig?.appTools?.find(tool => tool.name === AppTool.Draw);
  const isDrawingDisabled = drawingTool?.status !== "active";
  const isPatient = user?.isPatient;
  const allowAnnotations = Boolean(user) && !isPatient && !isDrawingDisabled;
  const patientEducationTool = appConfig?.appTools?.find(tool => tool.name === AppTool.Educate);
  const isPatientEducationDisabled = patientEducationTool?.status !== "active";
  const {
    selectedRevisionId
  } = useTopicContext();
  const {
    dispatchDrawingTools,
    drawingToolsData: {
      canvasPaths,
      drawingEnabled,
      isDrawing,
      stageRef
    }
  } = useDrawingToolsContext();
  const route = useRoute();
  const [getPresignedUrl, isSubmittingGetPresignedUrl] = useMutation(getPresignedUrlMutation);
  const [isSaved, setIsSaved] = useState(false);
  // Have to create a custom 'busy' state here instead of using the mutation because we handle a couple more promises after the mutation resolves.
  const [isSaving, setIsSaving] = useState(false);
  const [drawingMode, setDrawingMode] = useState(DrawingMode.Pen);
  const [customizationPopupOpen, setCustomizationPopupOpen] = useState(false);
  const [customizationLayout, setCustomizationLayout] = useState({
    width: 0,
    x: 0,
    y: 0
  });
  const handleCustomizationLayout = useCallback(({
    nativeEvent: {
      layout
    }
  }) => {
    setCustomizationLayout({
      width: 400,
      x: layout.left + layout.width - 400,
      y: layout.top + layout.height
    });
  }, [setCustomizationLayout]);

  // State for the modal that handles errors or exiting without saving
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalState, setModalState] = useState(CpDrawingModalState.SaveAndExitWarning);
  const [closeModalOnConfirm, setCloseModalOnConfirm] = useState(false);
  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);
  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    if (closeModalOnConfirm && (modalState === CpDrawingModalState.Success || modalState === CpDrawingModalState.SessionWarning)) {
      onCloseFullScreen();
    }
  }, [closeModalOnConfirm, modalState, onCloseFullScreen]);
  const handleExitWithouSaving = useCallback(() => {
    setIsModalOpen(false);
    onCloseFullScreen();
  }, [onCloseFullScreen]);
  const handleOpenCustomizationPopup = useCallback(() => {
    setCustomizationPopupOpen(true);
  }, []);
  const handleCloseCustomizationPopup = useCallback(() => {
    setCustomizationPopupOpen(false);
  }, []);
  const handlePressDrawing = useCallback(() => {
    dispatchDrawingTools({
      payload: {
        drawingEnabled: true,
        isDrawing: !isDrawing
      },
      type: DrawingToolsActions.SetDrawingOptions
    });
  }, [dispatchDrawingTools, isDrawing]);
  const handleEnableEraserMode = useCallback(() => {
    if (stageRef?.current) {
      stageRef.current.eraseMode(true);
      setDrawingMode(DrawingMode.Eraser);
    }
  }, [stageRef]);
  const handleEnablePenMode = useCallback(() => {
    if (stageRef?.current) {
      stageRef.current.eraseMode(false);
      setDrawingMode(DrawingMode.Pen);
    }
  }, [stageRef]);
  const handleClearCanvas = useCallback(() => {
    if (stageRef?.current) {
      stageRef.current.clearCanvas();
    }
  }, [stageRef]);
  const handleUndoCanvas = useCallback(() => {
    if (stageRef?.current) {
      stageRef.current.undo();
    }
  }, [stageRef]);
  const handleRedoCanvas = useCallback(() => {
    if (stageRef?.current) {
      stageRef.current.redo();
    }
  }, [stageRef]);

  // Displays a warning if the user is about to exit the fullscreen mode without saving their drawing
  const handleCloseFullScreen = useCallback(async () => {
    // Checks for the presence of unsaved changes by comparing current canvas paths to paths at the context
    // If the paths array paths length are different, then there are unsaved changes
    let isDirty;
    if (stageRef?.current) {
      const pathsArray = await stageRef.current.exportPaths();
      isDirty = pathsArray.some((path, index) => canvasPaths[index]?.paths.length !== path.paths.length);
    }
    setCloseModalOnConfirm(true);
    if (isSaved || !allowAnnotations || !isDirty) {
      onCloseFullScreen();
      return;
    }
    handleOpenModal();
    setModalState(CpDrawingModalState.SaveAndExitWarning);
  }, [allowAnnotations, canvasPaths, handleOpenModal, isSaved, onCloseFullScreen, stageRef]);
  const handleSaveImage = useCallback(async () => {
    if (stageRef?.current) {
      const paths = await stageRef.current.exportPaths();
      trackGoogleEvent({
        action: "clicked Save button",
        name: "drawingTools"
      });
      if (!paths.length) {
        dispatchPatientSession({
          payload: route.params.selectedRevisionId || "",
          type: PatientSessionActions.ClearAnnotation
        });
        dispatchDrawingTools({
          payload: [],
          type: DrawingToolsActions.SetCanvasPaths
        });
        dispatchDrawingTools({
          payload: null,
          type: DrawingToolsActions.SetUploadedImage
        });
        setModalState(CpDrawingModalState.Success);
        setIsModalOpen(true);
        return;
      }
      setIsSaving(true);
      const {
        current
      } = stageRef;
      let fields;
      let presignedUrl;
      getPresignedUrl({
        onCompleted: ({
          uploadAnnotation
        }) => {
          Log.info(`CpDrawingToolsButtons - ${uploadAnnotation?.url}`);
          if (uploadAnnotation?.url) {
            presignedUrl = uploadAnnotation.url;
            fields = uploadAnnotation.fields;
            Log.info(`CpDrawingToolsButtons - ${presignedUrl}`);
            current.exportImage("jpeg").then(async img => {
              if (presignedUrl) {
                const file = await convertBase64ToFile(img, `https://${fields.xAmzMetaOriginalFilename}`, fields.contentType);
                const formData = {
                  contentType: fields.contentType,
                  file,
                  key: fields.key,
                  policy: fields.policy,
                  xAmzAlgorithm: fields.xAmzAlgorithm,
                  xAmzCredential: fields.xAmzCredential,
                  xAmzDate: fields.xAmzDate,
                  xAmzMetaOriginalFilename: fields.xAmzMetaOriginalFilename,
                  xAmzSecurityToken: fields.xAmzSecurityToken,
                  xAmzSignature: fields.xAmzSignature
                };
                const response = await uploadToS3(presignedUrl, formData);
                if (response.status === "error") {
                  Log.error("CpDrawingToolsButtons - Error uploading to S3");
                  setIsSaving(false);
                  handleOpenModal();
                  setModalState(CpDrawingModalState.Error);
                  return;
                }
                if (response.status === "success" && response.assetUrl) {
                  // Gets url from response and adds to the patient session ctx
                  Log.info(`CpDrawingToolsButtons - S3 Upload: ${response.assetUrl}`);
                  dispatchPatientSession({
                    payload: {
                      resourceNodeRevisionId: selectedRevisionId || "",
                      s3Key: fields.key,
                      selected: true,
                      url: `https://${response.assetUrl}`
                    },
                    type: PatientSessionActions.AddAnnotation
                  });
                  dispatchDrawingTools({
                    payload: img,
                    type: DrawingToolsActions.SetUploadedImage
                  });
                  setIsSaved(true);
                  setIsSaving(false);

                  // Close modal and do not show success modal if the user saves after being warned about patient session
                  if (modalState === CpDrawingModalState.SessionWarning) {
                    if (closeModalOnConfirm && !isPatientSession) {
                      onCloseFullScreen();
                    }
                    setIsModalOpen(false);
                    return;
                  }
                  setIsModalOpen(true);
                  setModalState(CpDrawingModalState.Success);
                }
              }
            });
            // Export current drawing path to context in case we need to import it later
            dispatchDrawingTools({
              payload: paths,
              type: DrawingToolsActions.SetCanvasPaths
            });
          }
        },
        onError: err => {
          Log.error(`CpDrawingToolsButtons - ${err}`);
          setIsSaving(false);
          handleOpenModal();
          setModalState(CpDrawingModalState.Error);
        },
        variables: {
          input: {
            mimeType: "image/jpeg"
          }
        }
      });
    }
  }, [closeModalOnConfirm, dispatchDrawingTools, dispatchPatientSession, getPresignedUrl, handleOpenModal, isPatientSession, modalState, onCloseFullScreen, route.params.selectedRevisionId, selectedRevisionId, stageRef, trackGoogleEvent]);
  const handlePressSave = useCallback(async () => {
    if (!isPatientSession && !isPatientEducationDisabled) {
      setIsModalOpen(true);
      setModalState(CpDrawingModalState.SessionWarning);
      return;
    }
    await handleSaveImage();
  }, [handleSaveImage, isPatientEducationDisabled, isPatientSession]);
  const renderCloseFullScreenButton = useMemo(() => {
    return <_StyledPrPressable accessibilityRole="button" onPress={handleCloseFullScreen} testID="CpDrawingToolsButtonsCloseFullScreen" $_css5={styles.rowAndCenter}>
        <PrIcon fill={buttonColors.primary} icon={IconName.fullscreenExit} size={isWideScreen ? iconSizes.large : iconSizes.medium} />
        <_StyledPrText2 children={translations.buttons.fullscreenClose} $_css6={localStyles.fullscreenCloseText} />
      </_StyledPrPressable>;
  }, [buttonColors.primary, handleCloseFullScreen, isWideScreen]);

  // Guard - drawing tools should only be available if:
  // - It is an authenticated non-patient user
  // - The drawing tool is not disabled
  if (!allowAnnotations) {
    return renderCloseFullScreenButton;
  }
  return <>
      <_StyledView2 onLayout={handleCustomizationLayout} $_css7={localStyles.drawContainer}>
        <CpDrawingModal closeModalOnConfirm={closeModalOnConfirm} isBusy={isSaving} isOpen={isModalOpen} onClose={handleCloseModal} onCloseFullScreen={onCloseFullScreen} onExitWithoutSaving={handleExitWithouSaving} onSave={handleSaveImage} onSubmit={handlePressSave} state={modalState} />
        {isDrawing && drawingEnabled ? <React.Fragment>
            <_StyledPrButton2 accessibilityHint={formatMessage(translations.accessibility.hint.clearCanvas)} onPress={handleClearCanvas} testID="CpDrawingToolsButtonsClearCanvas" type="text" $_css8={[styles.flexSingle, localStyles.clearButton, isMediumScreen ? localStyles.buttonBigger : undefined]}>
              <_StyledView3 $_css9={[styles.center, isWideScreen ? styles.row : undefined]}>
                <_StyledPrIcon2 fill={buttonColors.text} icon={IconName.close} size={iconSizes.small} $_css10={styles.marginHorizontalSingle} />
                <_StyledPrText3 children={localTranslations.clear} $_css11={[localStyles.buttonText, localStyles.clearButtonText]} />
              </_StyledView3>
            </_StyledPrButton2>
            <DrawingToolButton busy={isSubmittingGetPresignedUrl || isSaving} icon={IconName.save} label={localTranslations.save} onPress={handlePressSave} testID="CpDrawingToolsButtonsSave" />
            <DrawingToolButton icon={IconName.undo} label={localTranslations.undo} onPress={handleUndoCanvas} />
            <DrawingToolButton icon={IconName.redo} label={localTranslations.redo} onPress={handleRedoCanvas} />
            <DrawingToolButton accessibilityHint={formatMessage(translations.accessibility.hint.palette)} icon={IconName.colorLens} label={localTranslations.palette} onPress={handleOpenCustomizationPopup} selected={customizationPopupOpen} testID="CpDrawingToolsButtonsPalette" />
            <DrawingToolButton accessibilityHint={drawingMode === DrawingMode.Pen ? formatMessage(translations.accessibility.hint.eraser) : formatMessage(translations.accessibility.hint.eraserDisable)} icon={IconName.eraser} label={localTranslations.eraser} onPress={drawingMode === DrawingMode.Pen ? handleEnableEraserMode : handleEnablePenMode} selected={drawingMode === DrawingMode.Eraser} />
            {/* Popup with the color palette and the stroke weight picker */}
            <CpDrawingStrokeOptions layout={customizationLayout} onClose={handleCloseCustomizationPopup} open={customizationPopupOpen} />
          </React.Fragment> : null}
        <_StyledPrButton3 accessibilityHint={isDrawing ? formatMessage(translations.accessibility.hint.drawDisable) : formatMessage(translations.accessibility.hint.draw)} onPress={handlePressDrawing} testID={isDrawing ? "CpDrawingToolsButtonsDraw-selected" : "CpDrawingToolsButtonsDraw"} type="text" $_css12={[styles.flexSingle, localStyles.button, localStyles.drawButton, isMediumScreen ? localStyles.buttonBigger : undefined, isDrawing ? [localStyles.selected, localStyles.drawButtonOpen] : undefined]}>
          <_StyledView4 $_css13={[styles.center, isWideScreen ? styles.row : undefined]}>
            <_StyledPrIcon3 fill={isDrawing ? buttonColors.text : buttonColors.primary} icon={IconName.brush} size={iconSizes.small} $_css14={styles.marginHorizontalSingle} />
            <_StyledPrText4 children={translations.buttons.draw} $_css15={[localStyles.buttonText, isDrawing ? localStyles.selectedText : undefined]} />
          </_StyledView4>
        </_StyledPrButton3>
      </_StyledView2>
      {renderCloseFullScreenButton}
    </>;
};
export default CpDrawingToolsButtons;
var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpDrawingToolsButtons___StyledPrButton",
  componentId: "sc-1eml9kq-0"
})(["", ""], p => p.$_css);
var _StyledView = _styled(View).withConfig({
  displayName: "CpDrawingToolsButtons___StyledView",
  componentId: "sc-1eml9kq-1"
})(["", ""], p => p.$_css2);
var _StyledPrIcon = _styled(PrIcon).withConfig({
  displayName: "CpDrawingToolsButtons___StyledPrIcon",
  componentId: "sc-1eml9kq-2"
})(["", ""], p => p.$_css3);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpDrawingToolsButtons___StyledPrText",
  componentId: "sc-1eml9kq-3"
})(["", ""], p => p.$_css4);
var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpDrawingToolsButtons___StyledPrPressable",
  componentId: "sc-1eml9kq-4"
})(["", ""], p => p.$_css5);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpDrawingToolsButtons___StyledPrText2",
  componentId: "sc-1eml9kq-5"
})(["", ""], p => p.$_css6);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CpDrawingToolsButtons___StyledView2",
  componentId: "sc-1eml9kq-6"
})(["", ""], p => p.$_css7);
var _StyledPrButton2 = _styled(PrButton).withConfig({
  displayName: "CpDrawingToolsButtons___StyledPrButton2",
  componentId: "sc-1eml9kq-7"
})(["", ""], p => p.$_css8);
var _StyledView3 = _styled(View).withConfig({
  displayName: "CpDrawingToolsButtons___StyledView3",
  componentId: "sc-1eml9kq-8"
})(["", ""], p => p.$_css9);
var _StyledPrIcon2 = _styled(PrIcon).withConfig({
  displayName: "CpDrawingToolsButtons___StyledPrIcon2",
  componentId: "sc-1eml9kq-9"
})(["", ""], p => p.$_css10);
var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpDrawingToolsButtons___StyledPrText3",
  componentId: "sc-1eml9kq-10"
})(["", ""], p => p.$_css11);
var _StyledPrButton3 = _styled(PrButton).withConfig({
  displayName: "CpDrawingToolsButtons___StyledPrButton3",
  componentId: "sc-1eml9kq-11"
})(["", ""], p => p.$_css12);
var _StyledView4 = _styled(View).withConfig({
  displayName: "CpDrawingToolsButtons___StyledView4",
  componentId: "sc-1eml9kq-12"
})(["", ""], p => p.$_css13);
var _StyledPrIcon3 = _styled(PrIcon).withConfig({
  displayName: "CpDrawingToolsButtons___StyledPrIcon3",
  componentId: "sc-1eml9kq-13"
})(["", ""], p => p.$_css14);
var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpDrawingToolsButtons___StyledPrText4",
  componentId: "sc-1eml9kq-14"
})(["", ""], p => p.$_css15);