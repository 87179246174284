import _styled from "styled-components";
import React from "react";
import { SafeAreaView } from "react-native";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CtVerifyEmail from "components/containers/CtVerifyEmail";
import { styles } from "services/Theme";
import translations from "translations";
/**
 * Screen for new users to create their own account from the provider app
 */
const ScVerifyEmail = ({
  route
}) => {
  const {
    params
  } = route;
  const {
    confirmEmailToken
  } = params;
  return <_StyledSafeAreaView $_css={[styles.screen, styles.flexSingle]}>
      <CpUpdatePageTitle title={translations.screens[route.name]} />
      <CtVerifyEmail confirmEmailToken={confirmEmailToken} />
    </_StyledSafeAreaView>;
};
export default ScVerifyEmail;
var _StyledSafeAreaView = _styled(SafeAreaView).withConfig({
  displayName: "ScVerifyEmail___StyledSafeAreaView",
  componentId: "sc-19f5s4n-0"
})(["", ""], p => p.$_css);