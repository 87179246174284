import _styled from "styled-components";
import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { View } from "react-native";
import CpFormRadioSegmented from "components/form/CpFormRadioSegmented";
import CpFormSelect from "components/form/CpFormSelect";
import CpFormTextInput from "components/form/CpFormTextInput";
import { FormNecessity } from "components/form/types";
import PrText from "components/primitives/PrText";
import { backendSupportedLocaleNames, backendSupportedLocales, useBackendSupportedLocale, useMapOptionsTranslations } from "services/Intl";
import { css } from "services/Theme";
import translations from "translations";
const localTranslations = defineMessages({
  firstName: {
    id: "seqE6B",
    defaultMessage: "First name"
  },
  lastName: {
    id: "9bjoOZ",
    defaultMessage: "Last name"
  },
  nameText: {
    id: "zmrpa1",
    defaultMessage: "Your name as it will be seen by patients."
  },
  otherPrefixes: {
    id: "+r+aW9",
    defaultMessage: "Sir, Ma'am, Esq."
  },
  preferredLanguage: {
    id: "xCJFRq",
    defaultMessage: "Preferred Language"
  },
  prefix: {
    id: "poSMXL",
    defaultMessage: "Prefix"
  }
});
const localStyles = {
  nameText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    padding-bottom: 0;
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  nameTextEdit: css`
    padding-left: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  textInputWrapper: css`
    margin-bottom: 0;
  `
};
/**
 * The Personal Profile Form
 */
const CpPersonalProfileForm = ({
  readonly
}) => {
  const {
    formatMessage
  } = useIntl();
  const locale = useBackendSupportedLocale();
  const formContext = useFormContext();
  const {
    watch
  } = formContext;
  const prefixInput = watch("prefix");
  const prefixes = useMapOptionsTranslations("prefixes", {
    valueAsTranslation: true
  });
  const localeOptions = useMemo(() => {
    return backendSupportedLocales.map(mappedLocale => {
      return {
        name: backendSupportedLocaleNames[mappedLocale],
        value: mappedLocale
      };
    });
  }, []);
  return <>
      <CpFormRadioSegmented defaultValue={locale} label={localTranslations.preferredLanguage} name="preferredLocale" radioOptions={localeOptions} readonly={readonly} />
      <View>
        <_StyledPrText children={formatMessage(localTranslations.nameText)} $_css={[localStyles.nameText, readonly ? undefined : localStyles.nameTextEdit]} />
      </View>
      <CpFormSelect label={localTranslations.prefix} name="prefix" necessity={FormNecessity.Optional} placeholder={formatMessage(translations.labels.selectOne)} readonly={readonly} selectOptions={prefixes} />
      {prefixInput === formatMessage(translations.prefixes.other) ? <CpFormTextInput accessibilityLabel={formatMessage(translations.labels.otherPrefix)} label={translations.labels.otherPrefix} name="otherPrefix" necessity={FormNecessity.Optional} placeholder={formatMessage(translations.labels.examplePlaceholder, {
      name: formatMessage(localTranslations.otherPrefixes)
    })} readonly={readonly} testID="CpPersonalProfileFormOtherPrefix" wrapperStyle={localStyles.textInputWrapper} /> : null}
      <CpFormTextInput accessibilityLabel={formatMessage(localTranslations.firstName)} label={localTranslations.firstName} name="firstName" placeholder={formatMessage(translations.labels.examplePlaceholder, {
      name: "Cristina"
    })} readonly={readonly} testID="CpPersonalProfileFormFirstName" wrapperStyle={localStyles.textInputWrapper} />
      <CpFormTextInput accessibilityLabel={formatMessage(localTranslations.lastName)} label={localTranslations.lastName} name="lastName" placeholder={formatMessage(translations.labels.examplePlaceholder, {
      name: "Yang"
    })} readonly={readonly} testID="CpPersonalProfileFormLastName" />
    </>;
};
export default CpPersonalProfileForm;
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpPersonalProfileForm___StyledPrText",
  componentId: "sc-a0t79p-0"
})(["", ""], p => p.$_css);