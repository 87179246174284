import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback } from "react";
import { ScrollView, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import CpAppFooter from "components/app/CpAppFooter";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpBackgroundHeader from "components/common/CpBackgroundHeader";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { useIntl } from "react-intl";
import { css, rnStyles, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  linkText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("xlarge")};
  `,
  linksContainer: css`
    padding-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  manageAccountContainer: css`
    min-height: 90vh;
    padding-vertical: ${({
    theme
  }) => theme.spacing.double}px;
  `
};
/**
 * Account Management menu screen containing links to an authenticated users different settings pages
 */
const ScManageAccount = ({
  navigation,
  route
}) => {
  const {
    contentMinHeight,
    isNarrowScreen
  } = useScreenLayout();
  const {
    formatMessage
  } = useIntl();
  const handleProfileDetailsPress = useCallback(() => navigation.navigate(Routes.profile_details), [navigation]);
  const handleChangePasswordPress = useCallback(() => navigation.navigate(Routes.change_password), [navigation]);
  const handleChangeAccountEmailPress = useCallback(() => navigation.navigate(Routes.change_account_email), [navigation]);
  return <_StyledSafeAreaView $_css={[styles.flexSingle, styles.screen]}>
      <CpUpdatePageTitle title={translations.screens[route.name]} />
      <ScrollView contentContainerStyle={rnStyles.flexSingle}>
        <CpBackgroundHeader header={translations.screens.manage_account}>
          <_StyledView $_css2={[localStyles.manageAccountContainer, !isNarrowScreen ? styles.limitedLargeScreenWidth : undefined, css`
                min-height: ${contentMinHeight}px;
              `]}>
            <_StyledView2 $_css3={localStyles.linksContainer}>
              <PrPressable accessibilityHint={formatMessage(translations.accessibility.hint.navigatesTo, {
              pageName: formatMessage(translations.screens.profile_details)
            })} accessibilityRole="link" onPress={handleProfileDetailsPress} testID="ScManageAccountProfileDetails">
                <_StyledPrText children={formatMessage(translations.screens.profile_details)} $_css4={localStyles.linkText} />
              </PrPressable>
              <PrPressable accessibilityHint={formatMessage(translations.accessibility.hint.navigatesTo, {
              pageName: formatMessage(translations.screens.change_account_email)
            })} accessibilityRole="link" onPress={handleChangeAccountEmailPress} testID="ScManageAccountChangeAccountEmail">
                <_StyledPrText2 children={formatMessage(translations.screens.change_account_email)} $_css5={localStyles.linkText} />
              </PrPressable>
              <PrPressable accessibilityHint={formatMessage(translations.accessibility.hint.navigatesTo, {
              pageName: formatMessage(translations.screens.change_password)
            })} accessibilityRole="link" onPress={handleChangePasswordPress} testID="ScManageAccountChangePassword">
                <_StyledPrText3 children={formatMessage(translations.screens.change_password)} $_css6={localStyles.linkText} />
              </PrPressable>
            </_StyledView2>
          </_StyledView>
          <CpAppFooter />
        </CpBackgroundHeader>
      </ScrollView>
    </_StyledSafeAreaView>;
};
export default ScManageAccount;
var _StyledSafeAreaView = _styled(SafeAreaView).withConfig({
  displayName: "ScManageAccount___StyledSafeAreaView",
  componentId: "sc-1of48ay-0"
})(["", ""], p => p.$_css);
var _StyledView = _styled(View).withConfig({
  displayName: "ScManageAccount___StyledView",
  componentId: "sc-1of48ay-1"
})(["", ""], p => p.$_css2);
var _StyledView2 = _styled(View).withConfig({
  displayName: "ScManageAccount___StyledView2",
  componentId: "sc-1of48ay-2"
})(["", ""], p => p.$_css3);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "ScManageAccount___StyledPrText",
  componentId: "sc-1of48ay-3"
})(["", ""], p => p.$_css4);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "ScManageAccount___StyledPrText2",
  componentId: "sc-1of48ay-4"
})(["", ""], p => p.$_css5);
var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "ScManageAccount___StyledPrText3",
  componentId: "sc-1of48ay-5"
})(["", ""], p => p.$_css6);