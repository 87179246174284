import { useNavigation } from "@react-navigation/native";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import CpHeaderButton from "components/common/CpHeaderButton";
import { ActionButtonUserType } from "components/primitives/PrActionButton/PrActionButton";
import { IconName } from "components/primitives/PrIcon";
import Routes from "navigators/Routes";
import translations from "translations";

/**
 * Renders a button for the user to go to the help/training guides screen.
 */
const HelpButton = ({
  type = ActionButtonUserType.NavHeader
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    navigate
  } = useNavigation();
  const handleRegionNavigation = useCallback(() => {
    navigate(Routes.help);
  }, [navigate]);
  return <CpHeaderButton accessibilityHint={formatMessage(translations.accessibility.hint.navigatesTo, {
    pageName: formatMessage(translations.screens.help)
  })} icon={IconName.iconFaqCircle} label={formatMessage(translations.screens.help)} onPress={handleRegionNavigation} shortLabel={formatMessage(translations.labels.help)} testID="HelpButton" type={type} />;
};
export default HelpButton;