import _styled2 from "styled-components";
import _styled from "styled-components";
import React from "react";
import { ActionButtonUserType } from "components/primitives/PrActionButton/PrActionButton";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import { styles } from "services/Theme";
/**
 * Buttons to handle opening the PackageSession modal from either the navHeader or the account menu
 */
const CpTextCallToActionButton = ({
  text,
  type = ActionButtonUserType.NavHeader,
  ...pressableProps
}) => {
  if (type === ActionButtonUserType.AccountMenu) {
    return null;
  }
  return (
    // CTA for practitioners to go to provider app
    <_StyledPrPressable {...pressableProps} accessibilityRole="link" $_css={styles.navHeaderStyles.callToActionButton}>
      <_StyledPrText children={text} numberOfLines={3} $_css2={styles.navHeaderStyles.callToActionText} />
    </_StyledPrPressable>
  );
};
export default CpTextCallToActionButton;
var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpTextCallToActionButton___StyledPrPressable",
  componentId: "sc-1aize8s-0"
})(["", ""], p => p.$_css);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpTextCallToActionButton___StyledPrText",
  componentId: "sc-1aize8s-1"
})(["", ""], p => p.$_css2);