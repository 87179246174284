import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { View } from "react-native";
import CpReadonlyInput from "components/common/CpReadonlyInput";
import CpFormInputError from "components/form/CpFormInputError";
import { FormNecessity } from "components/form/types";
import PrSelectMulti from "components/primitives/PrSelectMulti";
import PrSelectSingle from "components/primitives/PrSelectSingle";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import { useFormLabel } from "hooks/useFormLabel";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  container: css`
    background-color: ${({
    theme
  }) => theme.formColors.fieldBackground};
    border-color: ${({
    theme
  }) => theme.formColors.fieldBorderInactive};
    border-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    border-width: 1px;
    position: relative;
  `,
  containerFocus: css`
    border-color: ${({
    theme
  }) => theme.formColors.fieldBorderActive};
  `,
  label: css`
    color: ${({
    theme
  }) => theme.formColors.fieldLabelInactive};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    font-weight: bold;
    padding-left: ${({
    theme
  }) => theme.spacing.single}px;
    padding-top: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  labelFocus: css`
    color: ${({
    theme
  }) => theme.formColors.fieldLabelActive};
  `,
  wrapper: css`
    background-color: ${({
    theme
  }) => theme.formColors.fieldBackground};
    flex: 1;
    margin-bottom: ${({
    theme
  }) => theme.spacing.double}px;
    width: 100%;
  `
};
/**
 * A form component for select input.
 * In case you have more than one select input for the same form, use the order prop to adjust the z-index
 * So if you have a form component with 3 selects in, give them the following:
 * First: 1; Second: 2; Third: 3.
 */
const CpFormSelect = ({
  defaultValue = "",
  fallbackReadonlyValue,
  isMulti = false,
  label,
  name,
  necessity = FormNecessity.None,
  order = 0,
  placeholder,
  readonly,
  searchable = false,
  selectOptions,
  style,
  testID,
  ...otherProps
}) => {
  const formContext = useFormContext();
  const {
    formState: {
      errors
    },
    getValues,
    watch
  } = formContext;
  const error = errors[name];
  const valuePresent = getValues()[name]?.length > 0;
  const complete = valuePresent && !error;
  const currentValue = watch(name);
  const {
    formatMessage
  } = useIntl();
  const formattedLabel = useFormLabel({
    label,
    necessity
  });
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = focus => {
    setIsFocused(focus);
  };
  const {
    spacing
  } = useAppTheme();
  const [inputLayout, setInputLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0
  });
  const handleLayout = useCallback(({
    nativeEvent: {
      layout
    }
  }) => {
    setInputLayout(layout);
  }, []);

  // Following logic is to get the height of the closed dropdown and store it into a variable (inputHeight)
  // so we can set it as the min-height for the input
  const [dropdownLayout, setDropdownLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0
  });
  const [inputHeight, setInputHeight] = useState(0);
  useEffect(() => {
    if (!inputHeight) {
      setInputHeight(dropdownLayout.height);
    }
  }, [dropdownLayout.height, inputHeight, inputLayout.height, spacing.single]);
  const handleDropdownLayout = useCallback(({
    nativeEvent: {
      layout
    }
  }) => {
    setDropdownLayout(layout);
  }, []);
  const wrapperStyle = useMemo(() => {
    return {
      height: inputLayout.height
    };
  }, [inputLayout]);
  const renderSelectInput = useCallback(({
    field
  }) => {
    return isMulti ? <PrSelectMulti onChangeOption={field.onChange} onFocus={handleFocus} placeholder={placeholder} searchable={searchable} selectOptions={selectOptions} testID={testID} value={field.value} {...otherProps} /> : <PrSelectSingle onChangeOption={field.onChange} onFocus={handleFocus} placeholder={placeholder} searchable={searchable} selectOptions={selectOptions} testID={testID} value={field.value} {...otherProps} />;
  }, [isMulti, placeholder, searchable, testID, selectOptions, otherProps]);
  const currentValueName = useMemo(() => {
    if (isMulti && currentValue && !currentValue.length) {
      return formatMessage(translations.labels.none);
    }
    return isMulti ? selectOptions.filter(option => currentValue?.includes(option.value)).map(option => option.name).join("\n") : selectOptions.find(option => option.value === currentValue)?.name;
  }, [isMulti, currentValue, selectOptions, formatMessage]);
  if (readonly) {
    return <CpReadonlyInput fallbackValue={fallbackReadonlyValue} label={label} value={currentValueName} />;
  }
  return <>
      <_StyledView onLayout={handleLayout} style={wrapperStyle} $_css={localStyles.wrapper} $_css2={100 - order} $_css3={inputHeight}>
        <_StyledView2 onLayout={handleDropdownLayout} style={style} $_css4={[styles.formField, localStyles.container, isFocused ? localStyles.containerFocus : undefined]}>
          <_StyledPrText children={formattedLabel} $_css5={[localStyles.label, complete || isFocused ? localStyles.labelFocus : undefined]} />
          <Controller defaultValue={isMulti ? [] : defaultValue} name={name} render={renderSelectInput} />
        </_StyledView2>
      </_StyledView>
      {error && <CpFormInputError>{error.message}</CpFormInputError>}
    </>;
};
export default CpFormSelect;
var _StyledView = _styled(View).withConfig({
  displayName: "CpFormSelect___StyledView",
  componentId: "sc-pg8sba-0"
})(["", ";z-index:", ";min-height:", "px;"], p => p.$_css, p => p.$_css2, p => p.$_css3);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CpFormSelect___StyledView2",
  componentId: "sc-pg8sba-1"
})(["", ""], p => p.$_css4);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpFormSelect___StyledPrText",
  componentId: "sc-pg8sba-2"
})(["", ""], p => p.$_css5);