import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useMemo, useState } from "react";
import { Pressable, TextInput, View } from "react-native";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import useAppTheme from "hooks/useAppTheme";
import useAsText from "hooks/useAsText";
import { css } from "services/Theme";
import { iconSizes } from "services/Theme/common";
const localStyles = {
  clear: css`
    align-items: center;
    justify-content: center;
    padding-top: 4px;
    margin-right: 6px;
    width: ${({
    theme
  }) => theme.buttonHeights.medium}px;
  `,
  icon: css`
    padding-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  iconLast: css`
    padding-right: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  passwordPadding: css`
    padding-right: ${({
    theme
  }) =>
  // Same as below, but since password has two icons we double the values
  theme.spacing.double + (theme.spacing.double + theme.iconSizes.small * 2)}px;
  `,
  revealPassword: css`
    align-items: center;
    justify-content: center;
    padding-top: 4px;
    width: ${({
    theme
  }) => theme.buttonHeights.medium}px;
  `,
  textInput: css`
    color: ${({
    theme
  }) => theme.textColors.default};
    flex: 1;
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    outline-width: 0px;
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
    padding-left: ${({
    theme
  }) => theme.spacing.single}px;
    padding-right: ${({
    theme
  }) =>
  // Padding right for the input must take into consideration the icon size + a double theme padding
  theme.spacing.single + theme.spacing.double + theme.iconSizes.small}px;
  `
};
/**
 * Themes TextInput and extends with the ability to render TextValue as the placeholder.
 * Also adds a clear button and buttons to reveal/hide password content.
 */
const PrTextInput = ({
  forwardRef,
  onChangeText,
  onClear,
  onLayout,
  password,
  placeholder,
  placeholderTextColor,
  textContentType,
  value = "",
  ...otherProps
}) => {
  const {
    buttonColors,
    textColors
  } = useAppTheme();
  const [revealPassword, updateRevealPassword] = useState(false);

  // The layout rectangle where the search results are to be positioned
  const [inputLayout, setInputLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0
  });

  // Saves the layout of the search input for positioning the search results
  const handleInputLayout = useCallback(event => {
    const {
      nativeEvent: {
        layout
      }
    } = event;
    setInputLayout(layout);
    onLayout && onLayout(event);
  }, [onLayout]);
  const handleChangeText = useCallback(text => {
    onChangeText && onChangeText(text);
  }, [onChangeText]);
  const handleClear = useCallback(() => {
    // allows us to call a callback when we clear the input (like returning to focus the text input on a searchable dropdown)
    onClear && onClear();
    handleChangeText("");
  }, [handleChangeText, onClear]);
  const placeholderText = useAsText(placeholder); // We've constrained the placeholder types so this should be ok

  const accessoryContainerStyle = useMemo(() => {
    return {
      flexDirection: "row",
      height: inputLayout.height,
      justifyContent: "flex-end",
      left: inputLayout.x + inputLayout.width - 92,
      position: "absolute",
      top: inputLayout.y,
      width: 92
    };
  }, [inputLayout]);
  return (
    // View added as a reference for absolute positioned accessory buttons
    <>
      <_StyledTextInput {...otherProps} clearButtonMode="never" onChangeText={handleChangeText} onLayout={handleInputLayout} placeholder={placeholderText} placeholderTextColor={placeholderTextColor || textColors.textInputPlaceholder} ref={forwardRef} secureTextEntry={password && !revealPassword} textContentType={!textContentType && password ? "password" : textContentType} value={value} $_css={[localStyles.textInput, password && localStyles.passwordPadding]} />
      <View style={accessoryContainerStyle}>
        {password && <_StyledPressable onPress={() => updateRevealPassword(!revealPassword)} $_css2={localStyles.revealPassword}>
            {({
            hovered,
            pressed
          }) => <_StyledPrIcon fill={hovered || pressed ? buttonColors.primary : undefined} icon={revealPassword ? "eyeClosed" : "eyeOpen"} size={iconSizes.small} $_css3={localStyles.icon} />}
          </_StyledPressable>}
        <_StyledPressable2 onPress={handleClear} testID="PrTextInput-clear" $_css4={localStyles.clear}>
          {({
            hovered,
            pressed
          }) => {
            return <_StyledPrIcon2 fill={hovered || pressed ? buttonColors.destructive : undefined} icon={IconName.close} size={iconSizes.small} $_css5={[localStyles.icon, localStyles.iconLast]} />;
          }}
        </_StyledPressable2>
      </View>
    </>
  );
};
export default React.forwardRef((props, ref) => <PrTextInput forwardRef={ref} {...props} />);
var _StyledTextInput = _styled(TextInput).withConfig({
  displayName: "PrTextInput___StyledTextInput",
  componentId: "sc-ajerkp-0"
})(["", ""], p => p.$_css);
var _StyledPressable = _styled(Pressable).withConfig({
  displayName: "PrTextInput___StyledPressable",
  componentId: "sc-ajerkp-1"
})(["", ""], p => p.$_css2);
var _StyledPrIcon = _styled(PrIcon).withConfig({
  displayName: "PrTextInput___StyledPrIcon",
  componentId: "sc-ajerkp-2"
})(["", ""], p => p.$_css3);
var _StyledPressable2 = _styled(Pressable).withConfig({
  displayName: "PrTextInput___StyledPressable2",
  componentId: "sc-ajerkp-3"
})(["", ""], p => p.$_css4);
var _StyledPrIcon2 = _styled(PrIcon).withConfig({
  displayName: "PrTextInput___StyledPrIcon2",
  componentId: "sc-ajerkp-4"
})(["", ""], p => p.$_css5);