import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import CpAppLoading from "components/app/CpAppLoading";
import CpNavigationRedirect from "components/app/CpNavigationRedirect";
import CpPublicNavHeader from "components/app/CpPublicNavHeader";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import ScChangeRegion from "components/screens/ScChangeRegion";
import ScForgotPassword from "components/screens/ScForgotPassword";
import ScManageAccount from "components/screens/ScManageAccount";
import ScNotFound from "components/screens/ScNotFound";
import ScProfileDetails from "components/screens/ScProfileDetails";
import ScResetPassword from "components/screens/ScResetPassword";
import ScSignout from "components/screens/ScSignout";
import ScVerifyEmail from "components/screens/ScVerifyEmail";
import useImdSession from "hooks/useImdSession";
import imdLogo from "images/imdLogo.png";
import Routes from "navigators/Routes";
import createStackNavigatorWithHistory from "navigators/createStackNavigatorWithHistory";
import Config from "services/Config";
import translations from "translations";
const ScCreateAccount = React.lazy(() => import("components/screens/ScCreateAccount"));
const ScChangeAccountPassword = React.lazy(() => import("components/screens/ScChangeAccountPassword"));
const ScChangeAccountEmail = React.lazy(() => import("components/screens/ScChangeAccountEmail"));
const ScHelp = React.lazy(() => import("components/screens/ScHelp"));
const ScHome = React.lazy(() => import("components/screens/ScHome"));
const ScPolicies = React.lazy(() => import("components/screens/ScPolicies"));
const ScAccountWizard = React.lazy(() => import("components/screens/ScAccountWizard"));
const ScSelectOrganization = React.lazy(() => import("components/screens/ScSelectOrganization"));
const ScSigninProvider = React.lazy(() => import("components/screens/ScSigninProvider"));
const ScSupport = React.lazy(() => import("components/screens/ScSupport"));
const ScTermsOfUse = React.lazy(() => import("components/screens/ScTermsOfUse"));
const ScTopic = React.lazy(() => import("components/screens/ScTopic"));
const Stack = createStackNavigatorWithHistory();

/**
 * The root navigator sets up react navigation with the top-most navigator as a tab navigator
 * where the tab view is hidden on larger devices.
 */
const NvProviderRoot = () => {
  const {
    formatMessage
  } = useIntl();
  const {
    initialized,
    user
  } = useImdSession();
  const renderHeader = useCallback(stackHeaderProps => {
    return <CpPublicNavHeader {...stackHeaderProps} />;
  }, []);
  const appName = formatMessage(translations.appNames[Config.APP_NAME]);
  const screenOptions = useMemo(() => ({
    header: renderHeader,
    headerMode: "float",
    // This must be float mode, or the header moves to the end of the page on screen readers
    title: formatMessage(translations.screens.app)
  }), [formatMessage, renderHeader]);

  // Do not render the app until imd context is initialized
  if (!initialized) {
    return <CpAppLoading />;
  } else {
    return <>
        <CpUpdatePageTitle description={translations.labels.appTagline} image={`${document.location.origin}${imdLogo}`} imageAlt={translations.labels.altImdLogo} pageType="website" title={appName} />
        <CpNavigationRedirect waitForAuth={true} />
        <Stack.Navigator initialRouteName={user ? Routes.home : Routes.signin} screenOptions={screenOptions}>
          {user ? <>
              <Stack.Screen component={ScHome} name={Routes.home} options={{
            title: formatMessage(translations.screens.home)
          }} />
              <Stack.Screen component={ScAccountWizard} name={Routes.profile_wizard} options={{
            title: formatMessage(translations.screens.profile_wizard),
            headerShown: false
          }} />
              <Stack.Screen component={ScChangeAccountEmail} name={Routes.change_account_email} options={{
            title: formatMessage(translations.screens.change_account_email)
          }} />
              <Stack.Screen component={ScChangeAccountPassword} name={Routes.change_password} options={{
            title: formatMessage(translations.screens.change_password)
          }} />
              <Stack.Screen component={ScManageAccount} name={Routes.manage_account} options={{
            title: formatMessage(translations.screens.manage_account)
          }} />
              <Stack.Screen component={ScProfileDetails} name={Routes.profile_details} options={{
            title: formatMessage(translations.screens.profile_details)
          }} />
              <Stack.Screen component={ScSelectOrganization} name={Routes.change_clinic} options={{
            title: formatMessage(translations.screens.change_clinic)
          }} />
              <Stack.Screen component={ScTopic} name={Routes.topic} options={{
            title: formatMessage(translations.screens.topic)
          }} />
            </> : <>
              <Stack.Screen component={ScSigninProvider} name={Routes.home} options={{
            title: formatMessage(translations.screens.signin),
            headerShown: false
          }} />
              <Stack.Screen component={ScCreateAccount} name={Routes.create_account} options={{
            title: formatMessage(translations.screens.create_account),
            headerShown: false
          }} />
              <Stack.Screen component={ScForgotPassword} name={Routes.forgot_password} options={{
            title: formatMessage(translations.screens.forgot_password),
            headerShown: false
          }} />
              <Stack.Screen component={ScResetPassword} name={Routes.reset_password} options={{
            title: formatMessage(translations.screens.reset_password),
            headerShown: false
          }} />
              <Stack.Screen component={ScSigninProvider} name={Routes.signin} options={{
            title: formatMessage(translations.screens.signin),
            headerShown: false
          }} />
            </>}
          <Stack.Screen component={ScChangeRegion} name={Routes.change_region} options={{
          title: formatMessage(translations.screens.change_region)
        }} />
          <Stack.Screen component={ScHelp} name={Routes.help} options={{
          title: formatMessage(translations.screens.help)
        }} />
          <Stack.Screen component={ScNotFound} name={Routes.not_found} options={{
          title: formatMessage(translations.screens.not_found)
        }} />
          <Stack.Screen component={ScSupport} name={Routes.support} options={{
          title: formatMessage(translations.screens.support)
        }} />
          <Stack.Screen component={ScTermsOfUse} name={Routes.terms_of_use} options={{
          title: formatMessage(translations.screens.terms_of_use)
        }} />
          <Stack.Screen component={ScPolicies} name={Routes.policies} options={{
          title: formatMessage(translations.screens.policies)
        }} />
          <Stack.Screen component={ScVerifyEmail} name={Routes.verify_email} options={{
          title: formatMessage(translations.screens.verify_email)
        }} />
          <Stack.Screen component={ScSignout} name={Routes.signout} />
        </Stack.Navigator>
      </>;
  }
};
export default NvProviderRoot;