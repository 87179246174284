import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
/* eslint-disable react/no-unstable-nested-components */
import { useNavigation } from "@react-navigation/native";
import parse, { domToReact } from "html-react-parser";
import React, { useCallback, useEffect, useState } from "react";
import { Linking, View } from "react-native";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import { useIntl } from "react-intl";
import { css } from "services/Theme";
import translations from "translations";
const localStyles = {
  buttonContainer: css`
    margin-vertical: ${({
    theme
  }) => theme.spacing.single}px;
    max-width: 500px;
  `,
  buttonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    font-weight: normal;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.triple}px;
  `
};

/**
 * Renders a div with the provided content. Allows for more control over styling than WebView, and can render content with baked-in HTML markup.
 */
const CpDivHtmlWeb = ({
  content,
  style
}) => {
  const {
    setParams
  } = useNavigation();
  const {
    formatMessage
  } = useIntl();

  // Navigate to a resource such as a leaflet or monograph
  const handlePressResource = useCallback(resourceId => {
    setParams({
      viewResourceId: resourceId
    });
  }, [setParams]);
  const [htmlContent, setHtmlContent] = useState();

  // Parse the HTML content and render it, replacing our media type placeholders
  // with an actual button that takes you to that resource
  useEffect(() => {
    const options = {
      replace: ({
        attribs,
        children
      }) => {
        if (!attribs) {
          return;
        }
        if (attribs["data-id"] === "extra-resource") {
          return <_StyledPrButton accessibilityHint={attribs["data-accessibility-hint"] ?? ""} onPress={() => handlePressResource(attribs["data-resource"])} type="outline" $_css={localStyles.buttonContainer}>
              <_StyledPrText $_css2={localStyles.buttonText}>
                {domToReact(children, options)}
              </_StyledPrText>
            </_StyledPrButton>;
        } else if (attribs["data-id"] === "external-link") {
          return <_StyledPrButton2 accessibilityHint={formatMessage(translations.accessibility.hint.externalLink)} onPress={() => Linking.openURL(attribs["data-url"])} type="outline" $_css3={localStyles.buttonContainer}>
              <_StyledPrText2 $_css4={localStyles.buttonText}>
                {domToReact(children, options)}
              </_StyledPrText2>
            </_StyledPrButton2>;
        }
        return;
      }
    };
    setHtmlContent(parse(content, options));
  }, [content, formatMessage, handlePressResource]);
  return <View style={style}>
      {/* Forcing div to full height/width in order to get media (i.e. Videos) to fill area */}
      {/* eslint-disable-next-line react-native/no-inline-styles */}
      <div children={htmlContent} style={{
      width: "100%",
      height: "100%"
    }} />
    </View>;
};
export default React.memo(CpDivHtmlWeb);
var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpDivHtmlWeb___StyledPrButton",
  componentId: "sc-sz3zg7-0"
})(["", ""], p => p.$_css);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpDivHtmlWeb___StyledPrText",
  componentId: "sc-sz3zg7-1"
})(["", ""], p => p.$_css2);
var _StyledPrButton2 = _styled(PrButton).withConfig({
  displayName: "CpDivHtmlWeb___StyledPrButton2",
  componentId: "sc-sz3zg7-2"
})(["", ""], p => p.$_css3);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpDivHtmlWeb___StyledPrText2",
  componentId: "sc-sz3zg7-3"
})(["", ""], p => p.$_css4);