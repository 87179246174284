import _styled from "styled-components";
import { View } from "react-native";
import React from "react";
import { css, styles } from "../../services/Theme";
import useScreenLayout from "../../hooks/useScreenLayout";
import { GridItemWidths } from "../home/tabs/CpGridItem";
const localStyles = {
  box: css`
    background-color: ${({
    theme
  }) => theme.buttonColors.surface};
    border-radius: ${({
    theme
  }) => theme.borderRadii.soft}px;
    box-shadow: 0 4px 4px ${({
    theme
  }) => theme.brandColors.dropShadow};
    height: fit-content;
    overflow: hidden;
  `,
  container: {
    large: css`
      width: ${GridItemWidths.large}px;
      ${styles.marginSingle}
    `,
    small: css`
      width: ${GridItemWidths.small}px;
      ${styles.marginSingle}
    `
  }
};
const CpTopicCard = ({
  children
}) => {
  const {
    isWideScreen
  } = useScreenLayout();
  return <_StyledView $_css={[isWideScreen ? localStyles.container.large : localStyles.container.small, localStyles.box]}>
      {children}
    </_StyledView>;
};
export default CpTopicCard;
var _StyledView = _styled(View).withConfig({
  displayName: "CpTopicCard___StyledView",
  componentId: "sc-19ubp4l-0"
})(["", ""], p => p.$_css);