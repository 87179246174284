import _styled from "styled-components";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CtHelp from "components/containers/CtHelp";
import { styles } from "services/Theme";
import translations from "translations";
/**
 * The page that provides help and training for users.
 */
const ScHelp = ({
  route
}) => {
  return <_StyledSafeAreaView $_css={[styles.screen, styles.flexSingle]}>
      <CpUpdatePageTitle title={translations.screens[route.name]} />
      <CtHelp />
    </_StyledSafeAreaView>;
};
export default ScHelp;
var _StyledSafeAreaView = _styled(SafeAreaView).withConfig({
  displayName: "ScHelp___StyledSafeAreaView",
  componentId: "sc-1uhx6lf-0"
})(["", ""], p => p.$_css);