var _QlSelectOrganizationQuery;
import CtApiSuspense from "components/containers/CtApiSuspense";
import CtGraphqlNode from "components/containers/CtGraphqlNode";
import React from "react";
import { graphql } from "react-relay";
/**
 * Used to fetch the root ResourceNode along with its children to render the TOC
 */
//topicLayoutRootResourceNodeQuery
export const QlSelectOrganizationQueryRequest = _QlSelectOrganizationQuery !== void 0 ? _QlSelectOrganizationQuery : _QlSelectOrganizationQuery = require("./__generated__/QlSelectOrganizationQuery.graphql");
const QlSelectOrganization = ({
  children,
  variables
}) => {
  return <CtApiSuspense>
      <CtGraphqlNode query={QlSelectOrganizationQueryRequest} render={response => children(response)} variables={variables} />
    </CtApiSuspense>;
};
export default QlSelectOrganization;