import _styled18 from "styled-components";
import _styled17 from "styled-components";
import _styled16 from "styled-components";
import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { useNavigation } from "@react-navigation/native";
import { addMinutes, differenceInMilliseconds, minutesToMilliseconds } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { defineMessages, useIntl } from "react-intl";
import CpCountdown from "components/common/CpCountdown";
import CpFabButton from "components/common/CpFabButton";
import CpModal from "components/common/CpModal";
import CpNotification, { notificationStyles } from "components/common/CpNotification";
import CpPackageSessionDeleteTemplate from "components/patientSession/CpPackageSessionDeleteTemplate";
import CpPackageSessionReview from "components/patientSession/CpPackageSessionReview";
import CpPackageSessionSaveTemplate from "components/patientSession/CpPackageSessionSaveTemplate";
import CpPackageSessionSend from "components/patientSession/CpPackageSessionSend";
import CpPackageSessionStart from "components/patientSession/CpPackageSessionStart";
import CpPackageSessionSuccess from "components/patientSession/CpPackageSessionSuccess";
import CpPackageSessionTemplates from "components/patientSession/CpPackageSessionTemplates";
import CpPatientSessionHistory from "components/patientSession/CpPatientSessionHistory";
import CpPatientSessionInfo from "components/patientSession/CpPatientSessionInfo";
import CpPatientSessionReview from "components/patientSession/CpPatientSessionReview";
import CpPatientSessionSend from "components/patientSession/CpPatientSessionSend";
import CpPatientSessionStart from "components/patientSession/CpPatientSessionStart";
import CpPatientSessionStartFromHistory from "components/patientSession/CpPatientSessionStartFromHistory";
import CpPatientSessionSuccess from "components/patientSession/CpPatientSessionSuccess";
import { InactivitySteps, ModalSteps, PatientSessionActions } from "components/patientSession/PatientSession";
import { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useScreenLayout from "hooks/useScreenLayout";
import useSnackbar from "hooks/useSnackbar";
import Routes from "navigators/Routes";
import { AppTool } from "services/Constants";
import Log from "services/Log";
import { SnackbarIds } from "services/Snackbar";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  // Defined here vs using `styles.limitedLargeScreenWidth` as importing an equivalent style doesn't work because for some reason width remains fixed
  modalContainer: css`
    margin-horizontal: auto;
    max-width: 820px;
    padding: 0px;
  `
};
const localTranslations = defineMessages({
  clearHistoryHeader: {
    id: "/afECZ",
    defaultMessage: "For your privacy..."
  },
  clearHistoryText: {
    id: "BWpKwV",
    defaultMessage: "We've cleared your application history and sent you back Home."
  },
  countdown: {
    id: "c+AqR1",
    defaultMessage: "Estimated time before reset: {countdown}"
  },
  inactivityReminderHeader: {
    id: "VfiEKv",
    defaultMessage: "Still there?"
  },
  inactivityReminderText: {
    id: "rEuO2Z",
    defaultMessage: "Without further activity, we'll clear your application history and return you back Home."
  },
  reset: {
    id: "0Arsq4",
    defaultMessage: "I'm here!"
  }
});

/**
 * Renders the PatientSession modal for use within the provider app.
 * This is rendered at the App level in order to ensure that the modal is not reliant on components that may not exist on every experience.
 * Determines content to render based on context data and state (managed by `CxPatientSession`).
 * Renders snackbar to warn and inform when a user is inactive or been redirected home after prolonged inactivity
 */
const CtPatientSession = ({
  children,
  idleClearTimeMinutes = 2,
  idleReminderTimeMinutes = 5
}) => {
  const navigation = useNavigation();
  const {
    formatMessage
  } = useIntl();
  const {
    dispatchPatientSession,
    patientSessionData: {
      currentStep,
      inactivityTimer,
      isPackageSession,
      showSessionModal
    }
  } = usePatientSessionContext();
  const {
    addSnackbar,
    notificationWidth,
    removeSnackbar
  } = useSnackbar();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    appConfig
  } = useImdSession();
  const patientEducationTool = appConfig?.appTools?.find(tool => tool.name === AppTool.Educate);
  const patientPackagesTool = appConfig?.appTools?.find(tool => tool.name === AppTool.Packages);

  // We still want to enable tools as a default
  const isPatientEducationEnabled = patientEducationTool?.status === "active" || !patientEducationTool;
  const isPatientPackagesEnabled = patientPackagesTool?.status === "active" || !patientPackagesTool;
  const [lastActiveAt, setLastActiveAt] = useState(new Date());
  const handleActive = useCallback(() => {
    setLastActiveAt(new Date());
  }, []);
  const handleClearHistory = useCallback(() => {
    navigation.navigate(Routes.home);
    removeSnackbar(SnackbarIds.InactivityReminder);
    addSnackbar({
      container: "top-center",
      content: <CpNotification alert={true} label={formatMessage(localTranslations.clearHistoryHeader)} testID="CtPatientSession-snackbar-clearHistory" text={formatMessage(localTranslations.clearHistoryText)} />,
      id: SnackbarIds.InactivityClearHistory,
      width: notificationWidth
    });
    dispatchPatientSession({
      payload: {
        inactivityStep: InactivitySteps.Reset
      },
      type: PatientSessionActions.SetInactivity
    });
  }, [addSnackbar, dispatchPatientSession, formatMessage, navigation, notificationWidth, removeSnackbar]);
  const handleInactivityReminder = useCallback(() => {
    Log.info("CtPatientSession - Reminding user about inactivity");
    addSnackbar({
      container: "top-center",
      content: <CpNotification action={{
        label: formatMessage(localTranslations.reset),
        testID: "CtPatientSession-snackbar-warningReset",
        onPress: handleActive
      }} alert={true} content={<_StyledPrText children={formatMessage(localTranslations.countdown, {
        countdown: <_StyledCpCountdown
        // Determine current time, and add minutes to clear time - not 100% accurate but will give user a decent idea of when we'll clear history
        // NB: 'addMinutes' doesn't work well with numbers < 1
        end={addMinutes(new Date(), idleClearTimeMinutes).getTime()} $_css={styles.boldText} />
      })} $_css2={notificationStyles.text} />} label={localTranslations.inactivityReminderHeader} testID="CtPatientSession-snackbar-warning" text={localTranslations.inactivityReminderText} type="warning" />,
      dismiss: {
        // Preventing ability to click the snackbar which would clear the notification without triggering the timer reset
        click: false,
        duration: 0,
        touch: false
      },
      id: SnackbarIds.InactivityReminder,
      width: notificationWidth
    });
    dispatchPatientSession({
      payload: {
        inactivityStep: InactivitySteps.Reminded
      },
      type: PatientSessionActions.SetInactivity
    });
  }, [addSnackbar, dispatchPatientSession, formatMessage, handleActive, idleClearTimeMinutes, notificationWidth]);

  // Inactivity Monitor
  // Snackbar notification contents and callbacks
  const {
    reset,
    start
  } = useIdleTimer({
    onAction: handleActive,
    onIdle: handleClearHistory,
    onPrompt: handleInactivityReminder,
    promptTimeout: minutesToMilliseconds(idleClearTimeMinutes),
    startManually: true,
    throttle: 500,
    timeout: minutesToMilliseconds(idleReminderTimeMinutes)
  });

  // Prevent the inactivityTimer from starting unless the PatientEducationSession tool is enabled for this experience
  // Assuming PatientEducationSessions are enabled, the inactivityTimer will be set to `active` when a resource is added
  useEffect(() => {
    if (!!patientEducationTool && isPatientEducationEnabled && inactivityTimer !== InactivitySteps.Paused) {
      start();
    }
  }, [inactivityTimer, isPatientEducationEnabled, patientEducationTool, start]);

  // useIdleTimer does not seem to reset the timer when a user becomes active with the prompt open
  // so this handles that logic.
  useEffect(() => {
    if (inactivityTimer === InactivitySteps.Reminded) {
      const timeSinceActive = differenceInMilliseconds(new Date(), lastActiveAt);
      if (timeSinceActive < minutesToMilliseconds(idleReminderTimeMinutes)) {
        removeSnackbar(SnackbarIds.InactivityReminder);
        dispatchPatientSession({
          payload: {
            inactivityStep: InactivitySteps.Active
          },
          type: PatientSessionActions.SetInactivity
        });
        reset();
      }
    }
  }, [lastActiveAt, idleReminderTimeMinutes, reset, inactivityTimer, removeSnackbar, dispatchPatientSession]);

  // Rendering Stuff
  const handlePressPackageFab = useCallback(() => {
    dispatchPatientSession({
      type: PatientSessionActions.StopPackage
    });
  }, [dispatchPatientSession]);

  // PatientSession Modal callback and contents.
  // CxPatientSession reducer determines modal visibility and content to show, while the actual content component is chosen below
  const handleCloseModal = useCallback(() => {
    // Only dispatch if `showSessionModal` is true, this guards against calling it unnecessarily
    if (!showSessionModal) return;

    // If a user backgroundPresses on the success screen we want to make sure their session gets reset
    if (currentStep === ModalSteps.PackageSuccess) {
      navigation.navigate(Routes.home);
      dispatchPatientSession({
        type: PatientSessionActions.ResetPackage
      });
    } else if (currentStep === ModalSteps.SessionSuccess) {
      navigation.navigate(Routes.home);
      dispatchPatientSession({
        type: PatientSessionActions.ResetSession
      });
    } else {
      dispatchPatientSession({
        payload: {
          modalStep: ModalSteps.Close
        },
        type: PatientSessionActions.SetModal
      });
    }
  }, [currentStep, dispatchPatientSession, navigation, showSessionModal]);
  const ModalToRender = useMemo(() => {
    // Guard to ensure no modal steps can be viewed if Education and Packages are disabled
    if (!isPatientPackagesEnabled && !isPatientEducationEnabled) {
      return null;
    }
    const componentStyle = isNarrowScreen ? styles.paddingVerticalSingle : styles.paddingSingle;
    switch (currentStep) {
      case ModalSteps.Close:
        handleCloseModal();
        return null;

      // PackageSession modal screens
      case ModalSteps.PackageDelete:
        return <_StyledCpPackageSessionDeleteTemplate $_css3={componentStyle} />;
      case ModalSteps.PackageReview:
        return <_StyledCpPackageSessionReview onClose={handleCloseModal} $_css4={componentStyle} />;
      case ModalSteps.PackageSaveTemplate:
        return <_StyledCpPackageSessionSaveTemplate $_css5={componentStyle} />;
      case ModalSteps.PackageSelectTemplate:
        return <_StyledCpPackageSessionTemplates $_css6={componentStyle} />;
      case ModalSteps.PackageSend:
        return <_StyledCpPackageSessionSend onClose={handleCloseModal} $_css7={componentStyle} />;
      case ModalSteps.PackageStart:
        return <_StyledCpPackageSessionStart onClose={handleCloseModal} $_css8={componentStyle} />;
      case ModalSteps.PackageSuccess:
        return <_StyledCpPackageSessionSuccess onClose={handleCloseModal} $_css9={componentStyle} />;

      // PatientSession modal screens
      case ModalSteps.SessionHistory:
        return <_StyledCpPatientSessionHistory onClose={handleCloseModal} $_css10={componentStyle} />;
      case ModalSteps.SessionInfo:
        return <_StyledCpPatientSessionInfo $_css11={componentStyle} />;
      case ModalSteps.SessionReview:
        return <_StyledCpPatientSessionReview onClose={handleCloseModal} $_css12={componentStyle} />;
      case ModalSteps.SessionSend:
        return <_StyledCpPatientSessionSend onClose={handleCloseModal} $_css13={componentStyle} />;
      case ModalSteps.SessionStart:
        return <_StyledCpPatientSessionStart onClose={handleCloseModal} $_css14={componentStyle} />;
      case ModalSteps.SessionStartFromHistory:
        return <_StyledCpPatientSessionStartFromHistory onClose={handleCloseModal} $_css15={componentStyle} />;
      case ModalSteps.SessionSuccess:
        return <_StyledCpPatientSessionSuccess onClose={handleCloseModal} $_css16={componentStyle} />;
      default:
        return null;
    }
  }, [currentStep, handleCloseModal, isNarrowScreen, isPatientEducationEnabled, isPatientPackagesEnabled]);
  return <React.Fragment>
      {isPackageSession && <_StyledCpFabButton accessibilityLabel={formatMessage(translations.accessibility.label.stopPackage)} icon={IconName.iconPackageClosed} label={translations.buttons.stop} onPress={handlePressPackageFab} testID="CtPatientSession-stopPackageFab" $_css17={styles.fab} />}

      {children}

      {showSessionModal && <_StyledCpModal onBackgroundPress={handleCloseModal} visible={showSessionModal} $_css18={localStyles.modalContainer}>
          {ModalToRender}
        </_StyledCpModal>}
    </React.Fragment>;
};
export default CtPatientSession;
var _StyledCpCountdown = _styled(CpCountdown).withConfig({
  displayName: "CtPatientSession___StyledCpCountdown",
  componentId: "sc-qd744i-0"
})(["", ""], p => p.$_css);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CtPatientSession___StyledPrText",
  componentId: "sc-qd744i-1"
})(["", ""], p => p.$_css2);
var _StyledCpPackageSessionDeleteTemplate = _styled(CpPackageSessionDeleteTemplate).withConfig({
  displayName: "CtPatientSession___StyledCpPackageSessionDeleteTemplate",
  componentId: "sc-qd744i-2"
})(["", ""], p => p.$_css3);
var _StyledCpPackageSessionReview = _styled(CpPackageSessionReview).withConfig({
  displayName: "CtPatientSession___StyledCpPackageSessionReview",
  componentId: "sc-qd744i-3"
})(["", ""], p => p.$_css4);
var _StyledCpPackageSessionSaveTemplate = _styled(CpPackageSessionSaveTemplate).withConfig({
  displayName: "CtPatientSession___StyledCpPackageSessionSaveTemplate",
  componentId: "sc-qd744i-4"
})(["", ""], p => p.$_css5);
var _StyledCpPackageSessionTemplates = _styled(CpPackageSessionTemplates).withConfig({
  displayName: "CtPatientSession___StyledCpPackageSessionTemplates",
  componentId: "sc-qd744i-5"
})(["", ""], p => p.$_css6);
var _StyledCpPackageSessionSend = _styled(CpPackageSessionSend).withConfig({
  displayName: "CtPatientSession___StyledCpPackageSessionSend",
  componentId: "sc-qd744i-6"
})(["", ""], p => p.$_css7);
var _StyledCpPackageSessionStart = _styled(CpPackageSessionStart).withConfig({
  displayName: "CtPatientSession___StyledCpPackageSessionStart",
  componentId: "sc-qd744i-7"
})(["", ""], p => p.$_css8);
var _StyledCpPackageSessionSuccess = _styled(CpPackageSessionSuccess).withConfig({
  displayName: "CtPatientSession___StyledCpPackageSessionSuccess",
  componentId: "sc-qd744i-8"
})(["", ""], p => p.$_css9);
var _StyledCpPatientSessionHistory = _styled(CpPatientSessionHistory).withConfig({
  displayName: "CtPatientSession___StyledCpPatientSessionHistory",
  componentId: "sc-qd744i-9"
})(["", ""], p => p.$_css10);
var _StyledCpPatientSessionInfo = _styled(CpPatientSessionInfo).withConfig({
  displayName: "CtPatientSession___StyledCpPatientSessionInfo",
  componentId: "sc-qd744i-10"
})(["", ""], p => p.$_css11);
var _StyledCpPatientSessionReview = _styled(CpPatientSessionReview).withConfig({
  displayName: "CtPatientSession___StyledCpPatientSessionReview",
  componentId: "sc-qd744i-11"
})(["", ""], p => p.$_css12);
var _StyledCpPatientSessionSend = _styled(CpPatientSessionSend).withConfig({
  displayName: "CtPatientSession___StyledCpPatientSessionSend",
  componentId: "sc-qd744i-12"
})(["", ""], p => p.$_css13);
var _StyledCpPatientSessionStart = _styled(CpPatientSessionStart).withConfig({
  displayName: "CtPatientSession___StyledCpPatientSessionStart",
  componentId: "sc-qd744i-13"
})(["", ""], p => p.$_css14);
var _StyledCpPatientSessionStartFromHistory = _styled(CpPatientSessionStartFromHistory).withConfig({
  displayName: "CtPatientSession___StyledCpPatientSessionStartFromHistory",
  componentId: "sc-qd744i-14"
})(["", ""], p => p.$_css15);
var _StyledCpPatientSessionSuccess = _styled(CpPatientSessionSuccess).withConfig({
  displayName: "CtPatientSession___StyledCpPatientSessionSuccess",
  componentId: "sc-qd744i-15"
})(["", ""], p => p.$_css16);
var _StyledCpFabButton = _styled(CpFabButton).withConfig({
  displayName: "CtPatientSession___StyledCpFabButton",
  componentId: "sc-qd744i-16"
})(["", ""], p => p.$_css17);
var _StyledCpModal = _styled(CpModal).withConfig({
  displayName: "CtPatientSession___StyledCpModal",
  componentId: "sc-qd744i-17"
})(["", ""], p => p.$_css18);