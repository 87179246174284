import _styled2 from "styled-components";
import _styled from "styled-components";
import { useNetInfo } from "@react-native-community/netinfo";
import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
import { View } from "react-native";
import PrText from "components/primitives/PrText";
import { css } from "services/Theme";
const localStyles = {
  container: css`
    background-color: ${({
    theme
  }) => theme.brandColors.errorBackground};
    padding: ${({
    theme
  }) => theme.spacing.quarter}px;
  `,
  noConnectionText: css`
    color: ${({
    theme
  }) => theme.textColors.inverse};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    text-align: center;
  `
};
/**
 * Renders an offline indicator.
 */
const CpOfflineIndicator = ({
  style,
  text
}) => {
  // For some reason, this config always causes isInternetReachable to be false, even when it returns true
  // In fact even providing an empty object for the config causes it to be false
  const netInfo = useNetInfo(/*{
                             reachabilityTest: async (response) => {
                             console.log(response)
                             return response.status === 200
                             },
                             reachabilityUrl: `${Config.IMD_WEB_SERVICE_BASE_URL || "http://localhost:3000"}/healthcheck`,
                             }*/);
  if (netInfo.type === "unknown" || netInfo.isInternetReachable) return null;
  return <_StyledView style={style} $_css={localStyles.container}>
      <_StyledPrText style={style} $_css2={localStyles.noConnectionText}>
        {text || <FormattedMessage id="1J6JJZ" defaultMessage="No Internet Connection" />}
      </_StyledPrText>
    </_StyledView>;
};
export default memo(CpOfflineIndicator);
var _StyledView = _styled(View).withConfig({
  displayName: "CpOfflineIndicator___StyledView",
  componentId: "sc-172o768-0"
})(["", ""], p => p.$_css);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpOfflineIndicator___StyledPrText",
  componentId: "sc-172o768-1"
})(["", ""], p => p.$_css2);