import _styled2 from "styled-components";
import _styled from "styled-components";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { View } from "react-native";
import useAppTheme from "../../../hooks/useAppTheme";
import Routes from "../../../navigators/Routes";
import { css, styles } from "../../../services/Theme";
import translations from "../../../translations";
import buttons from "../../../translations/buttons";
import PrIcon, { IconName } from "../../primitives/PrIcon";
import PrPressable from "../../primitives/PrPressable";
import PrText from "../../primitives/PrText";
const localStyles = {
  buttonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    margin-left: ${({
    theme
  }) => theme.spacing.single}px;
  `
};
/**
 * Displays a button to navigate to a previous resource node.
 */
const CpTopicBackButton = ({
  label,
  rootRevisionId,
  selectedRevisionId,
  style
}) => {
  const navigation = useNavigation();
  const {
    brandColors,
    iconSizes
  } = useAppTheme();
  const handlePress = useCallback(() => {
    navigation.navigate(Routes.topic, {
      rootRevisionId,
      selectedRevisionId: selectedRevisionId
    });
  }, [navigation, rootRevisionId, selectedRevisionId]);
  return <PrPressable onPress={handlePress} style={style}>
      <_StyledView $_css={[styles.row, styles.center]}>
        <PrIcon fill={brandColors.primary} icon={IconName.keyboardArrowLeft} size={iconSizes.medium} />
        <_StyledPrText $_css2={localStyles.buttonText}>
          {label ? <FormattedMessage {...translations.buttons.backTo} values={{
          label: label
        }} /> : <FormattedMessage {...buttons.back} />}
        </_StyledPrText>
      </_StyledView>
    </PrPressable>;
};
export default CpTopicBackButton;
var _StyledView = _styled(View).withConfig({
  displayName: "CpTopicBackButton___StyledView",
  componentId: "sc-5bmkfq-0"
})(["", ""], p => p.$_css);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpTopicBackButton___StyledPrText",
  componentId: "sc-5bmkfq-1"
})(["", ""], p => p.$_css2);