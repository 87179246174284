import _styled from "styled-components";
import { debounce } from "debounce";
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { TextInput, View } from "react-native";
// eslint-disable-line no-restricted-imports

import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import useAppTheme from "hooks/useAppTheme";
import useAsText from "hooks/useAsText";
import { css } from "services/Theme";
import translations from "translations";
const localStyles = {
  overlay: css`
    bottom: 0px;
    left: 0px;
    position: fixed;
    right: 0px;
    top: 0px;
  `,
  searchBorder: css`
    border: 1px solid ${({
    theme
  }) => theme.brandColors.separator};
  `,
  textInput: css`
    background-color: ${({
    theme
  }) => theme.textColors.textInputSecondaryBackground};
    border-color: ${({
    theme
  }) => theme.brandColors.primary};
    border-radius: 30px;
    border-width: 0px;
    color: ${({
    theme
  }) => theme.textColors.default};
    height: 48px;
    outline-width: 0px;
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("xlarge")};
    padding-left: ${({
    theme
  }) => theme.spacing.single * 3}px;
    padding-right: ${({
    theme
  }) => theme.spacing.single * 3}px;
  `,
  textInputFocus: css`
    border-width: 1px;
  `
};
/**
 * A context search text input that presents search options for selection.
 *
 * The height and borderWidth are passed as props because they affect the
 * layout of the results menu which has to be calculated.
 */
const CpSearchBar = React.forwardRef(({
  onFocus,
  onInputLayout,
  onQueryChanged,
  placeholder,
  placeholderTextColor,
  style,
  textInputStyle
}, ref) => {
  const {
    brandColors,
    buttonColors,
    textColors
  } = useAppTheme();
  const {
    formatMessage
  } = useIntl();
  const textInputRef = useRef(null);
  const [inputSearchQuery, setInputSearchQuery] = useState("");
  const [isInFocus, setInFocus] = useState(false);
  const placeholderText = useAsText(placeholder); // We've constrained the placeholder types so this should be ok

  // Create a debounced function that updates the search after a 400ms settle time.
  const onChangeDebounced = useMemo(() => debounce(newText => {
    if (newText && newText.length > 1) {
      onQueryChanged(newText);
    } else {
      onQueryChanged(undefined);
    }
  }, 400), [onQueryChanged]);

  // Cancel any pending debounced fn if the component unmounts
  useEffect(() => onChangeDebounced.clear, [onChangeDebounced]);

  // Set the local value and call onChange
  const handleChangeText = useCallback(newValue => {
    setInputSearchQuery(newValue);
    if (newValue !== inputSearchQuery) onChangeDebounced(newValue);
  }, [inputSearchQuery, onChangeDebounced]);
  const handleBlur = useCallback(() => setInFocus(false), []);
  const handleFocus = useCallback(e => {
    setInFocus(true);
    onFocus && onFocus(e);
  }, [onFocus]);
  const handleClear = useCallback(() => {
    handleChangeText("");
  }, [handleChangeText]);

  // Pass the clear function out to the ref
  useImperativeHandle(ref, () => ({
    clear: handleClear
  }));

  // The layout rectangle where the search results are to be positioned
  const [inputLayout, setInputLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0
  });

  // Saves the layout of the search input for positioning the search results
  const handleInputLayout = useCallback(event => {
    const {
      nativeEvent: {
        layout
      }
    } = event;
    setInputLayout(layout);

    // Pass the layout up to the parent watchers
    onInputLayout?.(layout);
  }, [onInputLayout]);
  const accessoryButtonStyle = useMemo(() => ({
    alignItems: "center",
    backgroundColor: brandColors.searchPopupBorder,
    borderRadius: inputLayout.height / 2,
    height: inputLayout.height,
    justifyContent: "center",
    left: inputLayout.x + inputLayout.width - inputLayout.height,
    position: "absolute",
    top: inputLayout.y,
    width: inputLayout.height
  }), [inputLayout, brandColors]);
  const hasSearchQuery = Boolean(inputSearchQuery);
  return <View accessible accessibilityRole="search">
        {/* Must go last so that the input is above the screen overlay */}
        <_StyledTextInput accessibilityLabel={formatMessage(translations.accessibility.label.search)} clearButtonMode="never" onBlur={handleBlur} onChangeText={handleChangeText} onFocus={handleFocus} onLayout={handleInputLayout} placeholder={placeholderText} placeholderTextColor={placeholderTextColor || textColors.textInputPlaceholder} ref={textInputRef} style={style} testID="CpSearchBar" value={inputSearchQuery} $_css={[localStyles.textInput, isInFocus || inputSearchQuery.length > 0 ? localStyles.textInputFocus : localStyles.searchBorder, textInputStyle]} />

        {hasSearchQuery ? <PrPressable accessibilityLabel={formatMessage(translations.accessibility.label.searchClear)} accessibilityRole="button" focusedBackgroundColor="secondary" hoveredBackgroundColor="destructive" onPress={handleClear} style={accessoryButtonStyle}>
            <PrIcon fill={buttonColors.text} icon={IconName.close} />
          </PrPressable> : <View style={accessoryButtonStyle}>
            <PrIcon fill={buttonColors.text} icon="search" />
          </View>}
      </View>;
});
export default CpSearchBar;
var _StyledTextInput = _styled(TextInput).withConfig({
  displayName: "CpSearchBar___StyledTextInput",
  componentId: "sc-1ggmddz-0"
})(["", ""], p => p.$_css);