import { useNavigation } from "@react-navigation/native";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import CpHeaderButton from "components/common/CpHeaderButton";
import { ActionButtonUserType } from "components/primitives/PrActionButton/PrActionButton";
import { IconName } from "components/primitives/PrIcon";
import useImdSession from "hooks/useImdSession";
import Routes from "navigators/Routes";
import translations from "translations";

/**
 * Manage Account header action button
 */
const ManageAccountButton = ({
  type = ActionButtonUserType.AccountMenu
}) => {
  const {
    navigate
  } = useNavigation();
  const {
    user
  } = useImdSession();
  const {
    formatMessage
  } = useIntl();
  const userExists = Boolean(user);
  const handleManageAccountNavigation = useCallback(() => {
    navigate(Routes.manage_account);
  }, [navigate]);
  if (!userExists || user?.isPatient) {
    return null;
  }
  return <CpHeaderButton accessibilityHint={formatMessage(translations.accessibility.hint.navigatesTo, {
    pageName: formatMessage(translations.screens.manage_account)
  })} icon={IconName.cogs} label={translations.screens.manage_account} onPress={handleManageAccountNavigation} shortLabel={translations.screens.manage_account} testID={type === ActionButtonUserType.AccountMenu ? "ManageAccountButton-accountMenu" : "ManageAccountButton-header"} type={type} />;
};
export default ManageAccountButton;