import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import Color from "color";
import React, { useCallback, useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { View } from "react-native";
import Svg, { Path } from "react-native-svg";
import CpPopup from "components/common/CpPopup";
import { DisplayDirection } from "components/form/types";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrRadioSegmented from "components/primitives/PrRadioSegmented";
import PrText from "components/primitives/PrText";
import { DrawingToolsActions } from "components/topic/drawingTools/drawingTools";
import { StrokeWidthValues } from "contexts/CxDrawingTools";
import toUnhashedHex from "helpers/toUnhashedHex";
import useAppTheme from "hooks/useAppTheme";
import useDrawingToolsContext from "hooks/useDrawingToolsContext";
import { css, styles } from "services/Theme";
import { commonColors, iconSizes } from "services/Theme/common";
const PALETTE_COLORS = [commonColors.darkBlue, commonColors.blue, commonColors.green, commonColors.purple, commonColors.red, commonColors.pink, commonColors.orange, commonColors.yellow, commonColors.white, commonColors.black];
// This threshold is the minimum contrast ratio at which items require an outline or background to be distinguishable from its surroundings
// Contrast ranges from (White + white === 1) to (white + black === 21)
const CONTRAST_THRESHOLD = 3;
const localStyles = {
  colorContainer: css`
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  colorSquare: css`
    align-items: center;
    border-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    border: 1px solid ${({
    theme
  }) => theme.brandColors.separator};
    height: ${({
    theme
  }) => theme.buttonHeights.xxlarge}px;
    justify-content: center;
    margin: ${({
    theme
  }) => theme.spacing.single}px;
    width: ${({
    theme
  }) => theme.buttonHeights.xxlarge}px;
  `,
  colorSquareSelected: css`
    border: 2px solid;
  `,
  customizationModal: css`
    flex-direction: column;
    flex-wrap: wrap;
  `,
  popupContainer: css`
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
    padding-top: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  popupText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-weight: bold;
    padding-left: ${({
    theme
  }) => theme.spacing.single}px;
    padding-top: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  previewBox: css`
    border-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    justify-content: center;
    flex: 1;
    margin: ${({
    theme
  }) => theme.spacing.single}px;
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
    min-height: ${({
    theme
  }) => theme.buttonHeights.xxlarge + theme.spacing.single}px;
  `,
  strokeWeightContainer: css`
    align-items: center;
    justify-content: center;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `
};
const localTranslations = defineMessages({
  colors: {
    id: "gKEh7I",
    defaultMessage: "Colors"
  },
  heavy: {
    id: "XQioap",
    defaultMessage: "Heavy"
  },
  light: {
    id: "WTVHtF",
    defaultMessage: "Light"
  },
  medium: {
    id: "A22DPQ",
    defaultMessage: "Medium"
  },
  strokeWeight: {
    id: "8B+uiT",
    defaultMessage: "Stroke Weight"
  }
});
/**
 * Renders the stroke options (color, width) popup for the drawing.
 */
const CpDrawingStrokeOptions = ({
  layout,
  onClose,
  open
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    brandColors: {
      surface
    }
  } = useAppTheme();
  const {
    dispatchDrawingTools,
    drawingToolsData: {
      strokeColor,
      strokeWidth
    }
  } = useDrawingToolsContext();
  const strokeContrast = Color(surface).contrast(Color(strokeColor));
  const contrastedPreviewBackground = Color(strokeColor).isDark() ? Color(commonColors.white).fade(0.3).hexa() : Color(commonColors.black).fade(0.3).hexa();
  const strokeWidthOptions = useMemo(() => [{
    name: formatMessage(localTranslations.light),
    value: StrokeWidthValues.Light
  }, {
    name: formatMessage(localTranslations.medium),
    value: StrokeWidthValues.Medium
  }, {
    name: formatMessage(localTranslations.heavy),
    value: StrokeWidthValues.Heavy
  }], [formatMessage]);
  const handleChangeStrokeWidth = useCallback(newWidth => {
    dispatchDrawingTools({
      payload: {
        strokeWidth: newWidth
      },
      type: DrawingToolsActions.SetDrawingOptions
    });
  }, [dispatchDrawingTools]);
  const handleChangeColor = useCallback(color => {
    dispatchDrawingTools({
      payload: {
        strokeColor: color
      },
      type: DrawingToolsActions.SetDrawingOptions
    });
  }, [dispatchDrawingTools]);
  return <CpPopup layout={layout} onBackgroundPress={onClose} popupStyle={[styles.popupBorder, localStyles.popupContainer]} visible={open}>
      <_StyledView $_css={localStyles.customizationModal}>
        <_StyledPrText children={localTranslations.colors} $_css2={localStyles.popupText} />
        <_StyledView2 $_css3={localStyles.colorContainer}>
          {PALETTE_COLORS.map(color => {
          const isSelected = color === strokeColor;
          const colorContrast = Color(surface).contrast(Color(color));
          const contrastedBorderColor = Color(color).isDark() ? Color(commonColors.white).fade(0.65).hexa() : Color(commonColors.black).fade(0.65).hexa();
          return <_StyledPrPressable accessibilityRole="radio" accessibilityState={{
            checked: isSelected
          }} key={color} onPress={() => handleChangeColor(color)} testID={isSelected ? `CpDrawingStrokeOptionsColor-${toUnhashedHex(color)}-selected` : `CpDrawingStrokeOptionsColor-${toUnhashedHex(color)}`} $_css4={[localStyles.colorSquare, css`
                    background-color: ${color};
                  `, colorContrast < CONTRAST_THRESHOLD ? css`
                        border: 2px solid ${contrastedBorderColor};
                      ` : undefined]}>
                {isSelected &&
            // Using commonColors instead of brand/buttonColors because I dont want the colors to change when the theme changes
            <PrIcon fill={Color(color).isDark() ? commonColors.white : commonColors.black} icon={IconName.check} size={iconSizes.medium} />}
              </_StyledPrPressable>;
        })}
        </_StyledView2>
        <_StyledView3 $_css5={[localStyles.previewBox, strokeContrast < CONTRAST_THRESHOLD ? css`
                  background-color: ${contrastedPreviewBackground};
                ` : undefined]}>
          <Svg viewBox="0 0 340 50">
            <Path d="M20,15 c0,70 300,-50 300,20" fill="none" stroke={strokeColor} strokeLinecap="round" strokeWidth={strokeWidth} />
          </Svg>
        </_StyledView3>
        <_StyledPrText2 children={localTranslations.strokeWeight} $_css6={localStyles.popupText} />
        <_StyledView4 $_css7={localStyles.strokeWeightContainer}>
          <_StyledPrRadioSegmented displayDirection={DisplayDirection.Horizontal} onChangeOption={handleChangeStrokeWidth} radioOptions={strokeWidthOptions} testID="CpDrawingStrokeOptionsStrokeWidth" value={strokeWidth} $_css8={styles.fullWidth} />
        </_StyledView4>
      </_StyledView>
    </CpPopup>;
};
export default CpDrawingStrokeOptions;
var _StyledView = _styled(View).withConfig({
  displayName: "CpDrawingStrokeOptions___StyledView",
  componentId: "sc-z5si6a-0"
})(["", ""], p => p.$_css);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpDrawingStrokeOptions___StyledPrText",
  componentId: "sc-z5si6a-1"
})(["", ""], p => p.$_css2);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CpDrawingStrokeOptions___StyledView2",
  componentId: "sc-z5si6a-2"
})(["", ""], p => p.$_css3);
var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpDrawingStrokeOptions___StyledPrPressable",
  componentId: "sc-z5si6a-3"
})(["", ""], p => p.$_css4);
var _StyledView3 = _styled(View).withConfig({
  displayName: "CpDrawingStrokeOptions___StyledView3",
  componentId: "sc-z5si6a-4"
})(["", ""], p => p.$_css5);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpDrawingStrokeOptions___StyledPrText2",
  componentId: "sc-z5si6a-5"
})(["", ""], p => p.$_css6);
var _StyledView4 = _styled(View).withConfig({
  displayName: "CpDrawingStrokeOptions___StyledView4",
  componentId: "sc-z5si6a-6"
})(["", ""], p => p.$_css7);
var _StyledPrRadioSegmented = _styled(PrRadioSegmented).withConfig({
  displayName: "CpDrawingStrokeOptions___StyledPrRadioSegmented",
  componentId: "sc-z5si6a-7"
})(["", ""], p => p.$_css8);