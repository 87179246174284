import _styled21 from "styled-components";
import _styled20 from "styled-components";
import _styled19 from "styled-components";
import _styled18 from "styled-components";
import _styled17 from "styled-components";
import _styled16 from "styled-components";
import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ScrollView, View } from "react-native";
import CpError from "components/common/CpError";
import CpPatientSessionReviewSelect from "components/patientSession/CpPatientSessionReviewSelect";
import CpPatientSessionStats from "components/patientSession/CpPatientSessionStats";
import CpSessionProgress from "components/patientSession/CpSessionProgress";
import { ModalSteps, PatientSessionActions } from "components/patientSession/PatientSession";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import useAnalytics from "hooks/useAnalytics";
import useAppTheme from "hooks/useAppTheme";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import QlPatientSession from "query/QlPatientSession";
import { GoogleAnalyticsDimension } from "services/GoogleAnalytics";
import { getNameVariant } from "services/Graphql";
import { useBackendSupportedLocale } from "services/Intl";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  buttonContainer: css`
    align-items: center;
    padding-vertical: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  disclaimerContainer: css`
    background-color: ${({
    theme
  }) => theme.brandColors.background};
    border: 1px solid ${({
    theme
  }) => theme.brandColors.separator};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  disclaimerText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `,
  historySelectingLabel: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-weight: bold;
  `,
  marginRightDouble: css`
    margin-right: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  subtext: css`
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    padding-bottom: ${({
    theme
  }) => theme.spacing.half}px;
    padding-top: ${({
    theme
  }) => theme.spacing.single}px;
  `
};
/**
 * Renders the list of 'selectedResources' for approval by the user to send to a patient.
 */
const CpPatientSessionReview = ({
  onClose,
  style
}) => {
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    organization,
    user
  } = useImdSession();
  const navigation = useNavigation();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const locale = useBackendSupportedLocale();
  const {
    formatMessage
  } = useIntl();
  const {
    buttonColors,
    iconSizes
  } = useAppTheme();
  const {
    dispatchPatientSession,
    patientSessionData: {
      isPatientSession,
      selectedResources
    }
  } = usePatientSessionContext();
  const [reviewError, setReviewError] = useState();
  const queryVariables = useMemo(() => ({
    locale,
    revisionIds: selectedResources.map(resource => resource.revisionId)
  }), [locale, selectedResources]);
  useEffect(() => {
    if (reviewError && selectedResources.some(resource => resource.selected)) {
      // Reset error if we select something
      setReviewError(null);
    }
  }, [reviewError, selectedResources]);
  const handleReturnToHistory = useCallback(() => {
    dispatchPatientSession({
      payload: {
        modalStep: ModalSteps.SessionHistory,
        selectedResources: []
      },
      type: PatientSessionActions.SetSelectedResources
    });
  }, [dispatchPatientSession]);
  const handlePatientSessionInfo = useCallback(() => {
    dispatchPatientSession({
      payload: {
        modalStep: ModalSteps.SessionInfo
      },
      type: PatientSessionActions.SetModal
    });
  }, [dispatchPatientSession]);
  const handleClearAndReturn = useCallback(() => {
    // RESET_SESSION will close the modal for us
    dispatchPatientSession({
      type: PatientSessionActions.ResetSession
    });
    trackGoogleEvent({
      action: "Cleared",
      name: "patientSession",
      options: {
        dimensions: {
          [GoogleAnalyticsDimension.organizationId]: organization?._id,
          [GoogleAnalyticsDimension.practitionerType]: user?.practitionerProfile?.type || undefined
        }
      }
    });
    navigation.navigate(Routes.home);
  }, [dispatchPatientSession, navigation, organization?._id, trackGoogleEvent, user]);
  const handleConfirmSelection = useCallback(() => {
    if (selectedResources.every(resource => !resource.selected)) {
      // Nothing is selected, so we can't let the user proceed
      setReviewError(translations.errors.nothingSelected);
    } else {
      dispatchPatientSession({
        payload: {
          modalStep: ModalSteps.SessionSend
        },
        type: PatientSessionActions.SetModal
      });
    }
  }, [dispatchPatientSession, selectedResources]);
  const handleBack = useCallback(() => {
    dispatchPatientSession({
      payload: {
        modalStep: isPatientSession ? ModalSteps.SessionStart : ModalSteps.SessionHistory
      },
      type: PatientSessionActions.SetModal
    });
  }, [dispatchPatientSession, isPatientSession]);
  return <QlPatientSession variables={queryVariables}>
      {response => {
      if (response.resourceNodesByRevision?.nodes) {
        return <_StyledScrollView style={style} $_css={styles.sessionStyles.scrollView}>
              <CpSessionProgress accessibilityLabel={formatMessage(translations.accessibility.label.progressPatientSession)} accessibilityValue={{
            text: formatMessage(translations.labels.review)
          }}
          // PatientSessions do not have a History step, account for that here
          completedSteps={isPatientSession ? 0 : 1} showStart={!isPatientSession} totalSteps={isPatientSession ? 1 : 2} />
              <_StyledPrText accessibilityRole="header" $_css2={[styles.modalHeaderText, styles.paddingHorizontalSingle]}>
                <FormattedMessage id="uou2Vt" defaultMessage="Review Session" />
              </_StyledPrText>
              <CpPatientSessionStats />
              <_StyledView $_css3={styles.paddingHorizontalSingle}>
                <View>
                  {!isPatientSession && <>
                      <_StyledPrText2 $_css4={localStyles.subtext}>
                        <FormattedMessage id="NE7FEt" defaultMessage="The session length and resource count is based on the following selected resource from your session history. Click to select a different start point." />
                      </_StyledPrText2>
                      <PrButton onPress={handleReturnToHistory} testID="CpPatientSessionReview-reselect" type="text">
                        <_StyledPrText3 children={getNameVariant(response.resourceNodesByRevision?.nodes[0])} $_css5={styles.sessionStyles.buttonText} />
                      </PrButton>
                    </>}
                </View>

                {/* PatientSession Topic/Resource Selections */}
                <_StyledPrText4 $_css6={styles.paddingVerticalDouble}>
                  <FormattedMessage id="m4MHIJ" defaultMessage="Select the resources to share with your patient from the history below." />
                </_StyledPrText4>
                <_StyledPrButton onPress={handlePatientSessionInfo} testID="CpPatientSessionReview-info" type="text" $_css7={styles.rowAndCenter}>
                  <_StyledPrText5 $_css8={styles.sessionStyles.buttonText}>
                    <FormattedMessage id="vy59mS" defaultMessage="What am I sending, exactly?" />
                  </_StyledPrText5>
                  <_StyledPrIcon fill={buttonColors.primary} icon={IconName.info} size={iconSizes.small} $_css9={styles.alignSelf} />
                </_StyledPrButton>
                <CpPatientSessionReviewSelect nodes={response.resourceNodesByRevision?.nodes} />

                <_StyledCpError error={reviewError} $_css10={styles.marginTopDouble} />

                {/* Buttons */}
                <_StyledView2 $_css11={[localStyles.buttonContainer, !isNarrowScreen ? styles.sessionStyles.buttonContainerLarge : undefined]}>
                  <_StyledView3 $_css12={isNarrowScreen ? styles.fullWidth : [styles.rowAndCenter, localStyles.marginRightDouble]}>
                    <_StyledPrButton2 onPress={handleConfirmSelection} testID="CpPatientSessionReview-confirm" type="raised" $_css13={[styles.sessionStyles.button, isNarrowScreen ? styles.fullWidth : localStyles.marginRightDouble]}>
                      <_StyledPrText6 children={translations.buttons.next} $_css14={styles.sessionStyles.buttonText} />
                    </_StyledPrButton2>
                    <_StyledPrButton3 onPress={onClose} testID="CpPatientSessionReview-continue" type="outline" $_css15={[styles.sessionStyles.button, isNarrowScreen ? styles.fullWidth : undefined]}>
                      <_StyledPrText7 children={translations.buttons.continueSession} $_css16={styles.sessionStyles.buttonText} />
                    </_StyledPrButton3>
                  </_StyledView3>
                  <_StyledPrButton4 color="destructive" onPress={handleClearAndReturn} testID="CpPatientSessionReview-end" type="text" $_css17={[styles.sessionStyles.button, isNarrowScreen ? styles.fullWidth : undefined]}>
                    <_StyledPrText8 children={translations.buttons.clearAndReturnHome} $_css18={styles.sessionStyles.buttonText} />
                  </_StyledPrButton4>
                </_StyledView2>

                <_StyledView4 $_css19={isNarrowScreen ? styles.fullWidth : styles.flexEnd}>
                  <_StyledPrButton5 onPress={handleBack} testID="CpPackageSessionSend-back" type="outline" $_css20={styles.marginTopDouble}>
                    <_StyledPrText9 children={translations.buttons.back} $_css21={styles.sessionStyles.buttonText} />
                  </_StyledPrButton5>
                </_StyledView4>
              </_StyledView>
            </_StyledScrollView>;
      } else {
        return null;
      }
    }}
    </QlPatientSession>;
};
export default CpPatientSessionReview;
var _StyledScrollView = _styled(ScrollView).withConfig({
  displayName: "CpPatientSessionReview___StyledScrollView",
  componentId: "sc-d7rosh-0"
})(["", ""], p => p.$_css);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpPatientSessionReview___StyledPrText",
  componentId: "sc-d7rosh-1"
})(["", ""], p => p.$_css2);
var _StyledView = _styled(View).withConfig({
  displayName: "CpPatientSessionReview___StyledView",
  componentId: "sc-d7rosh-2"
})(["", ""], p => p.$_css3);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionReview___StyledPrText2",
  componentId: "sc-d7rosh-3"
})(["", ""], p => p.$_css4);
var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionReview___StyledPrText3",
  componentId: "sc-d7rosh-4"
})(["", ""], p => p.$_css5);
var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionReview___StyledPrText4",
  componentId: "sc-d7rosh-5"
})(["", ""], p => p.$_css6);
var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpPatientSessionReview___StyledPrButton",
  componentId: "sc-d7rosh-6"
})(["", ""], p => p.$_css7);
var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionReview___StyledPrText5",
  componentId: "sc-d7rosh-7"
})(["", ""], p => p.$_css8);
var _StyledPrIcon = _styled(PrIcon).withConfig({
  displayName: "CpPatientSessionReview___StyledPrIcon",
  componentId: "sc-d7rosh-8"
})(["", ""], p => p.$_css9);
var _StyledCpError = _styled(CpError).withConfig({
  displayName: "CpPatientSessionReview___StyledCpError",
  componentId: "sc-d7rosh-9"
})(["", ""], p => p.$_css10);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CpPatientSessionReview___StyledView2",
  componentId: "sc-d7rosh-10"
})(["", ""], p => p.$_css11);
var _StyledView3 = _styled(View).withConfig({
  displayName: "CpPatientSessionReview___StyledView3",
  componentId: "sc-d7rosh-11"
})(["", ""], p => p.$_css12);
var _StyledPrButton2 = _styled(PrButton).withConfig({
  displayName: "CpPatientSessionReview___StyledPrButton2",
  componentId: "sc-d7rosh-12"
})(["", ""], p => p.$_css13);
var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionReview___StyledPrText6",
  componentId: "sc-d7rosh-13"
})(["", ""], p => p.$_css14);
var _StyledPrButton3 = _styled(PrButton).withConfig({
  displayName: "CpPatientSessionReview___StyledPrButton3",
  componentId: "sc-d7rosh-14"
})(["", ""], p => p.$_css15);
var _StyledPrText7 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionReview___StyledPrText7",
  componentId: "sc-d7rosh-15"
})(["", ""], p => p.$_css16);
var _StyledPrButton4 = _styled(PrButton).withConfig({
  displayName: "CpPatientSessionReview___StyledPrButton4",
  componentId: "sc-d7rosh-16"
})(["", ""], p => p.$_css17);
var _StyledPrText8 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionReview___StyledPrText8",
  componentId: "sc-d7rosh-17"
})(["", ""], p => p.$_css18);
var _StyledView4 = _styled(View).withConfig({
  displayName: "CpPatientSessionReview___StyledView4",
  componentId: "sc-d7rosh-18"
})(["", ""], p => p.$_css19);
var _StyledPrButton5 = _styled(PrButton).withConfig({
  displayName: "CpPatientSessionReview___StyledPrButton5",
  componentId: "sc-d7rosh-19"
})(["", ""], p => p.$_css20);
var _StyledPrText9 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionReview___StyledPrText9",
  componentId: "sc-d7rosh-20"
})(["", ""], p => p.$_css21);