import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React from "react";
import { View } from "react-native";
import CpItem from "components/common/CpItem";
import PrIcon from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import useScreenLayout from "hooks/useScreenLayout";
import { getNameVariant } from "services/Graphql";
import { css, styles } from "services/Theme";
const localStyles = {
  item: css`
    border-bottom-width: 1px;
    border-color: #000;
    overflow-y: hidden;
  `,
  lastItem: css`
    border-bottom-width: 0px;
  `,
  sectionNode: css`
    background-color: ${({
    theme
  }) => theme.brandColors.nodeNavigatorPresentationSectionNode};
  `,
  sectionNodeText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-style: italic;
  `,
  selected: css`
    background-color: ${({
    theme
  }) => theme.brandColors.nodeNavigatorSelected};
  `,
  title: css`
    flex: 1;
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `
};
/**
 * An item in CpTopicPresentationTableOfContents.
 */
const CpTopicTableOfContentsPresentationItem = ({
  fill,
  icon,
  isLastItem,
  isSectionNode,
  isSelected,
  node,
  onPress,
  style
}) => {
  const {
    isWideScreen
  } = useScreenLayout();
  const {
    iconSizes
  } = useAppTheme();
  const title = getNameVariant(node) || "";
  return <_StyledCpItem onPressCallback={onPress} onPressValue={node} style={style} testID={`CpTopicTableOfContentsPresentationItem-${node.revisionId}`} $_css={[localStyles.item, isLastItem ? localStyles.lastItem : undefined, isSectionNode ? localStyles.sectionNode : undefined, isSelected ? localStyles.selected : undefined]}>
      <_StyledView $_css2={styles.rowAndCenter}>
        <_StyledPrText children={title} $_css3={[localStyles.title, isSectionNode && localStyles.sectionNodeText]} />
        {icon && <PrIcon fill={fill} icon={icon} size={isWideScreen ? iconSizes.small : iconSizes.medium} />}
      </_StyledView>
    </_StyledCpItem>;
};
export default CpTopicTableOfContentsPresentationItem;
var _StyledCpItem = _styled(CpItem).withConfig({
  displayName: "CpTopicTableOfContentsPresentationItem___StyledCpItem",
  componentId: "sc-mrzyiu-0"
})(["", ""], p => p.$_css);
var _StyledView = _styled(View).withConfig({
  displayName: "CpTopicTableOfContentsPresentationItem___StyledView",
  componentId: "sc-mrzyiu-1"
})(["", ""], p => p.$_css2);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpTopicTableOfContentsPresentationItem___StyledPrText",
  componentId: "sc-mrzyiu-2"
})(["", ""], p => p.$_css3);