import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { useNavigation } from "@react-navigation/native";
import CxContentLockedModal, { initialCxContentLockedModalData } from "contexts/CxContentLockedModal";
import { minutesToMilliseconds } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { ImageBackground, Linking, View } from "react-native";
import CpModal from "components/common/CpModal";
import CpNotification from "components/common/CpNotification";
import CpHomeSearch from "components/home/CpHomeSearch";
import CpHomeTabView from "components/home/CpHomeTabView";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import useImdSession from "hooks/useImdSession";
import useScreenLayout from "hooks/useScreenLayout";
import useSnackbar from "hooks/useSnackbar";
import Routes from "navigators/Routes";
import { SnackbarIds } from "services/Snackbar";
import { css, styles } from "services/Theme";
import translations from "translations";

// Make sure to change this if the background image changes
const backgroundImageHeight = "500px";
const noHeaderHeight = "150px";
const insecurePasswordSnackbarDuration = minutesToMilliseconds(1);
const localStyles = {
  background: css`
    background-image: ${({
    theme
  }) => theme.headerBackgroundColor};
    background-size: cover;
    height: 100%;
  `,
  buttonContainer: css`
    flex-direction: row;
    justify-content: flex-end;
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
    width: 100%;
  `,
  buttonRightSpacing: css`
    margin-right: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  imageStyle: css`
    height: ${backgroundImageHeight};
  `,
  mobileButtonSpacing: css`
    margin-top: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  search: css`
    z-index: 1; /* makes sure search popup renders over CpHomeTabView */
  `,
  tabNavigator: css`
    flex: 1;
    z-index: -1;
  `
};
const localTranslations = defineMessages({
  changePassword: {
    id: "8bG89W",
    defaultMessage: "Update password"
  },
  insecurePassword: {
    id: "trV3MD",
    defaultMessage: "Insecure Password"
  },
  updatedRequirements: {
    id: "IMqazO",
    defaultMessage: "For your security, we have updated our password requirements. Please update your account password."
  }
});

/**
 * Container that manages the layout of the home screen and nags the user if necessary
 */
const CtHome = () => {
  const {
    appConfig,
    user
  } = useImdSession();
  const {
    addSnackbar,
    notificationWidth,
    removeSnackbar
  } = useSnackbar();
  const navigation = useNavigation();
  const {
    formatMessage
  } = useIntl();
  const {
    isWideScreen
  } = useScreenLayout();
  const [showContentLockedModal, setShowContentLockedModal] = useState(initialCxContentLockedModalData);
  const openContentLockedModal = useCallback(() => {
    setShowContentLockedModal({
      visible: true
    });
  }, []);
  const closeContentLockedModal = useCallback(() => {
    setShowContentLockedModal({
      visible: false
    });
  }, []);
  const hideHeader = appConfig?.appHome?.hide;
  const navigateToChangePassword = useCallback(() => {
    removeSnackbar(SnackbarIds.InsecurePassword);
    navigation.navigate(Routes.change_password);
  }, [navigation, removeSnackbar]);
  const navigateToPractitionerApp = useCallback(() => {
    const url = "https://provider.imdhealth.app/";
    Linking.openURL(url);
    // Do we want to track this event?  If so, what kind of event is it?
  }, []);

  // Nags the user to update their password in case their current password is weak
  useEffect(() => {
    if (user && user.weakPassword) {
      addSnackbar({
        container: "top-center",
        content: <CpNotification action={{
          accessibilityHint: formatMessage(translations.accessibility.hint.navigatesTo, {
            pageName: formatMessage(translations.screens.change_password)
          }),
          accessibilityRole: "link",
          label: formatMessage(localTranslations.changePassword),
          onPress: navigateToChangePassword,
          testID: "CtHomeChangePassword"
        }} alert={true} label={formatMessage(localTranslations.insecurePassword)} text={formatMessage(localTranslations.updatedRequirements)} type="warning" />,
        dismiss: {
          click: true,
          duration: insecurePasswordSnackbarDuration,
          touch: true
        },
        id: SnackbarIds.InsecurePassword,
        width: notificationWidth
      });
    }
  }, [addSnackbar, formatMessage, navigateToChangePassword, notificationWidth, user]);
  return <CxContentLockedModal.Provider value={{
    ...showContentLockedModal,
    openContentLockedModal
  }}>
      <_StyledImageBackground accessibilityLabel="" accessibilityRole="none" imageStyle={{
      height: hideHeader ? noHeaderHeight : backgroundImageHeight
    }}
    // Previous home of the IMD icon background, but has since been disabled for all experiences
    source={{}} $_css={localStyles.background}>
        <_StyledCpHomeSearch $_css2={localStyles.search} />
        <_StyledCpHomeTabView $_css3={localStyles.tabNavigator} />
      </_StyledImageBackground>
      {/* ContentDisabled modal */}
      <_StyledCpModal onBackgroundPress={closeContentLockedModal} visible={showContentLockedModal.visible} $_css4={styles.marginHorizontalSingle}>
        <_StyledView $_css5={styles.modalContainer}>
          <_StyledPrText accessibilityRole="header" $_css6={styles.modalHeaderText}>
            <FormattedMessage id="YO8vdl" defaultMessage="Sign in Required" />
          </_StyledPrText>
          <_StyledView2 $_css7={[styles.paddingVerticalDouble]}>
            <PrText>
              <FormattedMessage id="A9+R7a" defaultMessage="We've curated this content specifically for Healthcare Professionals. To view, please sign in or create an IMD Health account. " />
            </PrText>
          </_StyledView2>
          <_StyledView3 $_css8={[isWideScreen ? localStyles.buttonContainer : undefined]}>
            <_StyledPrButton onPress={navigateToPractitionerApp} type="text" $_css9={[isWideScreen ? localStyles.buttonRightSpacing : localStyles.mobileButtonSpacing]}>
              <_StyledPrText2 children={translations.buttons.signinOrSignup} $_css10={styles.buttonText} />
            </_StyledPrButton>

            <_StyledPrButton2 onPress={closeContentLockedModal} $_css11={[isWideScreen ? undefined : localStyles.mobileButtonSpacing]}>
              <_StyledPrText3 children={translations.buttons.close} $_css12={styles.buttonText} />
            </_StyledPrButton2>
          </_StyledView3>
        </_StyledView>
      </_StyledCpModal>
    </CxContentLockedModal.Provider>;
};
export default CtHome;
var _StyledImageBackground = _styled(ImageBackground).withConfig({
  displayName: "CtHome___StyledImageBackground",
  componentId: "sc-vhjym0-0"
})(["", ""], p => p.$_css);
var _StyledCpHomeSearch = _styled(CpHomeSearch).withConfig({
  displayName: "CtHome___StyledCpHomeSearch",
  componentId: "sc-vhjym0-1"
})(["", ""], p => p.$_css2);
var _StyledCpHomeTabView = _styled(CpHomeTabView).withConfig({
  displayName: "CtHome___StyledCpHomeTabView",
  componentId: "sc-vhjym0-2"
})(["", ""], p => p.$_css3);
var _StyledCpModal = _styled(CpModal).withConfig({
  displayName: "CtHome___StyledCpModal",
  componentId: "sc-vhjym0-3"
})(["", ""], p => p.$_css4);
var _StyledView = _styled(View).withConfig({
  displayName: "CtHome___StyledView",
  componentId: "sc-vhjym0-4"
})(["", ""], p => p.$_css5);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CtHome___StyledPrText",
  componentId: "sc-vhjym0-5"
})(["", ""], p => p.$_css6);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CtHome___StyledView2",
  componentId: "sc-vhjym0-6"
})(["", ""], p => p.$_css7);
var _StyledView3 = _styled(View).withConfig({
  displayName: "CtHome___StyledView3",
  componentId: "sc-vhjym0-7"
})(["", ""], p => p.$_css8);
var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CtHome___StyledPrButton",
  componentId: "sc-vhjym0-8"
})(["", ""], p => p.$_css9);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CtHome___StyledPrText2",
  componentId: "sc-vhjym0-9"
})(["", ""], p => p.$_css10);
var _StyledPrButton2 = _styled(PrButton).withConfig({
  displayName: "CtHome___StyledPrButton2",
  componentId: "sc-vhjym0-10"
})(["", ""], p => p.$_css11);
var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CtHome___StyledPrText3",
  componentId: "sc-vhjym0-11"
})(["", ""], p => p.$_css12);