import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { ZodIssueCode } from "zod";
import Log from "services/Log";
import translations from "translations";
const {
  validations
} = translations;
/**
 * Returns a form resolver with an error map that localizes error messages.
 *
 * @example
 * const form = useForm<FormValues>({
 *   mode: "onBlur",
 *   resolver: useFormResolver(useMemo({
 *     name: zod.string().min(1)
 *     password: zod.string().min(8)
 *     custom_validator: zod.string().refine(...emailValidation())
 *     custom_validation_message: zod.string().nonEmpty({ message: formatMessage(customTranslation) })
 *   }, []))
 * })
 */
const useFormResolver = validationSchema => {
  const {
    formatMessage
  } = useIntl();
  const errorMap = useCallback((error, ctx) => {
    // If a custom message was provided, just use that
    if (error.message) return {
      message: error.message
    };
    Log.info("useFormResolver - zod error map handling", error);
    switch (error.code) {
      case ZodIssueCode.custom:
        {
          const translation = validations[error.params?.code];
          if (translation) {
            return {
              message: formatMessage(translation)
            };
          }
          error.params?.code ? Log.warn(`useFormResolver - translations.validations is missing a translation for ${error.params.code}`) : Log.warn("useFormResolver - custom error caught, but no custom error code was provided");
          break;
        }
      case ZodIssueCode.invalid_literal:
        if (error.path.includes("disclaimer")) {
          // Zod object type boolean wasn't working as intended so had to hack a way to display our desired error message
          return {
            message: formatMessage(validations.disclaimer)
          };
        }
        break;
      case ZodIssueCode.invalid_string:
        if (error.validation === "email") {
          return {
            message: formatMessage(validations.email)
          };
        }
        break;
      case ZodIssueCode.invalid_type:
        if (error.received === "undefined") {
          return {
            message: formatMessage(validations.required)
          };
        }
        break;
      case ZodIssueCode.too_small:
        return {
          message: formatMessage(validations.minLength, {
            minLength: error.minimum
          })
        };
    }
    Log.warn("useFormResolver - fell through to default error message. This shouldn't happen.");
    return {
      message: ctx.defaultError
    };
  }, [formatMessage]);
  return zodResolver(validationSchema, {
    errorMap
  });
};
export default useFormResolver;